import { createContext, React, useContext, useEffect, useState } from 'react';

const Context = createContext();

export const CategoryProvider = ({ categories, children }) => {
  // Throw Error if we don't get a nested categories object to extract categories from
  if (!categories)
    throw new Error(
      'CategoryProvider must have an object to extract categories from'
    );

  const [data, setData] = useState([]); // local state to store filtered categories

  useEffect(() => {
    setData(extractCategories(categories));
  }, [categories]);

  /**
   *
   * @param {*} nestedCategories
   */
  function extractCategories(nestedCategories) {
    const extractedCategories = nestedCategories.filter(
      (category, index, array) =>
        array.findIndex((t) => t.displayName === category.displayName) === index
    );

    return extractedCategories;
  }

  return <Context.Provider value={[data]}>{children}</Context.Provider>;
};

export const useCategories = () => {
  const context = useContext(Context);
  if (!context)
    throw new Error('useCategories must be used within a CategoryProvider');
  return context;
};
