import React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';
import { variant } from 'styled-system';

export const StyledVideo = styled('video')(
  ({ width }) =>
    css({
      position: 'relative',
      width: width ? width : 'full',
    }),
  variant({
    variants: {
      banner: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        left: 0,
        top: 0,
      },
    },
  }),
  composedStyledUtilities
);

export const Video = (props) => {
  return <StyledVideo {...props} />;
};
