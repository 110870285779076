import styled from 'styled-components';
import css from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';

export const StyledIFrame = styled('iframe').attrs({
  allow: 'autoplay; fullscreen; picture-in-picture',
})(
  (props) =>
    css({
      position: props?.position ? props.position : 'relative',
      width: props?.width ? props.width : 'full',
      height: props?.height ? props.height : 'full',
      border: 0,
    }),
  composedStyledUtilities
);

export const IFrame = ({ src, ...props }) => {
  return <StyledIFrame src={src} {...props}></StyledIFrame>;
};
