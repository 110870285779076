import { React, useState, useEffect } from 'react';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
import ReactHtmlParser from 'react-html-parser';

import {
  Box,
  Section,
  Heading,
  Flex,
  Image,
  Button,
  Select,
  Spinner,
  Text,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import { groupBy } from 'shared/utils/arrayUtils';
import { useRenewerItems } from 'shared/contexts/riverrenewer-context';

import { RenewerIssue } from './RenewerIssue';
import { RenewerSubscriptionForm } from './RenewerSubscriptionForm';
import {
  usePage
} from 'shared/contexts';

import logo from 'shared/assets/images/alex-river-renew-lockup.png';

const LatestNewsItem = ({ newsItem }) => {
  const { image } = newsItem || {};
  const [page] = usePage();
  const { pageAssets } = page || {};

  return (
    <Flex flexDirection="column" alignItems="stretch" height="full">
      <Box
        height={['22rem', null, '20rem']}
        mb={['3rem', null, '4rem']}
        bg="normal-gray-background"
        overflow={['hidden']}
      >
        {image && (
          <Box
            backgroundImage={[
              `url(${image?.sm})`,
              `url(${image?.md})`,
              `url(${image?.lg})`,
            ]}
            backgroundPosition="center"
            backgroundSize="cover"
            width={['full']}
            height={['full']}
            overflow="hidden"
          ></Box>
        )}
        <Box position={['absolute']} top={['1.25rem']} left={['1.5rem']}>
          <Image src={pageAssets?.find(asset => asset.assetTitle === 'logo').url || ''} width={'16rem'} ml={'-.5rem'} />
        </Box>
        <Heading
          as="h1"
          variant="renewer.title"
          position="absolute"
          top={['1.5rem']}
          right={['1.5rem']}
          fontSize={['3rem']}
          lineHeight={['1']}
        >
          The River
          <br />
          Renewer
        </Heading>
        <Flex
          position="absolute"
          bottom={0}
          alignItems="center"
          justifyContent="space-between"
          width="full"
          px={['2rem']}
          py={['1.25rem']}
          bg={['primary-blue-background-20']}
        >
          <Heading as="h2" variant="renewer.subtitle">
            News about what's boring and more
          </Heading>
          <Text
            ml="auto"
            color="white"
            fontSize="smplus"
            style={{ textTransform: 'uppercase' }}
          >
            {newsItem?.edition}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text fontSize={['1.25rem']}>
          {ReactHtmlParser(
            newsItem?.visibleContent?.html.replace('<p></p>', '')
          )}
        </Text>
      </Box>
      <Box mt={['1rem']}>
        <Text fontSize={['1.25rem']}>
          {ReactHtmlParser(
            newsItem?.hiddenContent?.html.replace('<p></p>', '')
          )}
        </Text>
      </Box>
    </Flex>
  );
};

export const RenewerEditions = ({ edition }) => {
  const { issues, loading, error } = useRenewerItems();
  const [editionFilter, setEditionFilter] = useState(); // currently applied filter value for edition, e.g. "Winter 2021"
  const [latestEdition, setLatestEdition] = useState(); // logically determined most recent version. Used as default display filter.
  const [renewerItems, setRenewerItems] = useState([]); // all renewer items grouped by edition
  const [displayItems, setDisplayItems] = useState([]); // individual renewer items of the edition = editionFilter

  useEffect(() => {
    if (issues && issues.all.length > 0) {
      // group the items by edition so we can display all items for a given edition
      const byGroup = groupBy(issues?.all, (i) => i.edition);

      setRenewerItems(byGroup);
      // determine the most recent edition to use as the default for display
      const latestEdition = issues?.all?.reduce((m, v, i) =>
        v.date > m.date && i ? v : m
      ).edition;

      setLatestEdition(latestEdition);
      setEditionFilter(latestEdition);
      setDisplayItems(byGroup.get(latestEdition));
    }
  }, [issues]);

  const handleEditionFilterSelected = (edition) => {
    if (edition.selection) {
      setDisplayItems(renewerItems.get(edition.selection));
    } else {
      setDisplayItems(renewerItems.get(latestEdition));
    }
    setEditionFilter(edition.selection);
  };

  const handlePage = (change) => {
    const keysArray = [...renewerItems.keys()];
    const currentIndex = keysArray.indexOf(editionFilter);
    let newIndex = currentIndex;

    if (change === 'previous') {
      if (currentIndex !== keysArray.length - 1) newIndex = currentIndex + 1;
    }

    if (change === 'next') {
      if (currentIndex > 0) {
        newIndex = currentIndex - 1;
      }
    }

    setEditionFilter(keysArray[newIndex]);
    setDisplayItems(renewerItems.get(keysArray[newIndex]));
  };

  return (
    <>
      <Section>
        <Container>
          {loading && (
            <Flex alignItems="center">
              <Spinner inline />{' '}
              <Text px={[6]}>Fetching the latest RiverRenewer...</Text>
            </Flex>
          )}
          {error && (
            <Text as="p">
              Sorry, there was an issue fetching the RiverRenewer.
            </Text>
          )}
          {!loading && !error && displayItems.length > 0 && (
            <Box
              display="grid"
              gridTemplateColumns={['1fr', null, '1fr 24rem']}
              gridAutoFlow="row"
              gridGap="3rem"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Box>
                <LatestNewsItem newsItem={displayItems && displayItems[0]} />
              </Box>

              <Box>
                <Box mb={['2.5rem']}>
                  <RenewerSubscriptionForm />
                </Box>

                <Box>
                  <Heading as="h3" variant="sidebar">
                    Past Editions
                  </Heading>
                  <Select
                    selectionHandler={handleEditionFilterSelected}
                    list={[...renewerItems.keys()]}
                    id="pastEditions"
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Container>
      </Section>

      {!loading &&
        !error &&
        displayItems.length > 0 &&
        displayItems.slice(1).map((issue) => {
          return <RenewerIssue key={issue?.id} issue={issue} />;
        })}

      {!loading && !error && displayItems.length > 0 && (
        <Section pt={['1.5rem', '1.5rem']}>
          <Container>
            <Flex justifyContent="space-between">
              <Button
                onClick={() => handlePage('previous')}
                variant="sliderControl"
              >
                <Text display="inline-flex" fontSize="2rem" mr=".5rem">
                  <IoIosArrowDropleftCircle />
                </Text>
                Previous
              </Button>
              <Button
                onClick={() => handlePage('next')}
                variant="sliderControl"
              >
                Next
                <Text display="inline-flex" fontSize="2rem" ml=".5rem">
                  <IoIosArrowDroprightCircle />
                </Text>
              </Button>
            </Flex>
          </Container>
        </Section>
      )}
    </>
  );
};
