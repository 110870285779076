import { React } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Heading, Section, Text } from 'shared/components/primitives';
import Container from 'shared/components/container/Container';

function NotFound() {
  let location = useLocation();
  return (
    <Box bg="light-gray-background">
      <Section>
        <Container>
          <Heading as="h1" variant="section">
            404 Page Not Found
          </Heading>
          <Text as="p">
            The page <code>{location.pathname}</code> could not be found.
          </Text>
        </Container>
      </Section>
    </Box>
  );
}

export default NotFound;
