import { React } from 'react';

import {
  List,
  ListItem,
  StyledNavLink,
  StyledNavExternalLink,
} from 'shared/components/primitives';
import { SubMenu, SiteMapSubMenu } from './SubMenu';

export const Menu = ({ data, submenu, variant, toggleMenu, ...props }) => {
  return (
    data &&
    data.map((item, index) => (
      <List variant={variant ? variant : 'menuList'} {...props} key={index}>
        {item.items ? (
          <SubMenu data={item} {...submenu} toggleMenu={toggleMenu} />
        ) : (
          <ListItem
            onClick={()=>toggleMenu()}
            variant={variant ? variant : 'menuList'}
            px={['2rem', null, null, 0]}
            py={['1.5rem', null, null, '1rem']}
          >
            {item.path.startsWith('https://') ? (
              <StyledNavExternalLink href={item.path}>
                {item.title}
              </StyledNavExternalLink>
            ) : (
              <StyledNavLink to={item.path} variant="mobileHeaderMenu">
                {item.title}
              </StyledNavLink>
            )}
          </ListItem>
        )}
      </List>
    ))
  );
};

export const SiteMapMenu = ({ data, submenu, variant, ...props }) => {
  return (
    data &&
    data.map((item, index) => (
      <List variant="siteMapMenu" {...props} key={index}>
        {item.items ? (
          <SiteMapSubMenu data={item} {...submenu} />
        ) : (
          <ListItem>
            {item.path.startsWith('https://') ? (
              <StyledNavExternalLink href={item.path} variant="sitemap">
                {item.title}
              </StyledNavExternalLink>
            ) : (
              <StyledNavLink to={item.path} variant="sitemap">
                {item.title}
              </StyledNavLink>
            )}
          </ListItem>
        )}
      </List>
    ))
  );
};
