import { React } from 'react';
import { FaArrowUp } from 'react-icons/fa';

import { IconButton } from 'shared/components/primitives';
import styled from 'styled-components';

/**
 * scrollTop
 *
 * Scroll the viewport back to the top
 */
const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const ScrollToTopButton = styled(IconButton)({});

const ScrollToTop = () => {
  return (
    <ScrollToTopButton
      name="scroll_to_top"
      onClick={scrollTop}
      border="none"
      borderRadius={'50%'}
      color="white"
      backgroundColor="green-background"
      fontSize={'1.25rem'}
      width={'3rem'}
      height={'3rem'}
      textAlign="center"
      justifyContent="center"
      p=".25rem"
      boxShadow="scrollToTopButton"
    >
      <FaArrowUp />
    </ScrollToTopButton>
  );
};

export default ScrollToTop;
