export const longEventDate = (date) => {
  if (!date) return;

  return new Date(date).toLocaleString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
};

export const eventTime = (date) => {
  if (!date) return;

  return new Date(date).toLocaleString('default', {
    hour: 'numeric',
    minute: '2-digit',
  });
};

export const shortEventMonth = (date) => {
  if (!date) return;

  return new Date(date).toLocaleString('default', {
    month: 'short',
  });
};

export const numericEventDay = (date) => {
  if (!date) return;

  return new Date(date).getDate();
};

export const longDate = (date) => {
  if (!date) return;

  return new Date(date).toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export const longDateUTC = (date) => {
  if (!date) return;

  return new Date(date).toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZon: 'UTC',
  });
};

/**
 * Returns an ISO DateTime string formatted for ICS files
 *
 * @param {*} date
 * @return {*}
 */
export const icsFormattedDateTime = (date) => {
  if (!date) {
    return `${new Date()
      .toISOString()
      .slice(0, -5)
      .replace(/-/g, '')
      .replace(/:/g, '')}Z`;
  } else {
    return `${new Date(date)
      .toISOString()
      .slice(0, -5)
      .replace(/-/g, '')
      .replace(/:/g, '')}Z`;
  }
};
