import { React, useState } from 'react';
import { FaBell } from 'react-icons/fa';

import { Box, Heading, IconButton, IconPillButton, Text, Spinner } from '../primitives';

import { useTrafficAlerts } from 'shared/contexts';
import { useToggle } from 'shared/hooks';
import { TrafficAlertSubscriptionModal } from '../../../trafficAlerts/TrafficAlerts';

const Notification = ({ total }) => {
  return (
    <Box
      position="absolute"
      bg="traffic-alert-background"
      top="-.25rem"
      right="-.25rem"
      width="1.25rem"
      height="1.25rem"
      pt="1px"
      borderRadius="1rem"
      textAlign="center"
    >
      <Text as="b" fontSize="xs" color="default-text">
        {total}
      </Text>
    </Box>
  );
};

export const TrafficAlertButton = ({ onClick }) => {
  let { loading, alerts, totalAlerts } = useTrafficAlerts();

  if (alerts && alerts.find(alert => alert.alertId === 'tbm')) {
    totalAlerts -= 1;
  }

  return (
    <IconButton
      position="relative"
      as="a"
      color="icon-text"
      p={['.75rem', '.5rem']}
      mr={['1rem', null, null, '1.5rem']}
      onClick={onClick}
    >
      <Text
        display={['none', 'inline-block']}
        fontSize="xs"
        fontWeight="bold"
        lineHeight="sm"
        pr={['.5rem']}
        style={{ textTransform: 'uppercase' }}
      >
        Alerts
      </Text>{' '}
      <Text display="flex" color="icon-background-light" lineHeight="sm">
        {loading ? <Spinner /> : <FaBell />}
      </Text>
      {!loading && totalAlerts ? <Notification total={totalAlerts} /> : ''}
    </IconButton>
  );
};

export const TrafficAlertSignUpButton = ({ onClick }) => {
  return (
    <IconPillButton
      as="a"
      position="relative"
      color="icon-text"
      p={['1rem', '1.25rem']}
      mr={['1rem', null, null, '1.5rem']}
      onClick={onClick}
      bg={"dark-background"}
    >
      <Text
        display={['none', 'inline-block']}
        fontSize="xs"
        fontWeight="bold"
        lineHeight="sm"
        pr={['.5rem']}
        style={{ textTransform: 'uppercase' }}
      >
        Sign Up For Alerts
      </Text>{' '}
      <Text display="flex" color="icon-background-light" lineHeight="sm">
        <FaBell />
      </Text>
    </IconPillButton>
  );
};

export const TrafficAlertMessage = ({ onClick }) => {
  let { alerts } = useTrafficAlerts();
  const { value, toggleValue } = useToggle();
  const [ openVal, setOpenVal ] = useState(false);

  alerts = alerts && alerts.filter(alert => alert.alertId?.toLowerCase() !== 'tbm');

  return (
    <Box
      display="grid"
      gridTemplateColumns={[
        '1fr',
        'repeat(2, 1fr)',
        null,
        'repeat(2, 1fr)',
      ]}
      alignItems="stretch"
      justifyContent="center"
      gridGap={['1.5rem', null, null, '2rem']}
      height="full"
      color="traffic-alert-text">
      <Box>
        <Heading
          as="h4"
          mb=".5rem"
          fontSize="sm"
          style={{ textTransform: 'uppercase' }}
        >
          Alert:{' '}
        </Heading>

        {alerts
          ? alerts.map((alert) => (
            <Text as="p" fontSize="sm" mt="0" mb=".5rem">
              {alert.message}
            </Text>
          ))
          : 'All clear!'}
      </Box>

      <Box alignSelf="center">
        <TrafficAlertSignUpButton
          onClick={
            () => setOpenVal(true)
          }
        />
      </Box>
      <TrafficAlertSubscriptionModal
        isOpen={openVal}
        closeModal={
          () => setOpenVal(false)
        }
      />
    </Box>
  );
};
