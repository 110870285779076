import { React, useEffect, useState } from 'react';

import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';
import {
  Box,
  Flex,
  Heading,
  Section,
  Button,
} from 'shared/components/primitives';
import FilterTagList from 'shared/components/tagList/FilterTagList';
import FeatureCard from 'news/FeatureCard';
import ProgramUpdateCard from 'news/programUpdates/ProgramUpdateCard';

import {
  CategoryProvider,
  ProgramUpdateProvider,
  useProgramUpdates,
} from 'shared/contexts';

function ProgramUpdatesContent() {
  const { programUpdates, latestProgramUpdate, loading, error } =
    useProgramUpdates();
  const [featuredProgramUpdate, setFeaturedProgramUpdate] = useState({});
  const [categories, setCategories] = useState([]);
  const [activeFilters, setactiveFilters] = useState([]);
  const [showCount, setShowCount] = useState(9); // temp way to handle "Load More"

  const pageTitle = 'Program Updates';

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (programUpdates) {
      setFeaturedProgramUpdate(latestProgramUpdate);
      setCategories(
        programUpdates.map((programUpdate) => programUpdate.categories).flat(2)
      );
    }
  }, [programUpdates, latestProgramUpdate]);

  const getProgamUpdatesForDisplay = () => {
    if (activeFilters.length > 0) {
      // Slicing after the first program update since it is used as the Featured Update
      return programUpdates
        .slice(1)
        .filter(
          (programUpdate) =>
            programUpdate.categories
              .flatMap((category) => category.displayName)
              .filter((programUpdate) => activeFilters.includes(programUpdate))
              .length > 0
        )
        .slice(0, showCount);
    }
    return programUpdates.slice(1, showCount);
  };

  const handleFilterChange = (category) => {
    // if activeFilters already contains the category, toggle it off, otherwise add it:
    setactiveFilters(
      activeFilters.includes(category)
        ? activeFilters.filter((i) => i !== category)
        : [...activeFilters, category]
    );
  };

  return (
    <Box bg="light-gray-background">
      <Banner pageTitle={pageTitle} />
      <BreadCrumbs />

      {featuredProgramUpdate && featuredProgramUpdate.length !== 0 && (
        <Section>
          <Container>
            <Heading variant="section">Featured Update</Heading>
            <FeatureCard
              orientation="horizontal"
              feature={featuredProgramUpdate}
              height={['auto']}
            />
          </Container>
        </Section>
      )}

      <Section>
        <Container>
          <Flex
            alignItems="flex-start"
            justifyContent="space-between"
            mb={['1.25rem']}
            flexDirection={['column', 'row']}
          >
            <Box flex={[1, null, '1 0 60%']}>
              <Heading as="h2" variant="section">
                Program Updates
              </Heading>
            </Box>

            <Box flex={[1, null, '1 0 40%']}>
              <CategoryProvider categories={categories}>
                <FilterTagList
                  filterToggleHandler={handleFilterChange}
                  limit={3}
                />
              </CategoryProvider>
            </Box>
          </Flex>
          <Box
            display="grid"
            gridTemplateColumns={[
              '1fr',
              'repeat(2, 1fr)',
              null,
              'repeat(3, 1fr)',
            ]}
            gridAutoFlow="row"
            alignItems="stretch"
            justifyContent="center"
            gridGap={['1.5rem', null, null, '2rem']}
          >
            {programUpdates &&
              getProgamUpdatesForDisplay().map((programUpdate) => (
                <ProgramUpdateCard
                  key={programUpdate.id}
                  programUpdate={programUpdate}
                />
              ))}
          </Box>
          <Flex justifyContent="center" pt="16">
            {showCount <= programUpdates?.length && (
              <Button
                variant="pill"
                onClick={() => setShowCount(showCount + 4)}
              >
                Load More
              </Button>
            )}
          </Flex>
        </Container>
      </Section>
    </Box>
  );
}

function ProgramUpdates() {
  return (
    <ProgramUpdateProvider>
      <ProgramUpdatesContent />
    </ProgramUpdateProvider>
  );
}

export default ProgramUpdates;
