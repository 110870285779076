import { gql } from '@apollo/client';

export const GET_RENEWER_ITEMS = gql`
  query GET_RENEWER_NEWS_ITEMS {
    renewerNewsItems(orderBy: date_DESC) {
      id
      edition
      createdAt
      date
      visibleContent {
        html
      }
      hiddenContent {
        html
      }
      image {
        id
        sm: url(
          transformation: {
            image: { resize: { width: 375, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        md: url(
          transformation: {
            image: { resize: { width: 640, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        lg: url(
          transformation: {
            image: { resize: { width: 768, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xl: url(
          transformation: {
            image: { resize: { width: 960, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xxl: url(
          transformation: {
            image: { resize: { width: 1280, fit: max } }
            document: { output: { format: jpg } }
          }
        )
      }
      imageCaption
      title
    }
  }
`;

export const SEND_SUBSCRIPTION = gql`
  mutation RenewerSubscription(
    $firstName: String!
    $lastName: String!
    $email: String!
    $terms: Boolean!
  ) {
    createRenewerSubscription(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        terms: $terms
      }
    ) {
      id
      firstName
      lastName
      email
      terms
    }
  }
`;
