import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  DotGroup,
} from 'pure-react-carousel';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
import { RichText } from '@graphcms/rich-text-react-renderer';

import { useCheckDevice } from 'shared/hooks';
import { usePage } from 'shared/contexts';
import {
  Box,
  Flex,
  Section,
  Image,
  Text,
  PillLink,
} from 'shared/components/primitives';

export const Highlights = () => {
  const isMobile = useCheckDevice();
  const [page] = usePage();
  const { sections } = page || {};
  const highlights = sections ? sections[1]?.blocks : [];

  const buttonStyles = {
    position: 'absolute',
    top: '50%',
    left: '2rem',
    transform: 'translateY(-50%)',
    width: '2.5rem',
    height: '2.5rem',
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    zIndex: 1,
    fontSize: '2.5rem',
    lineHeight: 1,
    color: 'white',
    '&focus': {
      border: 'none',
      outlineStyle: 'none',
      outline: 'none',
    },
  };

  return (
    <Section py={[0]} px={[0]}>
      <Flex flex="1" alignItems="stretch">
        {highlights && (
          <CarouselProvider
            className="carousel__homepage"
            isIntrinsicHeight
            totalSlides={highlights?.length}
          >
            <Flex alignItems="stretch">
              {!isMobile && (
                <ButtonBack style={buttonStyles}>
                  <IoIosArrowDropleftCircle color="#C7E1F0" />
                </ButtonBack>
              )}
              <Slider>
                {highlights?.map((slide, i) => {
                  let rgba = slide.backgroundColor?.rgba;
                  let backgroundColor = `rgba(${rgba?.r}, ${rgba?.g}, ${rgba?.b}, .9)`;

                  if(!slide.content[0] || !slide.content[1]) return <></>;

                  return (
                    <Slide index={i} key={slide.id}>
                      <Flex
                        flexDirection={['column', null, 'row']}
                        alignItems="stretch"
                        width={['100%']}
                        height={['full']}
                      >
                        <Box flex={['0 1 auto', null, 3, 4]}>
                          <RichText
                            content={slide.content[0]?.raw}
                            renderers={{
                              p: () => null,
                              img: ({ src, width, height, altText }) => (
                                <Image
                                  src={src}
                                  altText={altText}
                                  display="block"
                                  width={width}
                                  height={height}
                                  maxHeight={['18.75rem', '25rem', 'none']}
                                  style={{
                                    objectFit: 'cover',
                                  }}
                                />
                              ),
                            }}
                          />
                        </Box>
                        <Flex
                          bg={backgroundColor}
                          flex={['1 0 auto', 'auto', 4, 3]}
                          color="white-text"
                          flexDirection="column"
                        >
                          <Flex
                            alignItems="flex-start"
                            flexDirection="column"
                            justifyContent="space-between"
                            pt={['3rem', '4rem']}
                            pb={['5rem']}
                            px={['1.5rem', '5rem', '3.5rem', null, '6rem']}
                            pr={[null, null, '6.5rem']}
                          >
                            <Box mb={16}>
                              <RichText content={slide.content[1]?.raw} />
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="flex-start"
                              mt={['auto']}
                            >
                              <PillLink
                                to={slide.content[2]?.text}
                                bg="button-dark-background-color"
                              >
                                <Text color="white-text">Learn More</Text>
                              </PillLink>
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Slide>
                  );
                })}
              </Slider>
              {!isMobile && (
                <ButtonNext
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '2rem',
                    transform: 'translateY(-50%)',
                    width: '2.5rem',
                    height: '2.5rem',
                    padding: 0,
                    border: 0,
                    backgroundColor: 'transparent',
                    zIndex: 1,
                    fontSize: '2.5rem',
                    lineHeight: 1,
                    color: 'white',
                    '&focus': {
                      border: 'none',
                      outlineStyle: 'none',
                      outline: 'none',
                    },
                  }}
                >
                  <IoIosArrowDroprightCircle color="#C7E1F0" />
                </ButtonNext>
              )}
            </Flex>
            <Flex position="absolute" bottom="1.75rem" width="full">
              <Box flex={['0', null, 3, 4]}></Box>
              <Flex flex={['auto', null, 4, 3]} justifyContent="center">
                <DotGroup />
              </Flex>
            </Flex>
          </CarouselProvider>
        )}
      </Flex>
    </Section>
  );
};
