import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
import { RichText } from '@graphcms/rich-text-react-renderer';

import { Box, Flex, Section, IFrame } from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import { usePage } from 'shared/contexts';

export const PollutionProblems = () => {
  const [page] = usePage();
  const { contentItems, sections } = page || {};

  const slides = sections ? sections[0]?.blocks : [];

  return (
    <Section pt={['24', '24', '0']} background="white">
      <Flex
        display={['block', null, null, 'none']}
        flex="1"
        pr={[0, null, null, 16]}
        maxWidth={['100%']}
      >
        {contentItems && (
          <Box width="100%" paddingBottom="56.25%">
            <IFrame
              src={contentItems[1]?.text}
              position="absolute"
              width="100%"
              height="100%"
              frameBorder="0"
            />
          </Box>
        )}
      </Flex>
      <Container>
        <Flex
          alignItems={['flex-start', null, 'center']}
          flexDirection={['column', null, null, 'row']}
        >
          <Flex
            display={['none', null, null, 'flex']}
            flex="1"
            alignItems="center"
            pr={[0, null, null, 16]}
            maxWidth={['100%', null, '33.33%', '50%']}
          >
            {contentItems && (
              <Box width="100%" paddingBottom="56.25%">
                <IFrame
                  src={contentItems[1]?.text}
                  position="absolute"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                />
              </Box>
            )}
          </Flex>
          <Flex
            flex="1"
            pl={[0, null, null, '1rem']}
            pt={['3rem']}
            maxWidth={['100%', null, '66.66%', '50%']}
          >
            {slides && (
              <CarouselProvider
                isIntrinsicHeight
                totalSlides={slides.length}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <ButtonBack
                  style={{
                    color: 'white',
                    border: 0,
                    backgroundColor: 'white',
                    marginRight: '.75rem',
                  }}
                >
                  <IoIosArrowDropleftCircle color="#1890D7" fontSize="1.5rem" />
                </ButtonBack>
                <Slider>
                  {slides?.map((slide, i) => (
                    <Slide index={i} key={slide.id}>
                      <RichText content={slide.content[0]?.raw} />
                    </Slide>
                  ))}
                </Slider>
                <ButtonNext
                  style={{
                    color: 'white',
                    border: 0,
                    backgroundColor: 'white',
                  }}
                >
                  <IoIosArrowDroprightCircle
                    color="#1890D7"
                    fontSize="1.5rem"
                  />
                </ButtonNext>
              </CarouselProvider>
            )}
          </Flex>
        </Flex>
      </Container>
    </Section>
  );
};
