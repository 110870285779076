import { React, useState, useEffect } from 'react';
import {
  Box,
  Section,
  Flex,
  Text,
  Heading,
  TextLink,
  StyledEventCard,
  ExternalLink,
  Spinner,
} from 'shared/components/primitives';
import { GET_SEARCH_RESULTS } from './queries';
import { useLazyQuery } from '@apollo/client';
import Search from 'shared/components/header/Search';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';
import EventCard from 'community/events/EventCard';
import ProgramUpdateSummaryCard from 'news/programUpdates/ProgramUpdateSummaryCard';
import { Expandable } from 'shared/components/cards';
import theme from 'theme';
import { FiArrowRight, FiDownload } from 'react-icons/fi';
import { pageMap } from 'shared/routes/pageMap';
import ReactHtmlParser from 'react-html-parser';

const resultMargin = ['1.5rem', null, '3rem'];

function PageResults({ results }) {
  const map = pageMap();
  return (
    <>
      {results &&
        results.map((result, index) => {
          return (
            <Box
              key={index}
              backgroundColor="banner-background-color"
              backgroundImage={
                result.bannerImage?.url &&
                `${theme.colors['banner-gradient-blue-green']},
                            url("${result.bannerImage?.url}");`
              }
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              my={resultMargin}
              borderRadius=".75rem"
              p={[8, 16]}
            >
              <Flex
                flexDirection="column"
                textAlign="center"
                alignContent="center"
              >
                <Heading
                  as="h2"
                  color="white-text"
                  fontSize={['lg', 'xxl', null, 'xxxl']}
                >
                  {result.title}
                </Heading>
              </Flex>
              <Flex justifyContent="flex-end" mt="1rem" mr="1rem">
                <TextLink
                  to={{ pathname: map.get(result.slug) }}
                  variant="white"
                  color="white-text"
                >
                  See More
                  <Flex pl=".25rem" fontSize="sm" color="white-text">
                    <FiArrowRight />
                  </Flex>
                </TextLink>
              </Flex>
            </Box>
          );
        })}
    </>
  );
}

function RenewerNewsResults({ results }) {
  return (
    <>
      {results &&
        results.map((item, index) => {
          return (
            <StyledEventCard key={index} my={resultMargin} pr="1rem">
              <Box p={[8, 8, 16]}>
                <Expandable
                  key={item.id}
                  title={item.title}
                  image={item.image && item.image.url}
                  imageCaption={item.imageCaption}
                  visibleContent={item.visibleContent?.html}
                  hiddenContent={item.hiddenContent?.html}
                />
              </Box>
            </StyledEventCard>
          );
        })}
    </>
  );
}

function EventResults({ results }) {
  return (
    <>
      {results &&
        results.map((result, index) => {
          return <EventCard key={index} event={result} my={resultMargin} />;
        })}
    </>
  );
}

function ProgramUpdateResults({ results }) {
  return (
    <>
      {results &&
        results.map((result, index) => {
          return (
            <Box key={index} my={resultMargin}>
              <ProgramUpdateSummaryCard programUpdate={result} />
            </Box>
          );
        })}
    </>
  );
}

function DocumentResults({ results }) {
  return (
    <>
      {results &&
        results.map((result, index) => {
          return (
            <StyledEventCard key={index} my={resultMargin}>
              <Box p={'1rem'} bg="light-gray-background">
                <Text as="p" color="default-text" fontSize="med">
                  {`Document:  ${result.title}`}
                </Text>
                <Text as="p" color="#01375C" fontSize="1rem" margin="0.125rem">
                  {result.description}
                </Text>
                <Flex justifyContent="flex-end">
                  <ExternalLink
                    href={result.attachment?.url}
                    fontSize=".625rem"
                    fontWeight="bold"
                  >
                    DOWNLOAD
                    <FiDownload
                      style={{
                        color: '#1890D7',
                        width: '1.25rem',
                        height: '1.25rem',
                        marginLeft: '.750rem',
                      }}
                    />
                  </ExternalLink>
                </Flex>
              </Box>
            </StyledEventCard>
          );
        })}
    </>
  );
}

function FAQResults({ results }) {
  return (
    <>
      {results &&
        results.map((result, index) => {
          return (
            <StyledEventCard key={index} my={resultMargin}>
              <Box p={'1rem'} bg="light-gray-background">
                <Text as="p" color="default-text" fontSize="med">
                  {`FAQ:  ${result.question}`}
                </Text>
                <Text
                  as="p"
                  color="default-text"
                  fontSize="1rem"
                  margin="0.125rem"
                >
                  {ReactHtmlParser(result.answer.html)}
                </Text>
              </Box>
            </StyledEventCard>
          );
        })}
    </>
  );
}

function SearchResults(props) {
  const [searchQuery, setSearchQuery] = useState(
    props.location.search.replace('?q=', '')
  );
  const unescapedSearchQuery = searchQuery.replace('%20',' ');

  const [searchResults, setSearchResults] = useState([]);

  const [getSearchResults, { loading }] = useLazyQuery(GET_SEARCH_RESULTS, {
    variables: { searchTerm: unescapedSearchQuery },
    onCompleted: (d) => {
      setSearchResults(d);
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    getSearchResults();
  }, [searchQuery, getSearchResults]);

  if (loading) {
    return <Spinner />;
  }

  const totalResults = Object.entries(searchResults)?.reduce((sum, set) => {
    return sum + set[1].length;
  }, 0);

  return (
    <Box bg="light-gray-background">
      <BreadCrumbs />
      <Banner pageTitle="Search"></Banner>

      <Box pt={[16]}>
        <Flex
          justifyContent="center"
          marginLeft={['2%', '20%', '30%', '40%']}
          p={[0]}
        >
          <Search minWidth="30%" />
        </Flex>
      </Box>

      <Section pt={[0]}>
        <Container>
          <Heading as="h1" variant="section">
            Search Results:
          </Heading>
          {searchResults && totalResults > 0 && (
            <Box>
              <Text as="p">
                {`Found ${totalResults} results for "${unescapedSearchQuery}".`}
              </Text>
              <PageResults results={searchResults['pages']} />
              <EventResults results={searchResults['events']} />
              <ProgramUpdateResults results={searchResults['programUpdates']} />
              <DocumentResults results={searchResults['documents']} />
              <RenewerNewsResults results={searchResults['renewerNewsItems']} />
              <FAQResults results={searchResults['faqs']} />
            </Box>
          )}
          {totalResults === 0 && (
            <Box>
              <Text as="p">No results found.</Text>
            </Box>
          )}
        </Container>
      </Section>
    </Box>
  );
}

export default SearchResults;
