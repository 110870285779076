import { React, useEffect, useRef, useState } from 'react';
import find from 'lodash/find';
import kebabCase from 'lodash/kebabCase';

import { Box } from 'shared/components/primitives';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import { SiteCardSection } from 'shared/components';

import { usePage } from 'shared/contexts';

const View = ({ children }) => {
  const mounted = useRef(false);
  const [sites, setSites] = useState([]);

  const [page] = usePage();
  const { pageAssets, title } = page;

  useEffect(() => {
    mounted.current = true;

    if (mounted.current === true) {
      const areas = [
        'Hooffs Run',
        'AlexRenew',
        'Pendleton Street',
        'Royal Street',
        'Waterfront Tunnel',
        'Alerts'
      ];

      const currentSites = areas
        .filter((area) => area !== title && `${area} Site` !== title) // skip areas that match the current data title
        .map((area) => {
          let fieldName = `aoi-${area.split(' ').join('_')}`;
          let areaImage = pageAssets
            ? find(pageAssets, { testField: fieldName })?.url
            : null;

          let site = {
            name: area === 'Alerts' ? area : `${area} Site`, // make sure 'Alerts' doesn't get the 'Site' suffix
            image: areaImage,
            link: `/construction/${area !== 'AlexRenew' ? kebabCase(area) : 'alexrenew' // TODO: there is a better way to do this. not sure why a magic string comparison is needed but otherwise kebabCase will break AlexRenew into 'alex-renew'
              }`,
          };
          return site;
        });

      setSites(currentSites);
    }
    return () => {
      mounted.current = false;
    };
  }, [pageAssets, title]);

  return (
    <Box bg="white-background">
      <Banner bannerColor="green-background" />
      <BreadCrumbs />
      {children}
      <SiteCardSection
        bg="normal-gray-background"
        heading="More Areas of Interest"
        color="default-text"
        sites={sites}
        pb={['3rem']}
      />
    </Box>
  );
};

export default View;
