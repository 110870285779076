import { React } from 'react';
// import { IoChevronUp } from 'react-icons/io5';
import {
  Box,
  Flex,
  Heading,
  // IconButton,
  Image,
  StyledMemberCard,
  Text,
} from 'shared/components/primitives';
// import { useToggle } from 'shared/hooks';

// const MemberQuoteTab = ({ quote }) => {
//   const { value, toggleValue } = useToggle();

//   return (
//     <Flex
//       onClick={toggleValue}
//       flexDirection="column"
//       justifyContent="stretch"
//       position="absolute"
//       width="full"
//       height="full"
//       color="white"
//       textAlign="center"
//       style={{
//         transform: value
//           ? 'translateY(.5rem)'
//           : 'translateY(calc(100% - 4.4375rem))', // magic number, the height of the tab button plus the topmost text block
//         transition: 'transform 500ms cubic-bezier(0.23, 1, 0.320, 1)',
//       }}
//     >
//       <IconButton
//         variant="circle"
//         mb="-1.5rem" // pull the button down into the tab slightly
//         backgroundColor="green-background"
//       >
//         <Text color="white" fontSize="med">
//           <IoChevronUp />
//         </Text>
//       </IconButton>
//       <Flex
//         flexDirection="column"
//         alignItems="stretch"
//         height="full"
//         borderTopRightRadius=".375rem"
//         borderTopLeftRadius=".375rem"
//         backgroundColor="green-background"
//       >
//         <Text as="small" p="1rem .5rem" fontWeight="semibold" fontSize="xxs">
//           Favorite thing about Alexandria?
//         </Text>
//         <Flex
//           flex="1"
//           flexDirection="column"
//           justifyContent="center"
//           px={['2rem']}
//           pt={['1rem']}
//           pb={['4rem']}
//         >
//           <Text as="q" fontWeight="semibold" lineHeight="xl">
//             {quote}
//           </Text>
//         </Flex>
//       </Flex>
//     </Flex>
//   );
// };

const MemberCard = ({ member }) => {
  if (!member) return null;

  const { area, name, headshot, outfall } = member;
  // const isStakeholder = stakeholder === true ? true : false; // just checking for 'stakeholder' returns true, so check explicitly
  return (
    <StyledMemberCard>
      {headshot && (
        <Box flex="0" mb={['1.5rem']}>
          <Image variant="avatar" src={headshot.url} />
        </Box>
      )}
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        pb="0" // add padding to bring bottom-most text above tab button, set back to '3rem' when the quotes are back
        color="default-text"
        textAlign="center"
      >
        {name && (
          <Heading as="h4" variant="card">
            {name}
          </Heading>
        )}
        {area && (
          // set outfall=true condition to back to null when the quotes are back
          <Text fontSize={['sm', '1rem']} mb={outfall ? 'auto' : 'auto'}>
            {' '}
            {area}
          </Text>
        )}
        {outfall && (
          <Text as="b" mt="auto">
            {outfall}
          </Text>
        )}
      </Flex>
      {/* {quote && <MemberQuoteTab quote={quote} />} */}
    </StyledMemberCard>
  );
};

export default MemberCard;
