import { React, useEffect } from 'react';
import {
  Box,
  PillButton,
  Flex,
  Image,
  Section,
} from 'shared/components/primitives';
import { Expandable } from 'shared/components/cards';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';

import { usePage } from 'shared/contexts';
import find from 'lodash/find';

const AboutRiverRenew = () => {
  const pageTitle = 'About RiverRenew';
  const [{ loading, error, title, pageAssets, sections }] = usePage();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (error) {
    return <div>Problem loading page data...</div>;
  }
  if (loading) {
    return <div>Loading...</div>;
  }
  if (!title) {
    return <div>No data</div>;
  }

  return (
    <Box bg="light-gray-background">
      <Banner pageTitle={pageTitle} />
      <BreadCrumbs />
      <Section>
        <Container>
          <Flex width="full" flexWrap="wrap" padding={[0]}>
            <Box
              flex={['100%', null, null, '50%']}
              pr={[null, null, null, '7rem']}
              alignSelf="baseline"
              order={[2, null, null, 1]}
            >
              {sections[0] && (
                <Expandable
                  title={sections[0]?.blocks[0]?.name}
                  visibleContent={sections[0]?.blocks[0]?.content[0]?.html}
                  hiddenContent={sections[0]?.blocks[0]?.content[1]?.html}
                />
              )}
            </Box>
            <Box flex={['100%', null, null, '50%']} order={[1, null, null, 2]}>
              <Image
                width={['full']}
                height={['auto']}
                maxHeight={['20rem']}
                style={{
                  objectFit: 'cover',
                }}
                src={
                  pageAssets
                    ? find(pageAssets, { assetTitle: '2017 Law' })?.url
                    : null
                }
                alt={``}
              />
            </Box>
          </Flex>
        </Container>
      </Section>
      <Section bg="white-background">
        <Container>
          <Flex width="full" flexWrap="wrap" padding={[0]}>
            <Box
              flex={['100%', null, null, '50%']}
              pr={[null, null, null, '7rem']}
              alignSelf="baseline"
              order={[2, null, null, 1]}
            >
              {sections[1] && (
                <Expandable
                  title={sections[1]?.blocks[0]?.name}
                  visibleContent={sections[1]?.blocks[0]?.content[0]?.html}
                />
              )}
              <PillButton
                as="a"
                href="/learn-more/how-water-works"
                maxWidth={'10rem'}
              >
                DIVE IN!
              </PillButton>
            </Box>
            <Box flex={['100%', null, null, '50%']} order={[1, null, null, 2]}>
              <Image
                width="100%"
                objectFit="contain"
                src={
                  pageAssets
                    ? find(pageAssets, { assetTitle: 'How Water Works' })?.url
                    : null
                }
                alt={`How water works`}
              />
            </Box>
          </Flex>
        </Container>
      </Section>
      <Section>
        <Container>
          <Flex width="full" flexWrap="wrap" padding={[0]}>
            <Box
              flex={['100%', null, null, '50%']}
              pr={[null, null, null, '7rem']}
              alignSelf="baseline"
              order={[2, null, null, 1]}
            >
              {sections[2] && (
                <Expandable
                  title={sections[2]?.blocks[0]?.name}
                  visibleContent={sections[2]?.blocks[0]?.content[0]?.html}
                  hiddenContent={sections[2]?.blocks[0]?.content[1]?.html}
                />
              )}
            </Box>
            <Box flex={['100%', null, null, '50%']} order={[1, null, null, 2]}>
              <Image
                width="100%"
                objectFit="contain"
                src={
                  pageAssets
                    ? find(pageAssets, { assetTitle: 'The Tunnel Project' })
                      ?.url
                    : null
                }
                alt={``}
              />
            </Box>
          </Flex>
        </Container>
      </Section>
      <Section bg="white">
        <Container>
          <Flex width="full" flexWrap="wrap" padding={[0]}>
            <Box
              flex={['100%', null, null, '50%']}
              pr={[null, null, null, '7rem']}
              alignSelf="baseline"
              order={[2, null, null, 1]}
            >
              {sections[3] && (
                <Expandable
                  title={sections[3]?.blocks[0]?.name}
                  visibleContent={sections[3]?.blocks[0]?.content[0]?.html}
                  hiddenContent={sections[3]?.blocks[0]?.content[1]?.html}
                />
              )}
            </Box>
            <Box flex={['100%', null, null, '50%']} order={[1, null, null, 2]}>
              <Image
                width="100%"
                objectFit="contain"
                src={
                  pageAssets
                    ? find(pageAssets, {
                      assetTitle: 'Completed RiverRenew Projects',
                    })?.url
                    : null
                }
                alt={``}
              />
            </Box>
          </Flex>
        </Container>
      </Section>
      <Section bg="light-gray-background">
        <Container>
          <Flex width="full" flexWrap="wrap" padding={[0]}>
            <Box
              flex={['100%', null, null, '50%']}
              pr={[null, null, null, '7rem']}
              alignSelf="baseline"
              order={[2, null, null, 1]}
            >
              {sections[4] && (
                <Expandable
                  title={sections[4]?.blocks[0]?.name}
                  visibleContent={sections[4]?.blocks[0]?.content[0]?.html}
                  hiddenContent={sections[4]?.blocks[0]?.content[1]?.html}
                />
              )}
            </Box>
            <Box flex={['100%', null, null, '50%']} order={[1, null, null, 2]}>
              <Image
                width="100%"
                objectFit="contain"
                src={
                  pageAssets
                    ? find(pageAssets, { assetTitle: 'Funding' })?.url
                    : null
                }
                alt={``}
              />
            </Box>
          </Flex>
        </Container>
      </Section>
    </Box>
  );
};

export default AboutRiverRenew;
