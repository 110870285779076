import { React } from 'react';
import { Box, Flex, StyledVideoCard, Heading, IFrame } from '../primitives';
import TagIndicatorList from 'shared/components/tagList/TagIndicatorList';

export const VideoCard = ({ video, height, containerHeight, ...props }) => {
  const { title, url, categories } = video;
  const videoUrl = url + '?title=0&byline=0&portrait=0';

  return (
    <StyledVideoCard height={containerHeight} {...props}>
      {videoUrl && (
        <IFrame
          src={
            videoUrl.startsWith('https://') ? videoUrl : `https://${videoUrl}`
          }
          width="420px"
          height="80%"
        />
      )}

      {(categories || title) && (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          px={['1.5rem']}
          py={['2.25rem']}
        >
          {categories && (
            <Box
              position={videoUrl ? 'absolute' : null}
              top="0"
              mb={videoUrl ? 0 : '1.5rem'}
              style={{ transform: videoUrl ? 'translateY(-50%)' : null }}
            >
              <TagIndicatorList colors={categories.flatMap((vc) => vc.color)} />
            </Box>
          )}

          {title && (
            <Heading
              as="h4"
              mb={[0, 9]}
              color="video-card-title"
              fontSize="med"
              fontWeight="bold"
            >
              {title}
            </Heading>
          )}
        </Flex>
      )}
    </StyledVideoCard>
  );
};

export const ResponsiveVideoCard = ({ video, ...props }) => {
  const { title, url, categories } = video;
  const videoUrl = url + '?title=0&byline=0&portrait=0';

  return (
    <StyledVideoCard {...props}>
      {videoUrl && (
        <Box p="56.25% 0 0 0" position="relative" bg="normal-gray-background">
          <IFrame
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            farmeBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            src={
              videoUrl.startsWith('https://') ? videoUrl : `https://${videoUrl}`
            }
          />
        </Box>
      )}

      {(categories || title) && (
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          px={['1.5rem']}
          py={['2.25rem']}
        >
          {categories && (
            <Box
              position={videoUrl ? 'absolute' : null}
              top="0"
              mb={videoUrl ? 0 : '1.5rem'}
              style={{ transform: videoUrl ? 'translateY(-50%)' : null }}
            >
              <TagIndicatorList colors={categories.flatMap((vc) => vc.color)} />
            </Box>
          )}

          {title && (
            <Heading
              as="h4"
              mb={[0, 9]}
              color="video-card-title"
              fontSize="med"
              fontWeight="bold"
            >
              {title}
            </Heading>
          )}
        </Flex>
      )}
    </StyledVideoCard>
  );
};
