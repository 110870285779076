import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { DefaultLayout } from 'shared/routes/DefaultLayout';
import Home from 'home/Home';
import Community from 'community';
import Construction from 'construction';
import { ContactUs } from 'contact-us/ContactUs';
import FAQs from 'faqs/FAQs';
import LearnMore from 'learn-more';
import Benefits from 'benefits/Benefits';
import NewsRoutes from 'news';
import Sitemap from 'shared/components/sitemap/Sitemap';
import SearchResults from 'search/SearchResults';

export default function Routes() {
  return (
    <Switch>
      <DefaultLayout exact path="/" component={Home} />
      <DefaultLayout path="/community" component={Community} />
      <DefaultLayout path="/news" component={NewsRoutes} />
      <DefaultLayout path="/construction" component={Construction} />
      <DefaultLayout path="/contact-us" component={ContactUs} />
      <DefaultLayout path="/faqs" component={FAQs} />
      <DefaultLayout path="/learn-more" component={LearnMore} />
      <DefaultLayout path="/benefits" component={Benefits} />
      <DefaultLayout path="/site-map" component={Sitemap} />
      <DefaultLayout path="/search" component={SearchResults} />
      <Redirect
        from="/listening-sessions"
        to="/community/community-listening-sessions"
      />
      <Redirect from="/meet-cloe" to="/community/cloes-corner" />
      <Redirect from="/Cloes-Corner" to="/community/cloes-corner" />
      <Redirect from="/tbm-naming" to="/news/program-updates/tbm-naming" />
      <DefaultLayout path="*" component={Home} />
    </Switch>
  );
}
