import styled from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';

export const Text = styled('span')(
  (props) =>
    css({
      fontFamily: "'Libre Franklin', sans-serif",
      lineHeight: '1.3125',
      transition: 'color 500ms cubic-bezier(0.23, 1, 0.320, 1)',
    }),
  variant({
    variants: {
      card: {
        date: {
          mb: '.75rem',
          color: 'default-text',
          fontSize: 'xxs',
          lineHeight: 'lg',
          textTransform: 'uppercase',
        },
      },
      contactInfo: {
        pt: '1rem',
        fontSize: ['smplus', 'med', null, 'lg'],
        fontWeight: 'bold',
        lineHeight: 'med',
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        width: '1px',
      },
    },
  }),
  composedStyledUtilities
);

/**
 * A presentational alternative to <hr />
 */
export const Rule = styled(Text)((props) =>
  css({
    display: 'block',
    width: 'full',
    height: props.thickness ? props.thickness : 1,
    my: '2rem',
    backgroundColor: props.backgroundColor
      ? props.backgroundColor
      : 'rule-color',
  })
);

export const MultiLineText = styled('span')(
  (props) =>
    css({
      fontFamily: "'Libre Franklin', sans-serif",
      wordBreak: 'break-word',
      overflow: 'visible',
    }),
  composedStyledUtilities
);

export const Heading = styled('h1')(
  (props) =>
    css({
      margin: 0,
      fontFamily: "'Libre Franklin', sans-serif",
      transition: 'color 500ms cubic-bezier(0.23, 1, 0.320, 1)',
    }),
  variant({
    variants: {
      card: {
        marginBottom: '1rem',
        color: 'card-header-color',
        fontSize: ['1.25rem', null, null, '1.5rem'],
        lineHeight: ['med', '1.25'],
      },
      section: {
        color: 'header-primary-text-color',
        fontSize: ['med', null, 'lg'],
        lineHeight: ['med', null, 'lg'],
        mb: '1.5rem',
      },
      subSection: {
        mb: '1.5rem',
        color: 'header-secondary-text-color',
        fontSize: ['med', null, '2rem'],
        lineHeight: ['med', null, 'lg'],
        fontWeight: 'bold',
      },
      sidebar: {
        color: 'header-secondary-text-color',
        fontSize: 'med',
        lineHeight: 'med',
        marginBottom: 6,
      },
      pill: {
        border: '2px solid',
        borderColor: 'white-border',
        borderRadius: 'pill',
        fontWeight: 'bold',
        pt: '.25rem',
        pb: '.125rem', // less bottom padding for the visual effect of centering text within the pill shape
        px: '1.5rem',
      },
      renewer: {
        title: {
          color: 'white',
          fontSize: 'lg',
        },
        subtitle: {
          color: 'white',
          fontSize: 'med',
          fontWeight: 'semibold',
          textTransform: 'uppercase',
        },
      },
      sitemap: {
        marginBottom: '.75rem',
        fontSize: 'med',
        fontWeight: 'bold',
      },
    },
  }),
  composedStyledUtilities
);
