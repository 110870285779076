import { React } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { icsFormattedDateTime } from 'shared/utils/date';
import { ExternalLink } from 'shared/components/primitives';

export function SaveToCalendar({
  children,
  id,
  startTime,
  duration,
  title,
  description,
  location,
  eventLink,
  eventId,
  margin,
  endTime,
  ...rest
}) {
  const dtstamp = icsFormattedDateTime();
  const formattedStartDate = icsFormattedDateTime(startTime);
  const formattedEndDate = () => {
    let startDate = new Date(startTime);
    startDate.setMinutes(startDate.getMinutes() + duration);

    return endTime
      ? icsFormattedDateTime(endTime)
      : icsFormattedDateTime(startDate);
  };
  const eventUrl = eventLink;

  const calendarUrl = [
    'BEGIN:VCALENDAR',
    'PRODID:-//www.riverrenew.com//Community Event',
    'VERSION:2.0',
    'CALSCALE:GREGORIAN',
    'METHOD:PUBLISH',
    `X-MS-OLK-FORCEINSPECTOROPEN:TRUE`,
    'BEGIN:VEVENT',
    `CLASS:PUBLIC`,
    `CREATED:${dtstamp}`,
    `LAST-MODIFIED:${dtstamp}`,
    `UID:${uuidv4()}`,
    `URL:${
      eventUrl ? eventUrl : 'https://www.riverrenew.com/community/events/' + eventId
    }`,
    `DTSTAMP:${dtstamp}`,
    `DTSTART:${formattedStartDate}`,
    `DTEND:${formattedEndDate()}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${description ? description.text : ''}`,
    `LOCATION:${location}`,
    `X-MICROSOFT-CDO-BUSYSTATUS:BUSY`,
    `X-MICROSOFT-CDO-IMPORTANCE:1`,
    `X-MICROSOFT-DISALLOW-COUNTER:FALSE`,
    `X-MS-OLK-AUTOFILLLOCATION:FALSE`,
    `X-MS-OLK-CONFTYPE:0`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\r\n');

  const filename = `${title}.ics`;
  const downloadUrl = window.URL.createObjectURL(
    new Blob([calendarUrl], { type: 'text/calendar' })
  );

  return (
    <ExternalLink
      href={downloadUrl}
      download={filename}
      target="_blank"
      margin={margin}
    >
      {children}
    </ExternalLink>
  );
}
