import React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';

const StyledFigure = styled('figure')(
  (props) =>
    css({
      pb: '1rem',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0,
      '> img': {
        width: 'full',
      },
    }),
  composedStyledUtilities
);

const StyledFigCaption = styled('figcaption')(
  (props) =>
    css({
      fontSize: ['xs'],
    }),
  composedStyledUtilities
);

/**
 * A generic Figure component
 * @param {ReactElement} children Children of Figure
 * @param {Object} caption Caption object to use for StyledFigCaption
 * @returns {ReactElement} Figure component
 */
export const Figure = ({ children, caption, ...props }) => {
  return (
    <StyledFigure {...props}>
      {children}
      {caption && (
        <StyledFigCaption {...caption.styles}>{caption}</StyledFigCaption>
      )}
    </StyledFigure>
  );
};
