import { React } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DefaultLayout } from 'shared/routes/DefaultLayout';
import NotFound from 'shared/components/notFound/NotFound';
import News from './News';
import ProgramUpdates from './programUpdates/ProgramUpdates';
import ProgramUpdateDetail from './programUpdates/ProgramUpdateDetail';
import InTheMedia from './inTheMedia/InTheMedia';
import { RiverRenewer } from './riverRenewer/RiverRenewer';

const NewsRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <DefaultLayout exact path={`${path}`} component={News} />
      <DefaultLayout
        exact
        path={`${path}/program-updates`}
        component={ProgramUpdates}
      />
      <Route
        path={`${path}/program-updates/:id`}
        component={ProgramUpdateDetail}
      />
      <DefaultLayout path={`${path}/in-the-media`} component={InTheMedia} />
      <DefaultLayout path={`${path}/river-renewer`} component={RiverRenewer} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default NewsRoutes;
