import { React, useState } from 'react';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import {
  Box,
  Button,
  ExternalLink,
  Flex,
  Heading,
  Image,
  StyledEventCard,
  Text,
} from 'shared/components/primitives';
import TagList from 'shared/components/tagList/TagList';
import { SaveToCalendar } from 'shared/components/calendar';
import { SharingLinksGroup } from 'shared/components/sharing';
import { useCheckDevice, useWindowDimensions } from 'shared/hooks/hooks';
import ReactHtmlParser from 'react-html-parser';
import {
  eventTime,
  longEventDate,
  numericEventDay,
  shortEventMonth,
} from 'shared/utils/date';

const EventSummaryCardContent = ({ event }) => {
  const {
    categories,
    date,
    endDate,
    description,
    location,
    thumbnail,
    title,
    registrationLink,
    id: eventId,
  } = event;
  const [showingMore, setShowingMore] = useState(false);
  const isMobile = useCheckDevice();
  const { width } = useWindowDimensions();

  return (
    <Flex flexDirection="column" alignItems="flex-start">
      {thumbnail && (
        <Box
          display="flex"
          width="100%"
          height="auto"
          style={{ overflow: 'hidden' }}
        >
          {width <= 640 && (
            <Image
              src={thumbnail?.sm}
              display="block"
              width="full"
              maxHeight={isMobile ? [150] : '234px'}
              style={{
                objectFit: 'cover',
              }}
            />
          )}
          {width > 640 && width <= 832 && (
            <Image
              src={thumbnail?.md}
              display="block"
              width="full"
              maxHeight={isMobile ? [150] : '234px'}
              style={{
                objectFit: 'cover',
              }}
            />
          )}
          {width > 832 && (
            <Image
              src={thumbnail?.lg}
              display="block"
              width="full"
              maxHeight={isMobile ? [150] : '234px'}
              style={{
                objectFit: 'cover',
              }}
            />
          )}
          {date && (
            <Box variant="dateChip">
              <Text>{shortEventMonth(date)}</Text>
              <Text>{numericEventDay(date)}</Text>
            </Box>
          )}
          {categories && (
            <Box position="absolute" bottom={0} px={[12]} py={[9]}>
              <TagList tags={categories} />
            </Box>
          )}
        </Box>
      )}

      <Box
        flex="1"
        width="full"
        px={isMobile ? ['.875rem'] : ['1.5rem']}
        pt={isMobile ? ['.875rem'] : ['1.5rem']}
        pb={isMobile ? ['.875rem'] : ['2rem']}
      >
        <Heading as="h3" variant="card">
          {title}
        </Heading>

        {date && (
          <Text
            display="block"
            variant="card.date"
            color="event-card-text-color"
          >
            {longEventDate(date)}
            {endDate && ` ${ReactHtmlParser('&mdash;')} ${eventTime(endDate)}`}
          </Text>
        )}

        {location && (
          <Text
            display="block"
            fontSize="1rem"
            fontWeight="bold"
            mb="1rem"
            color="event-details-text-color"
            bold
          >
            {location}
          </Text>
        )}

        <Box
          overflow="hidden"
          maxHeight={showingMore ? 'auto' : '3rem'}
          style={{
            // TODO: make this work.
            transition: 'all 1s cubic-bezier(0, 1, 0, 1)',
          }}
        >
          {description && (
            <Text fontSize="1rem" color="event-details-text-color">
              {ReactHtmlParser(description.html)}
            </Text>
          )}
        </Box>

        {description && (
          <Flex
            onClick={() => setShowingMore(!showingMore)}
            marginTop="1rem"
            flexDirection="row"
          >
            {showingMore && (
              <FiMinusCircle
                fill="event-minus-icon-background-color"
                color="white"
                fontSize="1.5rem"
              />
            )}
            {!showingMore && (
              <FiPlusCircle
                fill="event-plus-icon-background-color"
                color="white"
                fontSize="1.5rem"
              />
            )}
            <Text
              marginLeft=".5rem"
              fontSize="1rem"
              color="event-details-text-color"
            >
              {showingMore ? 'SHOW LESS' : 'SHOW MORE'}
            </Text>
          </Flex>
        )}

        {!isMobile && categories && (
          <Box pb={[9]} marginTop="1rem">
            <TagList tags={categories} />
          </Box>
        )}

        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexWrap="wrap"
          mt={-4}
        >
          <Flex flexDirection="row" flexWrap="wrap" marginTop="1rem">
            {registrationLink && (
              <Box>
                <ExternalLink
                  href={registrationLink}
                  target="_blank"
                  ml="0"
                  mr="1rem"
                >
                  {!isMobile && (
                    <Box
                      variant="pill"
                      bg="dark-background"
                      mt={4}
                      fontSize={'0.667rem'}
                      py="0.625rem"
                      px="1.25rem"
                    >
                      <Text>Register Now</Text>
                    </Box>
                  )}
                  {isMobile && (
                    <Text fontSize="0.667rem" mt={4}>
                      + Register Now
                    </Text>
                  )}
                </ExternalLink>
              </Box>
            )}
            <SaveToCalendar
              startTime={date}
              endTime={endDate}
              duration={120}
              description={description}
              title={title}
              location={location}
              margin={0}
              eventId={eventId}
            >
              <Button
                variant={isMobile ? 'link' : 'pill.outline'}
                mt={4}
                fontSize={'0.667rem'}
              >
                {isMobile ? '+ ' : ''}Add to Calendar
              </Button>
            </SaveToCalendar>
          </Flex>

          <Box flexBasis="100%" mt={[4, 8, 16]}>
            <SharingLinksGroup
              subject={title}
              linkUrl={window.location.href}
              color="#A5A5A5"
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

const EventSummaryCard = ({ event, ...props }) => {
  return (
    <StyledEventCard flexGrow="1" flexShrink="1" {...props}>
      <EventSummaryCardContent event={event} />
    </StyledEventCard>
  );
};

export default EventSummaryCard;
