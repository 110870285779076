import { React } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import NotFound from 'shared/components/notFound/NotFound';
import { DefaultLayout } from 'shared/routes/DefaultLayout';
import TrafficAlerts from 'trafficAlerts/TrafficAlerts';
import ConstructionPage from 'construction/ConstructionPage';

const Construction = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Redirect exact from={`${path}`} to={`${path}/hooffs-run-site`} />

      <DefaultLayout path={`${path}/alerts`} exact component={TrafficAlerts} />
      <DefaultLayout path={`${path}/:slug`} component={ConstructionPage} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Construction;
