import { gql } from '@apollo/client';

export const GET_FAQS = gql`
  query GET_FAQS {
    faqs(orderBy: sortOrder_ASC) {
      id
      question
      answer {
        html
      }
      categories {
        id
        displayName
      }
    }
  }
`;
