import { React } from 'react';
import {
  Flex,
  Heading,
  Section,
  TextLink,
  PillLink,
  ListItem,
  Text,
  MultiLineText,
  Box,
  WrappingColumn,
  WrappingColumnItem,
  Spinner,
} from 'shared/components/primitives';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';
import { Accordion } from 'shared/components/accordion/Accordion';
import { groupBy, sortStringKeys } from 'shared/utils/arrayUtils';
import DocumentListItem from 'shared/components/documents/DocumentListItem';
import { useDocuments } from 'shared/contexts';

const DocumentLibrary = (...props) => {
  const [documents, loading, error] = useDocuments();

  if (loading)
    return (
      <ListItem>
        <Spinner>Loading...</Spinner>
      </ListItem>
    );
  if (error)
    return (
      <ListItem>
        <Text>Error</Text>
      </ListItem>
    );
  if (!documents)
    return (
      <ListItem>
        <Text>No documents available yet.</Text>
      </ListItem>
    );

  // Group the documents by Group and then by SubGroup within groups:
  let finalDocumentCollection = [];
  const byGroup = groupBy(
    documents.filter((d) => d.group != null),
    (doc) => doc.group
  );

  const sortedByGroup = new Map([...byGroup].sort(sortStringKeys));

  sortedByGroup.forEach((values, key) => {
    let documentGroup = {
      groupKey: key,
      subGroupedDocuments: [],
    };
    const bySubGroup = groupBy(values, (doc) => doc.subGroup);
    bySubGroup.forEach((v, k) => {
      let subGroup = {
        subGroupKey: k,
        documents: [],
      };
      subGroup.documents = v;
      documentGroup.subGroupedDocuments.push(subGroup);
    });
    finalDocumentCollection.push(documentGroup);
  });

  return (
    <Box bg="light-gray-background">
      <Banner />
      <BreadCrumbs />

      <Section px={[16, null, null, 64]} pt={[46]} bg="gray-background">
        <Heading
          as="h2"
          mb={[20]}
          fontSize="1.875"
          color="default-text"
          marginTop="1.25rem"
          marginBottom=".5rem"
        >
          Our Document Library
        </Heading>
        <Box maxWidth="85%">
          <Text fontSize="1.25rem" color="default-text">
            For questions about our document library, including requests for
            documents in larger print or other accessible formats, please submit
            an inquiry on our
          </Text>
          <Text>&nbsp;</Text>
          <TextLink
            fontSize="1.25rem"
            color="default-text"
            fontWeight="null"
            textTransform="none"
            textDecoration="underline"
            to="/contact-us"
          >
            Contact Us
          </TextLink>
          <Text fontSize="1.25rem" color="default-text">
            &nbsp; page.
          </Text>
        </Box>
      </Section>

      <Section bg="white-background">
        <Container>
          <WrappingColumn cols="28.125rem 2">
            {finalDocumentCollection &&
                finalDocumentCollection.filter(group => group.groupKey !== '').map((group) => (
                <WrappingColumnItem key={group.groupKey}>
                  <Accordion contentFor={group.groupKey}>
                    <Box label={group.groupKey}>
                      <Accordion
                        contentFor={group.groupKey}
                        autoOpenFirstChild={true}
                      >
                        {group.subGroupedDocuments.map((sg) => (
                          <Box label={sg.subGroupKey} key={sg.subGroupKey}>
                            {sg.documents.map((doc) => (
                              <DocumentListItem
                                key={doc.id}
                                id={doc.id}
                                title={doc.title}
                                url={doc.attachment?.url}
                                filename={doc.attachment?.filename}
                              />
                            ))}
                          </Box>
                        ))}
                      </Accordion>
                    </Box>
                  </Accordion>
                </WrappingColumnItem>
              ))}
          </WrappingColumn>
        </Container>
      </Section>

      <Section px={[16, null, null, 64]} bg="normal-gray-background">
        <Box>
          <Flex
            flexDirection="column"
            textAlign="center"
            alignContent="center"
            justifyContent="center"
          >
            <Heading
              as="h4"
              mb={[20]}
              fontSize="1.875"
              color="default-text"
              marginTop="1.25rem"
              marginBottom=".5rem"
            >
              Haven't Found What You're Looking For?
            </Heading>

            <MultiLineText
              fontSize="1.25"
              color="default-text"
              maxWidth="45rem"
            >
              We're happy to help.
            </MultiLineText>

            <Box marginTop="1.625rem">
              <PillLink variant="pill" to="/contact-us">
                <Text>Contact Us</Text>
              </PillLink>
            </Box>
          </Flex>
        </Box>
      </Section>
    </Box>
  );
};

export default DocumentLibrary;
