import { forwardRef } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { css } from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';

export const StyledList = styled('ul')(
  (props) =>
    css({
      padding: 0,
      margin: 0,
      listStyle: 'none',
    }),
  (props) =>
    variant({
      variants: {
        menuList: {
          flex: ['1 1 100%'],
          pl: props?.pl ? props.pl : [0],
          pr: props?.pr ? props.pr : [0, null, null, '2rem'],
          color: 'white',
        },
        subMenuList: {
          display: 'flex',
          flex: '0',
          flexWrap: [null, 'wrap'],
          flexDirection: ['column', 'row', null, 'column'],
        },
        siteMapMenu: {
          mb: ['1.5rem'],
        },
      },
    }),
  composedStyledUtilities
);

// need to forwardRef here because we employ a ref on List in SubMenu to get the List's height
export const List = forwardRef((props, ref) => {
  return <StyledList ref={ref} {...props} />;
});
