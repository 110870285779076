import { RiArrowRightSFill } from 'react-icons/ri';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { useLocation } from 'react-router-dom';

import {
  Box,
  Section,
  Heading,
  Text,
  TextLink,
  Video,
  StyledNavExternalLink,
  VimeoPlayer,
} from 'shared/components';
import Container from 'shared/components/container/Container';
import { usePage } from 'shared/contexts';

export const Hero = () => {
  const [
    page,
    seo,
    ci,
    bannerImage,
    pageAssets,
    title,
    slug,
    documents,
    sections,
    videoCaption,
    loading,
    error,
  ] = usePage();
  const { contentItems, videoUrl } = page || {};
  const location = useLocation();

  if((title.toLowerCase() !== slug)) return <></>;

  return (
    <Section pt={[0, 0, 0, 0]}>
      {contentItems?.length > 0 && (
        <>
          <Box
            display="flex"
            alignItems="center"
            pt={['2rem', null, '6rem', '8rem']}
            pb={['2rem', null, '6rem', '8rem']}
            px={['2rem', null, null, null, '4rem']}
            mb={['2rem']}
            backgroundColor="black"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            overflow="hidden"
            style={{
              transition: 'height 500ms cubic-bezier(0.23, 1, 0.320, 1)',
            }}
          >
            {videoUrl && (
              <>
                <VimeoPlayer
                  player={{
                    isBackground: true,
                    slug: 'home-hero-video',
                    videoId: videoUrl,
                  }}
                  fullWidth={true}
                  showControls={false}
                />
                <Box
                  position="absolute"
                  top="0"
                  w="100vw"
                  h="100%"
                  bg="rgba(0, 0, 0, .4)"
                  mixBlendMode="multiply"
                  zIndex="base"
                />
              </>
            )}
            <Container px={[0]}>
              <RichText
                content={contentItems && contentItems[2]?.raw}
                renderers={{
                  h1: ({ children, ...rest }) => (
                    <Heading
                      as="h1"
                      width="full"
                      fontSize={['1.625rem', 'lg', 'xl', 'xxxl']}
                      color="header-white"
                      mb={[0]}
                      textAlign="center"
                      {...rest}
                    >
                      {children}
                    </Heading>
                  ),
                }}
              />
            </Container>
          </Box>
          <Container>
            <RichText
              content={contentItems[0]?.raw}
              renderers={{
                p: ({ children, ...rest }) => (
                  <Text
                    as="p"
                    pb={['1.5rem']}
                    fontSize={['1.125rem', '1.625rem', null, 'med']}
                    lineHeight={[1.35]}
                    color="default-text"
                    maxWidth={['100%', null, null, '100%']}
                    {...rest}
                  >
                    {children}
                  </Text>
                ),

                a: ({ children, href, ...rest }) => {
                  /**
                   * The browser's current location value.
                   */
                  const currentURL = new URL(window.location.href);

                  /**
                   * The link's url value
                   */
                  let linkURL = {};

                  /**
                   * Try to create a new URL out of the link's `href` prop
                   */
                  try {
                    linkURL = new URL(href);
                  } catch {
                    /**
                     * Otherwise set `linkURL` to the `href` prop itself
                     */
                    linkURL = href;
                  }

                  return (
                    <>
                      {linkURL.origin &&
                      linkURL.origin !== currentURL.origin ? (
                        /**
                         * Use an external link component if `linkURL` has an `origin` property and it matches `currentURL`'s origin
                         */
                        <StyledNavExternalLink
                          variant="text"
                          href={href}
                          color="link-dark-color"
                          fontSize={['xs', 'sm', '1rem']}
                          fontWeight="bold"
                          style={{ textTransform: 'uppercase' }}
                          {...rest}
                        >
                          {children}
                          <RiArrowRightSFill color="link-dark-color" />
                        </StyledNavExternalLink>
                      ) : (
                        /**
                         * Otherwise use `TextLink` with the `to` prop
                         */
                        <TextLink
                          to={href}
                          color="link-dark-color"
                          fontSize={['xs', 'sm', '1rem']}
                          fontWeight="bold"
                          style={{ textTransform: 'uppercase' }}
                          {...rest}
                        >
                          {children}
                          <RiArrowRightSFill color="link-dark-color" />
                        </TextLink>
                      )}
                    </>
                  );
                },
              }}
            />
          </Container>
        </>
      )}
    </Section>
  );
};
