import { React, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Section,
  Button,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import View from 'community/View';
import EventList from './EventList';
import { EventsSidebar, EventsDateFilter } from './EventsSidebar';
import FilterTagList from 'shared/components/tagList/FilterTagList';
import { CategoryProvider, EventProvider, useEvents } from 'shared/contexts';
import { useCheckDevice, useToggle } from 'shared/hooks';

function EventsContent() {
  const [events, loading, error] = useEvents(); // get events from EventProvider
  const { value, toggleValue } = useToggle();
  const [categories, setCategories] = useState([]);
  const [activeFilters, setactiveFilters] = useState([]);
  const [dateFilter, setDateFilter] = useState();
  const [showCount, setShowCount] = useState(4); // temp way to handle "Load More"
  const isMobile = useCheckDevice();

  useEffect(() => {
    // set local categories state to flattened array of categories referenced by  events
    setCategories(events.map((event) => event.categories).flat(2));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [events]);

  const getEventsForDisplay = () => {
    let displayEvents = [...events];

    if (activeFilters.length > 0) {
      displayEvents = displayEvents
        .filter(
          (event) =>
            event.categories
              .flatMap((category) => category.displayName)
              .filter((event) => activeFilters.includes(event)).length > 0
        )
        .sort((a, b) => {
          return a.date < b.date ? -1 : 1; // show next occurring events first
        });
    }

    // dateFilter applies to events in the past.  Otherwise, only show future events.
    if (dateFilter) {
      displayEvents = displayEvents
        .filter((event) => {
          let eventDate = new Date(event.date);
          let eventYear = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
          }).format(eventDate);
          let eventMonth = new Intl.DateTimeFormat('en-US', {
            month: 'long',
          }).format(eventDate);
          return (
            eventYear === dateFilter.year && eventMonth === dateFilter.month
          );
        })
        .sort((a, b) => {
          return a.date < b.date ? -1 : 1; // show next occurring events first
        });
    } else {
      displayEvents = displayEvents
        .filter((e) => Date.parse(e.date) >= Date.now())
        .sort((a, b) => {
          return a.date < b.date ? -1 : 1; // show next occurring events first
        });
    }

    //
    if (value === true) {
      displayEvents = events
        .filter((event) => {
          return Date.parse(event.date) < Date.now();
        })
        .sort((a, b) => a.date < b.date);
    }

    return displayEvents.slice(0, showCount); // show a slice of the displayEvents array
  };

  const handleFilterChange = (category) => {
    // if activeFilters already contains the category, toggle it off, otherwise add it:
    setactiveFilters(
      activeFilters.includes(category)
        ? activeFilters.filter((i) => i !== category)
        : [...activeFilters, category]
    );
  };

  const handleDateFilterSelected = (month, year) => {
    if (month && year) {
      setDateFilter({
        month: month,
        year: year,
      });
    } else {
      // hacky way to let them clear date filters
      setDateFilter(null);
    }
  };

  const togglePastEvents = () => {
    toggleValue(!value);
  };

  const handleClearAllFilters = () => {
    // hacky way to clear all category and date filters:
    window.location.reload();
  };

  return (
    <View>
      <Section>
        <Container style={{ padding: isMobile ? "0 1rem" : "" }} >
          <Heading as="h2" mb={[23]} variant="section">
            RiverRenew in the Community
          </Heading>

          {isMobile && (
            <CategoryProvider categories={categories}>
              <FilterTagList
                filterToggleHandler={handleFilterChange}
                limit={3}
              />
            </CategoryProvider>
          )}

          <Heading
            as="h3"
            mb={[21]}
            color="header-secondary-text-color"
            fontSize={isMobile ? '1rem' : '1.75rem'}
            fontWeight="600"
            variant="subSection"
          >
            Events
          </Heading>

          <Flex alignItems="flex-start">
            <Box flex="1" pr={isMobile ? [2] : [15]}>
              <EventList
                error={error}
                loading={loading}
                data={getEventsForDisplay()}
              />
              <Flex justifyContent="center" pt="16">
                {showCount <= getEventsForDisplay().length && (
                  <Button
                    variant="pill"
                    onClick={() => setShowCount(showCount + 4)}
                  >
                    Load More Events
                  </Button>
                )}
              </Flex>
              {isMobile && (
                <Box marginTop="1.5rem">
                  <Heading as="h3" variant="sidebar">
                    Past Events
                  </Heading>
                  <EventsDateFilter
                    dateFilterHandler={handleDateFilterSelected}
                  />
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="stretch"
                    mt="1rem"
                  >
                    <Button variant="pill" onClick={() => togglePastEvents()}>
                      {value
                        ? 'View Upcoming Events'
                        : 'View Past Events'}
                    </Button>
                    <br />
                    <Button
                      variant="pill"
                      onClick={() => handleClearAllFilters()}
                    >
                      CLEAR ALL
                    </Button>
                  </Flex>
                </Box>
              )}
            </Box>
            {!isMobile && (
              <Box flexGrow="0" flexShrink="0" flexBasis="424px">
                <CategoryProvider categories={categories}>
                  <EventsSidebar
                    filterToggleHandler={handleFilterChange}
                    dateFilterHandler={handleDateFilterSelected}
                    clearFiltersHandler={handleClearAllFilters}
                    togglePastEvents={togglePastEvents}
                    toggleValue={value}
                  />
                </CategoryProvider>
              </Box>
            )}
          </Flex>
        </Container>
      </Section>
    </View>
  );
}

const Events = () => (
  <EventProvider>
    <EventsContent />
  </EventProvider>
);

export default Events;
