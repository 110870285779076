import { React, useEffect, useState } from 'react';

import {
  Flex,
  List,
  ListItem,
  Text,
  Spinner,
} from 'shared/components/primitives';
import { useCheckDevice } from 'shared/hooks/hooks';
import EventCard from './EventCard';
import EventModal from './EventModal'

const EventList = ({ loading, error, data, cardOrientation }) => {
  const isMobile = useCheckDevice();
  const [modalToggleValue, setModalToggleValue] = useState(false)
  const [modalEventValue, setModalEventValue] = useState(null)

  useEffect(() => {
    if (data.length > 0) {
      setModalEventValue(data[0])
    }
  }, [])

  const openModalOperation = async (event) => {
    await setModalEventValue(event)
    await setModalToggleValue(true)
  }

  return (
    <>
      <List
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        mt={['-.5rem', '-.75rem']}
        mx={0}
      >
        {loading && (
          <ListItem>
            <Flex>
              <Spinner />
            </Flex>
          </ListItem>
        )}

        {error && (
          <ListItem>
            <Text>Error</Text>
          </ListItem>
        )}

        {(!data || data.length === 0) && (
          <ListItem>
            <Text>No events to show.</Text>
          </ListItem>
        )}
        {data &&
          data.map((event) => (
            <ListItem
              key={event.id}
              display="flex"
              flexGrow="0"
              flexShrink="1"
              flexBasis={[]}
              py={isMobile ? ['.25rem'] : ['.75rem']}
              px={null}
              marginBottom="1rem"
            >
              <EventCard event={event} onClick={() => isMobile ? openModalOperation(event) : null} />
            </ListItem>
          ))}
        {data.length > 0 &&
          <EventModal
            isOpen={modalToggleValue}
            event={modalEventValue}
            onClose={() => setModalToggleValue(false)}
          />
        }
      </List>
    </>
  );
};

export default EventList;
