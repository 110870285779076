import { Layer } from 'shared/components/map/Layer';

import {
  sidewalkDetours,
  sidewalkDetours3Start,
  sidewalkDetours3End,
} from '../geojson';

const layer = {
  id: 'sidewalk-detours',
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#7940A4',
    'line-width': 5,
    'line-dasharray': [3, 4],
  },
};

const arrowLayer1 = {
  id: 'sidewalkDetours-arrow-1',
  type: 'symbol',
  layout: {
    'symbol-placement': 'point',
    'icon-allow-overlap': false,
    'icon-image': 'arrow-purple',
    'icon-size': 0.75,
    'icon-rotate': 280,
    visibility: 'visible',
  },
};

const arrowLayer2 = {
  id: 'sidewalkDetours-arrow-2',
  type: 'symbol',
  layout: {
    'symbol-placement': 'point',
    'icon-allow-overlap': false,
    'icon-image': 'arrow-purple',
    'icon-size': 0.75,
    'icon-rotate': 280,
    visibility: 'visible',
  },
};

const arrowLayer3 = {
  id: 'sidewalkDetours-arrow-3',
  type: 'symbol',
  layout: {
    'symbol-placement': 'point',
    'icon-allow-overlap': false,
    'icon-image': 'arrow-purple',
    'icon-size': 0.75,
    'icon-rotate': 190,
    visibility: 'visible',
  },
};

const arrowLayer4 = {
  id: 'sidewalkDetours-arrow-4',
  type: 'symbol',
  layout: {
    'symbol-placement': 'point',
    'icon-allow-overlap': false,
    'icon-image': 'arrow-purple',
    'icon-size': 0.75,
    'icon-rotate': 100,
    visibility: 'visible',
  },
};

const arrowLayer5 = {
  id: 'sidewalkDetours-arrow-5',
  type: 'symbol',
  layout: {
    'symbol-placement': 'point',
    'icon-allow-overlap': false,
    'icon-image': 'arrow-purple',
    'icon-size': 0.75,
    'icon-rotate': 280,
    visibility: 'visible',
  },
};

export const SidewalkDetoursFeature = () => {
  return (
    <>
      <Layer
        key={arrowLayer1.id}
        data={sidewalkDetours3Start}
        layer={arrowLayer1}
      />
      <Layer
        key={arrowLayer2.id}
        data={sidewalkDetours3End}
        layer={arrowLayer2}
      />
      <Layer key={layer.id} data={sidewalkDetours} layer={layer} />
    </>
  );
};
