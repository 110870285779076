import { React, useState, useEffect } from 'react';

export const Select = ({ list, placeholder, name, id, selectionHandler, ...rest }) => {
  const defaultOption = 'Choose an option';
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(placeholder ? null : list[0]);
  const [select, setSelect] = useState(null);

  const toggling = (e) => {
    setSelect(e.target.closest('.custom-select'));
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (e) => {
    setSelectedOption(e.target.getAttribute('data-value'));
    if (selectionHandler) {
      selectionHandler({
        "name" : name,
        "selection" : e.target.getAttribute('data-value')
      });
    }
    setIsOpen(false);
  };

  const handleTrigger = (e) => {
    if (e.charCode === 13) toggling(e);
  };

  const setNextOption = () => {
    let nextOpt = select.querySelector('.selected')?.nextElementSibling;

    if (nextOpt) setSelectedOption(nextOpt.getAttribute('data-value'));
  };

  const setPreviousOption = () => {
    let previousOpt = select.querySelector('.selected')?.previousElementSibling;

    if (previousOpt) setSelectedOption(previousOpt.getAttribute('data-value'));
  };

  const handleLostFocus = (e) => {
    e.nativeEvent.stopImmediatePropagation();

    if (isOpen) {
      toggling(e);
      return;
    }
  };

  const handleBlur = (e) => {
    if (e.relatedTarget) {
      toggling(e);
      return;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', function (e) {
      if (isOpen) {
        switch (e.key.toLowerCase()) {
          case 'arrowdown':
            e.stopImmediatePropagation();
            e.preventDefault();
            setNextOption();
            break;
          case 'arrowup':
            e.stopImmediatePropagation();
            e.preventDefault();
            setPreviousOption();
            break;
          default:
            break;
        }
      }
    });
  });

  return (
    <div className="form-control">
      <div className="custom-select-wrapper">
        <div
          className={`custom-select ${isOpen ? 'open' : ''}`}
          onMouseLeave={handleLostFocus}
          onBlur={handleBlur}
          aria-expanded={isOpen}
        >
          <div
            className="custom-select__trigger"
            tabIndex="0"
            onClick={toggling}
            onKeyPress={handleTrigger}
          >
            <span>{selectedOption || placeholder || defaultOption}</span>
            <div className="arrow"></div>
          </div>
          <select
            name={name}
            id={id}
            className="hide"
            defaultValue={selectedOption}
          >
            {list.map((opt) => {
              return (
                <option value={opt} key={Math.random()}>
                  {opt}
                </option>
              );
            })}
          </select>
          <ul className="custom-options">
            <li
              className="custom-option"
              data-value=""
              onClick={onOptionClicked}
            >
              -- {placeholder || defaultOption} --
            </li>
            {list.map((opt) => {
              return (
                <li
                  className={`custom-option ${
                    selectedOption === opt ? 'selected' : ''
                  }`}
                  data-value={opt}
                  onClick={onOptionClicked}
                  key={Math.random()}
                >
                  {opt}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
