import { React } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { RiMapPin2Fill } from 'react-icons/ri';
import { Flex, Text, Heading, PillLink, StyledSiteCard } from '../primitives';

export const SiteCard = ({ site }) => {
  const { name, image, link } = site;
  return (
    <StyledSiteCard
      flex="1"
      px={['1.125rem', '2rem']}
      py={['1rem', '2rem']}
      backgroundImage={`url(${image})`}
    >
      <Flex
        position="relative"
        flexDirection={['row', 'column']}
        alignItems={['center', 'flex-start']}
        justifyContent={['space-between']}
        height="full"
        zIndex="1"
      >
        <Text color="white" fontSize="med" mr={[4, 0]} mb={[0, 3]}>
          <RiMapPin2Fill />
        </Text>
        {name && (
          <Heading as="h5" color="white" fontSize={['18px', 'med']} mb={[0, 9]}>
            {name}
          </Heading>
        )}
        {link && (
          <PillLink
            variant="pill"
            to={link}
            flex={['0 0 auto']}
            size={['2.5rem', 'auto']}
            px={[0, '1.125rem']}
            py={[0, '.75rem']}
            ml={['auto', 0]}
            mt="auto"
          >
            <Text display={['none', 'inline']}>View More</Text>
            <Flex
              display={['flex', 'none']}
              justifyContent="center"
              fontSize={['med']}
            >
              <FiArrowRight />
            </Flex>
          </PillLink>
        )}
      </Flex>
    </StyledSiteCard>
  );
};
