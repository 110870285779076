import { useState, useEffect } from 'react';

/**
 * Returns the current width and height of the window object
 *
 * @return {*} Width & height of the viewport
 */
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/**
 * A hook to retrieve the current width and height of the window object from state
 *
 * @export
 * @return {*} Dimensions of the window
 */
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useCheckDevice() {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
          setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  // TODO: refactor to make this more robust to handle tablet, device orientation, browser compatability.

  const device = !!navigator.maxTouchPoints ? 'mobile' : 'computer';
  if (device === 'mobile' || width <= 768) {
    return true;
  }
  return false;
}
