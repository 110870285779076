import { useEffect, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import {
  Box,
  Flex,
  Input,
  Button,
  Checkbox,
  Spinner,
  Text,
} from 'shared/components/primitives';
import { FormPanel } from 'shared/components/form';

import { sendGtmEvent } from 'shared/utils/analytics';

const Form = ({ status, message, onValidated }) => {
  const [errorMessage, setErrorMessage] = useState();

  let email, terms;

  const submit = (event) => {
    event.preventDefault();

    onValidated({
      EMAIL: email.value,
      TERMS: terms.checked,
    });
  };

  const handleErrors = (message) => {
    let replacedMessage = message.replace(/[0-9] - /g, '');

    // If this email is already subscribed, reset the form
    if (message && message.includes('already subscribed')) resetForm();

    setErrorMessage(replacedMessage);
  };

  const resetForm = () => {
    email.value = '';
    terms.checked = false;
  };

  const submitSuccess = () => {
    // send a 'newSubscriber' event to GTM...
    sendGtmEvent('newSubscriber', {
      EMAIL: email.value,
    });

    resetForm();
  };

  useEffect(() => {
    if (status === 'error') handleErrors(message);
    if (status === 'success') submitSuccess();
  }, [status, message]);

  return (
    <Box as="form" onSubmit={(e) => submit(e)} mt={['1rem']}>
      <Box>
        <Flex>
          <Input
            ref={(node) => (email = node)}
            type="email"
            id="email"
            placeholder="Email Address"
            required
          />
        </Flex>
        <Flex mb={[12]}>
          <Checkbox
            ref={(node) => (terms = node)}
            id="terms"
            name="terms"
            label="By continuing, I agree to AlexRenew’s Terms of Use and acknowledge AlexRenew’s Privacy Policy.*"
            required
          />
        </Flex>
        <Button type="submit" variant="primary" mb="1rem">
          {status === 'sending' ? <Spinner /> : 'Subscribe'}
        </Button>
      </Box>
      {status && (
        <Box py={[8]} mt={[8]}>
          {status === 'error' && (
            <Text
              color="error"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          )}
          {status === 'success' && (
            <Text color="green" dangerouslySetInnerHTML={{ __html: message }} />
          )}
        </Box>
      )}
    </Box>
  );
};

export const RenewerSubscriptionForm = () => {
  const url = process.env.REACT_APP_MAILCHIMP_RIVER_RENEWER_LIST_URL;

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <FormPanel title="Subscribe to The River Renewer via Email!">
          <Text as="p">
            Enter your email address to get the latest editions of the River
            Renewer sent directly to your inbox.
          </Text>
          <Form
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        </FormPanel>
      )}
    />
  );
};
