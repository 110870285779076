import { React, createRef, useEffect, useState } from 'react';
import ReactMapGL, {
  AttributionControl,
  Layer as ReactMapGLLayer,
  NavigationControl,
  Source,
} from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

// added the following 6 lines.
import mapboxgl from 'mapbox-gl';

import { useCheckDevice } from 'shared/hooks';
import {
  Box,
  PillButton,
  Flex,
  Heading,
  Text,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import { TrafficAlertPopup } from 'shared/components/map/Popup';
import {
  AlexRenewRoutes,
  ConstructionSiteFeatures,
  HooffsRunInterceptorFeature,
  PendletonStRoutes,
  RouteSpine,
  RoyalStRoutes,
  DukeStRoutes,
  OutfallFeatures,
  TrafficAlertFeatures,
  TunnelLineFeature,
  DiversionFacilityFeatures,
  PumpingStationFeature,
  SidewalkClosuresFeature,
  SidewalkDetoursFeature,
} from './features';

import arrowBlue from 'shared/assets/images/arrow-blue@2x.png';
import arrowGreen from 'shared/assets/images/arrow-green@2x.png';
import arrowGray from 'shared/assets/images/arrow-gray.png';
import arrowPurple from 'shared/assets/images/arrow-purple.png';

import sidewalkMarker from 'shared/assets/images/sidewalk-icon.png';
import parkingMarker from 'shared/assets/images/parking-icon.png';
import laneMarker from 'shared/assets/images/lane-icon.png';
import trafficMarker from 'shared/assets/images/icon-traffic-alert.png';

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;
const mapboxStyleURL = process.env.REACT_APP_MAPBOX_STYLE_URL;

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved, max-len
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const InteractiveMap = ({ hideFeatures, toggleFeatures, settings }) => {
  const isMobile = useCheckDevice();
  const {
    hideTrafficAlerts,
    hideAlexRenew,
    hidePendeltonSt,
    hideTunnel,
    hideConstructionSites,
    hideRouteSpine,
    hideRoyalSt,
    hideDukeSt,
    hideHooffsRunInterceptor,
    hideOutfalls,
    hideDiversionFacilities,
    hidePumpingStation,
    hideSidewalkClosures,
    hideSidewalkDetours,
  } = hideFeatures;
  const {
    alexRenew,
    trafficAlerts,
    constructionSites,
    tunnel,
    pendletonSt,
    royalSt,
    dukeSt,
    routeSpine,
    hooffsRunInterceptor,
    outfalls,
    diversionFacilities,
    pumpingStation,
    sidewalkClosures,
    sidewalkDetours,
  } = toggleFeatures;

  const [viewport, setViewport] = useState({
    width: '100%',
    height: '100%',
    latitude: settings.latitude ? settings.latitude : 38.8,
    longitude: settings.longitude ? settings.longitude : -77.05,
    zoom: settings.zoom ? settings.zoom : isMobile ? 13 : 14,
  });

  // track and set changes to the viewport
  function onViewportChange(newViewport) {
    setViewport({ ...viewport, ...newViewport });
  }

  let mapRef = createRef();

  useEffect(() => {
    // load and add images used as directional arrows for the haul routes
    const map = mapRef.current.getMap();
    map.loadImage(arrowBlue, (error, image) => {
      if (error) throw error;
      if (!map.hasImage('arrow-blue')) map.addImage('arrow-blue', image);
    });
    map.loadImage(arrowGreen, (error, image) => {
      if (error) throw error;
      if (!map.hasImage('arrow-green')) map.addImage('arrow-green', image);
    });
    map.loadImage(arrowGray, (error, image) => {
      if (error) throw error;
      if (!map.hasImage('arrow-gray')) map.addImage('arrow-gray', image);
    });
    map.loadImage(arrowPurple, (error, image) => {
      if (error) throw error;
      if (!map.hasImage('arrow-purple')) map.addImage('arrow-purple', image);
    });
    map.loadImage(sidewalkMarker, (error, image) => {
      if (error) throw error;
      if (!map.hasImage('sidewalkMarker')) map.addImage('sidewalkMarker', image);
    });
    map.loadImage(parkingMarker, (error, image) => {
      if (error) throw error;
      if (!map.hasImage('parkingMarker')) map.addImage('parkingMarker', image);
    });
    map.loadImage(laneMarker, (error, image) => {
      if (error) throw error;
      if (!map.hasImage('laneMarker')) map.addImage('laneMarker', image);
    });
     map.loadImage(trafficMarker, (error, image) => {
      if (error) throw error;
      if (!map.hasImage('trafficMarker')) map.addImage('trafficMarker', image);
    });

  }, [mapRef]);
  const [ value, toggleValue ] = useState(false);
  const [ loc, setLoc ] = useState([]);
  const [ desc, setDesc ] = useState('');
  const [ alert, setAlert ] = useState(null);
  const [ color, setColor ] = useState('popup-yellow');
  const [ content, setContent ] = useState(null);
  const [ icon, setIcon ] = useState(null);
  const [ alertId, setAlertId ] = useState(null);

  const getIcon = (_alert) => {
    switch(_alert.alertType) {
      case 'sidewalk':
        return sidewalkMarker;
      case 'parking':
        return parkingMarker;
      case 'lane':
        return laneMarker;
      default:
        return trafficMarker;
    }
  }

  return (
    <ReactMapGL
      ref={mapRef}
      interactiveLayerIds={['clusters','cluster-count','unclustered-point','unclustered-point2','unclustered-point3','unclustered-point4']}
      mapboxApiAccessToken={mapboxToken}
      mapStyle={mapboxStyleURL}
      {...viewport}
      scrollZoom={false}
      width="100%"
      attributionControl={false}
      onViewportChange={newViewport => onViewportChange(newViewport)}
      onClick={event => {
        if(event.features.length <= 0) return;

        const map = mapRef.current.getMap();
        const feature = event.features[0];
        const id = feature?.layer.id;
        const clusterId = feature?.properties.cluster_id;
        const mapboxSource = map.getSource('sidewalkClosures2');
        const _alert = event.features[0].properties.alert ? JSON.parse(event.features[0].properties.alert) : null;

        if(event.features.length > 0 && id.includes('unclustered-point')) {
          setAlert(_alert);

          if(alertId === _alert.id) {
            setAlertId(null);
            return;
          }

          if(_alert) {
            setIcon(getIcon(_alert));
            setColor('#FFC000');
            setLoc(event.lngLat);
            setDesc(event.features[0].properties.name);
            setContent(null);
            toggleValue(!value);

            setAlertId(_alert.id);
          }
        }

        if(id.includes('unclustered-point')) return;

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          map.easeTo({
            center: feature.geometry.coordinates,
            zoom,
            duration: 500
          });

          onViewportChange({...viewport, zoom: zoom});
        });
      }}
    >
      <TrafficAlertPopup
        className={color}
        color={color}
        icon={icon && icon}
        iconWidth="20%"
        latitude={loc[1] ? loc[1] : 0}
        longitude={loc[0] ? loc[0] : 0}
        alert={alert && alert}
        desc={desc && desc}
        showPopup={value}
        content={content}
        offsetTop={-40}
        nullAlert={() => setAlert(null)}
        toggleShowPopup={val => {
          toggleValue(val ? val : !value);
        }}
       />
      {!hideConstructionSites && constructionSites && (
        <ConstructionSiteFeatures />
      )}
      {!hideTrafficAlerts && trafficAlerts && <TrafficAlertFeatures />}
      {!hideOutfalls && outfalls && <OutfallFeatures currIcon={icon} popDesc={desc => setDesc(desc)} popLoc={loc => setLoc(loc)} popValue={(val, icon) => toggleValue(val)} popAlert={alert => setAlert(null)} popContent={content => setContent(null)} popIcon={ico => setIcon(ico)} popColor={col => setColor(col)} />}

      {!hideDiversionFacilities && diversionFacilities && (
        <DiversionFacilityFeatures />
      )}
      {!hideHooffsRunInterceptor && hooffsRunInterceptor && (
        <HooffsRunInterceptorFeature />
      )}
      {!hideAlexRenew && alexRenew && <AlexRenewRoutes popDesc={desc => setDesc(desc)} popLoc={loc => setLoc(loc)} popValue={(val) => toggleValue(val)} popAlert={alert => setAlert(null)} popContent={content => setContent(null)} popIcon={ico => setIcon(ico)} popColor={col => setColor(col)} />}
      {!hidePendeltonSt && pendletonSt && <PendletonStRoutes popDesc={desc => setDesc(desc)} popLoc={loc => setLoc(loc)} popValue={(val) => toggleValue(val)} popAlert={alert => setAlert(null)} popContent={content => setContent(null)} popIcon={ico => setIcon(ico)} popColor={col => setColor(col)} />}
      {!hideRoyalSt && royalSt && <RoyalStRoutes popDesc={desc => setDesc(desc)} popLoc={loc => setLoc(loc)} popValue={(val) => toggleValue(val)} popAlert={alert => setAlert(null)} popContent={content => setContent(null)} popIcon={ico => setIcon(ico)} popColor={col => setColor(col)} />}
      {!hideDukeSt && dukeSt && <DukeStRoutes popDesc={desc => setDesc(desc)} popLoc={loc => setLoc(loc)} popValue={(val) => toggleValue(val)} popAlert={alert => setAlert(null)} popContent={content => setContent(null)} popIcon={ico => setIcon(ico)} popColor={col => setColor(col)} />}
      {!hideRouteSpine && routeSpine && <RouteSpine />}
      {!hidePumpingStation && pumpingStation && <PumpingStationFeature />}
      {!hideSidewalkClosures && sidewalkClosures && <SidewalkClosuresFeature />}
      {!hideSidewalkDetours && sidewalkDetours && <SidewalkDetoursFeature />}
      {!hideTunnel && tunnel && <TunnelLineFeature popDesc={desc => setDesc(desc)} popLoc={loc => setLoc(loc)} popValue={() => toggleValue(!value)} popAlert={alert => setAlert(alert)} popContent={content => setContent(content)} popColor={col => setColor(col)} popIcon={ico => setIcon(ico)} />}
      <NavigationControl style={{ left: '2rem', top: '2rem' }} />
      <AttributionControl
        compact={true}
        style={{ left: '1.5rem', bottom: '1rem' }}
      />
    </ReactMapGL>
  );
};

const ToggleButton = ({ toggle, value, label, indicatorBackgroundColor }) => {
  return (
    <PillButton onClick={toggle} variant="filter">
      <Box
        as="span"
        size="1rem"
        mr=".5rem"
        borderRadius="pill"
        backgroundColor={
          value ? indicatorBackgroundColor : 'normal-gray-background'
        }
        style={{
          transitionDuration: '500ms',
          transitionProperty: 'background-color',
          transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.320, 1)',
        }}
      />
      <Text color={value ? 'default-text' : 'gray-text'}>{label}</Text>
    </PillButton>
  );
};

const Map = ({
  hideTitle,
  hideFilters,
  hideTrafficAlerts,
  hideAlexRenew,
  hideHooffsRunInterceptor,
  hidePendeltonSt,
  hideRouteSpine,
  hideRoyalSt,
  hideDukeSt,
  hideTunnel,
  hidePumpingStation = true, // force this to true until staging area polys are ready
  hideConstructionSites,
  hideOutfalls,
  hideDiversionFacilities = true, // force this to true until staging area polys are ready
  hideSidewalkClosures,
  hideSidewalkDetours,
  mapHeight,
  settings,
  title,
}) => {
  const [trafficAlerts, toggleTrafficAlerts] = useState(
    hideTrafficAlerts ? false : true
  );
  const [constructionSites, toggleConstructionSites] = useState(
    hideConstructionSites ? false : true
  );
  const [pendletonSt, togglePendletonSt] = useState(
    hidePendeltonSt ? false : true
  );
  const [tunnel, toggleTunnel] = useState(hideTunnel ? false : true);
  const [alexRenew, toggleAlexRenew] = useState(hideAlexRenew ? false : true);
  const [royalSt, toggleRoyalSt] = useState(hideRoyalSt ? false : true);
  const [dukeSt, toggleDukeSt] = useState(hideDukeSt ? false : true);
  const [pumpingStation, togglePumpingStation] = useState(
    hidePumpingStation ? false : true
  );
  const [outfalls, toggleOutfalls] = useState(hideOutfalls ? false : true);
  const [diversionFacilities, toggleDiversionFacilities] = useState(
    hideDiversionFacilities ? false : true
  );
  const [hooffsRunInterceptor, toggleHooffsRunInterceptor] = useState(
    hideHooffsRunInterceptor ? false : true
  );
  const [routeSpine, toggleRouteSpine] = useState(
    hideRouteSpine ? false : true
  );

  const [sidewalkClosures, toggleSidewalkClosures] = useState(
    hideSidewalkClosures ? false : true
  );
  const [sidewalkDetours, toggleSidewalkDetours] = useState(
    hideSidewalkDetours ? false : true
  );

  // collect the state of hidden features to pass to InteractiveMap
  const hideFeatures = {
    hideTrafficAlerts,
    hideAlexRenew,
    hidePendeltonSt,
    hideTunnel,
    hideConstructionSites,
    hideRouteSpine,
    hideRoyalSt,
    hideDukeSt,
    hideHooffsRunInterceptor,
    hideOutfalls,
    hidePumpingStation,
    hideDiversionFacilities,
    hideSidewalkClosures,
    hideSidewalkDetours,
  };

  // collect the state of toggled features to pass to InteractiveMap
  const toggleFeatures = {
    trafficAlerts,
    alexRenew,
    pendletonSt,
    tunnel,
    constructionSites,
    royalSt,
    dukeSt,
    routeSpine,
    hooffsRunInterceptor,
    outfalls,
    diversionFacilities,
    pumpingStation,
    sidewalkClosures,
    sidewalkDetours,
  };

  function toggleValue(func, value) {
    func(!value);
  }

  return (
    <Box width="full" backgroundColor="normal-gray-background">
      <Container px={[0, null, 0]}>
        <Flex
          as="header"
          flexDirection={['column', null, null, 'row']}
          pt={['1.5rem', null, null, '0px']}
          overflow="hidden"
        >
          {!hideTitle && (
            <Box
              flex={['0 1 auto', null, null, null, '0 0 auto']}
              pl="1.5rem"
              pr={['1.5rem', null, 0]}
            >
              <Heading
                as="h3"
                paddingBottom={['.5rem', null, null, 0]}
                fontSize={['sm', '1.125rem', null, '1.25rem']}
                lineHeight="sm"
                style={{ textTransform: 'uppercase' }}
              >
                {title || 'Interactive Tunnel Project Map'}
              </Heading>
            </Box>
          )}
          {!hideFilters && (
            <Flex
              width={['full', null, null, 'auto']}
              ml={[0, null, null, 'auto']}
              style={{ overflow: 'hidden' }}
            >
              <Flex
                flexWrap="nowrap"
                width="full"
                pl={['1rem']}
                pr={['1rem', null, null, 0]}
                py="1rem"
                style={{ overflowX: 'scroll' }}
              >
                {!hideSidewalkClosures && (
                  <>
                    <ToggleButton
                      indicatorBackgroundColor="valencia-red-background"
                      toggle={() =>
                        toggleValue(toggleSidewalkClosures, sidewalkClosures)
                      }
                      value={sidewalkClosures}
                      label="Sidewalk Closures"
                    />
                  </>
                )}
                {!hideSidewalkDetours && (
                  <>
                    <ToggleButton
                      indicatorBackgroundColor="purple-background"
                      toggle={() =>
                        toggleValue(toggleSidewalkDetours, sidewalkDetours)
                      }
                      value={sidewalkDetours}
                      label="Sidewalk Detours"
                    />
                  </>
                )}
                {!hideTunnel && (
                  <>
                    <ToggleButton
                      indicatorBackgroundColor="primary-blue-background"
                      toggle={() => toggleValue(toggleTunnel, tunnel)}
                      value={tunnel}
                      label="Waterfront Tunnel"
                    />
                  </>
                )}
                {!hideHooffsRunInterceptor && (
                  <ToggleButton
                    indicatorBackgroundColor="green-background"
                    toggle={() =>
                      toggleValue(
                        toggleHooffsRunInterceptor,
                        hooffsRunInterceptor
                      )
                    }
                    value={hooffsRunInterceptor}
                    label="Hooffs Run Interceptor"
                  />
                )}
                {!hideTrafficAlerts && (
                  <ToggleButton
                    indicatorBackgroundColor="traffic-alert-background"
                    toggle={() =>
                      toggleValue(toggleTrafficAlerts, trafficAlerts)
                    }
                    value={trafficAlerts}
                    label="Alerts"
                  />
                )}
                {!hideOutfalls && (
                  <ToggleButton
                    indicatorBackgroundColor="secondary-blue-background"
                    toggle={() => toggleValue(toggleOutfalls, outfalls)}
                    value={outfalls}
                    label="Outfalls"
                  />
                )}
                {/* {!hideDiversionFacilities && (
                <>
                  <ToggleButton
                    indicatorBackgroundColor="valencia-red-background"
                    toggle={() =>
                      toggleValue(
                        toggleDiversionFacilities,
                        diversionFacilities
                      )
                    }
                    value={diversionFacilities}
                    label="Diversion Facilities"
                  />
                </>
              )} */}
                {!hideConstructionSites && (
                  <ToggleButton
                    indicatorBackgroundColor="primary-orange-background"
                    toggle={() =>
                      toggleValue(toggleConstructionSites, constructionSites)
                    }
                    value={constructionSites}
                    label="Construction Staging Areas"
                  />
                )}
                <>
                  <ToggleButton
                    indicatorBackgroundColor="primary-orange-background"
                    toggle={() => {
                      toggleValue(toggleAlexRenew, alexRenew);
                      toggleValue(togglePendletonSt, pendletonSt);
                      toggleValue(toggleRoyalSt, royalSt);
                      toggleValue(toggleDukeSt, dukeSt);
                    }}
                    value={alexRenew}
                    label="Haul Routes"
                  />
                </>
                {!hideRouteSpine && (
                  <>
                    <ToggleButton
                      indicatorBackgroundColor="primary-blue-background"
                      toggle={() => toggleValue(toggleRouteSpine, routeSpine)}
                      value={routeSpine}
                      label="Main Haul Route"
                    />
                  </>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Container>
      <Box height={mapHeight ? mapHeight : ['25rem', null, '50rem']}>
        <InteractiveMap
          toggleFeatures={toggleFeatures}
          hideFeatures={hideFeatures}
          settings={settings}
        />
      </Box>
    </Box>
  );
};

InteractiveMap.defaultProps = {
  settings: {
    latitude: 38.8,
    longitude: -77.05,
    zoom: 5,
  },
};

export default Map;
