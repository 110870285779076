import { React } from 'react';
import { Flex, Text, Box, ExternalLink } from 'shared/components/primitives';
import { FiDownload, FiVideo } from 'react-icons/fi';

function DocumentListItem(props) {
  return (
    <Flex
      key={props.id}
      width="100%"
      flexDirection="row"
      justifyContent="space-between"
      borderBottom="solid .05rem lightgrey"
      paddingBottom=".5rem"
      paddingTop=".5rem"
    >
      <Text color="#01375C" fontSize="1.25rem" fontWeight="bold">
        {props.title}
      </Text>
      <Box justifyContent="space-between">
        <ExternalLink
          href={props.url}
          download={props.filename}
          target="_blank"
          fontSize=".625rem"
          fontWeight="bold"
        >
          { props.title.toLowerCase() === "meeting recording"
            ?
            <>
              VIEW
              <FiVideo style={{
                  color: '#1890D7',
                  width: '1.25rem',
                  height: '1.25rem',
                  marginLeft: '.750rem',
                }}
              />
            </>
            :
            <>
              DOWNLOAD
              <FiDownload style={{
                  color: '#1890D7',
                  width: '1.25rem',
                  height: '1.25rem',
                  marginLeft: '.750rem',
                }}
              />
            </>
          }

        </ExternalLink>
      </Box>
    </Flex>
  );
}

export default DocumentListItem;
