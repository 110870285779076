import { Box } from 'shared/components/primitives';
import { CardSection } from 'shared/components/cards';
import { EventProvider, useEvents } from 'shared/contexts';
import EventSummaryCard from 'community/events/EventSummaryCard';

const InOurCommunityContent = () => {
  const [events] = useEvents();
  let eventsDisplay = events
    .filter((e) => Date.parse(e.date) >= Date.now())
    .sort((a, b) => {
      return a.date > b.date ? 1 : -1;
    })
    .slice(0, 3);

  return (
    <Box
      display="grid"
      gridTemplateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
      gridAutoFlow="row"
      alignItems="stretch"
      justifyContent="center"
      gridGap={['1.5rem', null, null, '2rem']}
    >
      {eventsDisplay &&
        eventsDisplay.map((event, index) => {
          event.categories = null;
          event.description = null;

          return <EventSummaryCard key={event.id} event={event} />;
        })}
    </Box>
  );
};

export const InOurCommunity = () => {
  return (
    <CardSection
      heading="In Our Community"
      button={{ text: 'View All Events', to: '/community/events' }}
    >
      <EventProvider>
        <InOurCommunityContent />
      </EventProvider>
    </CardSection>
  );
};
