import { React } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

import { longDate } from 'shared/utils/date';

import {
  Box,
  Flex,
  Heading,
  PillLink,
  StyledFeatureCard,
  Text,
} from 'shared/components/primitives';

const FeatureCardContent = ({
  categories,
  description,
  orientation,
  primary,
  subtitle,
  title,
  to,
  date,
}) => {
  let { path } = useRouteMatch();
  const isHorizontal = orientation === 'horizontal';
  const padding = {
    wide: {
      x: primary ? '3rem' : isHorizontal ? '4rem' : '2rem',
      y: primary ? '1.75rem' : isHorizontal ? '4rem' : '1.5rem',
    },
  };

  const publishDate = longDate(date);

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      position={[isHorizontal ? null : 'absolute']}
      bottom={[0, null, null, isHorizontal ? null : 0]}
      width={['full', null, null, isHorizontal ? 'auto' : null]}
      maxWidth={[null, null, null, isHorizontal ? '32.875rem' : null]}
      height={['auto', null, null, isHorizontal ? 'full' : null]}
      px={['1.5rem', null, '2rem', padding.wide.x]}
      py={['1.125rem', null, '1.5rem', padding.wide.y]}
      ml={'auto'}
      bg="rgba(1, 55, 92, .8)"
      color="white"
    >
      {/* {categories && <TagList tags={categories} />} */}
      {publishDate && (
        <Text
          display="block"
          fontSize={['xs', null, null, 'sm']}
          fontWeight="semibold"
        >
          {publishDate}
        </Text>
      )}
      <Heading
        as="h2"
        fontSize={[
          'smplus',
          primary ? 'med' : null,
          null,
          null,
          primary ? 'lg' : 'med',
        ]}
        lineHeight={['xl']}
        mt={categories ? '1.5rem !important' : null}
      >
        {title}
      </Heading>
      <Flex
        alignItems={['center', null, null, isHorizontal ? 'flex-start' : null]}
        flexDirection={['row', null, null, isHorizontal ? 'column' : null]}
        width="full"
      >
        <Box flex="1" pr={'1rem'}>
          <Text
            as="p"
            fontWeight="medium"
            fontSize={['sm', 'smplus']}
            lineHeight={[1.35]}
            mt="0"
            mb="0"
          >
            {description || subtitle}
          </Text>
        </Box>
        <Box
          flex="0"
          justifySelf={isHorizontal ? 'flex-end' : null}
          mt={isHorizontal ? '3rem' : null}
        >
          <PillLink
            to={`${path}/${to}`}
            variant="pill"
            pb={[0, '0.625rem', '0.75rem']}
          >
            <Text display={['none', 'inline']}>View</Text>
            <Text display={['inline', 'none']} fontSize="med">
              <FiArrowRight />
            </Text>
          </PillLink>
        </Box>
      </Flex>
    </Flex>
  );
};

const FeatureCard = ({ orientation, feature, primary, height }) => {
  // Return early if no page content
  if (!feature) return null;

  const { featuredImage, ...rest } = feature;

  return (
    <StyledFeatureCard
      primary={primary}
      backgroundImage={featuredImage}
      height={height}
    >
      <FeatureCardContent
        orientation={orientation}
        primary={primary}
        to={feature.slug}
        {...rest}
      />
    </StyledFeatureCard>
  );
};

export default FeatureCard;
