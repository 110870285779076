import { React } from 'react';

import { Text } from 'shared/components/primitives';
import { useSiteOptions } from 'shared/contexts';

export const Colophon = () => {
  const { colophon } = useSiteOptions();

  return (
    <Text
      as="p"
      color="white"
      fontSize="1.25rem"
      fontWeight="medium"
      m={0}
      pt={['1.75rem', 0]}
      pb={['1rem', 0]}
    >
      {colophon && colophon}
    </Text>
  );
};
