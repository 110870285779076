import {
  createContext,
  React,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_SITEOPTIONS as QUERY } from 'shared/queries';

const Context = createContext();

export const SiteOptionProvider = ({ children }) => {
  let mounted = useRef(false);
  const [state, setState] = useState({});

  const [getData, { loading, error }] = useLazyQuery(QUERY, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (d) => {
      let siteOptions = Object.values(d)[0][0]; // GraphCMS lacks a singular content type, so we're taking the very first SiteOptions only
      if (!siteOptions) return;

      setState((state) => ({ ...state, ...siteOptions }));
    },
  });

  // getData when context is mounted
  useEffect(() => {
    mounted.current = true;
    if (mounted.current === true) getData();
    return () => {
      mounted.current = false;
      setState(null); // empty state on unmount
    };
  }, [getData]);

  // add 'loading' and 'error' into state
  useEffect(() => {
    setState((state) => ({ ...state, loading: loading, error: error }));
  }, [loading, error]);

  // pass state spread over an object as the Provider's value
  const value = { ...state };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useSiteOptions = () => {
  const context = useContext(Context);
  if (!context)
    throw new Error('useSiteOptions must be used within a SiteOptionProvider');
  return context;
};
