import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
  Box,
  Button,
  Flex,
  Heading,
  Section,
  Text,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import { Modal } from 'shared/components/modal/Modal';
import View from 'construction/View';
import Map from 'shared/components/map/Map';

import {
  TrafficAlertProvider,
  usePage,
  useTrafficAlerts,
} from 'shared/contexts';

import { useToggle } from 'shared/hooks';
import { AlertSubscriptionForm } from './AlertSubscriptionForm';

export const TrafficAlertSubscriptionModal = ({ closeModal, isOpen }) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <Box maxWidth="24rem" backgroundColor="normal-gray-background">
        <Box
          as="header"
          py="1.25rem"
          px="3rem"
          backgroundColor="primary-blue-background"
          textAlign="center"
        >
          <Heading as="h3" color="white" fontSize="med">
            Sign Up Today!
          </Heading>
        </Box>
        <Box px="1.5rem" py="1.5rem" textAlign="center">
          <Heading as="h4" mb={[8]}>
            Sign up for alerts.
          </Heading>
          <Text as="p" pb={[16]}>
            Enter your email address to get the traffic updates sent directly to
            your inbox.
          </Text>
          <AlertSubscriptionForm />
        </Box>
      </Box>
    </Modal>
  );
};

const TrafficAlertNotice = ({ openModal }) => {
  const { alerts, loading, error } = useTrafficAlerts();
  const [hasAlerts, setHasAlerts] = useState(false);

  useEffect(() => {
    if (!loading && alerts && alerts.length > 0) setHasAlerts(true);
  }, [alerts, loading]);

  return (
    <Box justifySelf="flex-end" width="full" mt={['2rem']}>
      <Box textAlign="center">
        <Box
          px="3rem"
          py="1.125rem"
          borderRadius="1rem"
          mb="2rem"
          display="grid"
          gridTemplateColumns="1fr"
          gridGap="1rem"
          backgroundColor={
            loading || hasAlerts
              ? 'traffic-alerts-present-background'
              : 'traffic-alerts-clear-background'
          }
        >
          <Button
            onClick={openModal}
            variant="pill"
            px="1rem"
            py="1.5rem"
          >
            Sign up for alerts
          </Button>
          <Box>
            <Heading
              as="h3"
              color={loading || hasAlerts ? null : 'olive-drab-text-color'}
              fontSize={['1.125rem']}
              mb={['.25rem', null, null, 0]}
            >
              {loading && 'Loading Alerts...'}
              {error && 'There was an error getting Alerts.'}
              {hasAlerts && alerts?.length === 1 && 'There is one active alert.'}
              {hasAlerts &&
                  alerts?.length > 1 &&
                  `There are ${alerts?.length} active alerts.`}
              {!loading && !alerts && 'Looks like the roads are all clear!'}
            </Heading>
            <Text color="default-text">
              {hasAlerts ? '' : 'No current alerts.'}
            </Text>
          </Box>
          <Box>
            <Text as="small" color="gray-text">
              Haul routes are subject for approval by the City of Alexandria
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const TrafficAlertsContent = () => {
  const [page] = usePage();
  const { contentItems } = page;

  const { value, toggleValue } = useToggle();

  // toggle modal closed if the outer container is clicked
  function closeModal(e) {
    e.stopPropagation();

    if (e.target.className.includes('modal__container')) {
      toggleValue();
    }
  }

  return (
    <>
      <View>
        <Section py="0 !important" bg="light-gray-background">
          <Container px={[0, null, null, '2rem']}>
            <Flex
              flexDirection={['column', null, null, 'row']}
              alignItems="center"
            >
              <Flex
                flex="1"
                flexDirection="column"
                alignItems="flex-start"
                width="full"
                maxWidth={['full', null, null, '50%']}
                minWidth={['full', null, null, '32rem']}
                pl={['2rem']}
                pr={['2rem', null, null, null, '4rem']}
                py="1.5rem"
              >
                <TrafficAlertNotice openModal={toggleValue} />
                <Box my="auto">
                  {contentItems && (
                    <>
                      <Heading as="h2" variant="section">
                        {ReactHtmlParser(contentItems[0]?.text)}
                      </Heading>
                      <Box lineHeight="xl">
                        {ReactHtmlParser(contentItems[1]?.html)}
                      </Box>
                    </>
                  )}
                </Box>
              </Flex>
              <Flex flex="1" width={['100vw', null, null, '50%', 'full']}>
                <Map
                  hideTitle
                  mapHeight="38rem"
                  settings={{
                    zoom: 13,
                    longitude: -77.05887376461403,
                    latitude: 38.80361889857869,
                  }}
                  hideTunnel={true}
                  hideHooffsRunInterceptor={true}
                  hideOutfalls={true}
                  hidePumpingStation={true}
                  hideDiversionFacilities={true}
                  hideConstructionSites={true}
                />
              </Flex>
            </Flex>
          </Container>
        </Section>
      </View>
      <TrafficAlertSubscriptionModal isOpen={value} closeModal={closeModal} />
    </>
  );
};

function TrafficAlerts() {
  return (
    <TrafficAlertProvider>
      <TrafficAlertsContent />
    </TrafficAlertProvider>
  );
}

export default TrafficAlerts;
