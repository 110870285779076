import { Link } from 'react-router-dom';

import { Flex, Image } from '../primitives';

const Logo = ({ logo }) => {
  return (
    <Flex
      flex="0"
      justifyContent="center"
      position={[null, null, null, 'absolute']}
      left="0"
      right="0"
      width={155}
      px={[0, null, null, 12, 60]}
      mx={[null, null, null, 'auto']}
      zIndex="101"
    >
      <Link to="/" title="Go to Homepage">
        <Image
          src={logo?.url}
          alt="RiverRenew Logo"
          width={155}
          height={75}
          ml={[-5, null, null, 0]}
        />
      </Link>
    </Flex>
  );
};

export default Logo;
