import { React, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Box, Flex, Heading, Section } from 'shared/components/primitives';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';
import FeatureCard from './FeatureCard';

import {
  ProgramUpdateProvider,
  usePage,
  useProgramUpdates,
  RiverRenewerProvider,
  useRenewerItems,
} from 'shared/contexts';

const ProgramUpdatesFeature = () => {
  const { latestProgramUpdate } = useProgramUpdates();

  const programUpdatesPage = {
    title: 'Program Updates',
    description: 'In-depth updates on RiverRenew.',
    featuredImage: latestProgramUpdate?.featuredImage,
    to: 'program-updates',
  };

  return (
    <FeatureCard
      height={['17.5rem', null, null, 'full']}
      primary={true}
      feature={programUpdatesPage}
    />
  );
};

const RiverRenewerFeature = () => {
  const { issues } = useRenewerItems();

  const riverRenewerPage = {
    title: 'The River Renewer',
    description:
      'Sign up for our quarterly e-newsletter and catch up on past editions.',
    featuredImage: issues?.all[0].image,
    to: 'river-renewer',
  };

  return <FeatureCard feature={riverRenewerPage} />;
};

const News = () => {
  const [page] = usePage();
  const { title, contentItems, pageAssets } = page || {};

  const inTheMediaPage = {
    title: 'In the Media',
    description: 'See what our community is saying about RiverRenew.',
    featuredImage: pageAssets && pageAssets[2],
    to: 'in-the-media',
  };

  return (
    <Box bg="light-gray-background">
      <Banner pageTitle={title} />
      <BreadCrumbs />
      <Section>
        <Container>
          <Heading as="h1" variant="section">
            {contentItems && ReactHtmlParser(contentItems[0]?.text)}
          </Heading>
          <Flex
            flexDirection={['column', null, null, 'row']}
            alignItems="stretch"
          >
            <Box
              alignItems="stretch"
              flex="1"
              pr={[0, null, null, 16]}
              mb={[16, null, null, 0]}
            >
              <ProgramUpdateProvider>
                <ProgramUpdatesFeature />
              </ProgramUpdateProvider>
            </Box>
            <Box
              flexGrow={['1', null, null, '0']}
              flexBasis={['auto', null, null, 420]}
            >
              <Box mb={16}>
                <RiverRenewerProvider>
                  <RiverRenewerFeature />
                </RiverRenewerProvider>
              </Box>
              <Box>
                <FeatureCard feature={inTheMediaPage} />
              </Box>
            </Box>
          </Flex>
        </Container>
      </Section>
    </Box>
  );
};

export default News;
