import React from 'react';

import { Layer } from 'shared/components/map/Layer';
import { hooffsRunInterceptor, hooffsRunInterceptor2, hooffsRunInterceptor3, hooffsRunInterceptor4, hooffsRunInterceptor5 } from '../geojson';

const layer = {
  id: 'hoofsRunInterceptor-route',
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#79A440',
    'line-width': 12,
  },
};

export const HooffsRunInterceptorFeature = () => {
  return (
    <>
      <Layer
        key={`hooffsRunInterceptor-layer1`}
        data={hooffsRunInterceptor}
        layer={layer}
      />
    </>
  );
};
