import { React } from 'react';
import { FiMail } from "react-icons/fi";
import { ExternalLink } from 'shared/components/primitives';

export function EmailAsLink({subject, linkUrl, color}) {

  const href = `mailto:?subject=${subject ? encodeURIComponent(subject) : 'RiverRenew link'}&body=${linkUrl ? encodeURIComponent(linkUrl) : '(Please paste the URL here'}`;

  return (
    <ExternalLink
      href={href}
      ml={0}
      mr={5}
      px={4}
    >
      <FiMail color={color ? color : "white"} fontSize="1.25rem" />
    </ExternalLink>

  );
}

