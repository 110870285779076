import { React, useState, useEffect } from 'react';
import styled from 'styled-components';
import { StyledCard, Text, Flex, Box } from '../primitives';
import { AiFillCloseCircle } from 'react-icons/ai';
import { FiMoreHorizontal } from 'react-icons/fi';


const AccordionBodyWrapper = styled.div`
    width: 95%;
    max-height: ${(props) => (props.open ? '400rem' : '0')};
    transition: all 0.3s ease;
    margin: 0.75rem;
    overflow: hidden;
`;

const AccordionSectionWrapper = styled.div`
    width: 100%;
`;

 const AccordionHeader = styled.span`
    font-family: "'Libre Franklin', sans-serif";
    background-color: #EBEDED;
    color: #01375C;
    font-size: 1.275rem;
    width: 100%;
    display: flex;
    align-items: start;
    max-width: 40rem;
    margin: 0 auto;
    position: relative;
 `;


export const Accordion = ({ children, ...props}) => {

    const [openSections, setOpenSections] = useState([]);
    const sections = (children instanceof Array) ? children : [children];

    useEffect(() => {
        if (sections && sections.length >= 1 && props.autoOpenFirstChild) {
            const firstChildLabel = sections[0].props.label;
            setOpenSections([firstChildLabel]);
        }
      }, []);

    const onSectionClick = (label) => {

        let copy = [...openSections];
        const isToggleAction = copy.includes(label);
        copy = [];
        if (!isToggleAction) {
            copy.push(label);
        }
        setOpenSections(copy);
    };

    return (
        <Flex flexDirection="column">
            {props.header &&
                <AccordionHeader>
                    {props.header}
                </AccordionHeader> 
            }
            {sections.map((child,index) => (
                <AccordionSection
                    key={child.props.label}
                    isOpen={openSections.includes(child.props.label) ? true : false} 
                    label={child.props.label}
                    clickHandler={onSectionClick}
                >
                    {child.props.children}
                </AccordionSection>
            ))}
        </Flex>
      );
}

export const AccordionSection = ({children, ...props}) => {

    const onToggleClick = () => {
         props.clickHandler(props.label);
    };

    const open = props.isOpen;

    return (
        <AccordionSectionWrapper>
            <StyledCard maxWidth="40rem"  backgroundColor="#EBEDED" m="0 auto" {...props}>
                <Flex onClick={onToggleClick} flexDirection='row' justifyContent="space-between" margin=".75rem" marginTop="1.2rem">
                    <Text 
                        // color={open ? "#01375C" : "#1890D7"} 
                        color="#01375C"
                        fontSize="1.625rem"
                        >
                            {props.label}
                    </Text>
                    <Box marginRight=".75rem">
                        {open ? <AiFillCloseCircle style={{color: '#1890D7', height:"2.75rem", width:"2.75rem"}} /> : <FiMoreHorizontal style={{color: "#1890D7", height: "2rem", width: "2rem", verticalAlign: "middle"}} />}
                    </Box>
                </Flex>
                <AccordionBodyWrapper open={open}>
                    {children}
                </AccordionBodyWrapper>        
            </StyledCard>
        </AccordionSectionWrapper>
    )
}
