export const validateForm = (form) => {
  const emailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let reqs = form.querySelectorAll('[required]');
  let numErrors = 0;
  reqs.forEach((req) => {
    if (req.value.length === 0) {
      req.classList.toggle('error');
      numErrors++;
    }

    if (req.type === 'email') {
      if (emailRegex.test(req.value) === false) {
        req.classList.toggle('error');
        numErrors++;
      }
    }
  });

  if (numErrors > 0) {
    return Promise.reject(false);
  } else {
    return Promise.resolve(true);
  }
};

export const resetForm = (form) => {
  let fields = form.querySelectorAll('input, textarea');

  fields.forEach((field) => {
    switch (field.type) {
      case 'checkbox':
        field.checked = false;
        break;
      default:
        field.value = '';
        break;
    }

    field.focus();
  });
};
