import styled from 'styled-components';
import {
  background,
  border,
  compose,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  typography,
  variant,
} from 'styled-system';
import { css } from '@styled-system/css';

const composedStyleProps = compose(
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  shadow,
  space,
  typography
);

export const Button = styled('button')(
  (props) =>
    css({
      border: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 2px #005fcc, 0 0 0 4px white',
      },
    }),
  (props) =>
    variant({
      variants: {
        circle: {
          justifyContent: 'center',
          size: '3rem',
          minHeight: '3rem', // width by itself does not work to make dimensions static
          p: 0,
          borderRadius: '100%',
        },
        link: {
          padding: 0,
          border: 'none',
          background: 'none',
          color: '#1890D7',
          fontWeight: '600',
        },
        share: {
          padding: '16px 24px 16px 0',
          background: 'transparent',
          color: '#a5a5a5',
          fontWeight: 600,
          textAlign: 'left',
          textTransform: 'uppercase',
        },
        pill: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          px: [5, 8, 24, null, 48],
          py: [5, 6, null, 8],
          borderRadius: '999px',
          background: '#1890D7',
          color: 'white',
          fontSize: 12,
          fontWeight: 700,
          lineHeight: '15px',
          textTransform: 'uppercase',
          whiteSpace: 'noWrap',
          textAlign: 'center',
          outline: {
            padding: '10px 20px',
            border: '2px solid #a5a5a5',
            borderRadius: '999px',
            background: 'transparent',
            color: '#a5a5a5',
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1,
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
          },
          solid: {
            padding: '10px 20px',
            borderRadius: '999px',
            background: 'transparent',
            color: '#a5a5a5',
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1,
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
          },
        },
        text: {
          padding: '.25rem .5rem',
          backgroundColor: 'transparent',
          color: 'gray-text',
          fontWeight: 'semibold',
          textTransform: 'uppercase',
        },
        primary: {
          width: 'full',
          px: props?.px ? props.px : ['2rem', '3rem'],
          py: props?.py ? props.py : ['.625rem', '1.125rem'],
          borderRadius: 2,
          backgroundColor: 'secondary-blue-background',
          color: 'white-text',
          fontSize: 'xs',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
        sliderControl: {
          display: 'flex',
          alignItems: 'center',
          px: [0],
          backgroundColor: 'transparent',
          fontSize: 'smplus',
          color: 'default-text',
        },
        toggleButton: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 42,
          height: 42,
          p: [4],
          borderRadius: 'circle',
          backgroundColor: 'transparent',
          color: 'white',
          fontSize: 'med',
          _hover: {
            backgroundColor: '#808080',
          },
        },
      },
    }),
  composedStyleProps
);

export const PillButton = styled(Button)(
  (props) =>
    css({
      display: 'inline-flex',
      alignItems: 'center',
      backgroundColor: 'pill-link-background-color',
      border: '0',
      borderRadius: 'pill',
      fontSize: 'xxs',
      color: 'pill-link-color',
      fontWeight: 'bold',
      justifyContent: 'center',
      lineHeight: 'sm',
      px: ['1.5rem', null, '2rem'],
      py: ['.625rem', '.75rem', null, '1rem'],
      textTransform: 'uppercase',
      textDecoration: 'none',
      whiteSpace: 'noWrap',
      '&:hover': {
        color: 'white',
        textDecoration: 'underline',
      },
      '&:focus': {
        color: 'white',
        outline: 'none',
        boxShadow: '0 0 0 2px #005fcc, 0 0 0 4px white',
      },
    }),
  variant({
    variants: {
      filter: {
        pr: ['1rem', null, '1rem'],
        pl: ['.5rem', null, '.5rem'],
        py: ['.5rem', null, null, '.5rem'],
        mr: '.5rem',
        backgroundColor: 'white',
        color: 'default-text',
        textTransform: 'capitalize',
        '&:hover': {
          color: 'default-text',
        },
        '&:focus': {
          color: 'default-text',
        },
      },
    },
  }),
  composedStyleProps
);

export const IconButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
});

export const IconPillButton = styled(PillButton)({
  display: 'inline-flex',
  alignItems: 'center',
});
