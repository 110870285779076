import { React, useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';

import {
  Box,
  Flex,
  StyledNavLink,
  List,
  ListItem,
} from 'shared/components/primitives';

import { headerNavigationItems } from 'shared/data/navigationItems';

const MenuItem = ({ index, item, numItems, ...props }) => {
  const { title, items, path } = item;

  const isFirst = index === 0;
  const isLast = index === numItems - 1;
  const [isOpen, setOpen] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = (e) => {
    e.preventDefault();

    setIsMouseDown(true);
  };

  const handleMouseUp = (e) => {
    setIsMouseDown(false);
  };

  const handleFocus = (e) => {
    if (isMouseDown) return;
    items && setOpen(true);
  };

  const handleBlur = (e) => {
    items && setOpen(false);
  };

  const handleMouseEnter = () => {
    items && setOpen(true);
  };

  const handleMouseLeave = () => {
    items && setOpen(false);
  };

  return (
    <ListItem
      position="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
    >
      {title && (
        <>
          <StyledNavLink
            to={path ? path : '#'}
            variant="primary"
            pl={[null, null, null, isFirst ? 0 : null]}
            pr={[null, null, null, isLast ? 0 : null]}
          >
            {title}
            {items && (
              <Box
                as="span"
                display={['none', null, null, 'inline-block']}
                pl={1}
                style={{ transform: 'translateY(2px)' }}
              >
                <TiArrowSortedDown />
              </Box>
            )}
          </StyledNavLink>
          {items && (
            <SubMenu display={isOpen ? 'block' : 'none'} items={items} />
          )}
        </>
      )}
    </ListItem>
  );
};

const SubMenuItem = ({ item, ...props }) => {
  return (
    <ListItem key={item.title} {...props}>
      <StyledNavLink to={item.path} variant="subMenu">
        {item.title}
      </StyledNavLink>
    </ListItem>
  );
};

const SubMenu = ({ children, items, ...props }) => {
  if (!items) {
    return null;
  }

  const subMenuItems = items.map((item, index) => {
    let isLastItem = index === items.length - 1;

    return <SubMenuItem key={index} item={item} pb={[isLastItem ? 0 : 8]} />;
  });

  return (
    <Box
      position={['absolute']}
      left="50%"
      pt={['.5rem']}
      style={{ transform: 'translateX(-50%)' }}
      {...props}
    >
      <Box
        position="absolute"
        left="50%"
        size="1.5rem"
        borderRadius=".25rem"
        style={{
          transform: 'rotate(45deg) translateX(-50%)',
          transformOrigin: 'center',
        }}
      />
      <List
        bg={'sub-nav-background-color'}
        px={['2rem']}
        py={['2rem']}
        borderBottomWidth={['.25rem']}
        borderBottomColor={'sub-nav-bottom-border'}
        borderBottomStyle={['solid']}
        borderRadius=".25rem"
        boxShadow="0 .25rem .5rem 0 rgba(0, 0, 0, .16)"
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {subMenuItems}
      </List>
    </Box>
  );
};

const WideMenu = ({ items = headerNavigationItems }) => {
  if (!items) {
    return null;
  }

  /**
   * Split items in half to create two menus, this is necessary to get the effect of equally spaced menu items without overlapping the logo
   */
  const midpoint = Math.ceil(items.length / 2);
  const splitItems = [items.slice(0, midpoint), items.slice(midpoint)];

  const menus = splitItems.map((menu, index) => {
    let isFirstMenu = index === 0;

    const menuItems = menu.map((item, index) => {
      let numItems = menu.length;
      return (
        <MenuItem
          key={item.label + index.toString()}
          item={item}
          numItems={numItems}
          index={index}
        />
      );
    });

    return (
      <List
        key={index}
        display="flex"
        flex="1"
        justifyContent={isFirstMenu ? 'flex-end' : 'flex-start'}
        mr={isFirstMenu ? [null, null, null, '5rem', '9rem'] : null}
        ml={!isFirstMenu ? [null, null, null, '5rem', '9rem'] : null}
      >
        {menuItems}
      </List>
    );
  });

  return (
    <Flex
      position="relative"
      zIndex="100"
      display={['none', null, null, 'flex']}
      justifyContent="space-between"
    >
      {menus}
    </Flex>
  );
};

export default WideMenu;
