import { gql } from '@apollo/client';

export const GET_PAST_LISTENING_SESSIONS = gql`
  query GET_PAST_LISTENING_SESSIONS {
    listeningSessions(orderBy: sessionDate_ASC) {
      id
      createdAt
      title
      description
      contents {
        raw
      }
      sessionDate
      videoLink
      presentation {
        fileName
        url
      }
      additionalResource
      thumbnail {
        url(
          transformation: {
            image: { resize: { width: 340, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        fileName
      }
    }
  }
`;
