import React from 'react';
import { useLocation } from 'react-router-dom';

import { Flex, Heading, Section, Box } from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import { pathToTitles } from 'shared/utils/conversion';

import { usePage } from 'shared/contexts';

const Banner = ({
  pageTitle,
  children,
  overlay,
  bannerImageUrl,
  imageUrlTablet,
  imageUrlMobile,
}) => {
  const [{ title, bannerImage }] = usePage();

  let location = useLocation(); // get location from Router
  let titles = pathToTitles(location.pathname); // convert the url path into titles
  let sectionTitle = titles[titles.length - 2]; // get the parent section's title from the second to last member of titles

  return title || pageTitle ? (
    <Section
      backgroundColor="banner-background-color"
      backgroundImage={bannerImageUrl?.length ? `url('${bannerImageUrl}')` : [
        `url("${imageUrlMobile ? imageUrlMobile : bannerImage?.sm}");`,
        `url("${imageUrlTablet ? imageUrlTablet : bannerImage?.md}");`,
        `url("${bannerImageUrl ? bannerImageUrl : bannerImage?.lg}");`,
        `url("${bannerImageUrl ? bannerImageUrl : bannerImage?.xl}");`,
        `url("${bannerImageUrl ? bannerImageUrl : bannerImage?.xxl}");`,
      ]}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      py={bannerImage || bannerImageUrl ? ['2rem', '3rem', '4rem', '7rem'] : ['2rem']}
    >
      {overlay && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="full"
          height="full"
          backgroundColor={['secondary-black-background']}
          opacity={[0.4]}
          zIndex={[0]}
        ></Box>
      )}
      <Container variant="readable">
        <Flex flexDirection="column" textAlign="center" alignContent="center">
          {sectionTitle && (
            <Flex display={['none', 'flex']} flexDirection="column">
              <Heading
                as="h3"
                variant="pill"
                mb={['1rem', null, '2rem']}
                color="header-white"
                fontSize="sm"
                style={{ textTransform: 'uppercase' }}
              >
                {sectionTitle}
              </Heading>
            </Flex>
          )}
          <Heading
            as="h2"
            color="white-text"
            fontSize={['lg', null, null, 'xl']}
            lineHeight={['sm', null, '1.1325']}
            pb={
              bannerImage && bannerImage.caption
                ? ['0.5rem', null, null, 0]
                : [0]
            }
          >
            {pageTitle || title}
          </Heading>
          {/* {bannerImage && bannerImage.caption && (
            <Box
              position={['relative', null, null, 'absolute']}
              right={[0, null, null, '4rem']}
              bottom={[0, null, null, '-5rem']}
            >
              <Text color="white-text" fontSize="sm" fontWeight="medium">
                {bannerImage.caption}
              </Text>
            </Box>
          )} */}
          {children}
        </Flex>
      </Container>
    </Section>
  ) : null;
};

export default Banner;
