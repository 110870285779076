import { gql } from '@apollo/client';

export const GET_EVENTS = gql`
  query GET_EVENTS {
    events(
      where: { categories_every: { displayName_not: "Unlisted" } }
      orderBy: date_DESC
    ) {
      categories {
        id
        displayName
        color
      }
      date
      description {
        html
        text
      }
      id
      location
      title
      virtualEvent
      thumbnail {
        id
        sm: url(
          transformation: {
            image: { resize: { height: 280, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        md: url(
          transformation: {
            image: { resize: { width: 280, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        lg: url(
          transformation: {
            image: { resize: { height: 460, fit: max } }
            document: { output: { format: jpg } }
          }
        )
      }
      endDate
      registrationLink
      agenda {
        url
      }
    }
  }
`;

export const GET_EVENTS_BY_CATEGORY = gql`
  query GET_EVENTS_BY_CATEGORY($category: String!) {
    events(
      where: { categories_some: { displayName_contains: $category } }
      orderBy: date_ASC
    ) {
      id
      categories {
        displayName
        color
      }
      date
      description {
        html
        text
      }
      endDate
      location
      title
      virtualEvent
      registrationLink
      thumbnail {
        id
        url(
          transformation: {
            image: { resize: { width: 400, height: 400, fit: clip } }
          }
        )
      }
    }
  }
`;

export const GET_EVENT = gql`
  query GET_EVENT($id: ID!) {
    event(where: { id: $id }) {
      categories {
        id
        displayName
        color
      }
      date
      description {
        html
        text
      }
      id
      location
      title
      virtualEvent
      thumbnail {
        id
        url
      }
      endDate
      registrationLink
    }
  }
`;
