import { React, useEffect } from 'react';
import { Box, Section } from 'shared/components/primitives';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';
import { SiteMapMenu } from 'shared/components/menu/Menu';
import { headerNavigationItems } from 'shared/data/navigationItems';

function Sitemap() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Box backgroundColor={['light-gray-background']}>
      <Banner pageTitle="Sitemap" />
      <BreadCrumbs />
      <Section>
        <Container>
          <SiteMapMenu data={headerNavigationItems} color="default-text" />
        </Container>
      </Section>
    </Box>
  );
}

export default Sitemap;
