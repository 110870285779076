import { React } from 'react';
import { FaXTwitter } from "react-icons/fa6";
import { ExternalLink } from 'shared/components/primitives';

export function ShareToTwitter({subject, linkUrl, color}) {

  const twitterXUrl = `https://x.com/share?url=${encodeURIComponent(linkUrl)}&text=${encodeURIComponent(subject)}`;

  return (
    <ExternalLink
        href={twitterXUrl}
        ml={0}
        mr={5}
        px={4}
      >
        <FaXTwitter color={color ? color : "white"} fontSize="1.25rem"  />
    </ExternalLink>
  );
}

