import { React, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { IoChevronDownSharp } from 'react-icons/io5';

import {
  Box,
  Figure,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
} from 'shared/components/primitives';

export const Expandable = ({
  title,
  image,
  imageCaption,
  visibleContent,
  hiddenContent,
}) => {
  const [showMore, setShowMore] = useState(false);

  const buttonText = showMore ? 'Show Less' : 'Show More'; // text of the toggle button

  const handleShowMore = (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  return (
    <Flex flexDirection={['column']} alignItems={['stretch']}>
      <Box
        display="grid"
        gridTemplateColumns={['1fr', null, image ? '1fr 1.5fr' : '1fr']}
        gridAutoFlow="row"
        gridGap={['1.5rem', null, '2rem', '3rem']}
        alignItems="center"
        justifyContent="center"
      >
        {image && (
          <Box>
            <Figure caption={imageCaption ? imageCaption : null}>
              <Image
                display={['block', null, 'none']}
                src={image?.sm}
                alt={imageCaption}
                width={['auto']}
                height={['full']}
                maxHeight={['20rem']}
                style={{
                  objectFit: 'cover',
                }}
              />
              <Image
                display={['none', null, 'block']}
                src={image?.md}
                alt={imageCaption}
                width={['auto']}
                height={['full']}
                maxHeight={['20rem']}
                style={{
                  objectFit: 'cover',
                }}
              />
            </Figure>
          </Box>
        )}
        <Box>
          <Heading as="h2" variant="subSection" color="default-text">
            {ReactHtmlParser(title)}
          </Heading>
          {visibleContent && (
            <Box>
              <Text fontSize={['1.25rem']}>
                {ReactHtmlParser(visibleContent.replace('<p></p>', ''))}
              </Text>
            </Box>
          )}
          {showMore && hiddenContent && (
            <Box>
              <Text fontSize={['1.25rem']}>
                {ReactHtmlParser(hiddenContent.replace('<p></p>', ''))}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      {hiddenContent && hiddenContent.length > 0 && (
        <Flex flex="0" borderBottom="videoFilter" mt={['auto']}>
          <Box marginLeft="auto">
            <IconButton onClick={handleShowMore} variant="text" pb={['.75rem']}>
              {buttonText}{' '}
              <Flex
                fontSize="1.5rem"
                ml=".5rem"
                style={{
                  transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                  transitionProperty: 'transform',
                  transitionDuration: '250ms',
                  transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.320, 1)',
                }}
              >
                <IoChevronDownSharp />
              </Flex>
            </IconButton>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
