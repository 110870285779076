const Color = require('color');

// Color Palette
const palette = {
  blacks: {
    'Primary Black': '#000',
    'Secondary Black': '#020202',
  },
  blues: {
    'Primary Blue': '#01375C',
    'Secondary Blue': '#1890D7',
    Lochmara: '#0677BD',
    'Link Water': '#C7E1F0',
    'Venice Blue': '#0A4F7D',
    'Aqua Island': '#9BC2D9',
  },
  grays: {
    Porcelain: '#F6F8F9',
    Iron: '#E6E7E8',
    'Black Haze': '#EBEDED',
    'Silver Chalice': '#A5A5A5',
    'Scarpa Flow': '#58585B',
    Gray: '#7f7f7f',
    'Mine Shaft': '#383838',
    lightgray: '#9D9D9D',
    Alto: '#CECECE',
  },
  greens: {
    Sushi: '#79A440',
    'Olive Drab': '#497412',
  },
  oranges: {
    'Primary Orange': '#EC7D30',
  },
  reds: {
    Valencia: '#D65641',
  },
  purples: {
    Heliotrope: '#DE87F8',
    Plum: '#7940A4',
  },
  whites: {
    'Primary White': '#fff',
  },
  yellows: {
    Amber: '#FFC000',
    Buttercup: '#F5A623',
    Galliano: '#DEA907',
  },
};

const blueGreenGradient = `linear-gradient(${Color(
  palette.blues['Lochmara']
).fade(0.6)}, ${Color(palette.greens['Sushi']).fade(0.6)})`;

// Component Colors
const colors = {
  // Common
  'default-text': palette.blues['Primary Blue'],
  'secondary-text-color': palette.blues['Secondary Blue'],
  'olive-drab-text-color': palette.greens['Olive Drab'],
  'white-text': palette.whites['Primary White'],
  'gray-text': palette.grays['Gray'],
  'rule-color': palette.grays['Silver Chalice'],
  'venice-blue-text-color': palette.grays['Venice Blue'],
  'scarpa-flow-text-color': palette.grays['Scarpa Flow'],
  'valencia-red': palette.reds['Valencia'],

  // Headers
  'header-primary-text-color': palette.blues['Primary Blue'],
  'header-secondary-text-color': palette.blues['Secondary Blue'],
  'header-white': palette.whites['Primary White'],
  'card-header-color': palette.blues['Venice Blue'],

  // Nav
  'nav-link-color': palette.blues['Venice Blue'],
  'nav-link-hover-color': palette.blues['Secondary Blue'],
  'sub-nav-background-color': palette.grays['Porcelain'],
  'sub-nav-bottom-border': palette.blues['Secondary Blue'],
  'sub-nav-link-color': palette.blues['Primary Blue'],
  'sub-nav-link-hover-color': palette.blues['Secondary Blue'],
  'link-dark-color': palette.blues['Primary Blue'],
  'link-light-color': palette.blues['Secondary Blue'],
  'link-light-hover-color': palette.blues['Primary Blue'],
  'link-white-color': palette.whites['Primary White'],
  'pill-link-background-color': palette.blues['Secondary Blue'],
  'pill-link-color': palette.whites['Primary White'],
  'breadcrumb-link-color': palette.blues['Primary Blue'],
  'breadcrumb-current-link-color': palette.blues['Secondary Blue'],
  'fa-bars': palette.blues['Lochmara'],
  'mobile-nav-menu-background-color': Color(palette.blues['Primary Blue']).fade(
    0.025
  ),

  // Borders
  'avatar-border-color': palette.blues['Secondary Blue'],
  'white-border': palette.whites['Primary White'],
  'divider-line': palette.grays['Silver Chalice'],
  'input-border': palette.grays['Alto'],

  // Breadcrumbs
  'dot-divider-color': palette.blues['Secondary Blue'],

  // Footer
  'footer-background-color': palette.blues['Secondary Blue'],
  'footer-mobile-menu-border': palette.blues['Lochmara'],
  'footer-link-color': palette.whites['Primary White'],

  // Buttons
  'button-normal-background-color': palette.blues['Secondary Blue'],
  'button-dark-background-color': palette.blues['Primary Blue'],
  'button-default-text-color': palette.whites['Primary White'],
  'button-hover-background-color': palette.greens['Sushi'],
  'button-disabled-background-color': Color(palette.blues['Secondary Blue'])
    .fade(0.5)
    .hex(),

  // News
  'news-hover-background-color': palette.greens['Sushi'],
  'news-hover-text-color': palette.whites['Primary White'],

  // Events
  'event-date-background-color': palette.greens['Sushi'],
  'event-category-background-color': palette.blues['Venice Blue'],
  'event-category-text-color': palette.whites['Primary White'],
  'event-details-text-color': palette.grays['Gray'],
  'event-details-links': palette.blues['Secondary Blue'],
  'event-details-title': palette.blues['Primary Blue'],
  'event-card-text-color': palette.grays['Silver Chalice'],
  'event-plus-icon-background-color': palette.grays['Silver Chalice'],
  'event-minus-icon-background-color': palette.blues['Lochmara'],
  'event-card-long-date-color': palette.grays['Silver Chalice'],
  'event-card-long-date-light-color': palette.grays['Black Haze'],

  // Backgrounds
  'white-background': palette.whites['Primary White'],
  'black-background': palette.blacks['Primary Black'],
  'secondary-black-background': palette.blacks['Secondary Black'],
  'light-background': palette.blues['Secondary Blue'],
  'medium-background': palette.blues['Link Water'],
  'dark-background': palette.blues['Primary Blue'],
  'banner-background-color': palette.greens['Sushi'],
  'light-gray-background': palette.grays['Porcelain'],
  'normal-gray-background': palette.grays['Black Haze'],
  'dark-gray-background': palette.grays['Silver Chalice'],
  'green-background': palette.greens['Sushi'],
  'event-hero-background': palette.greens['Sushi'],
  'image-background-color': palette.grays['Iron'],
  'banner-gradient-blue-green': blueGreenGradient,
  'primary-orange-background': palette.oranges['Primary Orange'],
  'amber-background': palette.yellows['Amber'],
  'primary-blue-background': palette.blues['Primary Blue'],
  'primary-blue-background-20': Color(palette.blues['Primary Blue']).fade(0.2),
  'primary-blue-background-35': Color(palette.blues['Primary Blue']).fade(0.35),
  'primary-blue-background-50': Color(palette.blues['Primary Blue']).fade(0.5),
  'secondary-blue-background': palette.blues['Secondary Blue'],
  'valencia-red-background': palette.reds['Valencia'],
  'secondary-blue-background-50': Color(palette.blues['Secondary Blue']).fade(
    0.5
  ),
  'secondary-blue-background-35': Color(palette.blues['Secondary Blue']).fade(
    0.35
  ),
  'purple-background': Color(palette.purples['Plum']),

  // Utility Bar
  'utility-bar-background': palette.blues['Primary Blue'],
  'utility-bar-contact-button-background': palette.blues['Secondary Blue'],
  'utility-bar-contact-phone-color': palette.blues['Primary Blue'],

  // Traffic Alert
  'traffic-alert-background': palette.yellows['Amber'],
  'traffic-alerts-present-background': Color(palette.yellows['Amber']).fade(
    0.8
  ),
  'traffic-alerts-clear-background': Color(palette.greens['Sushi']).fade(0.8),
  'traffic-alert-background-closed': palette.blues['Primary Blue'],
  'traffic-alert-text': palette.blues['Primary Blue'],

  // Icons
  'icon-text': palette.whites['Primary White'],
  'icon-background-white': palette.whites['Primary White'],
  'icon-background-dark': palette.blues['Primary Blue'],
  'icon-background-light': palette.blues['Secondary Blue'],

  // File Download
  'file-download-text-color': palette.whites['Primary White'],
  'file-download-icon-color': palette.blues['Venice Blue'],

  // Videos
  'video-card-title': palette.blues['Venice Blue'],

  // Program Updates
  'program-update-card-date': palette.blues['Lochmara'],

  // Contact
  'contact-intro': palette.blues['Venice Blue'],
};

const breakpoints = ['40em', '52em', '64em', '80em'];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const fonts = [];

fonts.system =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
fonts.brand = 'Libre Franklin';
fonts.global = `${fonts.brand}, ${fonts.system}`;
fonts.code =
  "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace";

const fontSizes = [];

fontSizes.xxs = '0.667rem';
fontSizes.xs = '0.778rem';
fontSizes.sm = '0.887rem';
fontSizes.smplus = '1.111rem';
fontSizes.med = '1.625rem';
fontSizes.lg = '2.188rem';
fontSizes.lgplus = '2.5rem';
fontSizes.xl = '3.125rem';
fontSizes.xxl = '4.167rem';
fontSizes.xxxl = '4rem';
fontSizes.xxxxl = '4.5rem';
fontSizes.xxxxxl = '5rem';

const lineHeights = [];

lineHeights.xxs = 0.5;
lineHeights.xs = 0.75;
lineHeights.sm = 1;
lineHeights.med = 1.15;
lineHeights.lg = 1.25;
lineHeights.xl = 1.5;

const fontWeights = [];

fontWeights.regular = 400;
fontWeights.medium = 500;
fontWeights.semibold = 600;
fontWeights.bold = 700;

const radii = [
  0, // 0px (keep array index 0 as value 0 so we can rely on the scale)
  '.125rem', // 2px
  '.25rem', // 4px
  '.325rem', // 6px
  '.5rem', // 8px
  '.625rem', // 10px
  '.75rem', // 12px
  '.825rem', // 14px
  '1rem', // 16px
  '1.125rem', // 18px
  '1.25rem', // 20px
  '1.5rem', // 24px
  '2rem', // 32px
  '3rem', // 48px
  '4rem', // 64px
];

radii.circle = '50%'; //
radii.pill = '100em'; // arbitrarily large radius creates a pill shape at any width or height rather than an oval

const sizes = [
  0, // 0px (keep array index 0 as value 0 so we can rely on the scale)
  '.125rem', // 2px
  '.25rem', // 4px
  '.325rem', // 6px
  '.5rem', // 8px
  '.625rem', // 10px
  '.75rem', // 12px
  '.825rem', // 14px
  '1rem', // 16px
  '1.125rem', // 18px
  '1.25rem', // 20px
  '1.5rem', // 24px
  '2rem', // 32px
  '3rem', // 48px
  '4rem', // 64px
];

sizes.full = '100%';
sizes.half = '50%';
sizes.quarter = '25%';
sizes.third = '33.3333%';
sizes.auto = 'auto';

const space = [
  0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40,
  42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64,
];

// const space = [
//   0, // 0px (keep array index 0 as value 0 so we can rely on the scale)
//   '.125rem', // 2px
//   '.25rem', // 4px
//   '.325rem', // 6px
//   '.5rem', // 8px
//   '.625rem', // 10px
//   '.75rem', // 12px
//   '.825rem', // 14px
//   '1rem', // 16px
//   '1.125rem', // 18px
//   '1.25rem', // 20px
//   '1.5rem', // 24px
//   '2rem', // 32px
//   '3rem', // 48px
//   '4rem', // 64px
// ];

const borders = {
  avatar: `.25rem solid #1890D7`,
  gray: { thin: '1px solid #a5a5a5' },
  videoFilter: `2px solid ${palette.grays['Alto']}`,
};

const shadows = {
  focusRing: '0 0 0 4px rgba(0, 0, 0, .125)',
  card: '0px 0px 30px #00000029',
  scrollToTopButton: '0px 3px 6px #00000029',
};

const theme = {
  breakpoints,
  borders,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  radii,
  sizes,
  space,
  shadows,
};

export default theme;
