import { React, useEffect, useRef, useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';

import {
  Box,
  Heading,
  List,
  ListItem,
  StyledNavLink,
  StyledNavExternalLink,
} from 'shared/components/primitives';
import { useToggle, useWindowDimensions } from 'shared/hooks';

export const SubMenu = ({ data, toggleMenu, ...props }) => {
  const { id, items, title } = data;
  const menuRef = useRef(null);
  const [menuHeight, setMenuHeight] = useState(0);
  const { value, toggleValue } = useToggle();
  const { width } = useWindowDimensions();
  const arrowRotation = value ? '180deg' : '0';

  useEffect(() => {
    if (menuRef.current)
      setMenuHeight(value ? menuRef.current.offsetHeight : '0px'); // fallback value MUST be '0px' as a string otherwise height becomes (I think) equivalent to line-height (1rem)
  }, [menuRef, value]);

  function toggleSubMenu() {
    // value should only toggle if the menu is displayed in narrow viewports
    if (width < '640') {
      toggleValue();
    }
  }
  return (
    <ListItem
      variant="titleItem"
      pb={[value ? '1rem' : 0, null, '.75rem', 0]}
      overflow={['hidden', null, null, 'visible']}
      onClick={toggleSubMenu}
      {...props}
    >
      <Box
        px={['2rem', null, null, 0]}
        pt={['1.5rem', null, null, '1rem']}
        pb={[value ? '1rem' : '1.5rem', '.5rem', null, '1rem']}
      >
        {title}
        <Box as="span" display={['inline-block', 'none']} pl={['.5rem']}>
          <TiArrowSortedDown
            style={{
              transform: `translateY(2px) rotate(${arrowRotation})`,
              transition: 'transform 500ms cubic-bezier(0.23, 1, 0.320, 1)',
            }}
          />
        </Box>
      </Box>
      <Box
        height={[menuHeight, 'auto']}
        px={[null, '.5rem', null, 0]}
        mx={[0, null, null, '-.5rem']}
        opacity={[value ? 1 : 0, 1]}
        style={{
          transitionDuration: '500ms',
          transitionProperty: 'opacity, height',
          transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.320, 1)',
        }}
      >
        <List key={`submenu-${id}`} variant="subMenuList" ref={menuRef}>
          {items.map((item, index) => (
            <ListItem onClick={toggleMenu ? toggleMenu : null} key={`submenu-item-${item.id}-${index}`} mb={[0, 0]}>
              {item.path.startsWith('https://') ? (
                <StyledNavExternalLink
                  href={item.path}
                  variant="mobileFooterSubMenu"
                >
                  {item.title}
                </StyledNavExternalLink>
              ) : (
                <StyledNavLink
                  to={item.path}
                  variant={
                    props.variant ? props.variant : 'mobileFooterSubMenu'
                  }
                >
                  {item.title}
                </StyledNavLink>
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </ListItem>
  );
};

export const SiteMapSubMenu = ({ data, ...props }) => {
  const { id, items, title } = data;

  return (
    <ListItem {...props}>
      <Heading as="h3" variant="sitemap">
        {title}
      </Heading>
      <List key={`submenu-${id}`} variant="siteMapSubMenu">
        {items.map((item, index) => (
          <ListItem key={`submenu-item-${item.id}-${index}`} mb={[0, 0]}>
            {item.path.startsWith('https://') ? (
              <StyledNavExternalLink href={item.path} variant="sitemapSubMenu">
                {item.title}
              </StyledNavExternalLink>
            ) : (
              <StyledNavLink to={item.path} variant="sitemapSubMenu">
                {item.title}
              </StyledNavLink>
            )}
          </ListItem>
        ))}
      </List>
    </ListItem>
  );
};
