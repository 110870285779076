import { React, useEffect } from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import ReactHtmlParser from 'react-html-parser';
import {
  Accordion,
  Box,
  Flex,
  Heading,
  MultiLineText,
  PillLink,
  Section,
  Text,
  TextLink,
  WrappingColumn,
  WrappingColumnItem,
  FetchStatus,
} from 'shared/components';
import Container from 'shared/components/container/Container';
import View from 'community/View';
import { FAQProvider, useFAQs, usePage } from 'shared/contexts';

const IntroSection = () => {
  const [{ contentItems }] = usePage();

  const renderers = {
    h2: ({ children, ...rest }) => (
      <Heading
        as="h2"
        mb={['1.5rem']}
        fontSize={['med', null, null, 'lg']}
        {...rest}
      >
        {children}
      </Heading>
    ),
    p: ({ children, ...rest }) => (
      <Text as="p" fontSize={['smplus', null, 'med']} {...rest}>
        {children}
      </Text>
    ),
    a: ({ children, href, ...rest }) => (
      <TextLink to={href} display="inline" fontSize={['80%']} {...rest}>
        {children}
      </TextLink>
    ),
  };
  return (
    <Section>
      <Container>
        {contentItems?.[0]?.raw && (
          <RichText content={contentItems[0].raw} renderers={renderers} />
        )}      </Container>
    </Section>
  );
};

const FAQSection = () => {
  const { faqs, loading, error } = useFAQs();

  return (
    <Section bg="white">
      <Container>
        <FetchStatus
          loading={{
            status: loading,
            message: 'Fetching Frequently Asked Questions...',
          }}
          error={{
            status: error,
            message:
              'Sorry, there was an issue fetching Frequently Asked Questions.',
          }}
        />

        {!loading && !error && faqs && (
          <WrappingColumn cols="28.125rem 2">
            {faqs?.all?.map((faq) => (
              <WrappingColumnItem key={faq.id}>
                <Accordion>
                  <div label={faq.question}>
                    <MultiLineText
                      color="#01375C"
                      fontSize="1rem"
                      margin="0.125rem"
                    >
                      {ReactHtmlParser(faq.answer.html)}
                    </MultiLineText>
                  </div>
                </Accordion>
              </WrappingColumnItem>
            ))}
          </WrappingColumn>
        )}
      </Container>
    </Section>
  );
};

const CallToActionSection = () => {
  return (
    <Section>
      <Container>
        <Flex
          flexDirection="column"
          textAlign="center"
          alignContent="center"
          justifyContent="center"
        >
          <Heading as="h4" mb={['1rem']} fontSize="lg">
            More Questions?
          </Heading>

          <MultiLineText fontSize="med" color="default-text" maxWidth="45rem">
            We're here to answer your questions.
            <br /> Click below to contact us directly.
          </MultiLineText>

          <Box marginTop="1.625rem">
            <PillLink variant="pill" to="/contact-us">
              <Text>Contact Us</Text>
            </PillLink>
          </Box>
        </Flex>
      </Container>
    </Section>
  );
};

const FAQs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <FAQProvider>
      <View>
        <IntroSection />
        <FAQSection />
        <CallToActionSection />
      </View>
    </FAQProvider>
  );
};

export default FAQs;
