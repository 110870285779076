import { React } from 'react';
import { MdClear } from 'react-icons/md';
import { FaBars } from 'react-icons/fa';

import { Box, Flex, IconButton, Text } from 'shared/components/primitives';
import { Menu } from 'shared/components/menu/Menu';
import Container from 'shared/components/container/Container';
import WideMenu from './WideMenu';
import Logo from './Logo';
import UtilityBar from './UtilityBar';

import { useToggle } from 'shared/hooks';
import { headerNavigationItems } from 'shared/data/navigationItems';
import { SiteOptionProvider, useSiteOptions } from 'shared/contexts';

const HeaderBody = ({ toggleScrolling }) => {
  const { logo } = useSiteOptions();
  const { value, toggleValue } = useToggle();

  function toggleMobileMenu() {
    toggleValue(); // toggle displaying the menu
    toggleScrolling(); // toggle scrolling the site
  }
  return (
    <>
      <Container
        as="nav"
        display="flex"
        justifyContent="space-between"
        height={['5rem', null, null, '7rem']}
        py={['.75rem']}
        px={['2rem', null, null, '1rem']}
        backgroundColor="white"
      >
        {/* DESKTOP MENU */}
        <WideMenu />

        <Logo logo={logo} />

        {/* MOBILE MENU */}
        <Flex
          display={['flex', null, null, 'none']}
          flexDirection="row"
          flex="1"
          position={['fixed', null, null, 'relative']}
          width={['100vw', 'auto']}
          height={['100%', null, null, 'auto']}
          top="0"
          left={[0, 'auto']}
          right={[null, 0]}
          zIndex="110"
          style={{
            transform: `translateX(${value ? 0 : '100%'})`,
            transition: 'transform 750ms cubic-bezier(0.23, 1, 0.320, 1)',
          }}
        >
          <Box
            height={['100vh', null, null, 'auto']}
            onClick={toggleMobileMenu}
            p=".5rem"
          >
            <IconButton bg="transparent" marginTop="2rem" p="0" onClick={toggleMobileMenu}>
              <Text fontSize="xxxl" display="flex" color="white-text">
                <MdClear />
              </Text>
            </IconButton>
          </Box>
          <Box
            bg="mobile-nav-menu-background-color"
            flex="1"
            height={['100%', null, null, 'auto']}
            overflowY="auto"
            pt={'4rem'}
          >
            <Menu key="mobile-header-menu" data={headerNavigationItems} toggleMenu={toggleMobileMenu} />
          </Box>
        </Flex>
        <Flex
          display={['flex', null, null, 'none']}
          flex="1"
          justifyContent="flex-end"
        >
          <IconButton
            bg="transparent"
            border="0"
            pr="0"
            onClick={toggleMobileMenu}
          >
            <Text
              display="inline-block"
              color="default-text"
              fontSize="sm"
              fontWeight="bold"
              lineHeight="sm"
              style={{ textTransform: 'uppercase' }}
            >
              Menu
            </Text>
            <Text
              display="flex"
              color="fa-bars"
              fontSize="med"
              lineHeight="sm"
              pl=".5rem"
            >
              <FaBars />
            </Text>
          </IconButton>
        </Flex>
      </Container>
      <UtilityBar />
      <Box
        bg="rgba(0,0,0, 0.35)"
        display={value ? "flex" : "none"}
        position={['fixed', null, null, 'relative']}
        width={['100vw', 'auto']}
        height={['100%', null, null, 'auto']}
        top="0"
        left={[0, 'auto']}
        right={[null, 0]}
        zIndex="105"
      />

    </>
  );
};

const Header = ({ toggleScrolling }) => {
  return (
    <SiteOptionProvider>
      <Box as="header" zIndex={100}>
        <HeaderBody toggleScrolling={toggleScrolling} />
      </Box>
    </SiteOptionProvider>
  );
};

export default Header;
