export const pageMap = () => {
  const pm = new Map();
  pm.set('home', '/');
  pm.set('search', '/search');
  pm.set(
    'african-american-heritage-park',
    '/construction/african-american-heritage-park'
  );
  pm.set(
    'hooffs-run',
    '/construction/hooffs-run'
  );
  pm.set('alexrenew', '/construction/alexrenew');
  pm.set('waterfront-tunnel', '/construction/waterfront-tunnel');
  pm.set('pendleton-street', '/construction/pendleton-street');
  pm.set('royal-street', '/construction/royal-street');
  pm.set('traffic-alerts', '/construction/alerts');
  pm.set('benefits', '/benefits');
  pm.set('events', '/community/events');
  pm.set('stakeholder-advisory-group', '/community/stakeholder-advisory-group');
  pm.set(
    'community-listening-sessions',
    '/community/community-listening-sessions'
  );
  pm.set('cloes-corner', '/community/cloes-corner');
  pm.set('councilboard-workgroup', '/community/councilboard-workgroup');
  pm.set('videos', '/community/videos');
  pm.set('news', '/news');
  pm.set('in-the-media', '/news/in-the-media');
  pm.set('river-renewer', '/news/river-renewer');
  pm.set('program-updates', '/news/program-updates');
  pm.set('contact-us', '/contact-us');
  pm.set('faqs', '/faqs');
  pm.set('about-river-renew', '/learn-more/about-river-renew');
  pm.set('how-water-works', '/learn-more/how-water-works');
  pm.set('about-alex-renew', '/learn-more/about-alex-renew');
  pm.set('documents', '/learn-more/documents');
  return pm;
};
