import { React, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { pathToTitle } from 'shared/utils/conversion';
import { sendPageView } from 'shared/utils/analytics';

export const SEO = ({ seo }) => {
  const location = useLocation();
  const [oldState, setOldState] = useState(null);
  const [oldPath, setOldPath] = useState(null);
  const path = location.pathname; // pathname provided by router
  const pageTitle = pathToTitle(path); // title resolved from computed router props

  return (
    <Helmet
      defaultTitle="RiverRenew | Investing in Healthier Waterways for Alexandria"
      titleTemplate="RiverRenew | %s"
      onChangeClientState={(newState) => {
        if (oldPath === path) return;

        if (oldState !== newState.title) {
          sendPageView(path, newState.title);
          setOldState(newState.title);
          setOldPath(path);
        }
      }}
    >
      <title>{pageTitle}</title>
      {seo?.metaDescription && (
        <meta name="description" content={seo?.metaDescription} />
      )}
      {seo?.keywords && seo?.keywords.length > 0 && (
        <meta name="keywords" content={seo?.keywords} />
      )}
      {seo?.noindex && <meta name="robots" content="noindex" />}
      {seo?.ogTitle && <meta property="og:title" content={seo?.ogTitle} />}

      {seo?.ogDescription && (
        <meta property="og:description" content={seo?.ogDescription} />
      )}
      {seo?.ogUrl && <meta property="og:url" content={seo?.ogUrl} />}
      {seo?.ogImage && <meta property="og:image" content={seo?.ogImage?.url} />}
      {seo?.twitterCard && (
        <meta name="twitter:card" content={seo?.twitterCard} />
      )}
    </Helmet>
  );
};
