import { React } from 'react';
import styled from 'styled-components';
import { css } from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';
import { variant } from 'styled-system';

export const StyledListItem = styled('li')(
  (props) => css(),
  (props) =>
    variant({
      variants: {
        // a list item that functions as a title
        titleItem: {
          fontSize: props?.fontSize ? props.fontSize : 'sm',
          fontWeight: props?.fontWeight ? props.fontWeight : 'bold',
          textTransform: props?.textTransform
            ? props.textTransform
            : 'uppercase',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    }),
  composedStyledUtilities
);

export const ListItem = (props) => {
  return <StyledListItem {...props} />;
};
