import { React } from 'react';

import {
  Box,
  Select,
  StyledCard,
  Heading,
  Button,
  Flex,
} from 'shared/components/primitives';
import FilterTagList from 'shared/components/tagList/FilterTagList';

export const EventsDateFilter = ({ dateFilterHandler }) => {
  const getYearArray = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2018;

    return Array.from({ length: currentYear - startYear + 1 }, (value, index) => String(currentYear - index));
  };
  const yearsOfEvents = getYearArray();
  const calendarMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dateFilterSelectionHandler = (selection) => {
    // should be object with name=[year] and selection=[month]
    if (dateFilterHandler) {
      dateFilterHandler(selection.selection, selection.name);
    }
  };

  return (
    <Box>
      {yearsOfEvents &&
        yearsOfEvents.map((year) => (
          <Box key={`year-${year}`} py=".5rem">
            <Select
              placeholder={year}
              list={calendarMonths}
              name={year}
              selectionHandler={dateFilterSelectionHandler}
            />
          </Box>
        ))}
    </Box>
  );
};

export const EventsSidebar = ({
  filterToggleHandler,
  dateFilterHandler,
  clearFiltersHandler,
  togglePastEvents,
  toggleValue,
}) => {
  return (
    <StyledCard py={8} px={12} overflow="visible">
      <FilterTagList filterToggleHandler={filterToggleHandler} limit={3} />
      <Box mt="1rem">
        <Heading as="h3" variant="sidebar">
          Past Events
        </Heading>
        <EventsDateFilter dateFilterHandler={dateFilterHandler} />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="stretch"
          mt="1rem"
        >
          <Button variant="pill" onClick={() => togglePastEvents()}>
            {toggleValue ? 'View Upcoming Events' : 'View Past Events'}
          </Button>
          <br />
          <Button variant="pill" onClick={() => clearFiltersHandler()}>
            Clear All
          </Button>
        </Flex>
      </Box>
    </StyledCard>
  );
};
