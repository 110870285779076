import { FiArrowRight } from 'react-icons/fi';

import {
  Box,
  Flex,
  Heading,
  TextLink,
  StyledArticleCard,
  Text,
} from 'shared/components/primitives';
import TagIndicatorList from 'shared/components/tagList/TagIndicatorList';

const ProgramUpdateCardContent = ({ programUpdate, showSubTitle }) => {
  const { categories, featuredImage, date, title, subtitle } = programUpdate;

  const publishDate = date
    ? new Date(date).toLocaleDateString('en-us', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : null;

  return (
    <>
      <Box
        backgroundImage={[
          `url(${featuredImage?.sm})`,
          `url(${featuredImage?.md})`,
          `url(${featuredImage?.lg})`,
        ]}
        backgroundPosition="center"
        backgroundSize="cover"
        width="full"
        maxHeight="14.5rem"
        height={['180px', '235px', null, null]}
      ></Box>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        px={['1.5rem']}
        py={['1.5rem', null, '2.25rem']}
      >
        {categories && (
          <Box
            position={featuredImage ? 'absolute' : null}
            top="0"
            mb={featuredImage ? 0 : '1.5rem'}
            style={{ transform: featuredImage ? 'translateY(-50%)' : null }}
          >
            <TagIndicatorList
              colors={categories.flatMap((category) => category.color)}
            />
          </Box>
        )}
        {publishDate && (
          <Text color="program-update-card-date" fontSize="xs" mb={5}>
            {publishDate}
          </Text>
        )}
        <Heading
          as="h2"
          fontSize={['smplus', 'med']}
          mb="auto"
          color="header-primary-text-color"
        >
          {title}
        </Heading>
        {showSubTitle && (
          <Box mt="1rem">
            <Text>{subtitle}</Text>
          </Box>
        )}
        <Box mt="1rem">
          <TextLink
            to={{ pathname: `/news/program-updates/${programUpdate.slug}` }}
            variant="dark"
            mt=".75rem"
          >
            More
            <Flex pl=".25rem" fontSize="smplus" color="secondary-text-color">
              <FiArrowRight />
            </Flex>
          </TextLink>
        </Box>
      </Flex>
    </>
  );
};

const ProgramUpdateCard = ({ programUpdate, showSubTitle }) => {
  // Return early if no page content
  if (!programUpdate) return null;

  return (
    <StyledArticleCard width="full" minWidth="17.5rem">
      <ProgramUpdateCardContent
        programUpdate={programUpdate}
        showSubTitle={showSubTitle}
      />
    </StyledArticleCard>
  );
};

export default ProgramUpdateCard;
