import { Text, Flex } from 'shared/components/primitives';

export const IconText = ({icon, text, iconSize, ...rest}) => {
  return (
    <Flex alignItems="center">
      <span className="icon-container" style={{ fontSize: iconSize || '0.75rem' }}>{ icon }</span>
      <Text {...rest}>{text}</Text>
    </Flex>
  )
}
