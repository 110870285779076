import { React } from 'react';
import { FaFacebookF, FaXTwitter, FaInstagram } from 'react-icons/fa6';
import { IconButton, List, ListItem } from 'shared/components/primitives';

const SocialMediaLink = ({ children, href, srContent }) => {
  return (
    <ListItem
      color={'white'}
      pr={['1rem', null, null, 0]}
      pl={[0, null, null, '1rem']}
    >
      <IconButton
        as="a"
        href={href}
        rel="noreferrer noopener"
        target="_blank"
        color="white"
        fontSize={'1.25rem'}
        p={['1rem']}
      >
        <span className="sr-only">{srContent}</span>
        {children}
      </IconButton>
    </ListItem>
  );
};

// TODO: Links and screen reader text should come from CMS eventually
function SocialMediaLinks() {
  return (
    <List display="flex" m={0} p={0} style={{ listStyleType: 'none' }}>
      <SocialMediaLink
        href="https://instagram.com/alexrenew"
        srContent="Follow AlexandriaRenew on Instagram"
      >
        <FaInstagram />
      </SocialMediaLink>
      <SocialMediaLink
        href="https://x.com/AlexandriaRenew"
        srContent="Follow AlexandriaRenew on X"
      >
        <FaXTwitter />
      </SocialMediaLink>
      <SocialMediaLink
        href="https://facebook.com/alexandriarenew"
        srContent="Follow AlexandriaRenew on Facebook"
      >
        <FaFacebookF />
      </SocialMediaLink>
    </List>
  );
}

export default SocialMediaLinks;
