import { createGlobalStyle } from 'styled-components';

// NOTE: the 'theme' prop *MUST* be passed to this function
export const GlobalStyles = createGlobalStyle(({ theme }) => {
  return `
    html, body {
      margin: 0;
      color: ${theme.colors['default-text']};
      font-family: ${theme.fonts.global};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 16px;
    }

    /* set button & code to override browser defaults */
    button { font-family: ${theme.fonts.global}; }

    code { font-family: ${theme.fonts.code}; }

    /* Set external link color */
    // a[target="_blank"] {
    //   color: ${theme.colors['secondary-text-color']};
    //   font-weight: ${theme.fontWeights.semibold};
    // }

    /*  Hide any empty paragraphs */
    p:empty {
      display: none;
      margin: none;
    }

    /* Remove margins from first and last paragraphs */
    p:first-of-type {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    p + img,
    P + img + p {
      margin-top: 1rem;
    }

    // Headings
    h1, h2, h3, h4, h5, h6 {
      font-weight: ${theme.fontWeights.bold};

      &:first-of-type {
        margin-top: 0;
      }
    }

    h1 { font-size: ${theme.fontSizes.xxl}; }
    h2 { font-size: ${theme.fontSizes.med}; }
    h3 { font-size: ${theme.fontSizes.med}; }
    h4 { font-size: ${theme.fontSizes.smplus}; }
    h5 { font-size: ${theme.fontSizes.sm}; }
    h6 { font-size: ${theme.fontSizes.xs}; }

    @media screen and (min-width: 52rem) {
      h2 { font-size: ${theme.fontSizes.lg}; }
      h3 { font-size: ${theme.fontSizes.med}; }
    }

    @media screen and (min-width: 64rem) {
      h2 { font-size: ${theme.fontSizes.xl}; }
      h3 { font-size: ${theme.fontSizes.lg}; }
    }

    .heading--section {
      margin-bottom: ${theme.space[12]};
      color: ${theme.colors['header-primary-text-color']}
      font-size: ${theme.fontSizes.med};
      line-height: ${theme.lineHeights.med};
    }

    .heading--subsection {
      margin-bottom: ${theme.space[12]};
      color: ${theme.colors['header-secondary-text-color']}
      font-size: ${theme.fontSizes.lg};
      font-weight: ${theme.fontWeights.semibold}
      line-height: ${theme.lineHeights.med};
    }


    // Alignment
    .text--left { text-align: left; }
    .text--right { text-align: right; }
    .text--center { text-align: center; }

    .align--left, img.align--left {
      margin-right: 1rem;
      display: inline;
      float: left;
    }

    .align--right, img.align--right {
      margin-left: 1rem;
      display: inline;
      float: right;
    }

    .align--center, img.align--center {
      margin-right: auto;
      margin-left: auto;
      display: block;
      clear: both;
    }


    // Captions
    .caption {
      padding-top: .5rem;
      margin-bottom: 1rem;
      text-align: center;
      line-height: ${theme.lineHeights.med};
      font-size: ${theme.fontSizes.sm};
    }

    // Colors
    .text-color--default { color: ${theme.colors['default-text']}}
    .text-color--secondary { color: ${theme.colors['secondary-text-color']}}
    .text-color--white { color: ${theme.colors['white-text']}}
    .text-color--gray { color: ${theme.colors['gray-text']}}
    .text-color--olive-drab { color: ${theme.colors['olive-drab-text-color']}}
    .text-color--venice-blue { color: ${theme.colors['venice-blue-text-color']}}

    .background--white { background-color: ${theme.colors['white-background']}}
    .background--black { background-color: ${theme.colors['black-background']}}
    .background--light { background-color: ${theme.colors['light-background']}}
    .background--medium { background-color: ${theme.colors['medium-background']}}
    .background--dark { background-color: ${theme.colors['dark-background']}}
    .background--light-gray { background-color: ${theme.colors['light-gray-background']}}
    .background--medium-gray { background-color: ${theme.colors['medium-gray-background']}}
    .background--dark-gray { background-color: ${theme.colors['dark-gray-background']}}
    .background--sushi { background-color: ${theme.colors['green-background']}}
    .background--primary-orange { background-color: ${theme.colors['primary-orange-background']}}
    .background--primary-blue { background-color: ${theme.colors['primary-blue-background']}}
    .background--secondary-blue { background-color: ${theme.colors['secondary-blue-background']}}
    .background--valencia-red { background-color: ${theme.colors['valencia-red-background']}}


    // Font Sizes
    .font-size--xxs { font-size: ${theme.fontSizes.xxs}; }
    .font-size--xs { font-size: ${theme.fontSizes.xs}; }
    .font-size--sm { font-size: ${theme.fontSizes.sm}; }
    .font-size--smplus{ font-size: ${theme.fontSizes.smplus}; }
    .font-size--med { font-size: ${theme.fontSizes.med}; }
    .font-size--lg { font-size: ${theme.fontSizes.lg}; }
    .font-size--lgplus { font-size: ${theme.fontSizes.lgplus}; }
    .font-size--xl { font-size: ${theme.fontSizes.xl}; }
    .font-size--xxl { font-size: ${theme.fontSizes.xxl}; }
    .font-size--xxxl { font-size: ${theme.fontSizes.xxxl}; }
    .font-size--xxxxl { font-size: ${theme.fontSizes.xxxxl}; }
    .font-size--xxxxxl { font-size: ${theme.fontSizes.xxxxxl}; }


    // Line Heights
    .line-height--xxs { line-height: ${theme.lineHeights.xxs}; }
    .line-height--sm { line-height: ${theme.lineHeights.sm}; }
    .line-height--med { line-height: ${theme.lineHeights.med}; }
    .line-height--lg { line-height: ${theme.lineHeights.lg}; }
    .line-height--xl { line-height: ${theme.lineHeights.xl}; }


    // Font Weights
    .font-weight--regular { font-weight: ${theme.fontWeights.regular}; }
    .font-weight--medium { font-weight: ${theme.fontWeights.medium}; }
    .font-weight--semibold { font-weight: ${theme.fontWeights.semibold}; }
    .font-weight--bold { font-weight: ${theme.fontWeights.bold}; }


    // Border Radii
    .radius--circle { border-radius: ${theme.radii.circle}; }
    .radius--pill { border-radius: ${theme.radii.pill}; }


    // Widths
    .width--auto { width: auto; }
    .width--full { width: 100%; }
    .width--half { width: 50%; }
    .width--quarter { width: 25%; }
    .width--three-quarters { width: 75%; }
    .width--third { width: 33.3333%; }
    .width--two-thirds { width: 66.6666%; }


    // Carousel
    .carousel {
      width: 100%; // set this to 100% otherwise carousel will ignore viewport boundary
    }

    .carousel__inner-slide img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    .carousel__homepage .carousel__inner-slide img {
      height: 100%;
      max-height: none;
    }

    .carousel__back-button,
    .carousel__next-button {
      transition: all 500ms cubic-bezier(0.23, 1, 0.320, 1);

      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }

    .carousel__dot {
      appearance: none;
      width: 1rem;
      height: 1rem;
      padding: 0;
      margin-left: .75rem;
      margin-right: .75rem;
      border: none;
      border-radius: 1rem;
      background-color: #EBEDED;
      transition: transform 500ms cubic-bezier(0.23, 1, 0.320, 1);

      &:hover,
      &:focus {
         transform: scale(1.25);
      }

      &:active {
         transform: scale(.75);
      }

      &.carousel__dot--selected {
         transform: scale(1.5);
      }
    }

    // Mapbox Overrides
    .mapboxgl-ctrl-logo {
      display: none !important;
    }
    .mapboxgl-popup {
      padding-top: 2.75rem;
      z-index: 1;
    }
    .mapboxgl-popup-content {
      padding: 0 !important;
      border-radius: 0 !important;
      background-color: #01375C !important;
    }
    .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
    .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
      border-top-color: ${theme.colors['dark-background']} !important;
    }
    .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
      border-top-color: #01375C !important;
    }
    .popup-green.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['green-background']};
    }
    .popup-green.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
    .popup-green.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['green-background']};
    }
    .popup-blue.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['secondary-blue-background']};
    }
    .popup-blue.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
    .popup-blue.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['secondary-blue-background']};
    }
    .popup-yellow.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['amber-background']};
    }
    .popup-yellow.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
    .popup-yellow.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['amber-background']};
    }
    .popup-orange.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['primary-orange-background']};
    }
    .popup-orange.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
    .popup-orange.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['primary-orange-background']};
    }
    .popup-gray.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['dark-gray-background']};
    }
    .popup-gray.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
    .popup-gray.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
      border-bottom-color: ${theme.colors['dark-gray-background']};
    }


    // Media
    video {
      display: block;
      width: 100%;
    }

    #home-hero-video {
      & > iframe {
        position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
      }
    }


    `;
});
