import TagManager from 'react-gtm-module';

// First arg must be the event name
// optional: Second arg can be a hash of variables
// ex. `sendGtmEvent('someEvent', { 'eventCategory':'past events' })`
export const sendGtmEvent = (eventName, rest) => {
  if (!eventName) return;

  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...rest,
    },
  });
};

export const sendPageView = (path, title) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'pageView',
      pagePath: path,
      pageTitle: title,
    },
  });

  setTimeout(() => sendGtmEvent('pageTimer'), 10000);
};
