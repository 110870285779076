import { useEffect } from 'react';

import { Box } from 'shared/components/primitives';

import {
  Hero,
  PollutionProblems,
  InteractiveTunnelProjectMap,
  LatestNews,
  Highlights,
  InOurCommunity,
} from './sections';

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Box as="main">
      <Hero />

      <InteractiveTunnelProjectMap />

      <PollutionProblems />

      <LatestNews />

      <Highlights />

      <InOurCommunity />
    </Box>
  );
}

export default Home;
