export const pathToTitle = (path) => {
  return path
    .substring(1)
    .toLowerCase()
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .split('/')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' | ');
};

/**
 * Returns an array of capitalized titles from a given url path
 * @param {string} path The url path to be broken into titles
 * @return {string[]} An array of titles
 */
export const pathToTitles = (path) => {
  let pathTitles = path
    .substring(1)
    .toLowerCase()
    .split('/')
    .map((word) =>
      word
        .split('-')
        .map((words) => words.charAt(0).toUpperCase() + words.slice(1))
        .join(' ')
    );
  return pathTitles;
};

export const objKeyToTitle = (obj) => {
  obj = obj.split('_');
  obj = obj.map(function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  });

  return obj.join(' ');
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
