import { React } from 'react';
import { Box } from 'shared/components/primitives';
import {
  EmailAsLink,
  ShareToFacebook,
  ShareToLinkedIn,
  ShareToTwitter,
} from './index';

export function SharingLinksGroup({ subject, linkUrl, color }) {
  return (
    <Box margin=".5rem 0">
      {/* <div style={{borderColor:"white", borderRadius:"50%", border:"1px solid white", width: "100%", height: "100%"}}>

      </div> */}

      <EmailAsLink subject={subject} linkUrl={linkUrl} color={color} />
      <ShareToFacebook subject={subject} linkUrl={linkUrl} color={color} />
      <ShareToTwitter subject={subject} linkUrl={linkUrl} color={color} />
      <ShareToLinkedIn subject={subject} linkUrl={linkUrl} color={color} />
    </Box>
  );
}
