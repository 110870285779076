import { React, useEffect, useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
  Box,
  Flex,
  Heading,
  Rule,
  Section,
  Text,
  IFrame,
} from 'shared/components/primitives';
import MemberCardList from 'shared/components/memberCardList/MemberCardList';
import Container from 'shared/components/container/Container';
import DocumentList from 'shared/components/documents/DocumentList';
import View from 'community/View';
import EventList from 'community/events/EventList';
import {
  DocumentProvider,
  EventProvider,
  GroupMemberProvider,
  useDocuments,
  useEvents,
  useGroupMembers,
  usePage,
} from 'shared/contexts';
// import { VideoCard } from 'shared/components/videosGrid/VideoCard';

function IntroSection() {
  const [{ contentItems }] = usePage();

  return (
    <Section bg="green-background">
      <Container>
        <Flex
          flexDirection={['column', null, null, 'row']}
          px={[null, null, null, '1.5rem', '3rem']}
          color="white"
        >
          <Box
            flexShrink={[null, null, null, 0]}
            flexBasis={['auto', null, null, '40%']}
            width={['full']}
            pb={['1rem', null, null, 0]}
          >
            <Heading as="h2" variant="section" color="white" mb={[0]}>
              {contentItems && ReactHtmlParser(contentItems[0]?.text)}
            </Heading>
          </Box>
          <Box pl={[null, null, null, '2rem']}>
            <Text lineHeight={[1.35]}>
              {contentItems && ReactHtmlParser(contentItems[1]?.html)}
            </Text>
          </Box>
        </Flex>
      </Container>
    </Section>
  );
}

function MemberSection() {
  const [groupMembers, loading, error] = useGroupMembers(); // get groupMembers
  const [filteredMembers, setFilteredMembers] = useState([]); // create local state to store filtered list of members

  useEffect(() => {
    setFilteredMembers(groupMembers?.filter((member) => !member.stakeholder)); // set local state to filtered list of members who aren't stakeholders
  }, [groupMembers]);

  return (
    <Section bg="white">
      <Container>
        <Heading as="h2" variant="subSection">
          Meet the Council-Board Workgroup
        </Heading>
        <Rule color="rule-color" />
        <MemberCardList
          data={filteredMembers}
          loading={loading}
          error={error}
        />
      </Container>
    </Section>
  );
}

function GroupEvents() {
  const [events, loading, error] = useEvents(); // get events
  const [filteredEvents, setFilteredEvents] = useState([]); // create local state to store filtered list of events

  // memoize the filtering of events
  const cbwgEvents = useMemo(
    () =>
      events.filter((event) => {
        return (
          Date.parse(event.date) >= Date.now() &&
          event.categories.some(
            (category) =>
              category.displayName.includes('Council-Board Workgroup') // TODO: How do we utilize this string dynamically?
          )
        );
      }),
    [events]
  );

  useEffect(() => {
    // set local state to filtered list of events with the 'Council-board Workgroup' category
    setFilteredEvents(cbwgEvents);
  }, [cbwgEvents]);

  return (
    <Box width="full" flex="1" pr={[null, null, null, '1.5rem']}>
      <Heading as="h3" variant="subSection">
        Upcoming Meetings
      </Heading>
      <EventList error={error} loading={loading} data={filteredEvents} />
    </Box>
  );
}

function GroupDocuments() {
  const [documents, loading, error] = useDocuments(); // get documents
  const [quarterlyReports, setQuarterlyReports] = useState([]);
  const [upcomingMeetingMaterials, setUpcomingMeetingMaterials] = useState([]);
  const [pastMeetingMaterials, setPastMeetingMaterials] = useState([]);
  const [page] = usePage();
  const videoUrl = page && page && page.videoUrl ? page?.videoUrl : null;
  const videoCaption =
    page && page.contentItems ? page?.contentItems[2]?.text : null;

  const filterDocs = (groupName) => {
    return documents.filter((document) => {
      return (
        document.group &&
        document.group.length > 0 &&
        document.group === groupName
      );
    })
  };

  useEffect(() => {
    // set local state to filtered list of documents with the 'Council-board WorkGroup' category
    setQuarterlyReports(
      filterDocs('RiverRenew Quarterly Reports')
    );
    setUpcomingMeetingMaterials(
      filterDocs('Upcoming Council-Board Workgroup Meeting Materials')
    );
    setPastMeetingMaterials(
      filterDocs('Past Council-Board Workgroup Meeting Materials')
    );
  }, [documents]);

  return (
    <Box
      width="full"
      pt={['3rem', null, null, 0]}
      flexGrow={[1, null, null, 0]}
      flexShrink="0"
      flexBasis={['auto', null, null, '28rem']}
    >
      <Box>
        <Heading as="h3" variant="subSection">
          Quarterly Reports
        </Heading>
        <DocumentList data={quarterlyReports} loading={loading} error={error} />
      </Box>
      <Box mt={['1.5rem', null, null, '2rem']}>
        <Heading as="h3" variant="subSection">
          Meeting Materials
        </Heading>
        <Box mb={'2rem'}>
          <DocumentList
            mb={'2rem'}
            data={upcomingMeetingMaterials}
            loading={loading}
            error={error}
          />
        </Box>
        <Box>
          <DocumentList
            data={pastMeetingMaterials}
            loading={loading}
            error={error}
          />
        </Box>
      </Box>
      {videoUrl && (
        <Flex mt={['2.5rem']} flexDirection="column" alignItems="stretch">
          <Box height={['15rem']}>
            <IFrame
              src={videoUrl.startsWith('https://') ? videoUrl : `https://`}
            />
          </Box>
          <Box mt={['1rem']}>
            <Text as="p" color="scarpa-flow-text-color">
              {ReactHtmlParser(videoCaption)}
            </Text>
          </Box>
        </Flex>
      )}
    </Box>
  );
}

function GroupInfoSection() {
  return (
    <Section>
      <Container>
        <Flex
          flexDirection={['column', null, null, 'row']}
          alignItems={['flex-start']}
        >
          <EventProvider>
            <GroupEvents />
          </EventProvider>
          <DocumentProvider>
            <GroupDocuments />
          </DocumentProvider>
        </Flex>
      </Container>
    </Section>
  );
}

function CouncilBoard() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <View>
      <IntroSection />
      <GroupMemberProvider>
        <MemberSection />
      </GroupMemberProvider>
      <GroupInfoSection />
    </View>
  );
}

export default CouncilBoard;
