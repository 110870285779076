import { React } from 'react';

import { Text } from 'shared/components/primitives';
import { useSiteOptions } from 'shared/contexts';

export const Copyright = () => {
  const { copyright } = useSiteOptions();
  const year = new Date().getFullYear();
  return (
    <Text as="small" fontSize="1rem">
      &copy; {year} {copyright && copyright}
    </Text>
  );
};
