import { useEffect, useState } from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';

import { useWindowDimensions } from 'shared/hooks';
import { Flex, Text } from 'shared/components/primitives';

export const Carousel = ({ carousel, options }) => {
  const { infinite, slides, visibleSlides } = carousel || {};
  const { width } = useWindowDimensions();
  const [config, setConfig] = useState({});

  const buttonStyles = {
    position: config?.buttonPlacement === 'inside' ? 'absolute' : 'relative',
    height: '100%',
    padding: 0,
    paddingLeft: '.75rem',
    paddingRight: '.75rem',
    border: 0,
    color: 'white',
    backgroundColor: 'transparent',
    zIndex: 1,
  };

  useEffect(() => {
    // Set configuration options
    setConfig((state) => ({
      ...state,
      infinite: infinite,
      visibleSlides: visibleSlides,
      buttonPlacement: 'outside',
      ...options,
    }));
  }, [infinite, visibleSlides, options]);

  return slides && slides.length !== 0 ? (
    <Flex flex="1" justifyContent="center">
      <CarouselProvider
        isIntrinsicHeight
        infinite={config?.infinite}
        totalSlides={slides?.length}
        visibleSlides={config?.visibleSlides}
        step={width < '768' ? 1 : config?.visibleSlides}
        dragStep={width < '768' ? 1 : config?.visibleSlides}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <>
          {slides?.length > 1 && (
            <ButtonBack style={{ ...buttonStyles, left: 0 }}>
              <Text
                display="inline-flex"
                color="secondary-text-color"
                fontSize={['lg']}
                borderRadius="100%"
                background={
                  config?.buttonPlacement === 'inside' ? 'white' : 'transparent'
                }
                justifyContent="center"
                alignItems="center"
              >
                <IoIosArrowDropleftCircle />
              </Text>
            </ButtonBack>
          )}
          <Slider style={{ minWidth: '0.0625rem' }}>
            {slides?.map((slide, i) => {
              return (
                <Slide
                  index={i}
                  key={i}
                  style={{
                    flex: 1,
                    alignItems: 'stretch',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <RichText content={slide?.raw} />
                </Slide>
              );
            })}
          </Slider>
          {slides?.length > 1 && (
            <ButtonNext style={{ ...buttonStyles, right: 0 }}>
              <Text
                display="inline-flex"
                borderRadius="100%"
                background={
                  config?.buttonPlacement === 'inside' ? 'white' : 'transparent'
                }
                justifyContent="center"
                alignItems="center"
                color="secondary-text-color"
                fontSize={['lg']}
              >
                <IoIosArrowDroprightCircle />
              </Text>
            </ButtonNext>
          )}
        </>
      </CarouselProvider>
    </Flex>
  ) : null;
};
