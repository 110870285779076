import { Box } from 'shared/components/primitives';
import { CardSection } from 'shared/components/cards';
import { ProgramUpdateProvider, useProgramUpdates } from 'shared/contexts';
import ProgramUpdateCard from 'news/programUpdates/ProgramUpdateCard';

const LatestNewsContent = () => {
  const { programUpdates } = useProgramUpdates();

  return (
    <Box
      display="grid"
      gridTemplateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
      gridAutoFlow="row"
      alignItems="stretch"
      justifyContent="center"
      gridGap={['1.5rem', null, null, '2rem']}
    >
      {programUpdates &&
        programUpdates.slice(0, 3).map((programUpdate, index) => {
          programUpdate.categories = null;

          return (
            <ProgramUpdateCard
              key={programUpdate?.id}
              programUpdate={programUpdate}
              showSubTitle={true}
            />
          );
        })}
    </Box>
  );
};

export const LatestNews = () => {
  return (
    <CardSection
      heading="Latest News"
      button={{
        text: 'SEE ALL PROGRAM UPDATES',
        to: '/news/program-updates',
      }}
    >
      <ProgramUpdateProvider>
        <LatestNewsContent />
      </ProgramUpdateProvider>
    </CardSection>
  );
};
