import { React } from 'react';
import { FiLinkedin } from "react-icons/fi";
import { ExternalLink } from 'shared/components/primitives';

export function ShareToLinkedIn({subject, linkUrl, color}) {

  const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?mini=true&url=${encodeURIComponent(linkUrl)}&title=${encodeURIComponent(subject)}`;

  return (
    <ExternalLink
      href={linkedInUrl}
      ml={0}
      mr={5}
      px={4}
    >
      <FiLinkedin color={color ? color : "white"} fontSize="1.25rem"  />
    </ExternalLink>
  );
}

