import { React } from 'react';
import { Circle, Flex } from 'shared/components/primitives';

const TagIndicator = ({ height, width, color, margin }) => {
  return (
    <Circle
      height={height}
      width={width}
      backgroundColor={color}
      margin={margin}
    ></Circle>
  );
};

const TagIndicatorList = ({ colors }) => {
  return (
    <Flex flexDirection="row">
      {colors &&
        colors.map((color) => {
          return (
            <TagIndicator
              key={color}
              color={color.replace('hex_', '#')}
              width="15px"
              height="15px"
              margin="0 5px 0 0"
            />
          );
        })}
    </Flex>
  );
};

export default TagIndicatorList;
