import { gql } from '@apollo/client';

export const GET_PROGRAM_UPDATES = gql`
  query GET_PROGRAM_UPDATES {
    programUpdates(
      where: { categories_every: { displayName_not: "Unlisted" } }
      orderBy: date_DESC
    ) {
      id
      date
      title
      subtitle
      slug
      content {
        html
      }
      featuredImage {
        id
        fileName
        sm: url(
          transformation: {
            image: { resize: { width: 375, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        md: url(
          transformation: {
            image: { resize: { width: 640, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        lg: url(
          transformation: {
            image: { resize: { width: 768, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xl: url(
          transformation: {
            image: { resize: { width: 960, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xxl: url(
          transformation: {
            image: { resize: { width: 1280, fit: max } }
            document: { output: { format: jpg } }
          }
        )
      }
      categories {
        id
        color
        displayName
      }
    }
  }
`;

export const GET_PROGRAM_UPDATE_DETAIL = gql`
  query GET_PROGRAM_UPDATE_DETAIL($slug: String!) {
    programUpdate(where: { slug: $slug }) {
      slug
      id
      date
      title
      subtitle
      content {
        html
      }
      featuredImage {
        id
        fileName
        sm: url(
          transformation: {
            image: { resize: { width: 375, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        md: url(
          transformation: {
            image: { resize: { width: 640, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        lg: url(
          transformation: {
            image: { resize: { width: 768, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xl: url(
          transformation: {
            image: { resize: { width: 960, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xxl: url(
          transformation: {
            image: { resize: { width: 1280, fit: max } }
            document: { output: { format: jpg } }
          }
        )
      }
      categories {
        id
        color
        displayName
      }
      content2 {
        html
      }
      carouselItems {
        html
        raw
      }
      carousels {
        id
        slug
        title
        visibleSlides
        infinite
        slides {
          html
          raw
        }
      }
      surveyId
      surveyAppId
      surveyScriptUrl
      surveyTitle
      seo {
        id
        keywords
        metaDescription
        noindex
        ogDescription
        ogTitle
        ogUrl
        twitterCard
        ogImage {
          id
          fileName
          url
        }
      }
      videoUrl
    }
  }
`;

export const GET_RELATED_PROGRAM_UPDATES = gql`
  query GET_RELATED_PROGRAM_UPDATES($cats: [String!]) {
    programUpdates(
      where: { categories_some: { displayName_in: $cats } }
      orderBy: date_DESC
    ) {
      id
      createdAt
      date
      title
      subtitle
      slug
      content {
        html
      }
      featuredImage {
        id
        fileName
        sm: url(
          transformation: {
            image: { resize: { width: 375, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        md: url(
          transformation: {
            image: { resize: { width: 640, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        lg: url(
          transformation: {
            image: { resize: { width: 768, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xl: url(
          transformation: {
            image: { resize: { width: 960, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xxl: url(
          transformation: {
            image: { resize: { width: 1280, fit: max } }
            document: { output: { format: jpg } }
          }
        )
      }
      categories {
        id
        displayName
        color
      }
    }
  }
`;
