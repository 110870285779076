import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Image } from 'shared/components/primitives';
import { Marker } from '../Marker';
import { Popup } from '../Popup';

import { useToggle } from 'shared/hooks';

import { outfalls } from '../geojson';
import markerSVG from 'shared/assets/images/generic-marker.png';
import { Layer } from 'shared/components/map/Layer';

export const Feature = ({ site, popValue, popDesc, popLoc, popAlert, popIcon, popColor }) => {
  const { geometry, properties } = site;
  let latitude = geometry.coordinates[1];
  let longitude = geometry.coordinates[0];
  const [ value, toggleValue ] = useState(false);

  return (
    <>
      <Marker
        latitude={latitude}
        longitude={longitude}
        onClick={() => {
          popAlert();
          popDesc();
          popLoc();
          popIcon(markerSVG);
          popColor('secondary-blue-background');
          toggleValue(!value);
          popValue(!value);

        }}
        children={<Image display={['block']} src={markerSVG} width='25%' />}
      />
    </>
  );
};

const Features = ({popValue, popDesc, popLoc, popAlert, popIcon, popColor}) => {
  const [features, setFeatures] = useState([]);
  const mounted = useRef();

  const featuresMemo = useMemo(
    () =>
      outfalls.features.map((feature, index) => {
        return <Feature key={`outfalls-feature-${index}`} site={feature} popValue={value => popValue(value)} popDesc={desc => popDesc(feature.properties.name)} popLoc={loc => popLoc(feature.geometry.coordinates)} popAlert={alert => popAlert(null)} popIcon={ico => popIcon(ico)} popColor={col => popColor(col)} />;
      }),
    []
  );

  useEffect(() => {
    mounted.current = true;
    if (mounted.current === true) {
      setFeatures(featuresMemo);
    }
    return () => {
      mounted.current = false;
    };
  }, [featuresMemo, setFeatures]);

  return features ? features : null;
};

export const OutfallFeatures = ({ popRef, popValue, popDesc, popLoc, popAlert, popContent, popIcon, popColor }) => {
  return <Features popValue={value => popValue(value)} popDesc={desc => popDesc(desc)} popLoc={loc => popLoc(loc)} popAlert={alert => popAlert(null)} popIcon={ico => popIcon(ico)} popColor={col => popColor(col)} />;
};
