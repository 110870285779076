import { React } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import NotFound from 'shared/components/notFound/NotFound';
import CouncilBoard from './councilBoard/CouncilBoard';
import Events from './events/Events';
import EventDetail from './events/EventDetail';
import Videos from './videos/Videos';
import StakeholderAdvisoryGroup from './stakeholderAdvisoryGroup/StakeholderAdvisoryGroup';
import { DefaultLayout } from 'shared/routes/DefaultLayout';
import CommunityListeningSessions from './listening-sessions/CommunityListeningSessions';
import { CloesCorner } from './cloe/CloesCorner';
import TunnelTour from '../tour/TunnelTour';

const Community = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <DefaultLayout exact path={`${path}/events`} component={Events} />
      <DefaultLayout path={`${path}/events/:id`} component={EventDetail} />
      <DefaultLayout
        path={`${path}/councilboard-workgroup`}
        component={CouncilBoard}
      />
      <DefaultLayout
        path={`${path}/stakeholder-advisory-group`}
        component={StakeholderAdvisoryGroup}
      />
      <DefaultLayout path={`${path}/cloes-corner`} component={CloesCorner} />
      <DefaultLayout
        path={`${path}/community-listening-sessions`}
        component={CommunityListeningSessions}
      />
      <DefaultLayout path={`${path}/videos`} component={Videos} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Community;
