import { Section } from 'shared/components/primitives';
import Map from 'shared/components/map/Map';

export const InteractiveTunnelProjectMap = () => {
  return (
    <Section px="0 !important" pt={['0 !important']} pb={['0 !important']}>
      <Map
        settings={{
          zoom: 14,
          longitude: -77.05887376461403,
          latitude: 38.80361889857869,
        }}
        hideAlexRenew={true}
        hideDukeSt={true}
        hidePendeltonSt={true}
        hideRouteSpine={true}
        hideRoyalSt={true}
      />
    </Section>
  );
};
