import styled from 'styled-components';
import { variant } from 'styled-system';
import { css } from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';

export const StyledTextarea = styled('textarea')(
  (props) =>
    css({
      fontFamily: ['global'], // needed to override user agent stylesheet
      width: 'full',
      height: 'auto',
      px: ['1.75rem'],
      py: ['1.5rem'],
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'scarpa-flow-text-color',
      borderRadius: '.25rem',
      mb: ['1.125rem'],
      color: 'scarpa-flow-text-color',
      fontSize: 'smplus',
      '&::placeholder': {
        opacity: 1,
        fontFamily: 'global',
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 2px #005fcc, 0 0 0 4px white',
      },
      '&:invalid': {
        '&:focus': {
          borderColor: 'valencia-red',
          boxShadow: '0 0 0 2px rgb(214, 86, 65), 0 0 0 4px white',
        },
      },
    }),
  (props) => variant({}),
  composedStyledUtilities
);

export const Textarea = (props) => {
  return <StyledTextarea {...props} />;
};
