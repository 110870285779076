import {
  createContext,
  React,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_RENEWER_ITEMS as QUERY } from 'shared/queries';

const Context = createContext();

const RiverRenewerProvider = ({ children }) => {
  let mounted = useRef(false);
  const [state, setState] = useState({});

  const [getData, { loading, error }] = useLazyQuery(QUERY, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (d) => {
      let renewerIssues = Object.values(d)[0];

      if (!renewerIssues) return; // return if we don't have any items

      const editions = renewerIssues?.reduce((r, a, i) => {
        r[a.edition] = r[a.edition] || {};
        r[a.edition].title = a.edition || '';
        r[a.edition].issues = r[a.edition].issues || [];
        r[a.edition].issues.push(a);
        r[a.edition].issues.sort((a, b) => a.date - b.date);
        return r;
      }, []);

      // const erdiitions = {
      //   2021: [
      //     {
      //       edition: 'Winter',
      //       issues: [
      //         {
      //           // ...issue
      //         },
      //       ],
      //     },
      //     {
      //       edition: 'Fall',
      //       issues: [
      //         {
      //           // ...issue
      //         },
      //       ],
      //     },
      //   ],
      // };

      setState({
        issues: { all: renewerIssues },
        editions: { all: editions, latest: editions[0] },
      });
    },
  });

  // getData when context is mounted
  useEffect(() => {
    mounted.current = true;
    if (mounted.current === true) getData();
    return () => {
      mounted.current = false;
      setState(null); // empty state on unmount
    };
  }, [getData]);

  // add 'loading' and 'error' into state
  useEffect(() => {
    setState((state) => ({ ...state, loading: loading, error: error }));
  }, [loading, error]);

  // pass state spread over an object as the Provider's value
  const value = { ...state };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useRenewerItems = () => {
  const context = useContext(Context);
  if (!context)
    throw new Error(
      'useRenewerItems must be used within a RiverRenewerProvider'
    );
  return context;
};

export { RiverRenewerProvider, useRenewerItems };
