import { gql } from '@apollo/client';

export const GET_SEARCH_RESULTS = gql`
query GET_SEARCH_RESULTS($searchTerm: String!) {
  pages(where: {_search: $searchTerm}) {
    id
    title
    slug
    bannerImage {
      url
    }
    contentSearch
    contentItems {
      html
    }
  }
  documents(where: {_search: $searchTerm}, orderBy: publishedAt_DESC, first: 5) {
    id
    title
    description
    attachment {
      url
    }
  }
  programUpdates(where: {_search: $searchTerm}, orderBy: publishedAt_DESC, first: 5) {
    id
    date
    title
    subtitle
    slug
    content {
      html
    }
    featuredImage {
      id
      fileName
      url
    }
    categories {
      id
      color
      displayName
    }
  }
  renewerNewsItems(where: {_search: $searchTerm}, orderBy: publishedAt_DESC, first: 5) {
    id
    edition
    createdAt
    date
    visibleContent { html }
    hiddenContent { html }
    image { url }
    imageCaption
    title
  }
  faqs(where: {_search: $searchTerm}, orderBy: publishedAt_DESC, first: 5) {
    answer {
      html
      text
    }
    question
    id
  }
  events(where: {_search: $searchTerm}, orderBy: publishedAt_DESC, first: 5) {
    categories {
      id
      displayName
      color
    }
    date
    description {
      html
    }
    id
    location
    title
    virtualEvent
    thumbnail {
      id
      url
    }
    endDate
  }
}

`;
