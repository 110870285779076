import React, { useEffect, useRef, useState } from 'react';
import { Source, Layer as ReactMapGLLayer } from 'react-map-gl';

export const Layer = ({ data, layer }) => {
  const mounted = useRef(null);
  const [layerData, setLayerData] = useState(data);
  const [layerStyle, setLayerStyle] = useState(layer);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      setLayerData(data);
      setLayerStyle(layer);
    }
    return () => {
      mounted.current = false;
    };
  }, [data, layer]);

  return layerData ? (
    <Source key={`source-`} type="geojson" data={layerData}>
      <ReactMapGLLayer key={`layer-`} {...layerStyle} />
    </Source>
  ) : null;
};
