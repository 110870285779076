import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { FaDownload } from 'react-icons/fa';
import {
  CarouselProvider,
  Slide,
  Slider,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';

import { usePage } from 'shared/contexts';
import { useCheckDevice } from 'shared/hooks/hooks';
import { sendGtmEvent } from 'shared/utils/analytics';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';
import {
  Section,
  Box,
  Heading,
  Text,
  Image,
  ExternalLink,
  Flex,
  IFrame,
} from 'shared/components/primitives';
import { formatBytes } from 'shared/utils/conversion';
import { Carousel } from 'shared/components/carousel/Carousel';

import cloe from 'shared/assets/images/cloe.svg';
import bubble from 'shared/assets/images/bubble.svg';

const DownloadLink = ({ label, downloadSrc, downloadTitle, ...rest }) => {
  return (
    <ExternalLink
      variant="pill"
      href={`${downloadSrc}`}
      download={downloadTitle}
      onClick={() => {
        sendGtmEvent('activityDownloadButtonClicked', {
          linkAddress: downloadSrc,
          activityName: downloadTitle,
        });
      }}
      {...rest}
    >
      {label ? label : 'Download'}
      <Text color="white" pl={['.5rem']}>
        <FaDownload />
      </Text>
    </ExternalLink>
  );
};

const ActivityCard = ({ activity }) => {
  const { thumbnails, title, description, attachment } = activity;

  return activity ? (
    <Flex
      alignItems="flex-start"
      flexDirection={['column']}
      height="full"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        width="full"
        height={['9.5rem', null, '14rem']}
        borderRadius=".25rem"
        style={{ overflow: 'hidden' }}
      >
        <Image
          src={thumbnails[0]?.url}
          width="full"
          height="auto"
          style={{
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box pt={['1.5rem']} pb={['1rem', '1.5rem']} mb={['auto']}>
        <Heading as="h3" variant="card">
          {title}
        </Heading>
        <Text as="p">{description}</Text>
      </Box>
      {attachment && (
        <DownloadLink
          downloadSrc={attachment?.url}
          downloadTitle={attachment?.fileName}
        />
      )}
    </Flex>
  ) : null;
};

const IntroSection = ({ contentItems }) => {
  const isMobile = useCheckDevice();
  return (
    <Section pt={['3rem']}>
      <Container>
        <Flex
          alignItems="stretch"
          flexDirection={['column', null, null, 'row']}
          justifyContent={['center']}
        >
          <Box
            display="flex"
            flexDirection="column"
            flexGrow="1"
            flexShrink="0"
            justifyContent="center"
            maxWidth={['full', null, null, '32.75rem', '38.75rem']}
            pl={[0, null, null, null, '2rem']}
            pr={[0, null, '4rem']}
          >
            <Heading as="h2" variant="section">
              {ReactHtmlParser(contentItems[0]?.text)}
            </Heading>
            <Text color="default-text" fontSize={isMobile ? '1rem' : '1.25rem'}>
              {ReactHtmlParser(contentItems[1]?.html)}
            </Text>
          </Box>
          <Box
            flex="1"
            pt={['2rem', null, null, 0]}
            mx={['-2rem', null, null, 0]}
          >
            <Box
              position={['relative', null, null, 'absolute']}
              width="100%"
              height="120%"
              minHeight="20rem"
              overflow={['hidden', null, null, 'visible']}
            >
              <Image
                src={bubble}
                position="absolute"
                bottom={['-40%', null, null, 0]}
                left={['-50%', null, null, '15%']}
                width={['200%', null, null, 'full']}
                height={['120%', null, null, '110%']}
                style={{ objectFit: 'cover', objectPosition: 'top' }}
              />
              <Image
                src={cloe}
                position="absolute"
                bottom={['-10%', null, null, '-5%']}
                left={[0, null, null, '-15%', '-32%']}
                width={['full', null, null, '60%', 'full']}
                height={['120%', null, null, 'full', '120%']}
                style={{ objectFit: 'contain' }}
              />
            </Box>
            <Box
              pr={['4rem', null, null, 0]}
              pl={['4rem', null, null, '50%']}
              pt={['2rem', null, null, 0]}
            >
              <Heading as="h3" variant="subSection" color="default-text">
                Fun Facts:
              </Heading>
              <Text>{ReactHtmlParser(contentItems[2]?.html)}</Text>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Section>
  );
};

const CloesStorybook = ({ carousel, contentItems, pageAssets }) => {
  return (
    <Section bg="white">
      <Container>
        <Flex flexDirection={['column', null, 'row']}>
          <Heading as="h2" variant="section">
            Story Time with Cloe
          </Heading>
          <Box flex="1">{ReactHtmlParser(contentItems[3]?.html)}</Box>
          <Box
            flex="1"
            lineHeight="1.35"
            pt={['1.5rem', '2rem', 0]}
            pl={[0, null, '1.5rem', '2rem']}
          >
            <DownloadLink
              label={`Download eBook Format (${formatBytes(
                pageAssets[2]?.size
              )})`}
              downloadSrc={pageAssets[2]?.url}
              downloadTitle={pageAssets[2]?.fileName}
            />
          </Box>
        </Flex>
        <Carousel carousel={carousel} />
      </Container>
    </Section>
  );
};

const FeaturedActivitySection = ({ activity }) => {
  const { document, carousel, video } = activity || {};
  const { title, description, attachment, thumbnails } = document || {};

  return activity ? (
    <Section bg="white">
      <Container display={['block', null, 'none']}>
        <Heading as="h2" variant="section">
          Learning Activities
        </Heading>
      </Container>
      <Container px={[0, null, '2rem', null, '4rem']}>
        <Flex
          alignItems="stretch"
          flexDirection={['column', null, 'row']}
          flexWrap={['wrap', null, 'nowrap']}
          bg="normal-gray-background"
          borderRadius=".25rem"
          overflow="hidden"
        >
          <Box
            flex={1}
            order={[1, null, 0]}
            maxWidth={['full', null, '28rem']}
            px={['2rem', null, '2.75rem']}
            pt={['2.75rem', null, '3.25rem']}
            pb={['3.25rem']}
          >
            {/* <Box
              display="inline"
              bg="green-background"
              px={['1rem']}
              py={['.25rem']}
            >
              <Text
                color="white"
                fontWeight="bold"
                style={{ textTransform: 'uppercase' }}
              >
                Featured Activity
              </Text>
            </Box> */}
            <Box pt={[0, null, '1.5rem']} pb={['1rem', '1.5rem']} mb={['auto']}>
              <Heading as="h3" variant="card">
                {title}
              </Heading>
              <Text as="p">{description}</Text>
            </Box>
            {attachment && (
              <DownloadLink
                downloadSrc={attachment?.url}
                downloadTitle={attachment?.fileName}
              />
            )}
          </Box>
          <Flex flex="1" order={[0, null, 1]}>
            <Container
              position="absolute"
              zIndex={1}
              alignSelf="flex-start"
              px={['2rem', null, 0]}
            >
              <Box
                display="inline-block"
                bg="green-background"
                px={['1rem']}
                py={['.25rem']}
              >
                <Text
                  color="white"
                  fontWeight="bold"
                  style={{ textTransform: 'uppercase' }}
                >
                  Featured Activity
                </Text>
              </Box>
            </Container>
            {thumbnails && (
              <Image
                position="absolute"
                src={thumbnails[0].url}
                width="full"
                height="full"
                style={{ objectFit: 'cover' }}
              />
            )}
            {video && (
              <IFrame
                src={video.startsWith('https://') ? video : `https://${video}`}
                width={['full']}
                height={['full']}
                minHeight={['20rem']}
              />
            )}
            {/* {carousel && (
              <Carousel
                carousel={carousel}
                options={{ buttonPlacement: 'inside' }}
              />
            )} */}
          </Flex>
        </Flex>
      </Container>
    </Section>
  ) : null;
};

const ActivitySection = ({ activities }) => {
  return (
    <Section>
      <Container px={[0, null, '2rem', null, '4rem']}>
        <Box display={['block', null, null, 'none']}>
          <CarouselProvider isIntrinsicHeight totalSlides={activities.length}>
            <Slider style={{ paddingLeft: '2rem', paddingRight: '4rem' }}>
              {activities.map((activity, i) => (
                <Slide
                  index={i}
                  key={`slide-${activity.id}`}
                  style={{
                    minWidth: '15rem',
                    maxWidth: '30rem',
                    paddingRight: '2rem',
                  }}
                >
                  <ActivityCard activity={activity} />
                </Slide>
              ))}
            </Slider>
            <Box mt={['1.5rem', '2rem']} textAlign="center">
              <ButtonBack
                style={{
                  display: 'inline-flex',
                  color: '#1890D7',
                  fontSize: '2rem',
                  border: 0,
                  backgroundColor: 'transparent',
                  paddingLeft: 0,
                  paddingRight: '.25rem',
                }}
              >
                <IoIosArrowDropleftCircle />
              </ButtonBack>
              <ButtonNext
                style={{
                  display: 'inline-flex',
                  color: '#1890D7',
                  fontSize: '2rem',
                  border: 0,
                  backgroundColor: 'transparent',
                  paddingLeft: '.25rem',
                  paddingRight: 0,
                }}
              >
                <IoIosArrowDroprightCircle />
              </ButtonNext>
            </Box>
          </CarouselProvider>
        </Box>

        <Box
          display={['none', null, null, 'grid']}
          gridGap={['2rem']}
          gridTemplateColumns={[
            '1fr',
            'repeat(2, 1fr)',
            null,
            'repeat(3, 1fr)',
          ]}
          gridAutoFlow="row"
          alignItems="stretch"
          justifyContent="center"
        >
          {activities.map((activity) => {
            return (
              <ActivityCard
                key={`activity-${activity.id}`}
                activity={activity}
              />
            );
          })}
        </Box>
      </Container>
    </Section>
  );
};

export const CloesCorner = () => {
  const [{ contentItems, documents, pageAssets, carousels, videoUrl }] =
    usePage();

  const [cloesCarousel, setCloesCarousel] = useState({});
  const [activities, setActivities] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    // Set state of Cloe's Storybook Carousel
    setCloesCarousel((state) => ({
      ...state,
      ...carousels?.find((carousel) =>
        carousel?.slug?.includes('the-backhoe-brothers-dig-deep-carousel')
      ),
    }));

    // Set state of Activities
    setActivities((state) => ({
      ...state,
      all: documents?.slice(1),
      featured: {
        document: documents ? documents[0] : {},
        carousel:
          carousels?.find((carousel) =>
            carousel?.slug?.includes('featured-activity')
          ) || {},
        video: videoUrl ? videoUrl : '',
      },
    }));
  }, [carousels, documents, videoUrl]);

  if (!contentItems || contentItems.length === 0) {
    return <>Loading...</>;
  }

  return (
    <Box bg="light-gray-background">
      <Banner />
      <BreadCrumbs />

      <IntroSection contentItems={contentItems} />

      <CloesStorybook
        carousel={cloesCarousel}
        contentItems={contentItems}
        pageAssets={pageAssets}
      />

      {activities?.featured && (
        <FeaturedActivitySection activity={activities?.featured} />
      )}

      {activities?.all && <ActivitySection activities={activities?.all} />}
    </Box>
  );
};
