import { React, useState } from 'react';
import { IoCloseCircle } from 'react-icons/io5';

import { Box, Flex, List, ListItem, Text } from 'shared/components/primitives';

const Tag = ({ tag, clickHandler }) => {
  const [isToggledOn, setIsToggledOn] = useState(false);
  const { displayName, color } = tag;

  return (
    <Box
      as="span"
      display="flex"
      variant="pill"
      bg={color ? `${color.replace('hex_', '#')}` : ''}
      onClick={
        clickHandler
          ? () => {
              setIsToggledOn(!isToggledOn);
              clickHandler(displayName);
            }
          : null
      }
      style={{
        cursor: clickHandler ? 'pointer' : 'default',
      }}
    >
      <Text>{displayName}</Text>
      {isToggledOn && (
        <Flex pl=".5rem">
          <IoCloseCircle />
        </Flex>
      )}
    </Box>
  );
};

const TagList = ({ tags, clickHandler }) => {
  // Don't render if there are no tags
  if (!tags) return null;

  const tagItems = tags.map((tag, index) => {
    return (
      <ListItem key={`${tag.id}-listitem-${index}`} mr={[2]} mt={[2]}>
        <Tag tag={tag} clickHandler={clickHandler} />
      </ListItem>
    );
  });

  return (
    <List display="flex" flexWrap="wrap">
      {tags && tagItems}
    </List>
  );
};

export default TagList;
