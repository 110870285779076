import { React } from 'react';
import { List, ListItem } from 'shared/components/primitives';
import EventSummaryCard from '../events/EventSummaryCard';
import { useCheckDevice } from 'shared/hooks/hooks';

const SessionList = ({ events }) => {
  const isMobile = useCheckDevice();

  return (
    <>
      <List
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="baseline"
        mx={!isMobile ? '-1rem' : 0}
      >
        {events &&
          events.map((event) => {
            // setting these to null to control the display:
            event.thumbnail = null;
            event.categories = null;
            return (
              <ListItem
                key={event.id}
                display="flex"
                flexGrow="0"
                flexShrink="1"
                flexBasis={!isMobile ? ['33.3333%'] : []}
                maxWidth={!isMobile ? ['33.3333%'] : []}
                mx=".75rem"
              >
                <EventSummaryCard event={event} />
              </ListItem>
            );
          })}
      </List>
    </>
  );
};

export default SessionList;
