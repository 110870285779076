export const tbmProgress = {
  geometry: {
    coordinates: [
      -77.04081927293672,
      38.79377285322002
    ],
    type: "Point"
  },
  type: "Feature",
  properties: {}
};

export const constructionSites = {
  type: 'FeatureCollection',
  features: [
    //{
    //geometry: {
    //coordinates: [-77.04526019266518, 38.79399083702671],
    //type: 'Point',
    //},
    //type: 'Feature',
    //properties: {
    //name: 'Royal Street',
    //link: '/construction/royal-street',
    //},
    //},
    //{
    //geometry: {
    //coordinates: [-77.03877030759013, 38.80922524497868],
    //type: 'Point',
    //},
    //type: 'Feature',
    //properties: {
    //name: 'Pendleton Street',
    //link: '/construction/pendleton-street',
    //},
    //},
    //{
    //geometry: {
    //coordinates: [-77.05919948777098, 38.79803165705496],
    //type: 'Point',
    //},
    //type: 'Feature',
    //properties: {
    //name: 'AlexRenew',
    //link: '/construction/alexrenew',
    //},
    //},
    //{
    //geometry: {
    //coordinates: [-77.05929687640955, 38.80166641363513],
    //type: 'Point',
    //},
    //type: 'Feature',
    //properties: {
    //name: 'Hooffs Run Site',
    //link: '/construction/hooffs-run',
    //},
    //},
  ],
};

export const haulRoutes = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        route: 'Pendleton Street 2',
      },
      geometry: {
        coordinates: [
          [-77.050811, 38.803164],
          [-77.049089, 38.811171],
          [-77.048588, 38.813434],
        ],
        type: 'LineString',
      },
      id: '023dffa979e80ed45273e9110de9e51e',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Pendleton Street 1',
      },
      geometry: {
        coordinates: [
          [-77.051858, 38.803368],
          [-77.050154, 38.811319],
          [-77.039125, 38.809899],
        ],
        type: 'LineString',
      },
      id: '0e6cfcaee43adbad883a02c68cbb2688',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Duke Street',
      },
      geometry: {
        coordinates: [
          [-77.068081, 38.8055],
          [-77.063387, 38.804924],
          [-77.062213, 38.804741],
          [-77.061521, 38.804631],
          [-77.059514, 38.804366],
          [-77.059467, 38.804219],
          [-77.059666, 38.803341],
          [-77.059772, 38.803058],
          [-77.060207, 38.800952],
          [-77.060362, 38.800626],
        ],
        type: 'LineString',
      },
      id: '24e9d38ffe5bbe09995560aaa21bb8f4',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Royal Street Out',
      },
      geometry: {
        coordinates: [
          [-77.045733, 38.794201],
          [-77.045788, 38.794491],
          [-77.045602, 38.795448],
          [-77.045509, 38.795579],
          [-77.045063, 38.797645],
          [-77.045081, 38.797798],
          [-77.04484, 38.798965],
          [-77.051388, 38.799778],
        ],
        type: 'LineString',
      },
      id: '4cd19cbb358afa5a7b3e1bda30062f31',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Route Spine In',
      },
      geometry: {
        coordinates: [
          [-77.051853, 38.803367],
          [-77.051933, 38.803008],
          [-77.051933, 38.802774],
          [-77.051813, 38.80243],
          [-77.051372, 38.801962],
          [-77.051252, 38.801416],
          [-77.051302, 38.800798],
          [-77.051526, 38.799736],
          [-77.05173, 38.799001],
          [-77.051867, 38.798652],
        ],
        type: 'LineString',
      },
      id: '637f531695504ce75c9389b709c45ae0',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Eisenhower Ave',
      },
      geometry: {
        coordinates: [
          [-77.073133, 38.801159],
          [-77.072997, 38.801354],
          [-77.072731, 38.801404],
          [-77.072411, 38.801362],
          [-77.070089, 38.801014],
          [-77.061688, 38.800393],
          [-77.061409, 38.800376],
          [-77.061303, 38.800313],
          [-77.061191, 38.800221],
          [-77.061098, 38.800143],
          [-77.06088, 38.80008],
          [-77.060582, 38.800002],
          [-77.060488, 38.799915],
          [-77.06042, 38.799794],
          [-77.060463, 38.799479],
        ],
        type: 'LineString',
      },
      id: 'cb975770b58fcefe25a357ac99f375a9',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Duke Street 2',
      },
      geometry: {
        coordinates: [
          [-77.059526, 38.804361],
          [-77.056599, 38.803994],
          [-77.055645, 38.803851],
          [-77.055097, 38.803784],
          [-77.054163, 38.803656],
          [-77.053893, 38.803604],
          [-77.052978, 38.803484],
          [-77.052554, 38.803429],
          [-77.05235, 38.803386],
          [-77.051851, 38.803357],
        ],
        type: 'LineString',
      },
      id: 'dd87386f0abf6a8b30f6d9ab486fad01',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Royal Street In',
      },
      geometry: {
        coordinates: [
          [-77.045725, 38.794206],
          [-77.045788, 38.79449],
          [-77.045604, 38.795452],
          [-77.045604, 38.795604],
          [-77.045163, 38.797663],
          [-77.04508, 38.797786],
          [-77.051705, 38.798607],
        ],
        type: 'LineString',
      },
      id: 'e8b4bea52136439b15db29fafc612fd6',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Route Spine Out',
      },
      geometry: {
        coordinates: [
          [-77.051371, 38.799767],
          [-77.051156, 38.800927],
          [-77.050952, 38.802102],
          [-77.050797, 38.803171],
        ],
        type: 'LineString',
      },
      id: 'f97460bb829478fc52c66b10365501a2',
    },
  ],
};

export const pendletonStRoutes = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        route: 'Pendleton Street 2',
      },
      geometry: {
        coordinates: [
          [-77.050811, 38.803164],
          [-77.049089, 38.811171],
          [-77.04908589840643, 38.81115206323338],
          [-77.04883140897957, 38.81230011952664],
          [-77.04107617853685, 38.81131905438957],
          [-77.04131727378328, 38.810191856487904],
        ],
        type: 'LineString',
      },
      id: '023dffa979e80ed45273e9110de9e51e',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Pendleton Street 1',
      },
      geometry: {
        coordinates: [
          [-77.051858, 38.803368],
          [-77.050154, 38.811319],
          [-77.039125, 38.809899],
        ],
        type: 'LineString',
      },
      id: '0e6cfcaee43adbad883a02c68cbb2688',
    },
  ],
};

export const alexrenewRoutes = {
  type: 'Feature',
  properties: {
    route: 'Eisenhower Ave',
  },
  geometry: {
    coordinates: [
      [-77.073133, 38.801159],
      [-77.072997, 38.801354],
      [-77.072731, 38.801404],
      [-77.072411, 38.801362],
      [-77.070089, 38.801014],
      [-77.061688, 38.800393],
      [-77.061409, 38.800376],
      [-77.061303, 38.800313],
      [-77.061191, 38.800221],
      [-77.061098, 38.800143],
      [-77.06088, 38.80008],
      [-77.060582, 38.800002],
      [-77.060488, 38.799915],
      [-77.06042, 38.799794],
      [-77.060463, 38.799479],
    ],
    type: 'LineString',
  },
  id: 'cb975770b58fcefe25a357ac99f375a9',
};

export const dukeStRoutes = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        route: 'Duke Street',
      },
      geometry: {
        coordinates: [
          [-77.068081, 38.8055],
          [-77.063387, 38.804924],
          [-77.062213, 38.804741],
          [-77.061521, 38.804631],
          [-77.059514, 38.804366],
          [-77.059467, 38.804219],
          [-77.059666, 38.803341],
          [-77.059772, 38.803058],
          [-77.060207, 38.800952],

        ],
        type: 'LineString',
      },
      id: '24e9d38ffe5bbe09995560aaa21bb8f4',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Duke Street 2',
      },
      geometry: {
        coordinates: [
          [-77.059526, 38.804361],
          [-77.056599, 38.803994],
          [-77.055645, 38.803851],
          [-77.055097, 38.803784],
          [-77.054163, 38.803656],
          [-77.053893, 38.803604],
          [-77.052978, 38.803484],
          [-77.052554, 38.803429],
          [-77.05235, 38.803386],
          [-77.051851, 38.803357],
        ],
        type: 'LineString',
      },
      id: 'dd87386f0abf6a8b30f6d9ab486fad01',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Duke Street 3',
      },
      geometry: {
        coordinates: [
          [
            -77.05975424663288,
            38.80310908154115
          ],
          [
            -77.05535229341409,
            38.80266627603484
          ],
          [
            -77.05510411207509,
            38.80379618808564
          ]
        ],
        type: 'LineString',
      },
      id: 'dd87386f0abf6a8b30f6d9ab486fad02',
    },
  ],
};

export const royalStRoutes = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        route: 'Royal Street In',
      },
      geometry: {
        coordinates: [
          [-77.045725, 38.794206],
          [-77.045788, 38.79449],
          [-77.045604, 38.795452],
          [-77.045604, 38.795604],
          [-77.045163, 38.797663],
          [-77.04508, 38.797786],
          [-77.051705, 38.798607],
        ],
        type: 'LineString',
      },
      id: 'e8b4bea52136439b15db29fafc612fd6',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Royal Street Out',
      },
      geometry: {
        coordinates: [
          [-77.045733, 38.794201],
          [-77.045788, 38.794491],
          [-77.045602, 38.795448],
          [-77.045509, 38.795579],
          [-77.045063, 38.797645],
          [-77.045081, 38.797798],
          [-77.04484, 38.798965],
          [-77.051388, 38.799778],
        ],
        type: 'LineString',
      },
      id: '4cd19cbb358afa5a7b3e1bda30062f31',
    },
  ],
};

export const routeSpine = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        route: 'Route Spine In',
      },
      geometry: {
        coordinates: [
          [-77.051853, 38.803367],
          [-77.051933, 38.803008],
          [-77.051933, 38.802774],
          [-77.051813, 38.80243],
          [-77.051372, 38.801962],
          [-77.051252, 38.801416],
          [-77.051302, 38.800798],
          [-77.051526, 38.799736],
          [-77.05173, 38.799001],
          [-77.051867, 38.798652],
        ],
        type: 'LineString',
      },
      id: '637f531695504ce75c9389b709c45ae0',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Route Spine Out',
      },
      geometry: {
        coordinates: [
          [-77.051371, 38.799767],
          [-77.051156, 38.800927],
          [-77.050952, 38.802102],
          [-77.050797, 38.803171],
        ],
        type: 'LineString',
      },
      id: 'f97460bb829478fc52c66b10365501a2',
    },
  ],
};

export const hooffsRunInterceptor = {
  type: 'Feature',
  properties: {},
  geometry: {
    coordinates: [
      [-77.058008, 38.803735],
      [-77.060265, 38.79955],
      [-77.059687, 38.798832],
      [-77.059888, 38.797748],
      [-77.059386, 38.79765]
    ],
    type: 'LineString',
  },
};

export const unionStMulti = {
  geometry: {
    coordinates: [
      [
        [
          -77.03946193271425,
          38.810058601406844
        ],
        [
          -77.03948508904651,
          38.80997858736808
        ],
        [
          -77.0392878842301,
          38.809950475433276
        ],
        [
          -77.03907765911323,
          38.80983809359019
        ],
        [
          -77.03907609812471,
          38.80962383733805
        ],
        [
          -77.03912487952043,
          38.809347469383525
        ],
        [
          -77.03926437445301,
          38.808786772197394
        ],
        [
          -77.03872778439599,
          38.80873016451129
        ],
        [
          -77.03849637185093,
          38.8087000394428
        ],
        [
          -77.03846515633279,
          38.80889160140685
        ],
        [
          -77.03828615633279,
          38.80979860140685
        ],
        [
          -77.03888962706478,
          38.80992509742088
        ],
        [
          -77.03903307776713,
          38.80996355317292
        ],
        [
          -77.03914386622695,
          38.81002138806967
        ],
        [
          -77.03946193271425,
          38.810058601406844
        ]
      ]
    ],
    type: "Polygon"
  },
  type: "Feature",
  properties: {}
};

export const hooffsRunInterceptorMulti = {
  "geometry": {
    "coordinates": [
      [
        [
          [
            -77.06042595603057,
            38.799652585628735
          ],
          [
            -77.06047388955376,
            38.799420974354774
          ],
          [
            -77.06018628841531,
            38.79936867493055
          ],
          [
            -77.05986034045863,
            38.79925660460688
          ],
          [
            -77.06019587512029,
            38.797583000137706
          ],
          [
            -77.06005207455067,
            38.79755311398654
          ],
          [
            -77.05972612659478,
            38.79741115459646
          ],
          [
            -77.05942893875218,
            38.79744851235816
          ],
          [
            -77.05927555147836,
            38.797381268373215
          ],
          [
            -77.05883456306694,
            38.79729908119447
          ],
          [
            -77.05870993590645,
            38.797926689976435
          ],
          [
            -77.05977406011719,
            38.79809853427457
          ],
          [
            -77.05944811216128,
            38.79945085972261
          ],
          [
            -77.05955356591188,
            38.79945833106294
          ],
          [
            -77.05960149943508,
            38.79921177642825
          ],
          [
            -77.05967819307158,
            38.799226719157645
          ],
          [
            -77.06007124796061,
            38.799607757699135
          ],
          [
            -77.06042595603057,
            38.799652585628735
          ]
        ]
      ],
      [
        [
          [
            -77.06046430284883,
            38.79903246343093
          ],
          [
            -77.06060810341766,
            38.79809853427457
          ],
          [
            -77.06150925365041,
            38.79815830610693
          ],
          [
            -77.06137503978577,
            38.799114648610725
          ],
          [
            -77.06046430284883,
            38.79903246343093
          ]
        ]
      ],
      [
        [
          [
            -77.06079025080551,
            38.79986178226187
          ],
          [
            -77.06089570455609,
            38.79915947685049
          ],
          [
            -77.06057934330437,
            38.79913706273385
          ],
          [
            -77.06056016989523,
            38.7995405157516
          ],
          [
            -77.06059851671348,
            38.79971235615827
          ],
          [
            -77.06069438375908,
            38.799824425765166
          ],
          [
            -77.06079025080551,
            38.79986178226187
          ]
        ]
      ],
      [
        [
          [
            -77.06028,
            38.799801
          ],
          [
            -77.059971,
            38.799807
          ],
          [
            -77.059891,
            38.800135
          ],
          [
            -77.059695,
            38.800668
          ],
          [
            -77.059409,
            38.801188
          ],
          [
            -77.05958,
            38.801221
          ],
          [
            -77.059529,
            38.801354
          ],
          [
            -77.059777,
            38.801407
          ],
          [
            -77.059777,
            38.801514
          ],
          [
            -77.059694,
            38.801507
          ],
          [
            -77.059686,
            38.801462
          ],
          [
            -77.059316,
            38.801402
          ],
          [
            -77.059216,
            38.801646
          ],
          [
            -77.058979,
            38.802213
          ],
          [
            -77.058642,
            38.802851
          ],
          [
            -77.058516,
            38.802867
          ],
          [
            -77.058047,
            38.803902
          ],
          [
            -77.058107,
            38.803928
          ],
          [
            -77.05809186503744,
            38.80401228422087
          ],
          [
            -77.05787306769419,
            38.803979544288765
          ],
          [
            -77.05769751370943,
            38.803953544214814
          ],
          [
            -77.057736,
            38.803732
          ],
          [
            -77.057895,
            38.803753
          ],
          [
            -77.057955,
            38.803552
          ],
          [
            -77.058021,
            38.803567
          ],
          [
            -77.058325,
            38.802826
          ],
          [
            -77.058397,
            38.802836
          ],
          [
            -77.058437,
            38.802723
          ],
          [
            -77.058371,
            38.802723
          ],
          [
            -77.058477,
            38.802336
          ],
          [
            -77.058582,
            38.802342
          ],
          [
            -77.058781,
            38.802002
          ],
          [
            -77.058886,
            38.801971
          ],
          [-77.0595, 38.800728],
          [
            -77.059829,
            38.799694
          ],
          [
            -77.059935,
            38.799711
          ],
          [
            -77.059921,
            38.79976
          ],
          [
            -77.060112,
            38.799694
          ],
          [
            -77.060211,
            38.799727
          ],
          [-77.06028, 38.799801]
        ]
      ]
    ],
    "type": "MultiPolygon"
  },
  "type": "Feature",
  "properties": {}
};

export const royalStMulti = {
  "type": "Feature",
  "properties": {},
  "geometry": {
    "coordinates": [
      [
        [
          -77.04574904468164,
          38.79420177660224
        ],
        [
          -77.04592,
          38.793461
        ],
        [
          -77.045802,
          38.793444
        ],
        [
          -77.045802,
          38.793554
        ],
        [
          -77.045272,
          38.793503
        ],
        [
          -77.04524,
          38.793705
        ],
        [
          -77.045413,
          38.793747
        ],
        [-77.04538, 38.79384],
        [
          -77.045153,
          38.793924
        ],
        [
          -77.04511601117058,
          38.79420489360247
        ],
        [
          -77.0453135167555,
          38.79421544945842
        ],
        [
          -77.0455110223404,
          38.79422600531438
        ],
        [
          -77.04564602234042,
          38.79422211702077
        ],
        [
          -77.04574904468164,
          38.79420177660224
        ]
      ]
    ],
    "type": "Polygon"
  }
};

export const outfall00 = {
  type: "FeatureCollection",
  features: [
    {
      geometry: {
        coordinates: [-77.03910666791658, 38.809877460243456],
        type: 'Point',
      },
      type: 'Feature',
      properties: {
        name: 'Outfall 001',
      }
    },
    {
      geometry: {
        coordinates: [-77.03910666791658, 38.809877460243456],
        type: 'Point',
      },
      type: 'Feature',
      properties: {
        name: 'Outfall 002',
      }
    }
  ],
};

export const outfall001 = {
  geometry: {
    coordinates: [-77.03910666791658, 38.809877460243456],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Outfall 001',
    cluster: true,
  },
};

export const outfall002 = {
  geometry: {
    coordinates: [-77.04582099440599, 38.79247902406139],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Outfall 002',
    cluster: true,
  },
};

export const outfall003 = {
  geometry: {
    coordinates: [
      -77.05797788353303,
      38.80364018455421
    ],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Outfall 003',
    cluster: true,
  },
};

export const outfall004 = {
  geometry: {
    coordinates: [-77.05793389289225, 38.803950107642216],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Outfall 004',
    cluster: true,
  },
};

export const outfalls = {
  type: 'FeatureCollection',
  features: [outfall001, outfall002, outfall003, outfall004],
};

export const diversionFacilities = {
  type: 'FeatureCollection',
  features: [
    {
      geometry: {
        coordinates: [-77.0454582854638, 38.79381497534368],
        type: 'Point',
      },
      type: 'Feature',
      properties: {
        name: 'Diversion Facility',
      },
    },
    {
      geometry: {
        coordinates: [-77.03848003192745, 38.80976469307319],
        type: 'Point',
      },
      type: 'Feature',
      properties: {
        name: 'Diversion Facility',
      },
    },
  ],
};

export const pumpingStation = {
  geometry: {
    coordinates: [-77.05974033311557, 38.79749566944744],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Pumping Station',
  },
};

export const sidewalkClosures = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        route: 'Sidewalk Closure 1',
      },
      geometry: {
        coordinates: [
          [
            -77.05808873575921,
            38.804030561193514
          ],
          [
            -77.057840828992,
            38.80399410982065
          ]
        ],
        type: 'LineString',
      },
      id: '637f531695504ce75c9389b709c45ae0',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Sidewalk Closure 2',
      },
      geometry: {
        coordinates: [
          [
            -77.057929701229,
            38.80395401328835
          ],
          [
            -77.05828519017868,
            38.80303907720315
          ]
        ],
        type: 'LineString',
      },
      id: 'f97460bb829478fc52c66b10365501a2',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Sidewalk Closure 3',
      },
      geometry: {
        coordinates: [
          [
            -77.05843954722188,
            38.80305730313626
          ],
          [
            -77.05812147816233,
            38.80302449645339
          ]
        ],
        type: 'LineString',
      },
      id: 'f97460bb829478fc52c66b10365501a3',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Sidewalk Closure 4',
      },
      geometry: {
        coordinates: [
          [
            -77.05958085384947,
            38.802973463805614
          ],
          [
            -77.05869213147609,
            38.80288233398585
          ]
        ],
        type: 'LineString',
      },
      id: 'f97460bb829478fc52c66b10365501a4',
    },
    {
      type: 'Feature',
      properties: {
        route: 'Sidewalk Closure 5',
      },
      geometry: {
        coordinates: [
          [
            -77.06038785887719,
            38.79992449588926
          ],
          [
            -77.06033004966946,
            38.799662841693305
          ]
        ],
        type: 'LineString',
      },
      id: 'f97460bb829478fc52c66b10365501a5',
    },
  ],
};

export const sidewalkDetours = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        route: 'Sidewalk Detour 3',
      },
      geometry: {
        coordinates:[
          [
            -77.05974106445166,
            38.803127785345765
          ],
          [
            -77.05948992220812,
            38.80424834711192
          ],
          [
            -77.0551072438397,
            38.80368039335042
          ],
          [
            -77.05532391557999,
            38.8027133265293
          ]
        ],
        type: 'LineString',
      },
      id: 'f97460bb829478fc52c66b10365501a3',
    },
  ],
};

export const sidewalkDetours1Start = {
  geometry: {
    coordinates: [
      -77.05948262663932,
      38.804121689544104
    ],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Sidewalk Detour Start',
  },
};

export const sidewalkDetours1End = {
  geometry: {
    coordinates: [
      -77.05727953065053,
      38.80384830414209
    ],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Sidewalk Detour Start',
  },
};

export const sidewalkDetours2Start = {
  geometry: {
    coordinates: [
      -77.05764750872727,
      38.802912637606795
    ],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Sidewalk Detour Start',
  },
};

export const sidewalkDetours2Start2 = {
  geometry: {
    coordinates: [
      -77.05982281918563,
      38.80268208133347
    ],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Sidewalk Detour Start 2',
  },
};

export const sidewalkDetours2End = {
  geometry: {
    coordinates: [-77.05963364042866, 38.803360747344385],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Sidewalk Detour Start',
  },
};

export const sidewalkDetours3Start = {
  geometry: {
    coordinates: [
      -77.05974054470107,
      38.80312750151171
    ],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Sidewalk Detour Start 3',
  },
};

export const sidewalkDetours3End = {
  geometry: {
    coordinates: [
      -77.05532347265843,
      38.802713034653635
    ],
    type: 'Point',
  },
  type: 'Feature',
  properties: {
    name: 'Sidewalk Detour End 3',
  },
};
