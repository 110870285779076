import { React, useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';
import {
  Box,
  Flex,
  Heading,
  Section,
  Button,
} from 'shared/components/primitives';
import FilterTagList from 'shared/components/tagList/FilterTagList';
import { CategoryProvider, usePage } from 'shared/contexts';
import { GET_MEDIA_MENTIONS } from 'shared/queries';
import { useLazyQuery } from '@apollo/client';
import MentionCard from 'news/inTheMedia/MentionCard';

function InTheMedia() {
  const data = usePage();
  const contentItems = data[0]?.contentItems;
  const mounted = useRef(true);
  const initialState = JSON.parse(
    localStorage.getItem('com.riverrenew.mediaMentions')
  );
  const [mediaMentions, setMediaMentions] = useState(
    initialState ? initialState : []
  );
  const [categories, setCategories] = useState([]);
  const [activeFilters, setactiveFilters] = useState([]);
  const [showCount, setShowCount] = useState(9); // temp way to handle "Load More"

  const [getMediaMentions] = useLazyQuery(GET_MEDIA_MENTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (mounted.current && data) {
        const sortedMentions = data.mediaMentions.sort((a, b) => {
          return Date.parse(b.date) - Date.parse(a.date);
        });

        const stringifiedData = JSON.stringify(data.mediaMentions);

        if (stringifiedData !== JSON.stringify(mediaMentions)) {
          setMediaMentions(sortedMentions);
          localStorage.setItem(
            'com.riverrenew.mediaMentions',
            JSON.stringify(sortedMentions)
          );
        }
      }
    },
  });

  useEffect(() => {
    mounted.current = true;
    getMediaMentions();
    return () => (mounted.current = false);
  }, [getMediaMentions]);

  useEffect(() => {
    setCategories(
      mediaMentions.map((mediaMention) => mediaMention.categories).flat(2)
    );
  }, [mediaMentions]);

  const getMediaMentionsForDisplay = () => {
    if (activeFilters.length > 0) {
      return mediaMentions
        .filter(
          (mediaMentions) =>
            mediaMentions.categories
              .flatMap((category) => category.displayName)
              .filter((mention) => activeFilters.includes(mention)).length > 0
        )
        .slice(0, showCount);
    }
    return mediaMentions.slice(0, showCount);
  };

  const handleFilterChange = (category) => {
    // if activeFilters already contains the category, toggle it off, otherwise add it:
    setactiveFilters(
      activeFilters.includes(category)
        ? activeFilters.filter((i) => i !== category)
        : [...activeFilters, category]
    );
  };

  const handleLoadMore = () => {
    setShowCount(showCount + 3);
  };

  return (
    <Box bg="light-gray-background">
      <Banner />
      <BreadCrumbs />

      <Section pb={['4rem']}>
        <Container>
          <Flex
            alignItems="flex-start"
            justifyContent="space-between"
            mb={['1.25rem']}
            flexDirection={['column', 'row']}
          >
            <Box flex={[1, null, '1 0 60%']}>
              <Heading as="h2" variant="section">
                {contentItems && ReactHtmlParser(contentItems[0]?.text)}
              </Heading>
            </Box>

            <Box flex={[1, null, '1 0 40%']}>
              <CategoryProvider categories={categories}>
                <FilterTagList
                  filterToggleHandler={handleFilterChange}
                  limit={3}
                />
              </CategoryProvider>
            </Box>
          </Flex>
          <Box
            display="grid"
            gridTemplateColumns={[
              '1fr',
              'repeat(2, 1fr)',
              null,
              'repeat(3, 1fr)',
              'repeat(4, 1fr)',
            ]}
            gridAutoFlow="row"
            alignItems="stretch"
            justifyContent="center"
            gridGap={['1.5rem', null, null, '2rem']}
          >
            {mediaMentions &&
              getMediaMentionsForDisplay().map((mention) => (
                <MentionCard key={mention.id} mention={mention} />
              ))}
          </Box>
        </Container>
        {showCount < mediaMentions.length && (
          <Box display="flex" marginTop="2rem" justifyContent="center">
            <Button variant="pill" alignItems="center" onClick={handleLoadMore}>
              LOAD MORE
            </Button>
          </Box>
        )}
      </Section>
    </Box>
  );
}

export default InTheMedia;
