import { React, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import find from 'lodash/find';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';

import {
  Flex,
  Heading,
  IFrame,
  Image,
  Section,
  Text,
  Box,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import View from 'construction/View';
import ProgramUpdateSummaryCard from 'news/programUpdates/ProgramUpdateSummaryCard';
import {
  ProgramUpdateProvider,
  usePage,
  useProgramUpdates,
} from 'shared/contexts';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useCheckDevice } from 'shared/hooks/hooks';

const SiteVideo = ({ pageAssets, timelapseVideoURL, timelapseVideoCaption }) => {
  const [isMatterPortTour, setIsMatterPortTour] = useState(false)
  useEffect(() => {
    if (!!timelapseVideoURL) {
      let match = timelapseVideoURL.includes("matterport")
      setIsMatterPortTour(match)
    }
  })

  const getPageAssetUrl = (key) => {
    if (!key) return;
    return pageAssets ? find(pageAssets, { testField: key })?.url : null;
  };

  return (
    <Box order={[0, 1]}>
      <Heading as="h3" mb="2rem" variant="subSection" color="default-text">
        {isMatterPortTour ? "Step Inside Our Tunnel-Boring Machine " : "Time-Lapse Camera"}
      </Heading>
      <Box width={['full']}
        position="relative"
        pb={timelapseVideoURL ? "56.25%" : "0"}
        height={timelapseVideoURL ? "0rem" : "auto"}
        overflow="hidden"
        max-width="100%"
      >
        {!timelapseVideoURL && (
          <Image
            src={pageAssets && getPageAssetUrl('live')}
            width={['full']}
            height={['full']}
            style={{ objectFit: 'cover', objectPosition: 'center' }}
          />
        )}
        {timelapseVideoURL && (
          <IFrame
            src={
              timelapseVideoURL.startsWith('https://')
                ? timelapseVideoURL
                : `https://${timelapseVideoURL}`
            }
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
          />
        )}
      </Box>
      {(timelapseVideoURL && timelapseVideoCaption) && (<Box>
        <Text as="small">
          {ReactHtmlParser(timelapseVideoCaption.html)}
        </Text>
      </Box>)}
    </Box>
  );
};

const ProgramUpdateBlock = ({ pageTitle }) => {
  const { programUpdates, loading, error } = useProgramUpdates();
  const [slides, setSlides] = useState([]);
  const isMobile = useCheckDevice;


  useEffect(() => {
    if (programUpdates) {
      let slides = [];
      let limit = 3;

      // extract the ProgramUpdates specific to this site by checking if their categories include the page's title
      let siteSpecificUpdates = programUpdates.filter((update) => {
        return update?.categories?.some((category) =>
          category?.displayName?.includes(pageTitle?.replace(' Site', ''))
        );
      });

      // split programUpdates up into arrays of three to use for slides
      for (let i = 0; i < siteSpecificUpdates.length; i += limit) {
        slides.push(siteSpecificUpdates.slice(i, i + limit));
      }

      setSlides(slides);
    }
  }, [programUpdates, pageTitle]);

  return (
    <Box order={[1, 0]} maxWidth={isMobile ? "90vw" : ""}>
      {loading && <Text>Loading...</Text>}
      {error && <Text>There was an error getting Program Updates</Text>}

      {programUpdates && slides && slides.length > 0 ? (
        <CarouselProvider
          isIntrinsicHeight
          totalSlides={slides.length}
          infinite={true}
        >
          <Flex
            justifyContent="space-between"
            flexWrap={['wrap']}
            mb={['1.5rem']}
          >
            <Heading
              as="h3"
              variant="subSection"
              width={['full', 'auto']}
              color="default-text"
              mb={[0]}
            >
              Site-Specific Program Updates
            </Heading>
            <Flex justifyContent={['center', 'flex-end']} mt={['1rem', 0]}>
              <ButtonBack
                disabled={slides && slides.length <= 1}
                style={{
                  display: 'inline-flex',
                  color: '#1890D7',
                  fontSize: '2rem',
                  border: 0,
                  backgroundColor: 'transparent',
                  paddingLeft: 0,
                  paddingRight: '.25rem',
                }}
              >
                <IoIosArrowDropleftCircle />
              </ButtonBack>
              <ButtonNext
                disabled={slides && slides.length <= 1}
                style={{
                  display: 'inline-flex',
                  color: '#1890D7',
                  fontSize: '2rem',
                  border: 0,
                  backgroundColor: 'transparent',
                  paddingLeft: '.25rem',
                  paddingRight: 0,
                }}
              >
                <IoIosArrowDroprightCircle />
              </ButtonNext>
            </Flex>
          </Flex>
          <Box mx={['-1rem']}>
            <Slider>
              {slides.map((slide, i) => (
                <Slide index={i} key={`slide-${i}`}>
                  <Box height="full" px={['1rem']} py={['.5rem']}>
                    <Box
                      display="grid"
                      gridTemplateColumns={[
                        '1fr',
                        'repeat(3, 1fr)',
                        null,
                        '1fr',
                      ]}
                      gridTemplateRows={[
                        'repeat(3, 1fr)',
                        '1fr',
                        null,
                        'repeat(3, 1fr)',
                      ]}
                      alignItems="stretch"
                      justifyContent="center"
                      gridGap={['1.5rem', null, null, '2rem']}
                      height="full"
                    >
                      {slide.map((update, i) => {
                        return (
                          <ProgramUpdateSummaryCard
                            key={update.id}
                            programUpdate={update}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                </Slide>
              ))}
            </Slider>
          </Box>
        </CarouselProvider>
      ) : (
        <>
          <Flex justifyContent="space-between" flexWrap="wrap" mb={['1.5rem']}>
            <Heading as="h3" variant="subSection" color="default-text" mb={[0]}>
              Site-Specific Program Updates
            </Heading>
          </Flex>
          <Box>
            <Text>
              No Program Updates for {pageTitle ? pageTitle : 'this site'} at
              the moment.
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};

const IntroSection = ({ contentItems, pageAssets }) => {
  return (
    <Section bg="light-gray-background">
      <Container>
        <Flex
          alignItems={['stretch', null, null, 'center']}
          flexDirection={['column', null, null, 'row']}
        >
          <Box
            flex={1}
            maxWidth={['40rem']}
            pl={[0, null, null, '1.5rem', '2rem']}
            pr={[0, null, null, '2rem', '4rem']}
          >
            <Heading as="h2" variant="section">
              {contentItems && ReactHtmlParser(contentItems[0].text)}
            </Heading>
            <Box>
              <Text>
                {contentItems && ReactHtmlParser(contentItems[1].html)}
              </Text>
            </Box>
          </Box>

          <Box
            flex={1}
            pt={['1.5rem', null, null, 0]}
            pl={[0, null, null, '2rem']}
          >
            <Box width="100%" position="relative">
              <Image
                src={pageAssets && pageAssets[0]?.url}
                width="full"
                height="full"
              />
            </Box>
          </Box>
        </Flex>
      </Container>
    </Section>
  );
};

const SiteUpdatesAndVideoSection = ({
  pageAssets,
  title,
  timelapseVideoURL,
  timelapseVideoCaption
}) => {
  return (
    <Section bg="white">
      <Container>
        <Box
          display="grid"
          gridTemplateColumns={['1fr', null, null, 'repeat(2, 1fr)']}
          gridTemplateRows={['repeat(2, auto)', null, null, '1fr']}
          alignItems="stretch"
          justifyContent="center"
          gridGap={['3rem', null, null, '2rem']}
        >
          <ProgramUpdateProvider>
            <ProgramUpdateBlock pageTitle={title} />
          </ProgramUpdateProvider>
          <SiteVideo
            pageAssets={pageAssets}
            timelapseVideoURL={timelapseVideoURL}
            timelapseVideoCaption={timelapseVideoCaption}
          />
        </Box>
      </Container>
    </Section>
  );
};

const ConstructionPage = () => {
  const [page] = usePage();
  const { title, contentItems, pageAssets, slug, videoUrl, videoCaption } = page;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  return page ? (
    <View>
      <IntroSection contentItems={contentItems} pageAssets={pageAssets} />

      {slug !== 'traffic-alerts' && (
        <SiteUpdatesAndVideoSection
          pageAssets={pageAssets}
          title={title}
          timelapseVideoURL={videoUrl}
          timelapseVideoCaption={videoCaption}
        />
      )}
    </View>
  ) : null;
};

export default ConstructionPage;
