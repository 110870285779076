import { gql } from '@apollo/client';

export const GET_VIDEOS = gql`
  query GET_VIDEOS($skip: Int) {
    videos(
      where: { categories_every: { displayName_not: "Unlisted" } }
      orderBy: date_DESC
      skip: $skip
    ) {
      id
      title
      url
      date
      categories {
        id
        color
        displayName
      }
    }
  }
`;
