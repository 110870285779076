import { React, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Flex,
  Heading,
  Section,
  Button,
  ListItem,
  Text,
  Spinner,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import View from 'community/View';
import { ResponsiveVideoCard } from 'shared/components/videosGrid/VideoCard';
import FilterTagList from 'shared/components/tagList/FilterTagList';
import { GET_VIDEOS } from 'shared/queries';

import { CategoryProvider } from 'shared/contexts';

const Videos = (props) => {
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeFilters, setactiveFilters] = useState([]);
  const [showCount, setShowCount] = useState(9); // temp way to handle "Load More"

  const [getVideos, { loading, error, data }] = useLazyQuery(GET_VIDEOS, {
    variables: { skip: 0 },
    fetchPolicy: 'no-cache',
    onCompleted: (d) => {
      setVideos(d.videos);
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    //  This script is needed to make the iframes responsive, keeping it here in case we need it for styling:
    //   var addScript = document.createElement('script');
    //   addScript.setAttribute('src', 'https://player.vimeo.com/api/player.js');
    //   document.body.appendChild(addScript);
    getVideos();
  }, [getVideos]);

  useEffect(() => {
    setCategories(videos.map((video) => video.categories).flat(2));
  }, [videos]);

  const handleLoadMore = (e) => {
    setShowCount(showCount + 3);
  };

  const handleFilterChange = (category) => {
    // if activeFilters already contains the category, toggle it off, otherwise add it:
    setactiveFilters(
      activeFilters.includes(category)
        ? activeFilters.filter((i) => i !== category)
        : [...activeFilters, category]
    );
  };

  const getVideosForDisplay = () => {
    if (activeFilters.length > 0) {
      return videos
        .filter(
          (v) =>
            v.categories
              .flatMap((vc) => vc.displayName)
              .filter((v) => activeFilters.includes(v)).length > 0
        )
        .slice(0, showCount);
    }
    return videos.slice(0, showCount);
  };

  if (loading)
    return (
      <ListItem>
        <Spinner>Loading...</Spinner>
      </ListItem>
    );
  if (error)
    return (
      <ListItem>
        <Text>Error</Text>
      </ListItem>
    );
  if (!data)
    return (
      <ListItem>
        <Text>No Videos available yet.</Text>
      </ListItem>
    );

  return (
    <View>
      <Section>
        <Container>
          <Flex
            alignItems="stretch"
            justifyContent="space-between"
            mb={['1.25rem']}
            flexDirection={['column', null, 'row']}
          >
            <Box flex={[1, null, '1 0 60%']}>
              <Heading as="h2" variant="section">
                See RiverRenew in Action
              </Heading>
            </Box>
            <Box flex={[1, null, '1 0 40%']}>
              <CategoryProvider categories={categories}>
                <FilterTagList filterToggleHandler={handleFilterChange} />
              </CategoryProvider>
            </Box>
          </Flex>

          <Box
            display="grid"
            gridTemplateColumns={[
              '1fr',
              'repeat(2, 1fr)',
              null,
              'repeat(3, 1fr)',
            ]}
            gridAutoFlow="row"
            gridGap="1.5rem"
            alignItems="stretch"
            justifyContent="center"
          >
            {videos &&
              getVideosForDisplay().map((video) => (
                <ResponsiveVideoCard key={video.id} video={video} />
              ))}
          </Box>
        </Container>
        {videos.length > showCount && (
          <Button
            variant="pill"
            alignItems="center"
            m="60px auto"
            onClick={handleLoadMore}
          >
            LOAD MORE VIDEOS
          </Button>
        )}
      </Section>
    </View>
  );
};

export default Videos;
