import { React } from 'react';
import MemberCard from './memberCard';
import { Box, StyledLoadingBox } from 'shared/components/primitives';

const MemberCardList = ({ data, loading, error }) => {
  return (
    <Box
      display="grid"
      gridAutoFlow="row"
      gridTemplateColumns={[
        '1fr',
        'repeat(2, 1fr)',
        'repeat(3, 1fr)',
        'repeat(4, 1fr)',
      ]}
      gridGap={['1.5rem']}
    >
      {loading && <StyledLoadingBox>Loading...</StyledLoadingBox>}
      {error && <Box>There was an problem displaying group members.</Box>}
      {!loading &&
        !error &&
        data &&
        data.map((member) => <MemberCard key={member.id} member={member} />)}
    </Box>
  );
};

export default MemberCardList;
