import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Image } from 'shared/components/primitives';
import { Layer } from 'shared/components/map/Layer';
import { Marker } from '../Marker';
import { ConstructionSitePopup } from '../Popup';

import { useToggle } from 'shared/hooks';

import { constructionSites, royalStMulti, unionStMulti, hooffsRunInterceptorMulti } from '../geojson';
import markerSVG from 'shared/assets/images/icon-marker-orange.svg';

export const ConstructionSiteFeature = ({ site }) => {
  const { geometry, properties } = site;
  let latitude = geometry.coordinates[1];
  let longitude = geometry.coordinates[0];

  const { value, toggleValue } = useToggle();

  return (
    <>
      <Marker
        latitude={latitude}
        longitude={longitude}
        onClick={toggleValue}
        children={<Image display={['block']} src={markerSVG} />}
      />

      <ConstructionSitePopup
        className="popup-orange"
        latitude={latitude}
        longitude={longitude}
        siteName={properties.name}
        showPopup={value}
        offsetTop={-40}
        toggleShowPopup={toggleValue}
        link={properties.link}
      />
    </>
  );
};

const layer2 = {
  id: 'hoofsRunInterceptor-boundary1',
  type: 'fill',
  source: 'route',
  layout: {},
  paint: {
    'fill-color': '#EC7D30',
    'fill-opacity': 0.75,
  },
};

const layer3 = {
  id: 'hoofsRunInterceptor-boundary2',
  type: 'fill',
  source: 'route',
  layout: {},
  paint: {
    'fill-color': '#EC7D30',
    'fill-opacity': 0.75,
  },
};

const layer4 = {
  id: 'hoofsRunInterceptor-boundary3',
  type: 'fill',
  source: 'route',
  layout: {},
  paint: {
    'fill-color': '#EC7D30',
    'fill-opacity': 0.75,
  },
};

const layer5 = {
  id: 'hoofsRunInterceptor-boundary4',
  type: 'fill',
  source: 'route',
  layout: {},
  paint: {
    'fill-color': '#EC7D30',
    'fill-opacity': 0.75,
  },
};

const Features = () => {
  const [features, setFeatures] = useState([]);
  const mounted = useRef();

  const featuresMemo = useMemo(
    () =>
      constructionSites.features.map((feature, index) => {
        return (
          <ConstructionSiteFeature
            key={`constructionSite-feature-${index}`}
            site={feature}
          />
        );
      }),
    []
  );

  useEffect(() => {
    mounted.current = true;
    if (mounted.current === true) {
      setFeatures(featuresMemo);
    }
    return () => {
      mounted.current = false;
    };
  }, [featuresMemo, setFeatures]);

  return features ? features : null;
};

export const ConstructionSiteFeatures = () => {
  return (
    <>
    <Features />
      <Layer
        key={layer5.id}
        data={unionStMulti}
        layer={layer5}
      />

      <Layer
        key={`hooffsRunInterceptor-layer3`}
        data={royalStMulti}
        layer={layer3}
      />
      <Layer
        key={`hooffsRunInterceptor-layer2`}
        data={hooffsRunInterceptorMulti}
        layer={layer2}
      />
    </>
  );
};
