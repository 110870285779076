import { React, createContext, useContext, useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';

const Context = createContext();

export const AnalyticsProvider = ({ children }) => {
  // local state to store TagManager args
  const [gtmArgs] = useState({
    gtmId: `${process.env.REACT_APP_GTM_ID}`,
  });

  const [gtmInitialized, setGtmInitialized] = useState(false);

  useEffect(() => {
    if(gtmInitialized === false) {
      TagManager.initialize(gtmArgs);
      setGtmInitialized(true);
    }
  }, [gtmArgs, gtmInitialized]);

  return <Context.Provider value={TagManager}>{children}</Context.Provider>;
};

export const useAnalytics = () => {
  const context = useContext(Context);
  if (!context)
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  return context;
};
