import styled from 'styled-components';

export const Circle = styled.span`
  border-radius: 50%;
  height: ${({ height = '100%' }) => height};
  width: ${({ width = '100%' }) => width};
  margin: ${({ margin = '0 0 0 0' }) => margin};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};
`;
