import { Flex, Text, Spinner } from 'shared/components';
import { IoSync } from 'react-icons/io5';
import { BsExclamationLg } from 'react-icons/bs';

/**
 * Renders a status message when loading or in error.
 *
 * @param {*} loading an object containing a loading status and message
 * @param {*} error an object containing an error status and message
 * @returns {JSX.Element} A Component that renders a status message when loading or in error
 * @example
 * ```tsx
 * <FetchStatus
 *  loading={{status: loadingStatus, message: loadingMessage}}
 *  error={{status: errorStatus, message: errorMessage}}
 * />
 * ```
 */
export const FetchStatus = ({ loading, error }) => {
  return loading?.status || error?.status ? (
    <Flex alignItems="center" justifyContent="flex-start">
      <Flex
        flex="0 0 auto"
        alignItems="center"
        width="4rem"
        height="4rem"
        mr={[6]}
      >
        {loading?.status && <Spinner inline fontSize="4rem" />}
        {error?.status && (
          <>
            <Text
              position="absolute"
              width="4rem"
              height="4rem"
              fontSize="1.5rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <BsExclamationLg />
            </Text>
            <Text
              position="absolute"
              width="4rem"
              height="4rem"
              fontSize="4rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IoSync />
            </Text>
          </>
        )}
      </Flex>
      {loading?.status && (
        <Text as="p" fontSize="1.5rem">
          {loading?.message && loading?.message}
        </Text>
      )}
      {error?.status && (
        <Text as="p" fontSize="1.5rem">
          {error?.message && error?.message}
        </Text>
      )}
    </Flex>
  ) : null;
};
