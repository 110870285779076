import { React, useState } from 'react';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import {
  Box,
  Button,
  ExternalLink,
  Flex,
  Heading,
  Image,
  StyledEventCard,
  Text,
  IconButton,
} from 'shared/components/primitives';
import { FiArrowRight } from 'react-icons/fi';
import TagList from 'shared/components/tagList/TagList';
import { SaveToCalendar } from 'shared/components/calendar';
import { SharingLinksGroup } from 'shared/components/sharing';
import { useCheckDevice, useWindowDimensions } from 'shared/hooks/hooks';
import ReactHtmlParser from 'react-html-parser';
import {
  eventTime,
  longEventDate,
  numericEventDay,
  shortEventMonth,
} from 'shared/utils/date';

const EventCardContent = ({ event, orientation }) => {

  const {
    id,
    categories,
    date,
    description,
    location,
    thumbnail,
    title,
    registrationLink,
    endDate,
    virtualEvent,
    agenda
  } = event;
  const [showingMore, setShowingMore] = useState(false);
  const isMobile = useCheckDevice();
  const { width } = useWindowDimensions();

  return (
    <Flex flexDirection="row" alignItems="stretch">
      {thumbnail && (
        <Box
          flex={isMobile ? '33.33%' : '50%'}
          display="flex"
          flexGrow="0"
          flexBasis={[380]}
          width="auto"
          maxWidth={[120, '17.5rem']}
          minWidth={isMobile ? [100] : [200]}
          style={{ overflow: 'hidden' }}
        >
          {width <= 640 && (
            <Image
              src={thumbnail?.sm}
              maxWidth="100%"
              height="100%"
              style={{
                objectFit: 'cover',
              }}
            />
          )}
          {width > 640 && width <= 832 && (
            <Image
              src={thumbnail?.md}
              maxWidth="100%"
              height="100%"
              style={{
                objectFit: 'cover',
              }}
            />
          )}
          {width > 832 && (
            <Image
              src={thumbnail?.lg}
              maxWidth="100%"
              height="100%"
              style={{
                objectFit: 'cover',
              }}
            />
          )}
          {date && !isMobile && (
            <Box variant="dateChip" display="flex">
              <Text>{shortEventMonth(date)}</Text>
              <Text>{numericEventDay(date)}</Text>
            </Box>
          )}
        </Box>
      )}

      <Box
        flex={isMobile ? '66.66%' : '50%'}
        width="auto"
        px={['1.125rem', '1.25rem', '1.5rem', '2rem', '3rem']}
        pt={['1.125rem', '1.25rem', '1.5rem', '2rem', '3rem']}
        pb={['1.125rem', '1.25rem', '1.5rem', '2rem', '3rem']}
      >
        <Heading as="h3" variant="card">
          {title}
        </Heading>
        {date && (
          <Text
            display="block"
            color={isMobile ? "gray" : "event-card-long-date-color"}
            fontSize={isMobile ? ["s", null, null, 'm'] : ['xxs', null, null, 'xs']}
            margin={isMobile ? "0% 0%" : ""}
            fontWeight="semibold"
          >
            {longEventDate(date)}
            {endDate && ` ${ReactHtmlParser('&mdash;')} ${eventTime(endDate)}`}
          </Text>
        )}
        {location && !virtualEvent && (
          <Text
            margin={isMobile ? "10% 0%" : "1rem 0"}
            display="block"
            color={isMobile ? "gray" : "event-card-long-date-color"}
            fontSize={isMobile ? ["s", null, null, 'm'] : ['xxs', null, null, 'xs']}
            fontWeight="semibold"
          >
            {location}
          </Text>
        )}

        {!isMobile && (
          <Box
            overflow="hidden"
            maxHeight={showingMore ? 'auto' : '3.6rem'}
            pt={['.25rem']}
            style={{
              // TODO: make this work
              transition: 'all 1s cubic-bezier(0, 1, 0, 1)',
            }}
          >
            {description && (
              <Text my={10} fontSize="sm" color="event-details-text-color">
                {ReactHtmlParser(description.html)}
              </Text>
            )}
          </Box>
        )}
        {!isMobile && (
          <Flex
            onClick={() => setShowingMore(!showingMore)}
            marginTop="1rem"
            flexDirection="row"
          >
            {showingMore && (
              <FiMinusCircle fill="#A5A5A5" color="white" fontSize="1.5rem" />
            )}
            {!showingMore && (
              <FiPlusCircle fill="#0677BD" color="white" fontSize="1.5rem" />
            )}
            <Text
              marginLeft=".5rem"
              fontSize="sm"
              fontWeight="semibold"
              color="event-details-text-color"
              cursor="pointer"
            >
              {showingMore ? 'SHOW LESS' : 'SHOW MORE'}
            </Text>
          </Flex>
        )}
        {!isMobile && categories && (
          <Box pb={[9]} marginTop="1rem">
            <TagList tags={categories} />
          </Box>
        )}

        <Flex flexWrap="wrap" alignItems="flex-start">
          <Flex
            alignItems="flex-start"
            flexDirection="row"
            flexWrap="wrap"
            mt={-4}
          >
            {isMobile ? (
              <Flex style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "Row",
                justifyContent: "center",
              }}>
                <Box
                  as="span"
                  display="flex"
                  variant="pill"
                  style={{
                    marginTop: `1rem`,
                    background:
                      "linear-gradient(to right, rgb(25,144,215, 0.6), rgb(25,144,215,0.9))",
                    width: "70%",
                    height: "2rem",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <Text>{"Learn More"}</Text>
                  <FiArrowRight style={{ marginLeft: "0.25rem" }} />
                </Box>
              </Flex>

            ) : (
              <Flex flexDirection="row" flexWrap="wrap">
                {registrationLink && (
                  <Box>
                    <ExternalLink href={registrationLink} ml="0" mr="1rem">
                      <Box
                        variant="pill"
                        bg="dark-background"
                        mt={4}
                        fontSize={'0.667rem'}
                        py="0.625rem"
                        px="1.25rem"
                      >
                        <Text>Register Now</Text>
                      </Box>
                    </ExternalLink>
                  </Box>
                )}
                <SaveToCalendar
                  id={id}
                  startTime={date}
                  endTime={endDate}
                  duration={120}
                  description={description}
                  title={title}
                  location={location}
                  margin={0}
                >
                  <Button
                    variant={isMobile ? 'link' : 'pill.outline'}
                    mt={4}
                    fontSize={'0.667rem'}
                    width={isMobile ? '100%' : 'auto'}
                  >
                    {isMobile ? '+ ' : ''}Add to Calendar
                  </Button>
                </SaveToCalendar>
                {agenda !== null && !!agenda?.url && !isMobile &&
                  <>
                    <ExternalLink href={agenda.url}>
                      <Button
                        variant={isMobile ? 'link' : 'pill.outline'}
                        mt={4}
                        fontSize={'0.667rem'}
                      >
                        VIEW AGENDA
                      </Button>
                    </ExternalLink>
                  </>
                }
              </Flex>)}

            {!isMobile && (
              <Box flexBasis="100%" mt={isMobile ? 0 : '1.25rem'}>
                <SharingLinksGroup
                  subject={title}
                  linkUrl={window.location.href}
                  color="#A5A5A5"
                />
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

//TODO: figure out what the point of orientation is

const EventCard = ({ event, orientation, ...props }) => {
  return (
    <StyledEventCard flexGrow="1" flexShrink="1" {...props}>
      <EventCardContent event={event} />
    </StyledEventCard>
  );
};

export default EventCard;
