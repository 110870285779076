import {
  createContext,
  React,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_FAQS as QUERY } from 'shared/queries';

const Context = createContext();

const FAQProvider = ({ children }) => {
  let mounted = useRef(false);
  const [state, setState] = useState({});

  const [getData, { loading, error }] = useLazyQuery(QUERY, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (d) => {
      let faqs = Object.values(d)[0];

      if (!faqs) return; // return if we don't have any items

      setState({
        faqs: { all: faqs },
      });
    },
  });

  // getData when context is mounted
  useEffect(() => {
    mounted.current = true;
    if (mounted.current === true) getData();
    return () => {
      mounted.current = false;
      setState(null); // empty state on unmount
    };
  }, [getData]);

  // add 'loading' and 'error' into state
  useEffect(() => {
    setState((state) => ({ ...state, loading: loading, error: error }));
  }, [loading, error]);

  // pass state spread over an object as the Provider's value
  const value = { ...state };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useFAQs = () => {
  const context = useContext(Context);
  if (!context) throw new Error('useFAQs must be used within a FAQProvider');
  return context;
};

export { FAQProvider, useFAQs };
