import { gql } from '@apollo/client';

export const GET_DOCUMENTS = gql`
  query GET_DOCUMENTS {
    documents(orderBy: date_DESC, first: 1000) {
      id
      title
      group
      subGroup
      attachment {
        fileName
        handle
        url
      }
      mediaLink
    }
  }
`;
