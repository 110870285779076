import { Button, Text } from 'shared/components';

/**
 * A button that, when pressed, will toggle a given value and display an 'on' or 'off' state.
 *
 * @property {boolean} toggle - The value being toggled.
 * @property {ReactNode} on - A Component rendered as the button's 'on' state.
 * @property {ReactNode} off - A Component rendered as the button's 'off' state.
 * @property {string} label - The button's label, visually hidden but present for screen readers.
 * @property {function} toggleFunction - A function to execute when the button is pressed that will (should) toggle the toggle value.
 * @returns {JSX.Element} A `<Button>` with an 'on' and 'off state
 * @example
 * ```tsx
 * <ToggleButton
 *  label={ label }
 *  toggle={ value }
 *  on={ <OnComponent /> }
 *  off={ <OffComponent /> }
 *  onClick={ toggleFunction }
 * />
 * ```
 */
export const ToggleButton = ({
  label,
  toggle,
  on,
  off,
  toggleFunction,
  ...rest
}) => {
  return (
    <Button
      variant="toggleButton"
      onClick={() => toggleFunction()}
      aria-pressed={toggle}
      {...rest}
    >
      {toggle ? on : off}
      <Text variant="visuallyHidden">{label}</Text>
    </Button>
  );
};
