import { React, useEffect, useState } from 'react';

import { groupBy } from 'shared/utils/arrayUtils';
import { Box, StyledLoadingBox } from 'shared/components/primitives';
import { Accordion } from 'shared/components/accordion/Accordion';
import DocumentListItem from './DocumentListItem';

const DocumentList = ({ data, loading, error }) => {
  const [groupedDocuments, setGroupedDocuments] = useState([]); // local state to store list of grouped documents to be mapped

  useEffect(() => {
    let finalDocumentCollection = []; // collection of document groups that will be stored in local state
    let byGroup = groupBy(data, (document) => document.group); // map of documents grouped by the 'group' property

    byGroup.forEach((values, key) => {
      let documentGroup = {
        groupKey: key,
        subGroupedDocuments: [],
      };
      const bySubGroup = groupBy(values, (doc) => doc.subGroup); // map of documents grouped by the 'subGroup' property

      bySubGroup.forEach((v, k) => {
        let subGroup = {
          subGroupKey: k,
          documents: [],
        };
        subGroup.documents = v;
        documentGroup.subGroupedDocuments.push(subGroup);
      });

      finalDocumentCollection.push(documentGroup); // push document groups to the collection
    });

    // set local state to the collection of document groups
    setGroupedDocuments(finalDocumentCollection);
  }, [data]);

  return (
    <Box>
      {loading && <StyledLoadingBox>Loading...</StyledLoadingBox>}
      {error && <Box>There was an problem displaying group members.</Box>}
      {!loading &&
        !error &&
        data &&
        groupedDocuments.map((documentGroup) => (
          <Accordion
            key={documentGroup.groupKey}
            contentFor={documentGroup.groupKey}
          >
            <Box label={documentGroup.groupKey}>
              <Accordion
                contentFor={documentGroup.groupKey}
                autoOpenFirstChild={true}
              >

                {documentGroup.subGroupedDocuments.map((sg) => (
                  <Box label={sg.subGroupKey} key={sg.subGroupKey}>
                    {sg.documents.map((doc) => (
                      <DocumentListItem
                        key={doc.id}
                        id={doc.id}
                        title={doc.title}
                        url={doc.title.toLowerCase() === "meeting recording" ? doc.mediaLink : doc.attachment?.url}
                      />
                    ))}
                  </Box>
                ))}
              </Accordion>
            </Box>
          </Accordion>
        ))}
    </Box>
  );
};

export default DocumentList;
