import { React, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  Flex,
  Heading,
  Section,
  Image,
  Text,
  Figure,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import { usePage } from 'shared/contexts';

const BenefitsCard = ({ iconUrl, text }) => {
  return (
    <Flex
      alignSelf="stretch"
      bg="normal-gray-background"
      justifyContent="flex-start"
      flexDirection="column"
      pt="1.5rem"
      px={['1.5rem', null, null, '3rem']}
      pb="2rem"
      borderRadius={[2]}
    >
      <Box mb="1.5rem">
        <Image src={iconUrl} width={['full']} />
      </Box>
      <Text
        as="p"
        m="0"
        color="default-text"
        textAlign="center"
        fontSize="1.25rem"
        lineSpacing="1.35"
      >
        {ReactHtmlParser(text)}
      </Text>
    </Flex>
  );
};

const Benefits = () => {
  const [page] = usePage();
  const { contentItems, pageAssets } = page;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (!contentItems || !pageAssets) {
    return <Text>Loading</Text>;
  }

  return (
    <Box px={[0]}>
      <Banner bannerColor="green-background" />
      <BreadCrumbs />
      <Section pb={[0, 0]} backgroundColor={['light-gray-background']}>
        <Container>
          <Heading as="h2" mb={['1rem']} variant="section">
            {ReactHtmlParser(contentItems[0]?.text)}
          </Heading>
          <Text lineHeight={1.35}>
            {ReactHtmlParser(contentItems[10]?.html)}
          </Text>
        </Container>
        <Box mt={['1.5rem', null, '3rem']} style={{ overflowX: 'scroll' }}>
          <Image
            src={pageAssets[0]?.url}
            display="block"
            width="auto"
            height={['36rem', null, null, '40rem', 'auto']}
            mx={['auto']}
            style={{ objectFit: 'cover' }}
          />
        </Box>
      </Section>

      <Section>
        <Container>
          <Box px={[null, null, '2rem', '4rem', '6rem']}>
            <Text color="default-text" fontSize={['1.25rem']}>
              {ReactHtmlParser(contentItems[1]?.html)}
            </Text>
          </Box>

          <Flex
            flexDirection={['column', null, 'row']}
            alignItems={['stretch']}
            justifyContent="center"
            mt={['1.5rem', null, '3rem']}
          >
            <Box
              flexGrow={[1]}
              flexShrink={[0]}
              flexBasis={['100%', null, '50%']}
              pr={[null, null, '1rem']}
            >
              <Image width="full" src={pageAssets[1]?.url} />
            </Box>
            <Flex
              flexGrow={[1]}
              flexShrink={[0]}
              flexBasis={['100%', null, '50%']}
              pl={[null, null, '1rem']}
              mt={['1rem', null, 0]}
            >
              <Box px={[null, null, '2rem', '3rem']}>
                <Text lineHeight="1.35">
                  {ReactHtmlParser(contentItems[2]?.html)}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Section>

      <Section>
        <Container>
          <Box
            display="grid"
            gridTemplateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
            gridAutoFlow="row"
            gridGap="1.5rem"
            alignItems="center"
            justifyContent="center"
          >
            <BenefitsCard
              iconUrl={pageAssets[2]?.url}
              text={contentItems[3]?.text}
            />
            <BenefitsCard
              iconUrl={pageAssets[3]?.url}
              text={contentItems[4]?.text}
            />
            <BenefitsCard
              iconUrl={pageAssets[4]?.url}
              text={contentItems[5]?.text}
            />
            <BenefitsCard
              iconUrl={pageAssets[5]?.url}
              text={contentItems[6]?.text}
            />
            <BenefitsCard
              iconUrl={pageAssets[6]?.url}
              text={contentItems[7]?.text}
            />
            <BenefitsCard
              iconUrl={pageAssets[7]?.url}
              text={contentItems[8]?.text}
            />
          </Box>
        </Container>
      </Section>

      <Section bg="light-gray-background">
        <Container>
          <Flex
            flexDirection={['column', null, 'row']}
            alignItems={['stretch']}
            justifyContent="center"
          >
            <Box
              flexGrow={[1]}
              flexShrink={[0]}
              flexBasis={['100%', null, '50%']}
              pr={[null, null, '1rem']}
            >
              <Figure caption={pageAssets[8]?.caption}>
                <Image src={pageAssets[8]?.url} />
              </Figure>
              <Figure caption={pageAssets[9]?.caption}>
                <Image src={pageAssets[9]?.url} />
              </Figure>
            </Box>

            <Box
              alignSelf={['center']}
              flexGrow={[1]}
              flexShrink={[0]}
              flexBasis={['100%', null, '50%']}
              pl={[null, null, '1rem']}
            >
              <Box px={[null, null, '2rem', '3rem']}>
                <Text color="default-text">
                  {ReactHtmlParser(contentItems[9]?.html)}
                </Text>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Section>
    </Box>
  );
};

export default Benefits;
