import React from 'react';
import { createPortal } from 'react-dom';
import { IoCloseCircle } from 'react-icons/io5';

import { Box, Button, Flex, Text } from 'shared/components/primitives';

export const Modal = ({ isOpen, closeModal, children, ...props }) =>
  isOpen
    ? createPortal(
      <React.Fragment>
        <Box
        position="fixed"
        top="0"
        left="0"
        zIndex="1040"
        width="100vw"
        height="100vh"
        backgroundColor="black-background"
        opacity=".5"
      />
          <Flex
          className="modal__container"
          justifyContent="center"
          position="fixed"
          top="0"
          left="0"
          zIndex="1050"
          width="full"
          height="full"
          overflowX="hidden"
          overflowY="auto"
          outline="0"
          onClick={
            (e) => {
              if (e.currentTarget === e.target) {
                closeModal(e);
              }
            }
          }
          aria-modal
          aria-hidden={isOpen ? false : true}
          tabIndex={-1}
          role="dialog"
          mt="1rem"
        >
            {children}
        </Flex>
        </React.Fragment>,
      document.body
    )
    : null;

export const DefaultModal = ({ closeModal, children, ...props }) => {
  return (
    <Modal {...props}>
      <Box
        position="relative"
        width="full"
        maxWidth="32rem"
        padding="1.25rem 2rem"
        margin="1.75rem auto"
        borderRadius=".5rem"
        backgroundColor="white-background"
        zIndex="100"
      >
        <Flex jusitfyContent="space-between">
          <Box ml="auto">
            <Button
              bg="transparent"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <Text
                aria-hidden="true"
                fontSize="med"
                opacity=".75"
                color="header-secondary-text-color"
              >
                <IoCloseCircle />
              </Text>
            </Button>
          </Box>
        </Flex>
        {children}
      </Box>
    </Modal>
  );
};

export default Modal;
