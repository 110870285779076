import { React } from 'react';
import { FiArrowRight } from 'react-icons/fi';

import {
  Box,
  Flex,
  Heading,
  ExternalLink,
  StyledArticleCard,
  Text,
} from 'shared/components/primitives';

const MentionCardContent = ({
  categories,
  organization,
  publishedAt,
  title,
  date,
  url,
  featuredImage,
  imageOrientation,
  hideCategories,
}) => {
  const publishDate = new Date(date + 'T00:00:00').toLocaleDateString('en-us');

  return (
    <Flex flexDirection="column" alignItems="flex-start">
      {categories && !hideCategories && (
        <Box
          background={categories[0].color.replace('hex_', '#')}
          width="100%"
          height="2rem"
        ></Box>
      )}
      <Flex
        flexDirection="column"
        alignItems="stretch"
        minHeight={['14rem']}
        px={['1.5rem']}
        py={['1.125rem', null, null, '1.5rem']}
      >
        <Text
          color="secondary-text-color"
          fontSize={['xs', 'sm']}
          pb={['0.5rem', null, null, '1.5rem']}
        >
          {organization} • {publishDate}
        </Text>
        <Heading
          as="h2"
          variant="card"
          mb="auto"
          color="venice-blue-text-color"
          fontSize={['sm', null, null, 'med']}
        >
          {title}
        </Heading>
        <ExternalLink href={url} variant="dark" m={0} mt=".75rem">
          VIEW SOURCE
          <Flex pl=".25rem" fontSize="med" color="secondary-text-color">
            <FiArrowRight />
          </Flex>
        </ExternalLink>
      </Flex>
    </Flex>
  );
};

const MentionCard = ({ mention, imageOrientation, hideCategories }) => {
  // Return early if no page content
  if (!mention) return null;

  return (
    <StyledArticleCard>
      <MentionCardContent
        {...mention}
        imageOrientation={imageOrientation}
        hideCategories={hideCategories}
      />
    </StyledArticleCard>
  );
};

export default MentionCard;
