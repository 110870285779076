import { Section } from 'shared/components/primitives';
import { Expandable } from 'shared/components/cards';
import Container from 'shared/components/container/Container';

export const RenewerIssue = ({ issue }) => {
  const { title, image, imageCaption, visibleContent, hiddenContent } =
    issue || {};

  return (
    <Section>
      <Container>
        <Expandable
          title={title}
          image={image}
          imageCaption={imageCaption}
          visibleContent={visibleContent?.html}
          hiddenContent={hiddenContent?.html}
        />
      </Container>
    </Section>
  );
};
