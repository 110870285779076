import { createContext, React, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_GROUP_MEMBERS as QUERY } from 'shared/queries';

const Context = createContext();

export const GroupMemberProvider = ({ children }) => {
  const localDataItem = 'com.riverrenew.groupMembers';
  const initialState = JSON.parse(localStorage.getItem(localDataItem));

  const [data, setData] = useState(initialState ? initialState : []);
  const [getData, { loading, error }] = useLazyQuery(QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (d) => {
      let items = Object.values(d)[0];
      let stringifiedData = JSON.stringify(items);
      if (stringifiedData !== JSON.stringify(data)) {
        setData(items);
        localStorage.setItem(localDataItem, JSON.stringify(items));
      }
    },
  });

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Context.Provider value={[data, loading, error]}>
      {children}
    </Context.Provider>
  );
};

export const useGroupMembers = () => {
  const context = useContext(Context);
  if (!context)
    throw new Error(
      'useGroupMembers must be used within a GroupMemberProvider'
    );
  return context;
};
