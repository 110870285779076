import { React, useEffect, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';
import {
  Box,
  Heading,
  Section,
  Flex,
  IconText,
  IconButton,
  Input,
  Select,
  Text,
  Textarea,
  Button,
  Checkbox,
  Rule,
} from 'shared/components/primitives';
import { FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { validateForm, resetForm } from 'shared/utils/form';
import { sendGtmEvent } from 'shared/utils/analytics';
import { Modal } from 'shared/components/modal/Modal';
import { useToggle } from 'shared/hooks';
import { useSiteOptions, SiteOptionProvider } from 'shared/contexts';
import { init } from 'emailjs-com';
import emailjs from 'emailjs-com';

const Hotline = () => {
  const { phoneNumber } = useSiteOptions();
  return phoneNumber ? (
    <Box>
      <IconText
        icon={<FaPhone />}
        text="Questions about construction?"
        color="#01375C"
      />
      <Text as="p" variant="contactInfo">
        Call us at:
        <br />
        {phoneNumber}
      </Text>
    </Box>
  ) : null;
};

const Address = () => {
  const { address, name } = useSiteOptions();
  return address ? (
    <Box>
      <IconText
        icon={<FaMapMarkerAlt />}
        text="Mailing Address"
        color="#01375C"
      />
      <Text as="p" variant="contactInfo">
        {name && name}
        <br />
        {address}
      </Text>
    </Box>
  ) : null;
};

const ContactModal = ({ closeModal, isOpen, message, title }) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <Box width="28rem" backgroundColor="normal-gray-background">
        <Box
          as="header"
          display="flex"
          justifyContent="space-between"
          py=".75rem"
          px="1.5rem"
          backgroundColor="primary-blue-background"
        >
          <Heading as="h3" color="white" fontSize="med" alignSelf="center">
            {title}
          </Heading>
          <IconButton background="none" px="0" ml="auto">
            <Text
              display="flex"
              color="white"
              fontSize="med"
              lineHeight="sm"
              onClick={(e) => closeModal(e)}
            >
              <AiFillCloseCircle />
            </Text>
          </IconButton>
        </Box>
        <Box px="1.5rem" pb="1.5rem" textAlign="center">
          <Text>{message}</Text>
        </Box>
      </Box>
    </Modal>
  );
};

export const ContactUs = () => {
  init("user_F2XYnK9VKe8mdhWIjrWQK");

  const areasOfInterest = [
    'Pendleton Street (Outfall 001)',
    'Royal Street (Outfall 002) ',
    'Hooffs Run Site (Outfalls 003/4)',
    'AlexRenew',
    'General Program Information ',
    'Media',
    'Other (please specify in your message)',
  ];

  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const { value, toggleValue } = useToggle();

  // toggle modal closed if the outer container is clicked
  function closeModal(e) {
    e.stopPropagation();

    toggleValue();
  }

  const sendEmailToAdmin = (form) => {
    emailjs.send('service_lmxucah', 'template_o45cglc', {
      message: `Message: ${form.querySelector('#message').value} \n Area of interest: ${form.querySelector('#areaOfInterest').value}`,
      from_name: `${form.querySelector('#first_name').value} ${form.querySelector('#last_name').value}`,
      from_email: form.querySelector('#email').value
    }).then((result) => {
      setModalTitle('Thank You!');
      setModalMessage(
        'Thank you for submitting your request. A member of our team will follow up with you shortly.'
      );
      sendGtmEvent('contactFormSubmitted');
    }, (error) => {
      setModalTitle('Sorry!');
      setModalMessage(
        'There was an issue sending your message! A member of our team will address this issue promptly.'
      );
    });
  };

  const handleContactSubmission = (e, subscribe) => {
    const form = e.target.closest('form');
    e.preventDefault();

    validateForm(form).then((result) => {
      if (result) {
        subscribe({
          MERGE0: form.querySelector('#email').value,
          MERGE1: form.querySelector('#first_name').value,
          MERGE2: form.querySelector('#last_name').value,
          MERGE3: form.querySelector('#message').value,
          MERGE4: form.querySelector('#areaOfInterest').value,
          MERGE5: form.querySelector('#opt_in').checked,
        });

        sendEmailToAdmin(form);

        toggleValue();
        resetForm(form);
      } else {
        setModalTitle('Error');
        setModalMessage('There was an error with your submission.');
        toggleValue();
      }
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <SiteOptionProvider>
      <Box backgroundColor={['light-gray-background']}>
        <Banner pageTitle={"We'd like to hear from you."} />
        <BreadCrumbs />
        <Section>
          <Container>
            <Box
              display="grid"
              gridTemplateColumns={['1fr', null, '1fr 2fr']}
              gridGap="2rem"
            >
              <Box>
                <Hotline />
                <Rule />
                <Address />
              </Box>
              <Box>
                <Box mt={['1rem', null, 0]} mb={['1rem']}>
                  <Heading
                    as="h4"
                    color="contact-intro"
                    fontSize={['sm', 'smplus', null, 'med']}
                  >
                    If you have a question not related to ongoing construction
                    activities, please complete and submit the form below, and a
                    member of our team will reach out to your shortly.
                  </Heading>
                </Box>
                <MailchimpSubscribe
                  url={process.env.REACT_APP_MAILCHIMP_CONTACT_LIST_URL}
                  render={({ subscribe, status, message }) => (
                    <Box
                      as="form"
                      onSubmit={(e) => handleContactSubmission(e, subscribe)}
                    >
                      <Box className="form-group form-group--row">
                        <Input
                          required
                          type="text"
                          placeholder="First Name"
                          id="first_name"
                        />
                        <Input
                          required
                          type="text"
                          placeholder="Last Name"
                          id="last_name"
                        />
                      </Box>
                      <Flex>
                        <Input
                          type="email"
                          placeholder="Email"
                          required
                          id="email"
                        />
                      </Flex>
                      <Flex>
                        <Select
                          placeholder="Primary Area of Interest"
                          list={areasOfInterest}
                          id="areaOfInterest"
                          // onChange={(e) => setAreaOfInterest(e.target.value)}
                        />
                      </Flex>
                      <Flex>
                        <Textarea placeholder="Message" id="message" required />
                      </Flex>
                      <Flex
                        alignItems={['center', 'center', 'flex-start']}
                        justifyContent="space-between"
                        className="form-group--row"
                      >
                        <Button
                          type="submit"
                          variant="pill"
                          p="1rem 3rem"
                          mr="1rem"
                        >
                          SUBMIT
                        </Button>
                        <Box
                          className="form-group form-group--column"
                          pl={['2rem']}
                        >
                          <Box mb={['1rem']}>
                            <Checkbox
                              required
                              id="terms"
                              name="terms"
                              label="By continuing, I agree to AlexRenew’s <a href='https://alexrenew.com/terms-of-use' target='_blank'>Terms of Use</a> and acknowledge AlexRenew’s Privacy Policy.*"
                            />
                          </Box>
                          <Box mb={['1rem']}>
                            <Checkbox
                              id="opt_in"
                              name="opt_in"
                              label="Please send occasional updates from RiverRenew to my inbox. (You may unsubscribe at any time.)"
                            />
                          </Box>
                        </Box>
                      </Flex>
                      {/* {submissionSuccess === true && (
                        <FormToast
                          color="green"
                          mt="2rem"
                          message="Thank you for contacting us!"
                        />
                      )}
                      {submissionSuccess === false && (
                        <FormToast
                          color="red"
                          mt="2rem"
                          message="There was an issue sending your request."
                        />
                      )} */}
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </Container>
        </Section>
      </Box>
      <ContactModal
        isOpen={value}
        closeModal={closeModal}
        title={modalTitle}
        message={modalMessage}
      />
    </SiteOptionProvider>
  );
};
