import { React } from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import {
  Box,
  ExternalLink,
  Flex,
  Heading,
  IFrame,
  Image,
  Text,
} from 'shared/components/primitives';

import { FiDownload } from 'react-icons/fi';

const PastListeningSessionCard = ({ session }) => {
  const {
    title,
    description,
    contents,
    videoLink,
    thumbnail,
    presentation,
    additionalResource,
  } = session || {};

  return (
    <Box
      display="grid"
      gridTemplateColumns={['1fr', null, '5fr 4fr']}
      gridGap={24}
      justifyContent="center"
      alignItems={['center']}
    >
      <Box order={['2', null, '1']}>
        {title && (
          <Heading as="h3" variant="subSection" mb="1rem">
            {title}
          </Heading>
        )}
        {description && (
          <Text as="p" lineHeight={[1.35]}>
            {description}
          </Text>
        )}
        {contents && <RichText content={contents.raw} />}

        {(presentation || additionalResource) && (
          <Box
            display="inline-grid"
            gridGap={[4]}
            gridTemplateColumns={['1fr', null, 'repeat(2, 1fr)']}
            mt={['1.5rem']}
          >
            {presentation && (
              <Box>
                <ExternalLink
                  variant="pill"
                  justifyContent="center"
                  alignItems="center"
                  href={presentation?.url}
                  download={presentation?.filename}
                >
                  <Text display="inherit" color="file-download-text-color">
                    DOWNLOAD PRESENTATION
                  </Text>
                  <Text display="inherit" pl={4} fontSize="1rem">
                    <FiDownload
                      style={{
                        color: '#0A4F7D',
                      }}
                    />
                  </Text>
                </ExternalLink>
              </Box>
            )}
            {/* {additionalResource && ( */}
            <Box>
              <ExternalLink variant="pill" href={additionalResource}>
                <Text color="file-download-text-color">
                  Additional Resources
                </Text>
              </ExternalLink>
            </Box>
            {/* )} */}
          </Box>
        )}
      </Box>

      <Box order={['1', null, '2']}>
        {videoLink && (
          <Box pt={['56.25%']}>
            <IFrame
              video={{ url: videoLink }}
              src={
                videoLink.startsWith('https://')
                  ? videoLink
                  : `https://${videoLink}`
              }
              position="absolute"
              top="0"
              width={['full']}
            />
          </Box>
        )}
        {thumbnail && thumbnail.url && (
          <Image
            src={thumbnail?.url}
            width={['full']}
            style={{
              objectFit: 'cover',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default PastListeningSessionCard;
