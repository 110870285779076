import { React } from 'react';
import { Marker as ReactMapGLMarker } from 'react-map-gl';

import { Box, Image } from 'shared/components/primitives';

import trafficAlertMarkerSVG from 'shared/assets/images/icon-traffic-alert.svg';
import sidewalkMarker from 'shared/assets/images/icon-sidewalk.svg';
import parkingMarker from 'shared/assets/images/icon-parking.svg';
import laneMarker from 'shared/assets/images/icon-lane.svg';

export const TrafficAlertMarker = (props) => {
  return (
    <Marker {...props}>
      <Image display={['block']} width="2rem" src={trafficAlertMarkerSVG} />
    </Marker>
  );
};

export const SidewalkMarker = (props) => {
  return (
    <Marker {...props}>
      <Image display={['block']} width="2.5rem" src={sidewalkMarker} />
    </Marker>
  );
};

export const ParkingMarker = (props) => {
  return (
    <Marker {...props}>
      <Image display={['block']} width="2.5rem" src={parkingMarker} />
    </Marker>
  );
};

export const LaneMarker = (props) => {
  return (
    <Marker {...props}>
      <Image display={['block']} width="2.5rem" src={laneMarker} />
    </Marker>
  );
};

export const Marker = ({
  children,
  onClick,
  latitude,
  longitude,
  ...props
}) => {
  if (!latitude || !longitude) return null; // return if no latitude or longitude are passed
  return (
    <ReactMapGLMarker latitude={latitude} longitude={longitude} {...props}>
      <Box
        onClick={onClick}
        pl="1px"
      >
        {children}
      </Box>
    </ReactMapGLMarker>
  );
};
