import { useEffect, useState } from 'react';

import MailchimpSubscribe from 'react-mailchimp-subscribe';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Spinner,
  Text,
} from 'shared/components/primitives';

import { sendGtmEvent } from 'shared/utils/analytics';

const Form = ({ status, message, onValidated }) => {
  const [errorMessage, setErrorMessage] = useState();

  let email, terms;

  const submit = (event) => {
    event.preventDefault();

    onValidated({
      EMAIL: email.value,
      TERMS: terms.checked,
    });
  };

  const handleErrors = (message) => {
    let replacedMessage = message.replace(/[0-9] - /g, '');

    // If this email is already subscribed, reset the form
    if (message && message.includes('already subscribed')) resetForm();

    setErrorMessage(replacedMessage);
  };

  const resetForm = () => {
    email.value = '';
    terms.checked = false;
  };

  const submitSuccess = () => {
    // send a 'newSubscriber' event to GTM...
    sendGtmEvent('newSubscriber', {
      EMAIL: email.value,
    });

    resetForm();
  };

  useEffect(() => {
    if (status === 'error') handleErrors(message);
    if (status === 'success') submitSuccess();
  }, [status, message]);

  return (
    <Box as="form" onSubmit={(e) => submit(e)}>
      <Box>
        <Flex>
          <Input
            ref={(node) => (email = node)}
            type="email"
            placeholder="Email"
            required
            id="email"
            hasError
          />
        </Flex>
        <Flex mb={[12]}>
          <Checkbox
            ref={(node) => (terms = node)}
            required
            id="terms"
            name="terms"
            label="By continuing, I agree to AlexRenew’s Terms of Use and acknowledge AlexRenew’s Privacy Policy.*"
          />
        </Flex>
        <Button type="submit" variant="primary" mb="1rem">
          {status === 'sending' ? <Spinner /> : 'Subscribe'}
        </Button>
      </Box>
      {status && (
        <Box py={[8]} mt={[8]}>
          {status === 'error' && (
            <Text
              color="error"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          )}
          {status === 'success' && (
            <Text color="green" dangerouslySetInnerHTML={{ __html: message }} />
          )}
        </Box>
      )}
    </Box>
  );
};

export const AlertSubscriptionForm = () => {
  const url = process.env.REACT_APP_MAILCHIMP_TRAFFIC_ALERTS_URL;

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <Form
          status={status}
          message={message}
          onValidated={(formData) => subscribe(formData)}
        />
      )}
    />
  );
};
