import styled from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';

export const Image = styled('img')(
  (props) => css({}),
  variant({
    variants: {
      avatar: {
        size: ['7rem', null, '9rem'],
        padding: '.25rem',
        borderRadius: 'circle',
        border: 'avatar',
        backgroundColor: 'image-background-color',
        backgroundClip: 'content-box',
        overflow: 'hidden',
        objectFit: 'cover',
      },
    },
  }),
  composedStyledUtilities
);
