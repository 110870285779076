import { React, useState } from 'react';
import { FaEnvelope, FaGlobeAmericas, FaPhone, FaSearch } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';

import {
  Box,
  PillLink,
  Flex,
  IconButton,
  Text,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import Localization from './Localization';
import { TrafficAlertButton, TrafficAlertMessage } from './TrafficAlerts';
import Search from './Search';

import { TrafficAlertProvider, useSiteOptions } from 'shared/contexts';

const UtilityButton = ({ icon, label, onClick }) => {
  return (
    <IconButton
      as="a"
      color="icon-text"
      p={['.75rem', '.5rem']}
      mr={['.5rem', null, null, '1rem']}
      onClick={onClick}
    >
      <Text
        display={['none', 'inline-block']}
        fontSize="xs"
        fontWeight="bold"
        lineHeight="sm"
        pr={['.5rem']}
        style={{ textTransform: 'uppercase' }}
      >
        {label}
      </Text>{' '}
      <Text display="flex" color="icon-background-light" lineHeight="sm">
        {icon}
      </Text>
    </IconButton>
  );
};

const UtilityBarContent = () => {
  const { phoneNumber } = useSiteOptions();

  // TODO: Probably a DRYer way to do this but works for now, just verbose
  const [isOpen, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [trafficOpen, setTrafficOpen] = useState(false);
  const [localesOpen, setLocalesOpen] = useState(false);

  const closeBar = (e) => {
    e.preventDefault();

    setOpen(false);
    setSearchOpen(false);
    setTrafficOpen(false);
    setLocalesOpen(false);
  };

  const toggleSearch = (e) => {
    e.preventDefault();
    setOpen(!isOpen);
    setSearchOpen(!searchOpen);
    setTrafficOpen(false);
    setLocalesOpen(false);
  };

  const toggleTraffic = (e) => {
    e.preventDefault();
    setOpen(!isOpen);
    setSearchOpen(false);
    setTrafficOpen(!trafficOpen);
    setLocalesOpen(false);
  };

  const toggleLocales = (e) => {
    e.preventDefault();
    setOpen(!isOpen);
    setSearchOpen(false);
    setTrafficOpen(false);
    setLocalesOpen(!localesOpen);
  };

  return (
    <>
      <Flex backgroundColor="utility-bar-background" justifyContent="center">
        <Container
          display="flex"
          justifyContent="space-between"
          py={['.75rem', '1rem']}
        >
          <Flex>
            <UtilityButton
              icon={<FaSearch />}
              label="Search"
              onClick={toggleSearch}
            />
            <TrafficAlertButton onClick={toggleTraffic} />
            <UtilityButton
              icon={<FaGlobeAmericas />}
              label="Language"
              onClick={toggleLocales}
            />
          </Flex>

          <Flex justifyContent="flex-end">
            <Text
              display={['none', null, 'inline']}
              fontSize="sm"
              fontWeight="semibold"
              lineHeight="lg"
              color="white"
              pr={'.75rem'}
            >
              Questions about construction? <br />
              Call the Construction Hotline.
            </Text>
            {phoneNumber && (
              <PillLink
                as="a"
                target="_self"
                href={`tel:${phoneNumber}`}
                display="flex"
                alignItems="center"
                color="white"
                backgroundColor="utility-bar-contact-button-background"
                borderRadius="pill"
                py={['.5rem']}
                px={['.75rem']}
                m="0"
                mr={['1rem']}
              >
                <Text
                  fontSize="xs"
                  fontWeight="bold"
                  lineHeight="med"
                  style={{ textTransform: 'uppercase' }}
                >
                  {phoneNumber}
                </Text>{' '}
                <Text
                  display="flex"
                  color="utility-bar-contact-phone-color"
                  fontSize="sm"
                  pl={'.5rem'}
                >
                  <FaPhone />
                </Text>
              </PillLink>
            )}
            <PillLink
              as="a"
              target="_self"
              href="/contact-us"
              display="flex"
              alignItems="center"
              color="white"
              backgroundColor="utility-bar-contact-button-background"
              borderRadius="pill"
              py={['.5rem']}
              px={['.75rem']}
              m="0"
            >
              <Text
                fontSize="xs"
                fontWeight="bold"
                lineHeight="med"
                style={{ textTransform: 'uppercase' }}
              >
                Contact Us
              </Text>{' '}
              <Text
                display="flex"
                color="utility-bar-contact-phone-color"
                fontSize="sm"
                pl={'.5rem'}
              >
                <FaEnvelope />
              </Text>
            </PillLink>
          </Flex>
        </Container>
      </Flex>
      <Flex
        display={isOpen ? 'flex' : 'none'}
        justifyContent="center"
        position="absolute"
        width="100%"
        backgroundColor={
          trafficOpen ? 'traffic-alert-background' : 'secondary-blue-background'
        }
        zIndex="90"
      >
        <Container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={['.75rem']}
        >
          <Box flex="1">
            {searchOpen && <Search />}
            {trafficOpen && <TrafficAlertMessage />}
            {localesOpen && <Localization />}
          </Box>
          <Box flex="0" pl="1.5rem">
            <IconButton
              bg="transparent"
              px="0"
              onClick={closeBar}
              color="default-text"
            >
              <Text
                display={['none', 'flex']}
                pr={['.25rem']}
                fontSize="sm"
                fontWeight="bold"
                style={{ textTransform: 'uppercase' }}
              >
                Close
              </Text>
              <Text fontSize="med" display="flex">
                <MdClear />
              </Text>
            </IconButton>
          </Box>
        </Container>
      </Flex>
    </>
  );
};

function UtilityBar() {
  return (
    <TrafficAlertProvider>
      <UtilityBarContent />
    </TrafficAlertProvider>
  );
}

export default UtilityBar;
