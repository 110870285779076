import { React, useState } from 'react';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
// import { AiFillCloseCircle } from 'react-icons/io5';
import { AiFillCloseCircle } from 'react-icons/ai';

import {
  Box,
  Heading,
  Image,
  ExternalLink,
  Flex,
  IconButton,
  Text,
  Button,
} from 'shared/components/primitives';

import TagList from 'shared/components/tagList/TagList';
import { SaveToCalendar } from 'shared/components/calendar';
import { SharingLinksGroup } from 'shared/components/sharing';
import { useCheckDevice, useWindowDimensions } from 'shared/hooks/hooks';
import ReactHtmlParser from 'react-html-parser';
import {
  eventTime,
  longEventDate,
  numericEventDay,
  shortEventMonth,
} from 'shared/utils/date';
import { Modal } from 'shared/components/modal/Modal';

const EventModal = ({ onClose, isOpen, event }) => {

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <Box width="28rem" backgroundColor="normal-gray-background" overflow="hidden" margin="auto">
        <Box
          as="header"
          display="flex"
          justifyContent="space-between"
          py=".75rem"
          paddingRight="1rem"
          paddingLeft="1rem"
          backgroundColor="primary-blue-background"
        >
          <IconButton style={{ marginRight: "0.5rem", background: "none" }} px="0" ml="auto">
            <Text
              display="flex"
              color="white"
              fontSize="med"
              lineHeight="sm"
              onClick={(e) => onClose(e)}
            >
              <AiFillCloseCircle />
            </Text>
          </IconButton>

          {(!!event) &&
            <Heading as="h3" color="white" fontSize="med" alignSelf="center">
              {event.title}
            </Heading>}
        </Box>
        {(!!event) &&
          <EventModalContent event={event} />
        }
        <Box px="1.5rem" pb="1.5rem" textAlign="center">
        </Box>
      </Box>
    </Modal>
  );
};

const EventModalContent = ({ event }) => {
  const {
    id,
    categories,
    date,
    description,
    location,
    thumbnail,
    title,
    registrationLink,
    endDate,
    virtualEvent,
    agenda
  } = event;
  const isMobile = useCheckDevice();
  return (
    <Box>
      <Box>
        {thumbnail && (
          <Box
            flex={'33.33%'}
            display="flex"
            flexGrow="0"
            flexBasis={[380]}
            width="100%"
            maxHeight="20vh"
            style={{ overflow: 'hidden' }}
          >
            <Image
              src={thumbnail?.lg}
              maxWidth="100%"
              height="100%"
              style={{
                objectFit: 'contain',
              }}
            />
          </Box>
        )}
      </Box>
      <Flex flexDirection="row" alignItems="stretch">
        {date && !isMobile && (
          <Box variant="dateChip" display="flex">
            <Text>{shortEventMonth(date)}</Text>
            <Text>{numericEventDay(date)}</Text>
          </Box>
        )}

        <Box
          flex={isMobile ? '66.66%' : '50%'}
          width="auto"
          px={['1.125rem', '1.25rem', '1.5rem', '2rem', '3rem']}
          pt={['1.125rem', '1.25rem', '1.5rem', '2rem', '3rem']}
          pb={['1.125rem', '1.25rem', '1.5rem', '2rem', '3rem']}
        >
          {date && (
            <Text
              display="block"
              color="black"
              fontSize={['l', null, null, 'l']}
              fontWeight="semibold"
            >
              {longEventDate(date)}
              {endDate && ` ${ReactHtmlParser('&mdash;')} ${eventTime(endDate)}`}
            </Text>
          )}
          {location && !virtualEvent && (
            <Text
              display="block"
              color="block"
              fontSize={['l', null, null, 'l']}
              fontWeight="semibold"
            >
              {location}
            </Text>
          )}

          <Box
            overflow="hidden"
            maxHeight={'auto'}
            pt={['.25rem']}
            style={{
              // TODO: make this work
              transition: 'all 1s cubic-bezier(0, 1, 0, 1)',
            }}
          >
            {description && (
              <div style={{ marginTop: "1vh", marginBottom: "1vh" }}>
                <Text my={10} fontSize="2vh" color="black" >
                  {ReactHtmlParser(description.html)}
                </Text>
              </div>

            )}
          </Box>
          <Box pb={[9]} marginTop="1rem">
            <TagList tags={categories} />
          </Box>

          <Flex flexWrap="wrap" alignItems="flex-start">
            <Flex
              alignItems="flex-start"
              flexDirection="row"
              flexWrap="wrap"
              mt={-4}
            >
              <Flex flexDirection="row" flexWrap="wrap">
                {registrationLink && (
                  <Box>
                    <ExternalLink href={registrationLink} ml="0" mr="1rem">
                      {!isMobile && (
                        <Box
                          variant="pill"
                          bg="dark-background"
                          mt={4}
                          fontSize={'0.667rem'}
                          py="0.625rem"
                          px="1.25rem"
                        >
                          <Text>Register Now</Text>
                        </Box>
                      )}
                      {isMobile && (
                        <Text fontSize="1rem" fontWeight="600" p={0} mt={4}>
                          + Register Now
                        </Text>
                      )}
                    </ExternalLink>
                  </Box>
                )}
                <SaveToCalendar
                  id={id}
                  startTime={date}
                  endTime={endDate}
                  duration={120}
                  description={description}
                  title={title}
                  location={location}
                  margin={0}
                >
                  <Button
                    variant={isMobile ? 'link' : 'pill.outline'}
                    mt={4}
                    fontSize={'1rem'}
                  >
                    {isMobile ? '+ ' : ''}Add to Calendar
                  </Button>
                </SaveToCalendar>
                {agenda !== null && !!agenda.url && isMobile &&
                  <>
                    <ExternalLink href={agenda.url}>
                      <Button
                        variant={isMobile ? 'link' : 'pill.outline'}
                        mt={4}
                        fontSize={'0.667rem'}
                      >
                        VIEW AGENDA
                      </Button>
                    </ExternalLink>
                  </>
                }
              </Flex>

              {!isMobile && (
                <Box flexBasis="100%" mt={isMobile ? 0 : '1.25rem'}>
                  <SharingLinksGroup
                    subject={title}
                    linkUrl={window.location.href}
                    color="#A5A5A5"
                  />
                </Box>
              )}
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};



export default EventModal;
