import { React } from 'react';

import { Box } from 'shared/components/primitives';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';

const View = ({ children, pageTitle }) => {
  return (
    <Box bg="light-gray-background">
      <Banner />
      <BreadCrumbs pageTitle={pageTitle} />
      {children}
    </Box>
  );
};

export default View;
