import { useState } from 'react';

// Hook for setting a toggle state for a component
export const useToggle = () => {
  const [value, setValue] = useState(false);

  function toggleValue() {
    setValue(!value);
  }

  return { value, toggleValue };
};
