import React, { useState } from 'react';
import { Layer } from 'shared/components/map/Layer';
import { useTrafficAlerts, TrafficAlertProvider } from 'shared/contexts';

const tunnelLineEntire = [
  [-77.059311, 38.797540],
  [-77.058784, 38.797215],
  [-77.057737, 38.797038],
  [-77.056734, 38.796772],
  [-77.055813, 38.79633],
  [-77.054228, 38.79565],
  [-77.052374, 38.795067],
  [-77.050474, 38.794812],
  [-77.048218, 38.794574],
  [-77.047338, 38.79443],
  [-77.046014, 38.794111],
  [-77.045437, 38.793806],
  [-77.04489, 38.793614],
  [-77.044163, 38.793462],
  [-77.043148, 38.793358],
  [-77.041976, 38.793406],
  [-77.041302, 38.79359],
  [-77.040802, 38.793784],
  [-77.040332, 38.794032],
  [-77.040019, 38.794259],
  [-77.039806, 38.794478],
  [-77.039583, 38.79475],
  [-77.039393, 38.795004],
  [-77.039292, 38.795316],
  [-77.039245, 38.795539],
  [-77.039263, 38.795807],
  [-77.039284, 38.796106],
  [-77.039308, 38.796331],
  [-77.039333, 38.796661],
  [-77.039336, 38.797038],
  [-77.039339, 38.797484],
  [-77.03929, 38.79853],
  [-77.039035, 38.800379],
  [-77.038757, 38.802014],
  [-77.038536, 38.803185],
  [-77.038279, 38.804705],
  [-77.03798, 38.806065],
  [-77.037899, 38.806502],
  [-77.03789, 38.806932],
  [-77.038001, 38.807485],
  [-77.038071, 38.80807],
  [-77.038209, 38.808488],
  [-77.038389, 38.808969],
  [-77.038486, 38.809402],
  [-77.038479, 38.809707],
];

const layer = {
  id: 'tunnelLine-route',
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#01375C',
    'line-width': 8,
  },
};

const layer2 = {
  id: 'tunnelLine-route2',
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#01375C',
    'line-width': 4,
    'line-dasharray': [2, 4],
  },
};

const layer3 = {
  id: 'tunnel-point',
  type: 'symbol',
  source: 'route',
  layout: {
    'symbol-placement': 'point',
    'icon-allow-overlap': true,
    'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 1, 15, 0.5],
    visibility: 'visible',
  },
};

const Features = ({ popDesc, popValue, popLoc, popContent, popColor, popIcon }) => {
  const [value, setToggleValue] = useState(false);
  const { alerts } = useTrafficAlerts();
  const tbm = alerts?.find(a => a.alertId === 'tbm');

  const completedCoords = [];
  const incompleteCoords = tunnelLineEntire;

  tunnelLineEntire.forEach(point => {
    const long = tbm?.location.longitude;
    const lat = tbm?.location.latitude;

    if (long > -77.039333) {
      if (lat > point[1] || long > point[0]) {
        completedCoords.push(point);
      }
    } else {
      if (tbm?.location.longitude > point[0]) {
        completedCoords.push(point);
      }
    }

  });

  const tunnelLine = {
    geometry: {
      coordinates: completedCoords,
      type: 'LineString',
    },
    type: 'Feature',
    properties: {},
  };

  const tunnelLine2 = {
    geometry: {
      coordinates: incompleteCoords,
      type: 'LineString',
    },
    type: 'Feature',
    properties: {},
  };

  const tunnelPoint = {
    geometry: {
      coordinates: completedCoords[completedCoords.length - 1],
      type: "Point"
    },
    type: "Feature",
    properties: {}
  };

  return alerts ? (
    <div>
      <Layer key={layer.id} data={tunnelLine} layer={layer} />
      <Layer key={layer2.id} data={tunnelLine2} layer={layer2} />
      <Layer key={layer3.id} data={tunnelPoint} layer={layer3} />
    </div>
  ) : null;
};

export const TunnelLineFeature = ({ popDesc, popLoc, popValue, popContent, popColor, popIcon }) => {


  return (
    <TrafficAlertProvider>
      <Features popDesc={desc => popDesc(desc)} popLoc={loc => popLoc(loc)} popValue={value => popValue(value)} popContent={content => popContent(content)} popColor={col => popColor(col)} popIcon={ico => popIcon(ico)} />
    </TrafficAlertProvider>
  );
};
