import { React, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import Container from 'shared/components/container/Container';
import { Box, Flex, Text, BreadCrumbLink } from 'shared/components/primitives';
import { pageMap } from 'shared/routes/pageMap';

const validRoutes = Array.from(pageMap().values());

/**
 * A single navigation link
 *
 * @param {string} to - The location to link the Crumb to
 * @param {string} text - The text to display in the Crumb
 */
const Crumb = ({ to, text, current, props }) => {
  if (validRoutes.includes(to)) {
    return (
      <BreadCrumbLink {...props} to={to} current={current}>
        <Text>{text}</Text>
      </BreadCrumbLink>
    );
  } else {
    return (
      <Text
        color="breadcrumb-link-color"
        fontSize="xs"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {text}
      </Text>
    );
  }
};

/**
 * A dot used as a divider between Crumbs
 *
 * @return {string} color - An optional override to the default color
 */
const Divider = ({ color }) => {
  let dotColor = color ? color : 'dot-divider-color';
  return (
    <Box px={4}>
      <Box bg={dotColor} width="4px" height="4px" borderRadius="circle" />
    </Box>
  );
};

/**
 * A horizontal list of navigational links that display the current location of the viewer in the page hierarchy
 */
const BreadCrumbs = ({ pageTitle }) => {
  const routes = [];
  const location = useLocation();
  const url = location.pathname.substring(1).split('/');

  for (let [index, value] of url.entries()) {
    if (!value) return;

    let title;
    switch (value.toLowerCase()) {
      case 'about-river-renew':
        title = 'About RiverRenew';
        break;
      case 'about-alex-renew':
        title = 'About AlexRenew';
        break;
      case 'councilboard-workgroup':
        title = 'Council-Board Workgroup';
        break;
      case 'alexrenew':
        title = 'AlexRenew';
        break;
      case 'cloes-corner':
        title = "Cloe's Corner";
        break;
      case 'faqs':
        title = 'FAQs';
        break;
      case 'river-renewer':
        title = 'The River Renewer';
        break;
      default:
        title = value
          .split('-')
          .map((words) => words.charAt(0).toUpperCase() + words.slice(1))
          .join(' ');
    }
    // route needs to include all levels:
    let path = '';
    for (let i = 0; i <= index; i++) {
      path = `${path}/${url[i]}`;
    }
    let route = { to: path, text: title };
    routes.push(route);
  }
  const numRoutes = routes.length - 1;

  return (
    <Box display={['none', 'block', null, null]} py={[16]} bg="white">
      <Container>
        <Flex>
          <Crumb text="Home" to="/" />
          {routes &&
            routes.length >= 1 &&
            routes.map((route, index) => {
              return (
                <Fragment key={route.text}>
                  {index === 0 && <Divider />}
                  {index < numRoutes && (
                    <Crumb
                      to={route.to}
                      text={route.text}
                      current={index === numRoutes}
                    />
                  )}
                  {index === numRoutes && (
                    <Crumb
                      to={route.to}
                      text={pageTitle ? pageTitle : route.text}
                      current={index === numRoutes}
                    />
                  )}
                  {index !== numRoutes && <Divider key={route.text} />}
                </Fragment>
              );
            })}
        </Flex>
      </Container>
    </Box>
  );
};

export default BreadCrumbs;
