import React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';

export const StyledSection = styled('section')(
  (props) =>
    css({
      position: 'relative',
      width: props?.width ? props.width : 'full',
      py: props?.py ? props.py : ['1.5rem', '3rem'],
      pt: props?.pt ? props.pt : ['1.5rem', '3rem'],
      pb: props?.pb ? props.pb : ['1.5rem', '3rem'],
      px: props?.px ? props.px : [null],
    }),
  composedStyledUtilities
);

export const Section = (props) => {
  return <StyledSection {...props} />;
};
