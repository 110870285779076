import { React, useEffect, useState } from 'react';
import { IoChevronDownSharp } from 'react-icons/io5';

import { Box, Flex, IconButton, Heading } from 'shared/components/primitives';
import TagList from './TagList';

import { useCategories } from 'shared/contexts';

const FilterTagList = ({ limit, filterToggleHandler }) => {
  const [categories] = useCategories(); // get categories from CategoryProvider
  const [showAllFilters, setShowAllFilters] = useState(false); // controls whether to display all filters
  const [filterValuesDisplay, setFilterValuesDisplay] = useState([]); // the values to display based on the show more/less toggle
  const buttonText = showAllFilters ? 'Show Less' : 'Show More'; // text of the toggle button
  const filterLimit = limit ? limit : 2; // number of filter buttons to display before having to show more

  useEffect(() => {
    setFilterValuesDisplay(categories.slice(0, filterLimit));
  }, [categories, filterLimit]);

  const onShowButtonToggle = () => {
    const actionResult = !showAllFilters;

    setFilterValuesDisplay(
      actionResult ? categories : categories.slice(0, filterLimit)
    );

    setShowAllFilters(actionResult);
  };
  return (
    <Box width="full" pb="1rem" borderBottom="videoFilter">
      <Flex justifyContent="space-between" mb={'.25rem'}>
        <Heading as="h4" variant="sidebar" mb={0}>
          Filter
        </Heading>
        {categories.length > filterLimit && (
          <IconButton onClick={onShowButtonToggle} variant="text">
            {buttonText}{' '}
            <Flex fontSize="1.5rem" pl=".5rem">
              <IoChevronDownSharp />
            </Flex>
          </IconButton>
        )}
      </Flex>

      <TagList tags={filterValuesDisplay} clickHandler={filterToggleHandler} />
    </Box>
  );
};

export default FilterTagList;
