import { gql } from '@apollo/client';

export const GET_SITEOPTIONS = gql`
  query GET_SITEOPTIONS {
    siteOptions(locales: [en, es]) {
      address
      colophon
      copyright
      logo {
        fileName
        id
        url
      }
      name
      phoneNumber
    }
  }
`;
