import { React, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  Flex,
  Heading,
  Section,
  Image,
  Text,
} from 'shared/components/primitives';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import Container from 'shared/components/container/Container';
import { usePage } from 'shared/contexts';

const HowWaterWorks = () => {
  const [page] = usePage();
  const { contentItems, pageAssets } = page;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (!contentItems || !pageAssets) {
    return <Text>Loading</Text>;
  }

  return (
    <Box bg="light-gray-background">
      <Banner pageTitle="How Does Water Work in Alexandria?" />
      <BreadCrumbs />

      <Section>
        <Container>
          <Heading as="h2" variant="section">
            {ReactHtmlParser(contentItems[0]?.text)}
          </Heading>
        </Container>
        <Container px={[0, null, '2rem', '3rem', '4rem']}>
          <Box
            display="flex"
            justifyContent={['flex-start', null, null, 'center']}
            alignItems="center"
            width="100%"
            overflow="auto"
            mb={[8, 16]}
          >
            <Box>
              <Image src={pageAssets[0]?.url} height="full" />
            </Box>
          </Box>
        </Container>
        <Container>
          <Text>{ReactHtmlParser(contentItems[1]?.html)}</Text>
        </Container>
      </Section>

      {/* 3 ENTITIES SECTION */}
      <Section bg={['white-background']}>
        <Container>
          <Heading as="h2" variant="section">
            {ReactHtmlParser(contentItems[2]?.html)}
          </Heading>
          <Box width="100%" overflow="auto">
            <Box width="100rem">
              <Image src={pageAssets[1]?.url} maxWidth="100%" />
            </Box>
          </Box>
        </Container>
      </Section>

      {/* 2 X 2 PICTURES + TEXT */}
      <Section>
        <Container>
          <Flex flexDirection={['column', null, 'row']}>
            <Box flex="1">
              <Image width="full" src={pageAssets[2]?.url} />
            </Box>
            <Box
              flex="1"
              lineHeight="1.35"
              pt={['1.5rem', '2rem', 0]}
              pl={[0, null, '1.5rem', '2rem']}
            >
              {ReactHtmlParser(contentItems[3]?.html)}
            </Box>
          </Flex>
        </Container>
      </Section>

      <Section bg={['white']}>
        <Container>
          <Flex flexDirection={['column', null, 'row']}>
            <Box
              flex="1"
              order={[1, null, 0]}
              lineHeight="1.35"
              pt={['1.5rem', '2rem', 0]}
              pr={[0, null, '1.5rem', '2rem']}
            >
              {ReactHtmlParser(contentItems[4]?.html)}
            </Box>
            <Box flex="1" order={[0, null, 1]}>
              <Image width="full" src={pageAssets[3]?.url} />
            </Box>
          </Flex>
        </Container>
      </Section>
    </Box>
  );
};

export default HowWaterWorks;
