import { React } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { Box, StyledInput } from 'shared/components/primitives';

let newSearchQuery = null;

const handleSearch = (e, history) => {
  e.preventDefault();
  history.push(`/search?q=${newSearchQuery}`);
  history.go();
};

function Search() {
  const history = useHistory();
  return (
    <Box
      as="form"
      m={[0]}
      minWidth="30%"
      onSubmit={(e) => handleSearch(e, history)}
    >
      <Box maxWidth="22rem">
        <StyledInput
          type="search"
          name="q"
          placeholder="What are you looking for?"
          variant="utilityBarSearch"
          onChange={(e) => (newSearchQuery = e.target.value)}
        />
        <Box
          position="absolute"
          top={[0]}
          right={[0]}
          display="flex"
          py={['.825rem']}
          px={['1rem']}
          color="icon-background-dark"
          fontSize="sm"
          lineHeight="sm"
          onClick={(e) => handleSearch(e, history)}
        >
          <FaSearch />
        </Box>
      </Box>
    </Box>
  );
}

export default Search;
