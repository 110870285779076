import { React, useEffect } from 'react';
import { Route } from 'react-router-dom';

import { PageProvider, usePage } from 'shared/contexts';
import { SEO } from 'shared/components/seo/SEO';

const Page = ({ children, ...rest }) => {
  const [page] = usePage();

  return (
    <>
      <SEO seo={page?.seo} />
      <Route {...rest}>{children}</Route>
    </>
  );
};

const DefaultLayout = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <PageProvider>
      <Page {...props} />
    </PageProvider>
  );
};

export { DefaultLayout };
