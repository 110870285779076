import { React, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';

import theme from 'theme';
import { Box } from 'shared/components/primitives';
import Routes from 'shared/routes/Routes';
import Footer from 'shared/components/footer/Footer';
import Header from 'shared/components/header/Header';

import './App.css';
import { AnalyticsProvider } from 'shared/contexts';
import { GlobalStyles } from 'shared/assets/styles/GlobalStyles';
import 'shared/utils/lang/i18n';

function App() {
  const [canScroll, setCanScroll] = useState(true);
  const appHeight = canScroll ? 'auto' : '100vh';

  function toggleScrolling() {
    setCanScroll(!canScroll);
  }

  return (
    <AnalyticsProvider>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <GlobalStyles theme={theme} />
            <Box className="App" height={appHeight} overflow={'hidden'}>
              <Header toggleScrolling={toggleScrolling} />
              <Routes />
              <Footer />
            </Box>
          </Router>
        </ThemeProvider>
      </HelmetProvider>
    </AnalyticsProvider>
  );
}

export default App;
