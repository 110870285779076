import { React, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  Box,
  Heading,
  Section,
  Text,
  Rule,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import View from 'community/View';
import EventCard from './EventCard';
import { GET_EVENT } from 'shared/queries';

const EventDetail = () => {
  const [event, setEvent] = useState(null);
  let { id } = useParams();

  const [getEvent, { loading, error }] = useLazyQuery(GET_EVENT, {
    variables: { id: id },
    fetchPolicy: 'no-cache',
    onCompleted: (d) => {
      setEvent(d.event);
    },
  });

  useEffect(() => {
    getEvent();
  }, [id, getEvent]);

  return (
    <View pageTitle={event && event?.title}>
      <Section>
        <Container>{event && <EventCard event={event} />}</Container>
      </Section>
    </View>
  );
};

export default EventDetail;
