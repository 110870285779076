import { React } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Flex,
  Image,
  Section,
  Text,
  ExternalLink,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import ScrollToTop from './ScrollToTop';
import { Copyright } from './Copyright';
import { Colophon } from './Colophon';
import SocialMediaLinks from './SocialMediaLinks';
import { Menu } from 'shared/components/menu/Menu';
import { useCheckDevice } from 'shared/hooks/hooks';
import { SiteOptionProvider } from 'shared/contexts';

import { footerNavigationItems } from 'shared/data/navigationItems';

import logo from 'shared/assets/images/RiverRenew_Horiz_notag_White@2x.png';
import alexRenewLogo from 'shared/assets/images/ar-logo.png';

const FooterBody = () => {
  const isMobile = useCheckDevice();

  return (
    <>
      <Section
        py={[0]}
        pt={[0, null, null, '2rem']}
        pb={['3.25rem', '2rem', null, '2rem']}
      >
        <Container px={[0]}>
          <Flex
            flexDirection={['column', null, null, 'row']}
            alignItems={['stretch', null, null, 'flex-start']}
            justifyContent={['none', 'space-between']}
            px={[0, null, null, '4rem']}
          >
            <Menu
              data={footerNavigationItems}
              color="white"
              submenu={{
                borderBottom: ['2px solid', null, null, 'none'],
                borderBottomColor: 'footer-mobile-menu-border',
              }}
            />
          </Flex>
        </Container>
        <Container>
          <Flex
            pt={['1.5rem', null, null, '2rem']}
            pb={[0, null, null, '1.75rem']}
            flexDirection={['column', 'row']}
            flexWrap={['wrap']}
            alignItems={['flex-start', 'center']}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flex={[null, null, '0']}
              pr={[null, '2rem', null, null, '4rem']}
            >
              <ExternalLink href="https://alexrenew.com" borderRight="solid 0.062rem white">
                <Image src={alexRenewLogo} width={'9rem'} ml={'-.5rem'} mr={'0.75rem'}/>
              </ExternalLink>
              <Link to="/">
                <Image src={logo} width={'9rem'} ml={['0.5rem']} />
              </Link>
            </Box>
            <Box flex={['1']} pr={[0, null, null, null, '4rem']}>
              <Colophon />
            </Box>
            <Flex
              flex={['1 1 100%', null, null, '0']}
              justifyContent={['flex-start', null, null, 'flex-end']}
              pt={[0, '1.5rem', null, 0]}
              ml={['-0.75rem', null, 0]}
            >
              <SocialMediaLinks />
            </Flex>
          </Flex>
        </Container>
      </Section>
      <Section
        py={[0]}
        pt={[0]}
        pb={[0]}
        backgroundColor="dark-background"
        color={'white'}
      >
        <Container py={['2rem', null, '1.5rem']}>
          <Box textAlign={isMobile ? 'center' : 'left'}>
            <Copyright />
          </Box>
          <Box
            position="absolute"
            top={'-1.5rem'}
            left={0}
            display="flex"
            alignItems="center"
            width="100%"
            px={['1.75rem', null, '4rem']}
            justifyContent={['center', 'flex-end']}
          >
            <ScrollToTop />
          </Box>
        </Container>
      </Section>
    </>
  );
};

function Footer() {
  return (
    <SiteOptionProvider>
      <Box as="footer" bg={'light-background'}>
        <FooterBody />
      </Box>
    </SiteOptionProvider>
  );
}

export default Footer;
