import styled from 'styled-components';

//https://styled-css-grid.js.org/

const autoRows = ({ minRowHeight = '20px' }) => `minmax(${minRowHeight}, auto)`;
const frGetter = (value) =>
  typeof value === 'number' ? `repeat(${value}, 1fr)` : value;
const gap = ({ gap = '8px' }) => gap;
const flow = ({ flow = 'row' }) => flow;
const formatAreas = (areas) => areas.map((area) => `"${area}"`).join(' ');

export const Grid = styled.div`
  display: grid;
  height: ${({ height = 'auto' }) => height};
  grid-auto-flow: ${flow};
  grid-auto-rows: ${autoRows};
  ${({ rows }) => rows && `grid-template-rows: ${frGetter(rows)}`};
  grid-template-columns: ${({ columns = 6 }) => frGetter(columns)};
  grid-gap: ${gap};
  ${({ columnGap }) => columnGap && `column-gap: ${columnGap}`};
  ${({ rowGap }) => rowGap && `row-gap: ${rowGap}`};
  ${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)}`};
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent}`};
  ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};
  ${({ padding }) => padding && `padding: ${padding}`};
`;

export const Cell = styled.div`
  height: 100%;
  min-width: 0;
  grid-column-end: ${({ width = 1 }) => `span ${width}`};
  grid-row-end: ${({ height = 1 }) => `span ${height}`};
  ${({ left }) => left && `grid-column-start: ${left}`};
  ${({ top }) => top && `grid-row-start: ${top}`};
  ${({ center }) => center && `text-align: center`};
  ${({ area }) => area && `grid-area: ${area}`};
  ${
    /* prettier-ignore */
    ({ middle }) => middle && `
    display: inline-flex;
    flex-flow: column wrap;
    ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
    justify-self: stretch;
    `
  };
`;

export const WrappingColumn = styled.div`
  padding: 10px 0;
  columns: ${({ cols = '200px 4' }) => cols};
  column-gap: ${({ columnGap = '15px' }) => columnGap};

  display: block;
  column-fill: balance;
`;

export const WrappingColumnItem = styled.div`
  min-width: calc(100% - 4px);
  display: inline-block;
  position: relative;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
  margin: 0.65rem;
`;
