import { React, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Section,
  Box,
  Flex,
  Heading,
  Text,
  Spinner,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import View from '../View';
import SessionList from './SessionList';
import { GET_EVENTS_BY_CATEGORY } from 'shared/queries';
import { GET_PAST_LISTENING_SESSIONS } from 'shared/queries/listeningSession-queries';
import PastListeningSessionCard from './PastListeningSessionCard';
import ReactHtmlParser from 'react-html-parser';
import { useCheckDevice } from 'shared/hooks/hooks';
import { usePage } from 'shared/contexts';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';

const CommunityListeningSessions = () => {
  const [upcomingSessions, setUpcomingSessions] = useState([]); //  all upcoming sessions (come from events data source)
  const [upcomingSessionsDisplay, setUpcomingSessionsDisplay] = useState([]); // slice of the upcoming sessions that is currently shown
  const [
    upcomingSessionsDisplayStartIndex,
    setupcomingSessionsDisplayStartIndex,
  ] = useState(0); // index of first item being shown
  const [showPageForward, setShowPageForward] = useState(false);
  const [showPageBack, setShowPageBack] = useState(false);
  const [pastSessions, setPastSessions] = useState([]); // all past sessions (different data source)
  const isMobile = useCheckDevice();
  let cardsToShowCount = isMobile ? 1 : 3; // temp solution until we implement global solution for device detection
  const [{ title, contentItems }] = usePage();

  const [getUpcomingSessions, { loading, error, data }] = useLazyQuery(
    GET_EVENTS_BY_CATEGORY,
    {
      variables: { category: 'Community Listening Sessions' },
      fetchPolicy: 'no-cache',
      onCompleted: (d) => {
        const filteredEvents = d.events.filter(
          (e) => Date.parse(e.date) >= Date.now()
        );
        setUpcomingSessions(filteredEvents);
        setUpcomingSessionsDisplay(
          filteredEvents.slice(
            upcomingSessionsDisplayStartIndex,
            cardsToShowCount
          )
        );

        if (filteredEvents.length > cardsToShowCount) setShowPageForward(true);
      },
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    getUpcomingSessions();
  }, [getUpcomingSessions]);

  const [getPastSessions, { loadingPast, errorPast, dataPast }] = useLazyQuery(
    GET_PAST_LISTENING_SESSIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (d) => {
        setPastSessions(d.listeningSessions);
      },
    }
  );

  useEffect(() => {
    getPastSessions();
  }, [getPastSessions]);

  const handlePaging = (dir) => {
    let newStartIndex = upcomingSessionsDisplayStartIndex;
    if (dir === 'forward') {
      newStartIndex = upcomingSessionsDisplayStartIndex + cardsToShowCount;
      setupcomingSessionsDisplayStartIndex(newStartIndex);
      setUpcomingSessionsDisplay(
        upcomingSessions.slice(newStartIndex, newStartIndex + cardsToShowCount)
      );
    } else {
      // back
      newStartIndex = upcomingSessionsDisplayStartIndex - cardsToShowCount;
      if (newStartIndex < 0) {
        newStartIndex = 0;
      }
      setUpcomingSessionsDisplay(
        upcomingSessions.slice(newStartIndex, upcomingSessionsDisplayStartIndex)
      );
      setupcomingSessionsDisplayStartIndex(newStartIndex);
    }
    // hide the page button if there are no more items.  This logic needs refining.
    setShowPageForward(
      newStartIndex + cardsToShowCount > upcomingSessions.length - 1
        ? false
        : true
    );
    setShowPageBack(newStartIndex <= 0 ? false : true);
  };

  if (loading || loadingPast || !contentItems)
    return <Spinner>Loading...</Spinner>;
  if (error || errorPast) {
    return <Text>Error</Text>;
  }
  if (!data && !dataPast) return <Text>No items available.</Text>;

  return (
    <View>
      <Section bg="green-background">
        <Container>
          <Flex
            flexDirection={['column', null, null, 'row']}
            px={[null, null, null, '1.5rem', '3rem']}
            color="white"
          >
            <Box
              flexShrink={[null, null, null, 0]}
              flexBasis={['auto', null, null, '40%']}
              width={['full']}
              pb={['1rem', null, null, 0]}
            >
              <Heading as="h2" variant="section" color="white" mb={[0]}>
                {title}
              </Heading>
            </Box>
            <Box pl={[null, null, null, '2rem']}>
              <Text lineHeight={[1.35]}>
                {ReactHtmlParser(contentItems[0]?.html)}
              </Text>
            </Box>
          </Flex>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading as="h3" variant="section">
            Upcoming Listening Sessions
          </Heading>

          {upcomingSessions?.length > 0 && (
            <Flex
              padding={isMobile ? '.125rem' : '.5rem'}
              flexDirection="row"
              justifyContent="flex-end"
            >
              {showPageBack && (
                <IoIosArrowDropleftCircle
                  color="#1890D7"
                  fontSize="1.5rem"
                  style={{ margin: '0 1rem 0 0' }}
                  onClick={() => handlePaging('back')}
                />
              )}
              {showPageForward && (
                <IoIosArrowDroprightCircle
                  color="#1890D7"
                  fontSize="1.5rem"
                  onClick={() => handlePaging('forward')}
                />
              )}
            </Flex>
          )}

          <Flex alignItems="flex-start">
            {upcomingSessions?.length > 0 && (
              <Box flex="1">
                <SessionList events={upcomingSessionsDisplay} />

                <Box mt={[0, null, '1.5rem']}>
                  <Text color="gray-text" fontSize=".75rem">
                    {ReactHtmlParser(contentItems[1]?.text)}
                  </Text>
                </Box>
              </Box>
            )}
            {upcomingSessions?.length === 0 && (
              <Box flex="1">
                <Heading
                  as="h4"
                  color="header-primary-text-color"
                  fontSize="1.5rem"
                >
                  No Upcoming Events.
                </Heading>
              </Box>
            )}
          </Flex>
        </Container>
      </Section>

      <Section>
        <Container>
          <Heading as="h3" variant="section">
            Past Listening Sessions
          </Heading>
        </Container>
      </Section>
      {pastSessions &&
        pastSessions.map((session, index) => (
          <Section
            bg={index % 2 === 0 ? 'white-background' : 'light-gray-background'}
          >
            <Container>
              <PastListeningSessionCard key={session.id} session={session} />
            </Container>
          </Section>
        ))}
    </View>
  );
};

export default CommunityListeningSessions;
