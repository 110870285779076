import { forwardRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Box } from 'shared/components/primitives';

export const Checkbox = forwardRef(({ label, id, name, ...rest }, ref) => {
  return (
    <Box style={{ textAlign: 'left' }}>
      <input
        ref={ref}
        type="checkbox"
        className="styled-checkbox"
        id={id}
        name={name}
        defaultChecked={false}
        {...rest}
      />
      <label htmlFor={name}>{ReactHtmlParser(label)}</label>
    </Box>
  );
});
