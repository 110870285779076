import styled from 'styled-components';
import {
  background,
  border,
  compose,
  color,
  flexbox,
  layout,
  position,
  space,
  typography,
} from 'styled-system';
import css from '@styled-system/css';

const composedStyleProps = compose(
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  typography
);

export const Flex = styled('div')(
  (props) =>
    css({
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      position: 'relative',
      transitionProperty: 'background-color',
      transitionDuration: '250ms',
      transitionTimingFunction: 'cubic-bezier(0.23, 1, 0.320, 1)',
    }),
  composedStyleProps
);
