import {
  createContext,
  React,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { GET_PAGE_BY_SLUG as QUERY } from 'shared/queries';

const Context = createContext();

export const PageProvider = ({ children }) => {
  let mounted = useRef(false);
  const location = useLocation();
  const splitPathname = location.pathname.substring(1).split('/');
  const slug =
    location.pathname === '/'
      ? 'home'
      : splitPathname[splitPathname.length - 1]; // slug is assumed to be the last member of splitPathName

  const [data, setData] = useState([]); // local state for all raw page data received
  const [seo, setSEO] = useState([]); // local state for mapping SEO page data
  const [contentItems, setContentItems] = useState([]); // local state for mapping contentItems page data
  const [pageAssets, setPageAssets] = useState([]); // local state for mapping pageAssets page data
  const [bannerImage, setBannerImage] = useState([]); // local state for mapping bannerImage page data
  const [title, setTitle] = useState(''); // local state for mapping title page data
  const [documents, setDocuments] = useState([]);
  const [sections, setSections] = useState([]);
  const [videoCaption, setVideoCaption] = useState('');

  const [getData, { loading, error }] = useLazyQuery(QUERY, {
    fetchPolicy: 'no-cache',
    variables: { slug: slug },
    onCompleted: (d) => {
      let page = Object.values(d)[0];

      // this query will always return a response but not all "pages" will have a record in the CMS
      if (!page) {
        // if we don't get a page back, empty out page data so we don't print the previously returned page's state
        setData([]);
        setContentItems([]);
        setSEO([]);
        setPageAssets([]);
        setBannerImage([]);
        setTitle('');
        setDocuments([]);
        setSections([]);
        setVideoCaption('');
      } else {
        // if we do, set page data to the page that was returned
        setData(page);

        // these objects may not exist on every page, but set them as a convenience if they do
        setContentItems(page.contentItems ? page.contentItems : []);
        setSEO(page.seo ? page.seo : []);
        setPageAssets(page.pageAssets ? page.pageAssets : []);
        setBannerImage(page.bannerImage ? page.bannerImage : []);
        setTitle(page.title ? page.title : []);
        setDocuments(page.documents ? page.documents : []);
        setSections(page.sections ? page.sections : []);
        setVideoCaption(page.videoCaption ? page.videoCaption : '');
      }
    },
  });

  useEffect(() => {
    mounted.current = true;
    if (mounted.current === true) getData();
    return () => {
      mounted.current = false;
    };
  }, [getData, slug]);

  if(loading) return <>Loading...</>

  return (
    <Context.Provider
      value={[
        data,
        seo,
        bannerImage,
        contentItems,
        pageAssets,
        title,
        slug,
        documents,
        sections,
        videoCaption,
        loading,
        error,
      ]}
    >
      {children}
    </Context.Provider>
  );
};

export const usePage = () => {
  const context = useContext(Context);
  if (!context) throw new Error('usePage must be used within a PageProvider');
  return context;
};
