import { React } from 'react';
import { FiFacebook } from 'react-icons/fi';
import { ExternalLink } from 'shared/components/primitives';

export function ShareToFacebook({ subject, linkUrl, color }) {
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    linkUrl
  )}&text=${encodeURIComponent(subject)}`;

  return (
    <ExternalLink href={facebookUrl} ml={0} mr={5} px={4}>
      <FiFacebook color={color ? color : 'white'} fontSize="1.25rem" />
    </ExternalLink>
  );
}
