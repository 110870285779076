export const headerNavigationItems = [
  {
    id: 1,
    title: 'Home',
    path: '/',
  },
  {
    id: 2,
    title: 'Construction',
    path: null,
    items: [
      {
        id: '2-1',
        title: 'AlexRenew Site',
        path: '/construction/alexrenew',
      },
      {
        id: '2-2',
        title: 'Pendleton Street Site',
        path: '/construction/pendleton-street',
      },
      {
        id: '2-3',
        title: 'Royal Street Site',
        path: '/construction/royal-street',
      },
      {
        id: '2-4',
        title: 'Hooffs Run Site',
        path: '/construction/hooffs-run',
      },
      {
        id: '2-5',
        title: 'Waterfront Tunnel Site',
        path: '/construction/waterfront-tunnel',
      },
      {
        id: '2-6',
        title: 'Alerts',
        path: '/construction/alerts',
      },
    ],
  },
  {
    id: 3,
    title: 'Benefits',
    path: '/benefits',
  },
  {
    id: 4,
    title: 'Community',
    path: null,
    items: [
      { id: '4-1', title: 'Events', path: '/community/events' },
      {
        id: '4-2',
        title: 'Community Listening Sessions',
        path: '/community/community-listening-sessions',
      },
      {
        id: '4-3',
        title: 'Stakeholder Advisory Group',
        path: '/community/stakeholder-advisory-group',
      },
      { id: '4-4', title: "Cloe's Corner", path: '/community/cloes-corner' },
      { id: '4-5', title: 'Videos', path: '/community/videos' },
      {
        id: '4-6',
        title: 'Council-Board Workgroup',
        path: '/community/councilboard-workgroup',
      },
    ],
  },
  {
    id: 5,
    title: 'News',
    path: null,
    items: [
      { id: '5-1', title: 'News', path: '/news' },
      { id: '5-2', title: 'Program Updates', path: '/news/program-updates' },
      { id: '5-3', title: 'The River Renewer', path: '/news/river-renewer' },
      { id: '5-4', title: 'In the Media', path: '/news/in-the-media' },
    ],
  },
  {
    id: 6,
    title: 'Contact Us',
    path: '/contact-us',
  },
  {
    id: 7,
    title: 'FAQs',
    path: '/faqs',
  },
  {
    id: 8,
    title: 'Learn More',
    path: null,
    items: [
      {
        id: '8-1',
        title: 'About AlexRenew',
        path: '/learn-more/about-alex-renew',
      },
      {
        id: '8-2',
        title: 'About RiverRenew',
        path: '/learn-more/about-river-renew',
      },
      { id: '8-3', title: 'FAQs', path: '/faqs' },
      {
        id: '8-4',
        title: 'RiverRenew Documents',
        path: '/learn-more/documents',
      },
      {
        id: '8-5',
        title: 'How Water Works',
        path: '/learn-more/how-water-works',
      },
    ],
  },
];

export const footerNavigationItems = [
  {
    id: 1,
    title: 'Info',
    path: null,
    items: [
      { id: '1-2', title: 'Home', path: '/' },
      { id: '1-3', title: 'Benefits', path: '/benefits' },
      { id: '1-4', title: 'Contact Us', path: '/contact-us' },
      {
        id: '1-5',
        title: 'Terms of Use',
        path: 'https://alexrenew.com/terms-of-use',
      },
      { id: '1-6', title: 'Site Map', path: '/site-map' },
    ],
  },
  {
    id: 2,
    title: 'Construction',
    path: null,
    items: [
      {
        id: '2-1',
        title: 'AlexRenew Site',
        path: '/construction/alexrenew',
      },
      {
        id: '2-2',
        title: 'Pendleton Street Site',
        path: '/construction/pendleton-street',
      },
      {
        id: '2-3',
        title: 'Royal Street Site',
        path: '/construction/royal-street',
      },
      {
        id: '2-4',
        title: 'Hooffs Run Site',
        path: '/construction/hooffs-run',
      },
      {
        id: '2-5',
        title: 'Waterfront Tunnel Site',
        path: '/construction/waterfront-tunnel',
      },
      {
        id: '2-6',
        title: 'Alerts',
        path: '/construction/alerts',
      },
    ],
  },
  {
    id: 3,
    title: 'Community',
    path: null,
    items: [
      { id: '3-1', title: 'Events', path: '/community/events' },
      {
        id: '3-2',
        title: 'Community Listening Sessions',
        path: '/community/community-listening-sessions',
      },
      {
        id: '3-3',
        title: 'Stakeholder Advisory Group',
        path: '/community/stakeholder-advisory-group',
      },
      { id: '3-4', title: "Cloe's Corner", path: '/community/cloes-corner' },
      { id: '3-5', title: 'Videos', path: '/community/videos' },
      {
        id: '3-6',
        title: 'Council-Board Workgroup',
        path: '/community/councilboard-workgroup',
      },
    ],
  },
  {
    id: 4,
    title: 'News',
    path: null,
    items: [
      { id: '4-1', title: 'News', path: '/news' },
      { id: '4-2', title: 'Program Updates', path: '/news/program-updates' },
      { id: '4-3', title: 'The River Renewer', path: '/news/river-renewer' },
      { id: '4-4', title: 'In the Media', path: '/news/in-the-media' },
    ],
  },
  {
    id: 5,
    title: 'Learn More',
    path: null,
    items: [
      {
        id: '5-1',
        title: 'About AlexRenew',
        path: '/learn-more/about-alex-renew',
      },
      {
        id: '5-2',
        title: 'About RiverRenew',
        path: '/learn-more/about-river-renew',
      },
      { id: '5-3', title: 'FAQs', path: '/faqs' },
      {
        id: '5-4',
        title: 'RiverRenew Documents',
        path: '/learn-more/documents',
      },
      {
        id: '5-5',
        title: 'How Water Works',
        path: '/learn-more/how-water-works',
      },
    ],
  },
];
