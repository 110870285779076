import { gql } from '@apollo/client';

// export const GET_SITES = gql``;

export const GET_PAGE_BY_TITLE = gql`
  query GET_PAGE_BY_TITLE($title: String!) {
    pages(where: { title_contains: $title }) {
      id
      title
      contentItems {
        html
      }
      bannerImage {
        id
        fileName
        sm: url(
          transformation: {
            image: { resize: { width: 375, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        md: url(
          transformation: {
            image: { resize: { width: 640, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        lg: url(
          transformation: {
            image: { resize: { width: 768, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xl: url(
          transformation: {
            image: { resize: { width: 960, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xxl: url(
          transformation: {
            image: { resize: { width: 1280, fit: max } }
            document: { output: { format: jpg } }
          }
        )
      }
    }
  }
`;

export const GET_ASSET_BY_ID = gql`
  query GET_ASSET($id: ID!) {
    assets(where: { id: $id }) {
      id
      fileName
      url
      assetTitle
      testField
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES {
    filters: categories {
      id
      color
      displayName
    }
  }
`;

export const GET_PAGE_BY_SLUG = gql`
  query GET_PAGE_BY_SLUG($slug: String!) {
    page(where: { slug: $slug }) {
      id
      title
      contentItems {
        html
        text
        raw
      }
      bannerImage {
        id
        fileName
        sm: url(
          transformation: {
            image: { resize: { width: 375, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        md: url(
          transformation: {
            image: { resize: { width: 640, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        lg: url(
          transformation: {
            image: { resize: { width: 768, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xl: url(
          transformation: {
            image: { resize: { width: 960, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xxl: url(
          transformation: {
            image: { resize: { width: 1280, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        caption
      }
      pageAssets {
        url
        sm: url(
          transformation: {
            image: { resize: { width: 375, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        md: url(
          transformation: {
            image: { resize: { width: 640, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        lg: url(
          transformation: {
            image: { resize: { width: 768, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xl: url(
          transformation: {
            image: { resize: { width: 960, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        xxl: url(
          transformation: {
            image: { resize: { width: 1280, fit: max } }
            document: { output: { format: jpg } }
          }
        )
        assetTitle
        testField
        caption
        size
        fileName
      }
      seo {
        id
        keywords
        metaDescription
        noindex
        ogDescription
        ogTitle
        ogUrl
        twitterCard
        ogImage {
          id
          fileName
          url
        }
      }
      documents {
        id
        title
        description
        attachment {
          url
          fileName
        }
        thumbnails {
          url
        }
      }
      videoCaption {
        html
        text
      }
      videoUrl
      sections {
        id
        name
        blocks {
          __typename
          ... on TextContent {
            id
            name
            backgroundColor {
              rgba {
                r
                g
                b
              }
            }
            content {
              html
              raw
              json
              text
            }
          }
        }
      }
      carousels {
        id
        slug
        title
        visibleSlides
        infinite
        slides {
          raw
          html
        }
      }
    }
  }
`;
