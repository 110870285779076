import { gql } from '@apollo/client';

export const GET_GROUP_MEMBERS = gql`
  query GET_GROUP_MEMBERS {
    groupMembers {
      id
      area
      name
      outfall
      quote
      stakeholder
      headshot {
        fileName
        id
        url(
          transformation: {
            image: { resize: { width: 144, height: 144, fit: max } }
            document: { output: { format: jpg } }
          }
        )
      }
    }
  }
`;
