import { React, useEffect } from 'react';

import View from 'community/View';
import { RiverRenewerProvider } from 'shared/contexts/riverrenewer-context';

import { RenewerEditions } from './RenewerEditions';

export const RiverRenewer = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <RiverRenewerProvider>
      <View>
        <RenewerEditions />
      </View>
    </RiverRenewerProvider>
  );
};
