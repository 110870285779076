import { useEffect, useState, useRef } from 'react';
import Player from '@vimeo/player';
import { VscDebugPause } from 'react-icons/vsc';
import { IoPlayOutline, IoVolumeHigh, IoVolumeMute } from 'react-icons/io5';
import css from '@styled-system/css';

import { Box, Flex, ToggleButton } from 'shared/components';

/**
 *
 * @example
 * ```jsx
 * <VimeoPlayer
 *  player={vimeoPlayer}
 *  fullWidth={fullWidthVideo}
 *  showControls={showVideoControls}
 * />
 * ```
 */
export const VimeoPlayer = ({
  player,
  fullWidth,
  showControls = false,
  ...rest
}) => {
  const { videoId, slug, isBackground = false } = player || {};
  const [mute, setMute] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [aspectRatio, setAspectRatio] = useState(56.25);
  const playerElementRef = useRef(null);
  const playerRef = useRef(null);

  // Initialize the Player
  useEffect(() => {
    playerRef.current = new Player(playerElementRef?.current, {
      url: `https://player.vimeo.com/video/${videoId}`,
      background: isBackground,
    });

    // When the player is ready
    playerRef.current.ready().then(() => {
      // Set its aspect ratio within 2 decimals
      setAspectRatio(
        parseFloat(
          (
            (playerRef.current?.element?.height /
              playerRef.current?.element?.width) *
            100
          ).toFixed(2)
        )
      );
    });
  }, [playerElementRef, playerRef, videoId, isBackground]);

  // Play & pause the player, honor the 'Reduce Motion' setting by pausing the video
  useEffect(() => {
    if (!playing) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
  }, [playing]);

  // Mute the Player
  useEffect(() => {
    playerRef?.current?.setMuted(mute);
  }, [mute]);

  return (
    <>
      <Box
        ref={playerElementRef}
        id={slug}
        position={[fullWidth ? 'absolute' : null, null, null, 'absolute']}
        width={[fullWidth ? '100vw' : '100%']}
        paddingBottom={[`${aspectRatio}%`]}
        minHeight={[fullWidth ? '100%' : null, null, null, '100%']}
        minWidth={[fullWidth ? '177.77vh' : null, null, null, '177.77vh']}
        top={[fullWidth ? '50%' : null, null, null, '50%']}
        left={[fullWidth ? '50%' : null, null, null, '50%']}
        style={{
          transform: 'translate(-50%, -50%)',
        }}
        {...rest}
      />
      {showControls && (
        <Box
          position="absolute"
          zIndex="docked"
          right={[4, 8]}
          bottom={[8, 12, 16]}
        >
          <Flex>
            <ToggleButton
              label={`${playing ? 'Pause' : 'Play'} Video`}
              toggle={playing}
              on={
                <VscDebugPause
                  style={{
                    width: '24px',
                    height: '24px',
                    fontSize: '1.5rem',
                  }}
                />
              }
              off={
                <IoPlayOutline
                  style={{
                    position: 'relative',
                    right: -1,
                    width: '24px',
                    height: '24px',
                    fontSize: '1.5rem',
                  }}
                />
              }
              toggleFunction={() => setPlaying(!playing)}
            />
            <ToggleButton
              label={`${mute ? 'Unmute' : 'Mute'} Video`}
              toggle={mute}
              on={<IoVolumeHigh />}
              off={<IoVolumeMute />}
              toggleFunction={() => setMute(!mute)}
            />
          </Flex>
        </Box>
      )}
    </>
  );
};
