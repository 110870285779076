import React from 'react';

import { Image } from 'shared/components/primitives';

import { Layer } from '../Layer';
import { Popup } from '../Popup';
import { Marker } from '../Marker';

import { useToggle } from 'shared/hooks';

import {
  alexrenewRoutes,
  pendletonStRoutes,
  dukeStRoutes,
  royalStRoutes,
  routeSpine,
} from '../geojson';

import alexRenewIcon from 'shared/assets/images/icon-haul-route-alexRenew.svg';
import dukeStIcon from 'shared/assets/images/icon-haul-route-dukeSt.svg';
import pendletonStIcon from 'shared/assets/images/icon-haul-route-pendletonSt.svg';
import royalStRouteIcon from 'shared/assets/images/icon-haul-route-royalSt.svg';

import theme from 'theme';

const { colors } = theme;

const routeLine = {
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
};

const paint = {
  'line-width': 5,
  'line-dasharray': [0.5, 2],
};

export const AlexRenewRoutes = ({popValue, popDesc, popLoc, popAlert, popIcon, popColor}) => {
  const { value, toggleValue } = useToggle();
  const layers = {
    id: 'alexRenewRoutes-layer',
    type: 'line',
    paint: { ...paint, 'line-color': colors['primary-orange-background'] },
    ...routeLine,
  };

  return (
    <>
      <Layer
        key={`alexRenewRoutes-features-layer`}
        data={alexrenewRoutes}
        layer={layers}
      />
      <>
        <Marker
          latitude={38.80057240598612}
          longitude={-77.06385037853747}
          onClick={() => {
            popAlert("haul_route");
            popDesc('AlexRenew');
            popLoc([-77.06385037853747, 38.80057240598612]);
            popIcon(alexRenewIcon);
            popColor('primary-orange-background');
            toggleValue(value);
            popValue(!value);

          }}
        >
          <Image display={['block']} src={alexRenewIcon} />
        </Marker>
       </>
    </>
  );
};

export const PendletonStRoutes = ({popValue, popDesc, popLoc, popAlert, popIcon, popColor}) => {
  const { value, toggleValue } = useToggle();
  const layer1 = {
    id: 'pendletonSt-layer1',
    paint: { ...paint, 'line-color': colors['green-background'] },
    ...routeLine,
  };

  const layer2 = {
    id: 'pendletonSt-layer2',
    paint: { ...paint, 'line-color': colors['green-background'] },
    ...routeLine,
  };

  const arrowLayer1 = {
    id: 'pendletonSt-arrow-layer1',
    type: 'symbol',
    layout: {
      'symbol-placement': 'line',
      'symbol-spacing': 100,
      'icon-allow-overlap': false,
      'icon-image': 'arrow-green',
      'icon-size': 0.5,
      // 'icon-rotate': 90,
      visibility: 'visible',
    },
  };

  const arrowLayer2 = {
    id: 'pendletonSt-arrow-layer2',
    type: 'symbol',
    layout: {
      'symbol-placement': 'line',
      'symbol-spacing': 100,
      'icon-allow-overlap': false,
      // 'icon-anchor': 'center',
      'icon-image': 'arrow-green',
      'icon-size': 0.5,
      'icon-rotate': 180,
      // 'icon-keep-upright': true,
      // 'icon-offset': [60, 0],
      visibility: 'visible',
    },
    paint: {
      // 'icon-translate': [20, 40],
    },
  };

  return (
    <>
      <>
        <Layer
          key={`pendletonStRoutes-features-layer1`}
          data={pendletonStRoutes.features[0]}
          layer={layer1}
        />
        <Layer
          key={`pendletonStRoutes-arrows-layer1`}
          data={pendletonStRoutes.features[0]}
          layer={arrowLayer1}
        />
      </>
      <>
        <Layer
          key={`pendletonStRoutes-features-layer2`}
          data={pendletonStRoutes.features[1]}
          layer={layer2}
        />
        <Layer
          key={`pendletonStRoutes-arrows-layer2`}
          data={pendletonStRoutes.features[1]}
          layer={arrowLayer2}
        />
      </>
      <>
        <Marker
          latitude={38.80989858034175}
          longitude={-77.03906957470018}
          onClick={() => {
            popAlert("haul_route");
            popDesc('Pendleton Street');
            popLoc([-77.03906957470018, 38.80989858034175]);
            popIcon(pendletonStIcon);
            popColor('green-background');
            toggleValue(value);
            popValue(!value);

          }}
        >
          <Image display={['block']} src={pendletonStIcon} />
        </Marker>

      </>
    </>
  );
};

export const DukeStRoutes = ({popValue, popDesc, popLoc, popAlert, popIcon, popColor}) => {
  const { value, toggleValue } = useToggle();
  const layer = {
    id: 'dukeSt-layer',
    paint: { ...paint, 'line-color': colors['dark-gray-background'] },
    ...routeLine,
  };
  return (
    <>
      <Layer key={`dukeSt-features-layer`} data={dukeStRoutes} layer={layer} />
      <>
        <Marker
          latitude={38.804171894432244}
          longitude={-77.05804533845034}
          onClick={() => {
            popAlert("haul_route");
            popDesc('Hooffs Run');
            popLoc([-77.05804533845034, 38.804171894432244]);
            popIcon(dukeStIcon);
            popColor('dark-gray-background');
            toggleValue(value);
            popValue(!value);

          }}
        >
          <Image display={['block']} src={dukeStIcon} />
        </Marker>
      </>
    </>
  );
};

export const RoyalStRoutes = ({popValue, popDesc, popLoc, popAlert, popIcon, popColor}) => {
  const { value, toggleValue } = useToggle();
  const layer1 = {
    id: 'royalSt-layer1',
    paint: { ...paint, 'line-color': colors['secondary-blue-background'] },
    ...routeLine,
  };

  const arrowLayer1 = {
    id: 'royalSt-arrow-layer1',
    type: 'symbol',
    layout: {
      'symbol-placement': 'line',
      'symbol-spacing': 120,
      'icon-allow-overlap': false,
      'icon-image': 'arrow-blue',
      'icon-size': 0.5,
      visibility: 'visible',
    },
  };

  const layer2 = {
    id: 'royalSt-layer2',
    paint: { ...paint, 'line-color': colors['secondary-blue-background'] },
    ...routeLine,
  };

  const arrowLayer2 = {
    id: 'royalSt-arrow-layer2',
    type: 'symbol',
    layout: {
      'symbol-placement': 'line',
      'symbol-spacing': 120,
      'icon-allow-overlap': false,
      'icon-image': 'arrow-blue',
      'icon-size': 0.5,
      'icon-rotate': 180,
      visibility: 'visible',
    },
  };

  return (
    <>
      <>
        <Layer
          key={`royalStRoutes-inbound-features-layer1`}
          data={royalStRoutes.features[0]}
          layer={layer1}
        />
        <Layer
          key={`royalStRoutes-arrows-layer1`}
          data={royalStRoutes.features[0]}
          layer={arrowLayer1}
        />
      </>
      <>
        <Layer
          key={`royalStRoutes-inbound-features-layer2`}
          data={royalStRoutes.features[1]}
          layer={layer2}
        />
        <Layer
          key={`royalStRoutes-arrows-layer2`}
          data={royalStRoutes.features[1]}
          layer={arrowLayer2}
        />
      </>
      <>
        <Marker
          latitude={38.795434212551356}
          longitude={-77.0456133367432}
          onClick={() => {
            popAlert("haul_route");
            popDesc('Royal Street');
            popLoc([-77.0456133367432, 38.795434212551356]);
            popIcon(royalStRouteIcon);
            popColor('secondary-blue-background');
            toggleValue(value);
            popValue(!value);

          }}
        >
          <Image display={['block']} src={royalStRouteIcon} />
        </Marker>
      </>
    </>
  );
};

export const RouteSpine = () => {
  const layer = {
    id: 'routeSpine-layer',
    paint: {
      'line-color': colors['primary-blue-background'],
      'line-width': 12,
    },
    ...routeLine,
  };
  return (
    <Layer key={`routeSpine-features-layer`} data={routeSpine} layer={layer} />
  );
};
