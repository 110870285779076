import { React } from 'react';
import { IoSync } from 'react-icons/io5';
import { rotate360 } from 'shared/animations';
import styled, { css as styledcss } from 'styled-components';
import { css } from '@styled-system/css';
import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';

const StyledSpinner = styled('span')(
  ({ inline, fontSize }) =>
    css({
      display: inline ? 'inline-flex' : 'flex',
      flex: 0,
      justifyContent: 'center',
      transformOrigin: 'center',
      fontSize: fontSize ? fontSize : '1.5rem',
    }),
  composedStyledUtilities,
  styledcss`animation: ${rotate360} 2s linear infinite` // necessary to inline the animation keyframes
);

export const Spinner = (props) => (
  <StyledSpinner {...props}>
    <IoSync />
  </StyledSpinner>
);
