import { createContext, React, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_EVENTS as QUERY } from 'shared/queries';

const Context = createContext();

export const EventProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [getData, { loading, error }] = useLazyQuery(QUERY, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (d) => {
      let items = Object.values(d)[0];
      if (!items) return;
      setData(items);
    },
  });

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Context.Provider value={[data, loading, error]}>
      {children}
    </Context.Provider>
  );
};

export const useEvents = () => {
  const context = useContext(Context);
  if (!context)
    throw new Error('useEvents must be used within a EventProvider');
  return context;
};
