import { React } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import {
  Box,
  Flex,
  Heading,
  Image,
  TextLink,
  StyledArticleCard,
  Text,
} from 'shared/components/primitives';

const ProgramUpdateSummaryCardContent = ({ programUpdate }) => {
  const { featuredImage, date, title } = programUpdate;

  const publishDate = date
    ? new Date(date).toLocaleDateString('en-us', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : null;

  return (
    <Flex
      alignItems={['flex-start', null, null, 'stretch']}
      flexDirection={['column', null, null, 'row']}
      flexWrap={['wrap', null, null, 'nowrap']}
    >
      {featuredImage && (
        <Box
          backgroundImage={[
            `url(${featuredImage?.sm})`,
            `url(${featuredImage?.md})`,
            `url(${featuredImage?.lg})`,
          ]}
          backgroundPosition="center"
          backgroundSize="cover"
          width={['full', null, null, '40%']}
          height={['7.5rem', null, null, 'auto']}
          overflow="hidden"
        ></Box>
      )}

      <Flex
        flex={['1', null, null, '60%']}
        flexDirection="column"
        alignItems="flex-start"
        px={['1rem', null, null, '1.5rem']}
        pt={['.825rem', null, null, '2rem']}
        pb={['1.125rem', null, null, '1.5rem']}
      >
        {publishDate && (
          <Text
            color="secondary-text-color"
            pb={['.5rem']}
            fontSize={['xs', 'sm']}
            style={{
              textTransform: 'uppercase',
            }}
          >
            {publishDate}
          </Text>
        )}
        <Heading
          as="h2"
          variant="card"
          mb="auto"
          color="venice-blue-text-color"
          fontSize={['sm', null, 'smplus']}
        >
          {title}
        </Heading>
        <Box>
          <TextLink
            to={{ pathname: `/news/program-updates/${programUpdate.slug}` }}
          >
            More
            <Text
              display="inline-flex"
              pl=".25rem"
              fontSize="smplus"
              color="secondary-text-color"
            >
              <FiArrowRight />
            </Text>
          </TextLink>
        </Box>
      </Flex>
    </Flex>
  );
};

const ProgramUpdateSummaryCard = ({ programUpdate, showSubTitle }) => {
  // Return early if no page content
  if (!programUpdate) return null;

  return (
    <StyledArticleCard flexDirection={['column', null, 'row']}>
      <ProgramUpdateSummaryCardContent
        programUpdate={programUpdate}
        showSubTitle={showSubTitle}
      />
    </StyledArticleCard>
  );
};

export default ProgramUpdateSummaryCard;
