import { gql } from '@apollo/client';

export const GET_TRAFFIC_ALERTS = gql`
  query GET_TRAFFIC_ALERTS {
    trafficAlerts {
      endDate
      id
      message
      startDate
      location {
        distance(from: { latitude: 1.5, longitude: 1.5 })
        latitude
        longitude
      }
      sites {
        name
      }
      link
      alertId
      info {
        html
      }
      show
      alertType
    }
  }
`;

export const GET_LATEST_TRAFFIC_ALERT = gql`
  query GET_LATEST_TRAFFIC_ALERT {
    trafficAlerts(orderBy: publishedAt_DESC, first: 1) {
      id
      message
      location {
        latitude
        longitude
      }
      sites {
        name
      }
    }
  }
`;

export const GET_TRAFFIC_ALERTS_BY_SITE = gql`
  query GET_TRAFFIC_ALERTS_BY_SITE($site: String!) {
    trafficAlerts(where: { sites_every: { name_contains: $site } }) {
      id
      message
      location {
        latitude
        longitude
      }
    }
  }
`;
