import { React, useEffect, useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  Box,
  Flex,
  Heading,
  Section,
  Rule,
  Text,
  IFrame,
} from 'shared/components/primitives';
import MemberCard from 'shared/components/memberCardList/memberCard';
import Container from 'shared/components/container/Container';
import DocumentList from 'shared/components/documents/DocumentList';
import View from 'community/View';
import EventList from 'community/events/EventList';
import {
  DocumentProvider,
  EventProvider,
  GroupMemberProvider,
  useDocuments,
  useEvents,
  useGroupMembers,
  usePage,
} from 'shared/contexts';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/io';
import { useCheckDevice } from 'shared/hooks/hooks';
import { Link } from 'react-router-dom';

function IntroSection() {
  const [{ contentItems }] = usePage();

  return (
    <Section bg="green-background">
      <Container>
        <Flex
          flexDirection={['column', null, null, 'row']}
          px={[null, null, null, '1.5rem', '3rem']}
          color="white"
        >
          <Box
            flexShrink={[null, null, null, 0]}
            flexBasis={['auto', null, null, '40%']}
            width={['full']}
            pb={['1rem', null, null, 0]}
          >
            <Heading as="h2" variant="section" color="white" mb={[0]}>
              {contentItems && ReactHtmlParser(contentItems[0]?.text)}
            </Heading>
          </Box>
          <Box pl={[null, null, null, '2rem']}>
            <Text lineHeight={[1.35]}>
              {contentItems && ReactHtmlParser(contentItems[1]?.html)}
            </Text>
          </Box>
        </Flex>
      </Container>
    </Section>
  );
}

function MemberSection() {
  const [groupMembers] = useGroupMembers(); // get groupMembers
  const [filteredMembers, setFilteredMembers] = useState([]); // create local state to store filtered list of members
  const isMobile = useCheckDevice();

  useEffect(() => {
    setFilteredMembers(groupMembers?.filter((member) => member.stakeholder)); // set local state to filtered list of members who are stakeholders
  }, [groupMembers]);

  return (
    <Section bg="white">
      <Container>
        <Heading as="h2" variant="subSection">
          Who's Who on the 2024-2025 SAG
        </Heading>
        <Rule color="rule-color" />
        {filteredMembers && (
          <CarouselProvider
            isIntrinsicHeight
            infinite={true}
            visibleSlides={isMobile ? 1 : 4}
            totalSlides={filteredMembers.length}
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <ButtonBack
              style={{
                padding: 0,
                color: 'white',
                border: 0,
                backgroundColor: 'white',
                marginRight: '.75rem',
              }}
            >
              <Text
                display="inline-flex"
                color="secondary-text-color"
                fontSize={['lg']}
              >
                <IoIosArrowDropleftCircle />
              </Text>
            </ButtonBack>
            <Slider>
              {filteredMembers.map((member, i) => (
                <Slide index={i} key={member.id}>
                  <Flex alignItems="stretch" height="full" mx={['0.75rem']}>
                    <MemberCard key={member.id} member={member} />
                  </Flex>
                </Slide>
              ))}
            </Slider>
            <ButtonNext
              style={{
                padding: 0,
                color: 'white',
                border: 0,
                backgroundColor: 'white',
              }}
            >
              <Text
                display="inline-flex"
                color="secondary-text-color"
                fontSize={['lg']}
              >
                <IoIosArrowDroprightCircle />
              </Text>
            </ButtonNext>
          </CarouselProvider>
        )}
      </Container>
    </Section>
  );
}

function GroupEvents() {
  const [events, loading, error] = useEvents(); // get events
  const [filteredEvents, setFilteredEvents] = useState([]); // create local state to store filtered list of events

  // memoize the filtering of events
  const sagEvents = useMemo(
    () =>
      events
        .filter((event) => {
          return (
            Date.parse(event.date) >= Date.now() &&
            event.categories.some(
              (category) =>
                category.displayName.includes('Stakeholder Advisory Group') // TODO: How do we utilize this string dynamically?
            )
          );
        })
        .sort((a, b) => {
          return a.date < b.date ? -1 : 1;
        }),
    [events]
  );

  useEffect(() => {
    // set local state to filtered list of events with the 'Stakeholder Advisory Group' category
    setFilteredEvents(sagEvents);
  }, [sagEvents]);

  return (
    <Box width="full" flex="1" pr={[null, null, '1.5rem', '2rem']}>
      <Heading as="h3" variant="sidebar" mb={['2rem']}>
        Upcoming Meetings
      </Heading>
      <EventList error={error} loading={loading} data={filteredEvents} />
    </Box>
  );
}

function GroupDocuments() {
  const [page] = usePage();
  const [documents, loading, error] = useDocuments(); // get documents

  const [sagDocsGroup1, setsagDocsGroup1] = useState([]);
  const [sagDocsGroup2, setsagDocsGroup2] = useState([]);
  const [sagDocsGroup3, setsagDocsGroup3] = useState([]);
  const [sagDocsGroup4, setsagDocsGroup4] = useState([]);
  const [sagDocsGroup5, setsagDocsGroup5] = useState([]);
  const isMobile = useCheckDevice();

  useEffect(() => {

    setsagDocsGroup1(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2024-2025 Stakeholder Advisory Group'
        );
      })
    );

    setsagDocsGroup2(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2023-2024 Stakeholder Advisory Group'
        );
      })
    );

    setsagDocsGroup3(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2022-2023 Stakeholder Advisory Group'
        );
      })
    );

    setsagDocsGroup4(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2021-2022 Stakeholder Advisory Group'
        );
      })
    );

    // set local state to filtered list of documents with the 'Stakeholder Advisory Group' category

    setsagDocsGroup5(
      documents?.filter((document) => {
        return (
          document.group &&
          document.group.length > 0 &&
          document.group === '2019 Stakeholder Advisory Group'
        );
      })
    );

    // set local state to filtered list of documents with the 'Stakeholder Advisory Group' category
  }, [documents]);

  const videoUrl = page && page.videoUrl ? page?.videoUrl : null;
  const videoCaption =
    page && page.contentItems ? page?.contentItems[2]?.text : null;

  return (
    <Box
      width="full"
      flex="1"
      pt={['3rem', null, null, 0]}
      flexGrow={[1, null, null, 0]}
      flexShrink="0"
      flexBasis={['auto', null, null, '28rem']}
    >
      <Box mb={['1.5rem']}>
        <Heading as="h3" variant="sidebar" mb={['2rem']}>
          2024-2025 Stakeholder Advisory Group Meeting Materials
        </Heading>
        {sagDocsGroup1.length > 0 ?
          <DocumentList data={sagDocsGroup1} loading={loading} error={error} />
          : "No Meeting Materials Available"
        }
      </Box>
      <Box mb={['2.5rem']}>
        {videoUrl && !isMobile && (
          <Flex mt={['2.5rem']} flexDirection="column" alignItems="stretch">
            <Box height={['15rem']}>
              <IFrame
                src={videoUrl.startsWith('https://') ? videoUrl : `https://`}
              />
            </Box>
            <Box mt={['1rem']}>
              <Text as="p" color="scarpa-flow-text-color">
                {ReactHtmlParser(videoCaption)}
              </Text>
            </Box>
          </Flex>
        )}
      </Box>
      <Box>
        <Heading as="h3" variant="sidebar" mb={['2rem']}>
          Past Meeting Materials
        </Heading>
        <DocumentList data={sagDocsGroup2} loading={loading} error={error} />
        <DocumentList data={sagDocsGroup3} loading={loading} error={error} />
        <DocumentList data={sagDocsGroup4} loading={loading} error={error} />
        <DocumentList data={sagDocsGroup5} loading={loading} error={error} />
      </Box>
    </Box>
  );
}

function GroupInfoSection() {
  return (
    <Section>
      <Container>
        <Flex
          flexDirection={['column', 'column', null, 'row']}
          alignItems={['flex-start']}
        >
          <EventProvider>
            <GroupEvents />
          </EventProvider>
          <DocumentProvider>
            <GroupDocuments />
          </DocumentProvider>
        </Flex>
      </Container>
    </Section>
  );
}

const StakeholderAdvisoryGroup = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <View>
      <IntroSection />
      <GroupMemberProvider>
        <MemberSection />
      </GroupMemberProvider>
      <GroupInfoSection />
    </View>
  );
};

export default StakeholderAdvisoryGroup;
