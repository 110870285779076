import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import ReactHtmlParser from 'react-html-parser';
import ScriptTag from 'react-script-tag';

import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import ProgramUpdateCard from './ProgramUpdateCard';
import {
  Box,
  Heading,
  Section,
  Text,
  Image,
  ExternalLink,
  Rule,
  IFrame,
  Spinner,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import { SharingLinksGroup } from 'shared/components/sharing';
import { SEO } from 'shared/components/seo/SEO';
import {
  GET_PROGRAM_UPDATE_DETAIL,
  GET_RELATED_PROGRAM_UPDATES,
} from 'shared/queries';
import { Carousel } from 'shared/components/carousel/Carousel';

import groundBreakingLogo from 'shared/assets/images/river-renew-site-project-logo.png';

window._polldaddy = [] || window._polldaddy;

const RelatedUpdatesSection = ({ relatedUpdates }) => {
  return relatedUpdates ? (
    <Section>
      <Container>
        <Heading as="h2" variant="section">
          Related Updates
        </Heading>
        <Box
          display="grid"
          gridTemplateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
          gridAutoFlow="row"
          alignItems="stretch"
          justifyContent="center"
          gridGap={['1.5rem', null, null, '2rem']}
        >
          {relatedUpdates &&
            relatedUpdates
              .sort((a, b) => {
                return b.date < a.date ? -1 : 1; // show next occurring events first
              })
              .slice(0, 3)
              .map((programUpdate) => (
                <ProgramUpdateCard
                  key={programUpdate.id}
                  programUpdate={programUpdate}
                />
              ))}
        </Box>
      </Container>
    </Section>
  ) : null;
};

function ProgramUpdateDetail(props) {
  const [programUpdate, setProgramUpdate] = useState(null);
  const [relatedCategoryList, setRelatedCategoryList] = useState([]);
  const [relatedUpdates, setRelatedUpdates] = useState([]);
  let { id } = useParams();
  let location = window.location.href;

  const [getProgramUpdate, { loading, error }] = useLazyQuery(
    GET_PROGRAM_UPDATE_DETAIL,
    {
      variables: { slug: id },
      fetchPolicy: 'no-cache',
      onCompleted: (d) => {
        setProgramUpdate(d.programUpdate);
        setRelatedCategoryList(
          d.programUpdate.categories.map((cat) => cat.displayName)
        );
      },
    }
  );

  useEffect(() => {
    getProgramUpdate();
  }, [id, getProgramUpdate]);

  const [getRelatedProgramUpdates] = useLazyQuery(GET_RELATED_PROGRAM_UPDATES, {
    variables: { cats: relatedCategoryList },
    fetchPolicy: 'no-cache',
    onCompleted: (d) => {
      setRelatedUpdates(d.programUpdates);
    },
  });

  useEffect(() => {
    getRelatedProgramUpdates();
  }, [getRelatedProgramUpdates, relatedCategoryList]);

  const pageTitle = programUpdate?.title;

  const initSurveyIframe = () => {
    if (!isSurveyApplication(programUpdate.surveyAppId)) return;

    window._polldaddy.push( {
      type: 'iframe',
      auto: '1',
      domain: 'riverrenew.survey.fm',
      id: programUpdate.surveyAppId,
      single_mode: 'undefined',
      placeholder: 'pd_1643305663389'
    } );

    (function(d,c,j){if(!document.getElementById(j)){var pd=d.createElement(c),s;pd.id=j;pd.src='https://app.crowdsignal.com/survey.js';s=document.getElementsByTagName(c)[0];s.parentNode.insertBefore(pd,s);}}(document,'script','pd-embed'));
  };

  const isSurveyApplication = (surveyAppId) => {
    if (!surveyAppId) return false;

    return surveyAppId.toLowerCase().includes('application');
  }

  if (loading) return <Spinner>Loading...</Spinner>;
  if (error) {
    return <Text>Error</Text>;
  }
  if (!programUpdate) {
    return <Text>Must supply a program update.</Text>;
  }

  return (
    <>
      <SEO seo={programUpdate?.seo} />
      <Box bg="light-gray-background">
        <BreadCrumbs pageTitle={pageTitle} />
        <Banner
          pageTitle={pageTitle}
          bannerImageUrl={programUpdate?.featuredImage?.xl}
          imageUrlTablet={programUpdate?.featuredImage?.lg}
          imageUrlMobile={programUpdate?.featuredImage?.md}
          overlay
        >
          <Text color="white" marginTop=".75rem">
            {new Date(programUpdate?.date).toLocaleDateString('en-us', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
            })}
          </Text>
          <SharingLinksGroup
            marginTop=".75rem"
            subject={programUpdate?.title}
            linkUrl={location}
          />
        </Banner>
        <Section bg="white">
          <Container variant="readable">
            {loading && <Spinner>Loading...</Spinner>}
            {error && (
              <>
                <Heading as="h2" variant="section">
                  Error
                </Heading>
                <Text>Something went wrong loading this Program Update.</Text>
              </>
            )}
            {!loading && !error && programUpdate && (
              <>
                <Heading as="h2" variant="section">
                  {programUpdate?.subtitle}
                </Heading>
                <Box
                  display="grid"
                  gridTemplateColumns={[
                    '1fr',
                    null,
                    programUpdate?.surveyScriptUrl && !isSurveyApplication(programUpdate.surveyAppId) ? '1fr .25fr' : null,
                  ]}
                  gridAutoFlow="row"
                  gridGap="1.5rem"
                  justifyContent="center"
                >
                  <Box>
                    {programUpdate?.slug === 'livestream' ? (
                      <Box
                        display="grid"
                        gridTemplateColumns={['1fr', null, '2fr 1fr']}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box>
                          <Text>
                            {ReactHtmlParser(programUpdate?.content?.html)}
                          </Text>
                        </Box>
                        <Box textAlign="right">
                          <ExternalLink href="https://lightroom.adobe.com/gallery/f39f75c7bb5048b385a834483c021505/albums/d32a7a020ac14e599141dc543c22e3e5/assets">
                            <Image src={groundBreakingLogo} width={180} />
                          </ExternalLink>
                        </Box>
                      </Box>
                    ) : (
                      <Text>
                        {ReactHtmlParser(programUpdate?.content?.html)}
                      </Text>
                    )}
                    <Box mt="3rem">
                      <div className="pd-embed" id="pd_1643305663389"></div>
                      {initSurveyIframe()}
                    </Box>
                    {programUpdate?.videoUrl && (
                      <Box overflow="hidden" height="0" pt="56.25%" mt="1rem">
                        <IFrame
                          src={
                            programUpdate?.videoUrl.startsWith('https://')
                              ? programUpdate?.videoUrl
                              : `https://${programUpdate?.videoUrl}`
                          }
                          position="absolute"
                          top="0"
                          left="0"
                          width="full"
                          height="full"
                        />
                      </Box>
                    )}
                  </Box>
                  {programUpdate?.surveyId && !isSurveyApplication(programUpdate.surveyAppId) && programUpdate?.surveyScriptUrl && (
                    <Box>
                      <a
                        name={`pd_a_${programUpdate?.surveyId}`}
                        style={{
                          display: 'inline',
                          padding: '0px',
                          margin: '0px',
                        }}
                      ></a>
                      <Box
                        className="pd-embed"
                        id={`PDI_${programUpdate?.surveyId}`}
                      ></Box>
                      <ScriptTag
                        type="text/javascript"
                        charSet="utf-8"
                        src={programUpdate?.surveyScriptUrl}
                      />
                      <noscript>
                        <a href={`https://survey.fm/${programUpdate?.surveyId}`}>
                          {programUpdate?.surveyTitle}
                        </a>
                      </noscript>
                    </Box>
                  )}
                </Box>
                {(programUpdate?.carouselItems?.length > 0 ||
                  programUpdate?.content2) && <Rule />}
                {programUpdate?.carouselItems?.length > 0 && (
                  <Carousel
                    carousel={{
                      slides: programUpdate?.carouselItems,
                    }}
                  />
                )}
                {programUpdate?.carousels &&
                  programUpdate?.carousels.map((carousel) => (
                    <Carousel carousel={carousel} />
                  ))}
                {programUpdate?.content2 && (
                  <Text>{ReactHtmlParser(programUpdate?.content2?.html)}</Text>
                )}
              </>
            )}
          </Container>
        </Section>
        {relatedUpdates && (
          <RelatedUpdatesSection relatedUpdates={relatedUpdates} />
        )}
      </Box>
    </>
  );
}

export default ProgramUpdateDetail;
