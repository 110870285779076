import { React } from 'react';

import { Box, Flex, Heading } from 'shared/components/primitives';

export const FormPanel = ({ children, title }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      borderRadius={['.25rem']}
      overflow="hidden"
    >
      <Box as="header" bg="dark-background" py={['1rem']} px={['2rem']}>
        <Heading color="white" as="h4" textAlign="center" fontSize={['med']}>
          {title && title}
        </Heading>
      </Box>
      <Box bg="normal-gray-background" py={['1.5rem']} px={['1.5rem']}>
        {children}
      </Box>
    </Flex>
  );
};
