import React, { useState } from 'react';

import ReactMapGL, {
  Layer as ReactMapGLLayer,
  Source,
} from 'react-map-gl';
import {
  TrafficAlertMarker,
  SidewalkMarker,
  ParkingMarker,
  LaneMarker,
} from 'shared/components/map/Marker';
import { Marker } from '../Marker';
import { Image } from 'shared/components/primitives';
import tbmIcon from 'shared/assets/images/tbm.svg';
import { TrafficAlertPopup } from 'shared/components/map/Popup';
import sidewalkMarker from 'shared/assets/images/icon-sidewalk.svg';
import parkingMarker from 'shared/assets/images/icon-parking.svg';
import laneMarker from 'shared/assets/images/icon-lane.svg';
import trafficMarker from 'shared/assets/images/icon-traffic-alert.png';

import { TrafficAlertProvider, useTrafficAlerts } from 'shared/contexts';
import { useToggle } from 'shared/hooks';

export const TrafficAlertFeature = ({ alert }) => {
  const [ value, toggleValue ] = useState(false);
  const { location, show, alertType, ...rest } = alert;
  let AlertMarker;
  let markerIcon;

  switch(alertType) {
    case 'sidewalk':
      AlertMarker = SidewalkMarker;
      markerIcon = sidewalkMarker;
      break;

    case 'parking':
      AlertMarker = ParkingMarker;
      markerIcon = parkingMarker;
      break;

    case 'lane':
      AlertMarker = LaneMarker;
      markerIcon = laneMarker;
      break;
    default:
      AlertMarker = TrafficAlertMarker;
      markerIcon = trafficMarker;
  }

  return (location && show !== false) ? (
    <>
      <AlertMarker
        latitude={location.latitude ? location.latitude : 0}
        longitude={location.longitude ? location.longitude : 0}
        onClick={e => {
          e.stopPropagation();
          toggleValue(!value);
        }}
      />

      <TrafficAlertPopup
        className="popup-yellow"
        icon={markerIcon}
        iconWidth="20%"
        latitude={location.latitude ? location.latitude : 0}
        longitude={location.longitude ? location.longitude : 0}
        alert={alert}
        showPopup={value}
        offsetTop={-40}
        toggleShowPopup={() => toggleValue(!value)}
      />
    </>
  ) : null;
};

const Features = () => {
  const { alerts } = useTrafficAlerts();
  const { alertType } = alert;
  const keysFiltered = ['Lane','Sidewalk','Parking'];
  let markerIcon;

  switch(alertType) {
    case 'sidewalk':
      markerIcon = sidewalkMarker;
      break;

    case 'parking':
      markerIcon = parkingMarker;
      break;

    case 'lane':
      markerIcon = laneMarker;
      break;
    default:
      markerIcon = trafficMarker;
  }

  const filteredAlerts = alerts?.filter(a => a.alertId !== 'tbm');

  const alertsFeatures = filteredAlerts?.map(alert => {
    return {
      'geometry': {
        'coordinates': [alert.location.longitude, alert.location.latitude],
        'type': 'Point',
      },
      'type': 'Feature',
      'properties': {
        'name': alert.message,
        'alert': alert,
        'type': alert.alertType,
      },
      'layout': {
        'icon-image': 'sidewalkMarker',
      },
    };
  });

  const geoAlerts = {
    'type': 'FeatureCollection',
    'features': alertsFeatures,
  };

  const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    source: 'sidewalkClosures',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': ['step', ['get', 'point_count'], '#DAA520', 100, '#f1f075', 750, '#f28cb1'],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
      'circle-stroke-color':'#01375C',
      'circle-stroke-width': 3
    }
  };

  const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'sidewalkClosures2',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 20,
    },
    paint: {
      'text-color': '#01375C',
    }
  };

  const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'symbol',
    source: 'sidewalkClosures2',
    filter: ["all",
      ['in', 'Parking', ['string', ['get', 'name']]],
      ['has','name']
    ],
    'layout': {
      'icon-image': 'parkingMarker',
      'icon-size': 0.35,
    },
  };

  const unclusteredPointLayer2 = {
    id: 'unclustered-point2',
    type: 'symbol',
    source: 'sidewalkClosures2',
    filter: ["all",
      ['in', 'Sidewalk', ['string', ['get', 'name']]],
      ['has','name']
    ],
    'layout': {
      'icon-image': 'sidewalkMarker',
      'icon-size': 0.35,
      'icon-allow-overlap': true,
    },
  };

  const unclusteredPointLayer3 = {
    id: 'unclustered-point3',
    type: 'symbol',
    source: 'sidewalkClosures2',
    'icon-allow-overlap': true,
    filter: ["all",
      ['in', 'Lane', ['string', ['get', 'name']]],
      ['has','name']
    ],
    'layout': {
      'icon-image': 'laneMarker',
      'icon-size': 0.35,
    },
  };

  const unclusteredPointLayer4 = {
    id: 'unclustered-point4',
    type: 'symbol',
    source: 'sidewalkClosures2',
    'icon-allow-overlap': true,
    filter: ["all",
      ['==', ['string', ['get', 'type']], 'generic'],
      ['has','name']
    ],
    'layout': {
      'icon-image': 'trafficMarker',
      'icon-size': 0.75,
    },
  };

  return alerts ? (
    <div>
      <Source
        id="sidewalkClosures2"
        type="geojson"
        cluster={true}
        clusterMaxZoom={15}
        clusterRadius={40}
        data={geoAlerts}
      >
        <ReactMapGLLayer {...clusterLayer} />
        <ReactMapGLLayer {...clusterCountLayer} />
        <ReactMapGLLayer {...unclusteredPointLayer} />
        <ReactMapGLLayer {...unclusteredPointLayer2} />
        <ReactMapGLLayer {...unclusteredPointLayer3} />
        <ReactMapGLLayer {...unclusteredPointLayer4} />
      </Source>
    </div>
  ) : null;
};

export const TrafficAlertFeatures = () => {
  return (
    <TrafficAlertProvider>
      <Features />
    </TrafficAlertProvider>
  );
};
