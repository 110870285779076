import { React } from 'react';

import { Box, Flex, Section, Heading, PillLink, Text } from '../primitives';
import { SiteCard } from './Cards';
import Container from 'shared/components/container/Container';

export const CardSection = ({ children, heading, button, ...props }) => {
  return (
    <Section {...props}>
      <Container>
        <Flex
          flex="1"
          alignContent="center"
          justifyContent="space-between"
          mb={[38, 42]}
        >
          {heading && (
            <Box>
              <Heading as="h3" fontSize="med">
                {heading}
              </Heading>
            </Box>
          )}
          {button && (
            <Box>
              <PillLink to={button.to}>
                <Text>{button.text}</Text>
              </PillLink>
            </Box>
          )}
        </Flex>
        {children}
      </Container>
    </Section>
  );
};

export const SiteCardSection = ({ heading, button, sites, ...props }) => {
  const siteCards = sites?.map((site, index) => (
    <Box
      key={`siteCard-${site?.id}-${index}`}
      display="flex"
      flexGrow={[1, 0, null, 1]}
      flexShrink="1"
      flexBasis={['100%', '50%', null, '25%']}
      px={[0, '0.5rem']}
      py={['0.5rem']}
    >
      <SiteCard site={site} />
    </Box>
  ));

  return (
    <CardSection heading={heading} button={button} {...props}>
      <Flex
        alignItems={['center', 'stretch']}
        justifyContent="space-between"
        flexWrap="wrap"
        my={['-0.5rem']}
        mx={[0, '-0.5rem']}
      >
        {sites && siteCards}
      </Flex>
    </CardSection>
  );
};
