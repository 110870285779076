import { React } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import DocumentLibrary from './documents/DocumentLibrary';
import AboutAlexRenew from './AboutAlexRenew';
import AboutRiverRenew from './AboutRiverRenew';
import HowWaterWorks from './HowWaterWorks';
import { DocumentProvider } from 'shared/contexts';
import { DefaultLayout } from 'shared/routes/DefaultLayout';

const LearnMore = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <DefaultLayout path={`${path}/documents`}>
        <DocumentProvider>
          <DocumentLibrary />
        </DocumentProvider>
      </DefaultLayout>
      <DefaultLayout path={`${path}/about-alex-renew`}>
        <AboutAlexRenew />
      </DefaultLayout>
      <DefaultLayout path={`${path}/about-river-renew`}>
        <AboutRiverRenew />
      </DefaultLayout>
      <DefaultLayout path={`${path}/how-water-works`}>
        <HowWaterWorks />
      </DefaultLayout>
    </Switch>
  );
};

export default LearnMore;
