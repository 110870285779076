import styled from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';

import { composedStyledUtilities } from 'shared/utils/composedStyledUtilities';
import { loadingPulse } from 'shared/animations';

export const Box = styled('div')(
  (props) =>
    css({
      position: 'relative',
    }),
  variant({
    variants: {
      dateChip: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        top: '1.125rem',
        left: '1.5rem',
        width: ['5rem', '5.625rem'],
        height: ['5rem', '5.625rem'],
        padding: '.75rem',
        borderRadius: '999px',
        background: '#79A440',
        color: 'white',
        textAlign: 'center',
        textTransform: 'uppercase',

        '& span': {
          display: 'block',
          fontSize: '17px',
          fontWeight: '500',
          lineHeight: '17px',

          '& + span': {
            fontSize: '32px',
            fontWeight: '700',
            lineHeight: '39px',
          },
        },
      },
      pill: {
        border: '1px solid white-border',
        borderRadius: 'pill',
        color: 'white',
        display: 'inline-block',
        fontSize: 'xs',
        fontWeight: 'medium',
        lineHeight: 1,
        px: '.625rem',
        pt: '.25rem',
        pb: '.1875rem',
        textTransform: 'uppercase',
      },
    },
  }),
  composedStyledUtilities
);

export const StyledContainer = styled(Box)(
  (props) =>
    css({
      width: 'full',
      maxWidth: [null, null, null, '80rem', '90rem'],
      px: props?.px ? props.px : ['2rem', null, null, null, '4rem'],
      m: '0 auto',
    }),
  variant({
    variants: {
      readable: {
        maxWidth: [null, null, null, '67.5rem', null],
      },
    },
  })
);

export const StyledLoadingBox = styled(Box)`
  animation: ${loadingPulse} 1000ms infinite linear;
`;

export const BoxWithHoverBox = styled('div')(
  (props) =>
    css({
      position: 'relative',
      'div:first-child': {
        display: 'none',
      },
      '&:hover div:first-child': {
        display: 'block',
        visibility: 'visible',
      },
    }),
  composedStyledUtilities
);

export const BoxWithArrowRight = styled('div')(
  (props) =>
    css({
      '&::after': {
        content: JSON.stringify(''),
        position: 'absolute',
        left: '100%',
        top: '45%',
        borderTop: '20px solid transparent',
        borderLeft: '20px solid #01375C',
        borderBottom: '20px solid transparent',
      },
    }),
  composedStyledUtilities
);

export const BoxWithArrowLeft = styled('div')(
  (props) =>
    css({
      '&::after': {
        content: JSON.stringify(''),
        position: 'absolute',
        right: '100%',
        top: '45%',
        borderTop: '20px solid transparent',
        borderRight: '20px solid #01375C',
        borderBottom: '20px solid transparent',
      },
    }),
  composedStyledUtilities
);

export const BoxWithArrowTop = styled('div')(
  (props) =>
    css({
      '&::after': {
        content: JSON.stringify(''),
        position: 'absolute',
        right: '45%',
        bottom: '100%',
        borderLeft: '20px solid transparent',
        borderBottom: '20px solid #01375C',
        borderRight: '20px solid transparent',
      },
    }),
  composedStyledUtilities
);

export const BoxWithArrowBottom = styled('div')(
  (props) =>
    css({
      '&::after': {
        content: JSON.stringify(''),
        position: 'absolute',
        right: '45%',
        top: '100%',
        borderLeft: '20px solid transparent',
        borderTop: '20px solid #01375C',
        borderRight: '20px solid transparent',
      },
    }),
  composedStyledUtilities
);
