import { Layer } from 'shared/components/map/Layer';

import { sidewalkClosures } from '../geojson';

const layer = {
  id: 'sidewalk-closures',
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#D65641',
    'line-width': 5,
  },
};

export const SidewalkClosuresFeature = () => {
  return (
    <Layer
      key={`sidewalkClosures-layer`}
      data={sidewalkClosures}
      layer={layer}
    />
  );
};
