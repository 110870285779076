import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Image } from 'shared/components/primitives';
import { Marker } from '../Marker';
import { Popup } from '../Popup';

import { useToggle } from 'shared/hooks';

import { pumpingStation } from '../geojson';
import markerSVG from 'shared/assets/images/icon-marker-orange.svg';

export const Feature = ({ site }) => {
  const { geometry, properties } = site;
  let latitude = geometry.coordinates[1];
  let longitude = geometry.coordinates[0];

  const { value, toggleValue } = useToggle();

  return (
    <>
      <Marker
        latitude={latitude}
        longitude={longitude}
        onClick={toggleValue}
        children={<Image display={['block']} src={markerSVG} />}
      />

      <Popup
        latitude={latitude}
        longitude={longitude}
        content={properties.name}
        showPopup={value}
        stripeColor="orange-background"
        icon={markerSVG}
        hideButton={true}
        toggleShowPopup={toggleValue}
      />
    </>
  );
};

const Features = () => {
  const [features, setFeatures] = useState([]);
  const mounted = useRef();

  const featuresMemo = useMemo(
    () => <Feature key={`pumpingStation-feature`} site={pumpingStation} />,
    []
  );

  useEffect(() => {
    mounted.current = true;
    if (mounted.current === true) {
      setFeatures(featuresMemo);
    }
    return () => {
      mounted.current = false;
    };
  }, [featuresMemo, setFeatures]);

  return features ? features : null;
};

export const PumpingStationFeature = () => {
  return <Features />;
};
