import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  background,
  border,
  compose,
  color,
  flex,
  layout,
  space,
  typography,
  variant,
  fontSize,
} from 'styled-system';
import css from '@styled-system/css';

const composedStyleProps = compose(
  background,
  border,
  color,
  flex,
  layout,
  space,
  typography,
  fontSize
);

export const TextLink = styled(RouterLink)(
  (props) =>
    css({
      color: props?.color ? props.color : 'default-text',
      alignItems: 'center',
      border: '0',
      backgroundColor: 'transparent',
      display: 'inline-flex',
      fontSize: props?.fontSize ? props.fontSize : 'xs',
      fontWeight: props?.fontWeight ? props.fontWeight : 'bold',
      lineHeight: 'sm',
      textDecoration: props?.textDecoration ? props.textDecoration : 'none',
      textTransform: props?.textTransform ? props.textTransform : 'uppercase',
      transitionDuration: ' 250ms',
      transitionProperty: 'color, border-color',
      transitionTimingFunction: ' cubic-bezier(0.23, 1, 0.320, 1)',
    }),
  variant({
    variants: {
      light: {
        color: 'link-light-color',
        '&:hover, &:focus': {
          color: 'link-light-hover-color',
          borderColor: 'link-light-hover-color',
        },
      },
      white: {
        color: 'link-white-color',
        '&:hover, &:focus': {
          color: 'link-light-hover-color',
          borderColor: 'link-light-hover-color',
        },
        '&:visited': {
          color: 'link-white-color',
        },
      },
      dark: {
        color: 'link-dark-color',
        '&:hover, &:focus': {
          color: 'link-dark-hover-color',
          borderColor: 'link-dark-hover-color',
        },
      },
    },
  })
);

export const PillLink = styled(TextLink)(
  (props) =>
    css({
      display: 'inline-flex',
      alignItems: 'center',
      px: ['1.5rem', null, '2rem'],
      py: ['.625rem', '.75rem', null, '1rem'],
      border: '0',
      borderRadius: 'pill',
      backgroundColor: 'pill-link-background-color',
      color: 'pill-link-color',
      fontSize: 'xxs',
      fontWeight: 'bold',
      lineHeight: 'sm',
      textTransform: 'uppercase',
      whiteSpace: 'noWrap',
      '&:hover': {
        color: 'white',
      },
      '&:focus': {
        color: 'white',
        outline: 'none',
        boxShadow: '0 0 0 2px #005fcc, 0 0 0 4px white',
      },
    }),
  composedStyleProps
);

export const StyledNavLink = styled(RouterNavLink)(
  (props) =>
    css({
      color: 'inherit',
      fontSize: 'xs',
      lineHeight: 'med',
      textDecoration: 'none',
      transitionProperty: 'color, background-color, border-color',
      transitionDuration: ' 250ms',
      transitionTimingFunction: ' cubic-bezier(0.23, 1, 0.320, 1)',
      '&:hover, &:focus': {
        textDecoration: 'underline',
      },
    }),
  (props) =>
    variant({
      variants: {
        primary: {
          color: 'nav-link-color',
          fontWeight: 'bold',
          px: props?.px ? props.px : [null, null, null, '.5rem', '.75rem'],
          textTransform: 'uppercase',
          '&:hover, &:focus': {
            color: 'nav-link-hover-color',
          },
        },
        mobileHeaderMenu: {
          marginBottom: '.75rem',
          fontSize: 'sm',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
        subMenu: {
          color: 'nav-link-color',
          fontWeight: 'semibold',
          '&:hover, &:focus': {
            color: 'nav-link-hover-color',
          },
        },
        mobileFooterSubMenu: {
          display: 'block',
          px: props?.px ? props.px : ['2rem', '1.5rem', null, '.5rem'],
          py: props?.py ? props.py : ['1rem', null, null, '.25rem'],
          borderRadius: '.25rem',
          color: props?.color ? props?.color : 'white !important',
          fontWeight: '400 !important',
          textTransform: 'capitalize',
          '&:hover, &:focus': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, .125)',
          },
        },
        mobileHeaderSubMenu: {
          color: 'white-text',
          fontSize: 'sm',
          fontWeight: 'semibold',
        },
        sitemap: {
          marginBottom: '.75rem',
          fontSize: 'med',
          fontWeight: 'bold',
          textTransform: 'capitalize',
        },
        sitemapSubMenu: {
          fontSize: 'smplus',
          lineHeight: '1.5125',
        },
      },
    }),
  composedStyleProps
);

export const StyledNavExternalLink = styled('a').attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})(
  (props) =>
    css({
      color: 'inherit',
      fontSize: 'xs',
      lineHeight: 'med',
      textDecoration: 'none',
      transitionProperty: 'color, background-color, border-color',
      transitionDuration: ' 250ms',
      transitionTimingFunction: ' cubic-bezier(0.23, 1, 0.320, 1)',
      '&:hover, &:focus': {
        textDecoration: 'underline',
      },
    }),
  (props) =>
    variant({
      variants: {
        primary: {
          color: 'nav-link-color',
          fontWeight: 'bold',
          px: props?.px ? props.px : [null, null, null, '.5rem', '.75rem'],
          textTransform: 'uppercase',
          '&:hover, &:focus': {
            color: 'nav-link-hover-color',
          },
        },
        subMenu: {
          color: 'nav-link-color',
          fontWeight: 'semibold',
          '&:hover, &:focus': {
            color: 'nav-link-hover-color',
          },
        },
        mobileFooterSubMenu: {
          display: 'block',
          px: props?.px ? props.px : ['2rem', '1.5rem', null, '.5rem'],
          py: props?.py ? props.py : ['1rem', null, null, '.25rem'],
          borderRadius: '.25rem',
          color: props?.color ? props?.color : 'white !important',
          fontWeight: '400 !important',
          textTransform: 'capitalize',
          '&:hover, &:focus': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, .125)',
          },
        },
        mobileHeaderSubMenu: {
          color: 'white-text',
          fontSize: 'sm',
          fontWeight: 'semibold',
        },
        text: {
          color: props?.color ? props.color : 'default-text',
          alignItems: 'center',
          border: '0',
          backgroundColor: 'transparent',
          display: 'inline-flex',
          fontSize: props?.fontSize ? props.fontSize : 'xs',
          fontWeight: props?.fontWeight ? props.fontWeight : 'bold',
          lineHeight: 'sm',
          textDecoration: props?.textDecoration ? props.textDecoration : 'none',
          textTransform: props?.textTransform
            ? props.textTransform
            : 'uppercase',
          transitionDuration: ' 250ms',
          transitionProperty: 'color, border-color',
          transitionTimingFunction: ' cubic-bezier(0.23, 1, 0.320, 1)',
        },
      },
    }),
  composedStyleProps
);

export const BreadCrumbLink = styled(({ current, ...props }) => (
  <RouterNavLink {...props} />
))(
  (props) =>
    css({
      color: `${
        props.current
          ? 'breadcrumb-current-link-color'
          : 'breadcrumb-link-color'
      }`,
      fontSize: 'xs',
      fontWeight: 'bold',
      textDecoration: `${props.current ? 'underline' : 'none'}`,
      textTransform: 'capitalize',
      '&:hover, &:focus': {
        textDecoration: 'underline',
      },
    }),
  composedStyleProps
);

export const ExternalLink = styled('a').attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})(
  (props) =>
    css({
      display: 'inline-flex',
      alignItems: 'center',
      m: '0.5rem 1rem',
      background: 'transparent',
      color: props.color || 'link-light-color',
      fontSize: '0.75rem',
      textDecoration: 'none',
      fontWeight: 'bold',
    }),
  (props) =>
    variant({
      variants: {
        primary: {
          fontWeight: 'bold',
          px: [8],
          textTransform: 'uppercase',
          '&:hover, &:focus': {
            color: 'nav-link-hover-color',
          },
        },
        dark: {
          backgroundColor: 'transparent',
          color: 'nav-link-color',
          fontSize: 14,
          fontWeight: 600,
          lineHeight: '17px',
          textTransform: 'uppercase',
          whiteSpace: 'noWrap',
          '&:hover': {
            color: 'white',
          },
          '&:focus': {
            color: 'white',
            outline: 'none',
            boxShadow: '0 0 0 2px #005fcc, 0 0 0 4px white',
          },
        },
        pill: {
          px: ['1.5rem', null, '2rem'],
          py: ['.625rem', '.75rem', null, '1rem'],
          m: 0,
          border: '0',
          borderRadius: 'pill',
          backgroundColor: 'pill-link-background-color',
          color: props.color ? props.color : 'white',
          fontSize: 'xs',
          fontWeight: 'bold',
          lineHeight: 'sm',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          '&:hover': {
            color: 'white',
          },
          '&:focus': {
            color: 'white',
            outline: 'none',
            boxShadow: '0 0 0 2px #005fcc, 0 0 0 4px white',
          },
        },
      },
    }),
  composedStyleProps
);
