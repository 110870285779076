import { gql } from '@apollo/client';

export const GET_MEDIA_MENTIONS = gql`
  query GET_MEDIA_MENTIONS {
    mediaMentions(
      where: { categories_every: { displayName_not: "Unlisted" } }
      orderBy: date_DESC
    ) {
      id
      organization
      publishedAt
      title
      date
      url
      categories {
        color
        displayName
      }
    }
  }
`;
