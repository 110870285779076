import { React, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useLazyQuery } from '@apollo/client';
import { AiFillCloseCircle } from 'react-icons/ai';

import { GET_PAGE_BY_TITLE, GET_ASSET_BY_ID } from 'shared/queries';
import {
  Box,
  Button,
  Image,
  Text,
  ListItem,
  IconButton,
  Section,
  Spinner,
} from 'shared/components/primitives';
import Container from 'shared/components/container/Container';
import Banner from 'shared/components/banner/Banner';
import BreadCrumbs from 'shared/components/breadCrumbs/BreadCrumbs';
import { Modal } from 'shared/components/modal/Modal';

import { useToggle } from 'shared/hooks';

const AlexRenewModal = ({ closeModal, isOpen, imageUrl }) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <Container>
        <Box backgroundColor="normal-gray-background">
          <Box
            as="header"
            display="flex"
            py=".75rem"
            px="1.5rem"
            justifyContent="flex-end"
          >
            <IconButton background="none" px="0">
              <Text
                display="flex"
                color="icon-background-dark"
                fontSize="med"
                lineHeight="sm"
                onClick={(e) => closeModal(e)}
              >
                <AiFillCloseCircle />
              </Text>
            </IconButton>
          </Box>
          <Box px="1.5rem" pb="1.5rem" textAlign="center">
            <Image
              src={imageUrl}
              display="block"
              width={['auto', null, null, 'full']}
              height={['36rem', null, null, 'auto']}
              mx={['auto']}
              style={{ objectFit: 'cover' }}
            />
          </Box>
        </Box>
      </Container>
    </Modal>
  );
};

const AboutAlexRenew = () => {
  const [contentItems, setContentItems] = useState([]);
  const [imageUrl, setImageUrl] = useState('');
  const { value, toggleValue } = useToggle();

  // toggle modal closed if the outer container is clicked
  function closeModal(e) {
    e.stopPropagation();

    toggleValue();
  }

  const [getPageData, { loading, error, data }] = useLazyQuery(
    GET_PAGE_BY_TITLE,
    {
      variables: { title: 'About AlexRenew' },
      fetchPolicy: 'no-cache',
      onCompleted: (d) => {
        setContentItems(d.pages[0].contentItems);
      },
    }
  );

  const [getImageUrl] = useLazyQuery(GET_ASSET_BY_ID, {
    variables: { id: 'cklnwrwu0ejfy0a84k999gje9' },
    fetchPolicy: 'no-cache',
    onCompleted: (d) => {
      setImageUrl(d.assets[0].url);
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getPageData();
    getImageUrl();
  }, []);

  if (loading) return <Spinner>Loading...</Spinner>;
  if (error)
    return (
      <ListItem>
        <Text>Error</Text>
      </ListItem>
    );
  if (!data || contentItems.length === 0)
    return (
      <ListItem>
        <Text>No data available yet.</Text>
      </ListItem>
    );

  return (
    <>
      <Box bg="light-gray-background">
        <Banner />
        <BreadCrumbs />
        <Section>
          <Container>
            <Box
              display="grid"
              gridTemplateColumns={['1fr', null, '1fr 1.5fr']}
              gridAutoFlow="row"
              gridGap={['1.5rem', null, '2rem', '3rem']}
              alignItems="center"
              justifyContent="center"
            >
              <Box>{ReactHtmlParser(contentItems[0].html)}</Box>
              <Box borderRadius={['.25rem']} overflow="hidden">
                <Box
                  style={{
                    overflowX: 'scroll',
                  }}
                >
                  <Button variant="link" onClick={toggleValue} display="block">
                    <Image
                      src={imageUrl}
                      display="block"
                      width={['auto', null, null, 'full']}
                      height={['36rem', null, null, 'auto']}
                      mx={['auto']}
                      style={{ objectFit: 'cover' }}
                    />
                  </Button>
                </Box>
                <Box
                  py={['1.5rem', null, '2rem']}
                  px={['2rem', null, '3rem']}
                  bg={['normal-gray-background']}
                >
                  <Text color="gray-text" fontSize="sm">
                    {ReactHtmlParser(contentItems[1].html)}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Container>
        </Section>
      </Box>
      <AlexRenewModal
        isOpen={value}
        closeModal={closeModal}
        imageUrl={imageUrl}
      />
    </>
  );
};

export default AboutAlexRenew;
